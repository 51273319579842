<template>
	<div class="content">
		<header class="content__header">
			<slot name="header"></slot>
		</header>
		<main class="content__body">
			<slot name="body"></slot>
		</main>
		<footer class="content__footer">
			<slot name="footer"></slot>
		</footer>
	</div>
</template>

<script>
export default {
	name: 'Content',
};
</script>

<style></style>

<style lang="scss">
@import 'Content.scss';
</style>
