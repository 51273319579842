import { render, staticRenderFns } from "./CourrierTable.vue?vue&type=template&id=233c38ac&scoped=true"
import script from "./CourrierTable.vue?vue&type=script&lang=js"
export * from "./CourrierTable.vue?vue&type=script&lang=js"
import style0 from "./CourrierTable.vue?vue&type=style&index=0&id=233c38ac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "233c38ac",
  null
  
)

export default component.exports