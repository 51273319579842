<template>
	<GenericMultiselect
		class="container-multiselect"
		ref="container"
		:options="containerOptions"
		:placeholder="$t('modules.load_management.filter.container')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="false"
		:infiniteScroll="true"
		:type="'container'"
		:multiple="true"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CONTAINER_FILTER_REQUEST,
	SAVE_CONTAINER_FILTER,
} from '@/store/modules/filtersByModules/loadManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'ContainerInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('loadManagementFilters', {
			getContainerFilter: CONTAINER_FILTER_REQUEST,
			saveContainerFilter: SAVE_CONTAINER_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveContainerFilter(param);

			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getContainerFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		nextPage() {
			const meta = this.containerFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getContainerFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		createQueryString(containerNumber) {
			const query = new URLSearchParams();

			if (containerNumber) query.set('container', containerNumber);
			if (containerNumber) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.container.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.containerFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.container.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('loadManagementFilters', [
			'containerFilter',
			'containerFilterMeta',
			'containerFilterCache',
			'isLoadingContainerFilter',
			'lastQueryString',
		]),
		containerOptions() {
			const rows = this.containerFilter != undefined ? this.containerFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'ContainerInput.scss';
</style>
