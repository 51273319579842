<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 21 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.5271 21H14.603H6.39696H4.47291C2.55488 21 1 19.4607 1 17.5618V8.84736C1.00739 8.09967 1.36226 7.39702 1.96203 6.94256L8.51342 1.6853C9.6662 0.771566 11.3049 0.771566 12.4577 1.6853L19.038 6.93303C19.6355 7.38935 19.9898 8.09083 20 8.83784V17.5618C20 19.4607 18.4451 21 16.5271 21Z"
			stroke="#B47D4A"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.87145 10.2017V17.0618"
			:stroke="color"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.5382 6.91919V17.0619"
			:stroke="color"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.1285 13.8269V17.0619"
			:stroke="color"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#B47D4A',
		},
		height: {
			default: '22',
		},
		width: {
			default: '21',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
