<template>
	<div id="reset-password">
		<div class="lang">
			<LangSelector :isLogin="true" ref="langSelector" />
		</div>
		<div class="background">
			<img class="background__img" :src="imgPath" />
		</div>
		<div class="container">
			<div class="logo" v-if="isWeb">
				<CompanyLogo class="logo__img" />
				<p class="logo__text">Customer <span class="logo__text-break">service</span> <span class="logo__text-break">portal</span></p>
			</div>
			<div class="back-btn" v-if="!isWeb" @click="goToLogin()">
				<BackArrow />
			</div>
			<NewPasswordForm @showMessageScreen="showMessageScreen" v-if="!isChangingPassword" />
			<SuccessMessage :text="$t('modules.resetPassword.password_changed_succesfully')" v-else />
		</div>
		<Version />
	</div>
</template>

<script>
import LangSelector from '@/components/shared/LangSelector/LangSelector.vue';
import NewPasswordForm from '@/components/modules/RecoverPassword/NewPasswordForm/NewPasswordForm.vue';
import CompanyLogo from '@/components/shared/Icons/CompanyLogo.vue';
import SuccessMessage from '@/components/shared/SuccessMessage/SuccessMessage.vue';
import Version from '@/components/shared/Version/Version.vue';
import BackArrow from '@/components/shared/Icons/BackArrow.vue';

export default {
	name: 'RecoverPassword',
	components: {
		LangSelector,
		NewPasswordForm,
		CompanyLogo,
		SuccessMessage,
		Version,
		BackArrow,
	},
	data: () => ({
		windowWidth: window.innerWidth,
		isChangingPassword: false,
	}),
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		goToLogin() {
			this.$router.push('/login');
		},
		showMessageScreen() {
			this.isChangingPassword = !this.isChangingPassword;
			setTimeout(() => {
				this.$router.push('/login');
			}, 3500);
		},
	},
	computed: {
		isWeb() {
			return this.windowWidth > 992;
		},
		imgPath() {
			const web = require('@/assets/home.png');
			const mobile = require('@/assets/home-mobile.png');

			return this.isWeb ? web : mobile;
		},
	},
};
</script>

<style lang="scss">
@import 'RecoverPassword.scss';
</style>
