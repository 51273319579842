<template>
	<svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10 1.5L5.5 5.5L1 1.5"
			:stroke="color"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: '#234057',
		},
	},
};
</script>
