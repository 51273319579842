<template>
	<div id="contractTable">
		<div class="table-container" v-if="contractTable.length != 0 && !isLoadingContractTable">
			<table class="table">
				<thead class="table__header">
					<template v-for="(row, index) in contractTable">
						<tr :key="index" v-if="index == 0">
							<template v-for="(item, key) in userPreferences.detail">
								<th :key="key" v-if="item.active">
									{{ $t(`modules.contract_management.table.detail.${item.name}`) }}
								</th>
							</template>
						</tr>
					</template>
				</thead>
				<tbody>
					<template v-for="row in contractTable">
						<tr :key="row.id" @click="handleContractDetail(row.id, contractId)">
							<template
								v-for="(item, key, index) in userPreferences.detail"
								:class="[index % 2 == 1 ? 'stripped' : '']"
							>
								<td :key="key" v-if="item.active">
									{{ formatString(row[item.name], item) }}
								</td>
							</template>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<div class="loader" v-if="isLoadingContractTable">
			<SpinnerIcon />
		</div>
		<div class="no__data" v-if="contractTable.length == 0 && !isLoadingContractTable">
			{{ $t('errors.empty_data') }}
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterTable from '@/components/shared/Icons/FilterTable.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import moment from 'moment';

export default {
	name: 'Table',
	components: {
		FilterTable,
		SpinnerIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
		contractId: {
			required: true,
		},
	},
	data: () => ({
		isLoading: false,
	}),
	mounted() {},
	methods: {
		formatString(param, item) {
			let value;
			param == null ? (value = '') : (value = param);

			if (
				value.toString().includes('-') &&
				new Date(value) !== 'Invalid Date' &&
				!isNaN(new Date(value)) &&
				param.split('-')[0].match(/^[0-9]+$/)
			) {
				value = moment(param).format('DD/MM/YYYY');
			}

			if (item.name == 'status') {
				value = this.getStatusTranslation(param);
			}

			if (item.name == 'unitPrice' && param != '') {
				value = this.formatMoney(value);
			}

			if (item.name == 'totalKg' && param != '') {
				value = param.toLocaleString(undefined, {
					minimumFractionDigits: 3,
					maximumFractionDigits: 3,
				});
			}

			return value;
		},
		handleContractDetail(id, contractId) {
			this.$router.push({
				path: '/main/contractDetail/',
				query: { id: id, contractId: contractId },
			});
		},
		getStatusTranslation(status) {
			return {
				'EM PROCESSAMENTO': this.$t('contract_step.processing'),
				'RESTRIÇÕES CLIENTE': this.$t('contract_step.released_customer_restriction'),
				'EM PRODUÇÃO': this.$t('contract_step.in_production'),
				'EM CARREGAMENTO': this.$t('contract_step.in_loading'),
				'GATE IN ORIGEM': this.$t('contract_step.gate_in'),
				'AGUARDA PRÉ PGTO': this.$t('contract_step.pre_payment_pending'),
				EMBARCADO: this.$t('contract_step.boarded'),
				'DOCUMENTAÇÃO ENVIADA': this.$t('contract_step.documentation_sended'),
				'DOCUMENTAÇÃO ENTREGUE': this.$t('contract_step.documentation_delivered'),
				'ENTREGUE NO POD': this.$t('contract_step.delivered_in_pod'),
				'GATE OUT DESTINO': this.$t('contract_step.gate_out'),
				'VAZIO DEVOLVIDO': this.$t('contract_step.empty_return'),
				ABERTA: this.$t('contract_step.opened'),
				'ABERTA EM ATRASO': this.$t('contract_step.opened_delay'),
				'PAGO PARCIAL': this.$t('contract_step.partial_paid'),
				'PAGO TOTAL': this.$t('contract_step.total_paid'),
				FINALIZADO: this.$t('contract_step.finished'),
			}[status].toUpperCase();
		},
		formatMoney(value) {
			switch (this.userLang) {
				case 'pt-br':
					return value.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					});
				case 'en-us':
					return value.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
					});
				case 'es-es':
					return value.toLocaleString('en-US', {
						style: 'currency',
						currency: 'EUR',
					});
				default:
					return value;
			}
		},
	},
	computed: {
		...mapGetters('contractManagement', [
			'contractTable',
			'isLoadingContractTable',
			'userPreferences',
		]),
		...mapGetters('user', ['userLang']),
	},
};
</script>

<style scoped lang="scss">
@import 'Table.scss';
</style>
