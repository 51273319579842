<template>
	<div class="card-wrapper">
		<div class="card__content">
			<div class="card__icon" v-if="statusSuccess">
				<CogIcon v-if="icon == 'cog'" />
				<LiftIcon v-if="icon == 'lift'" />
				<TruckIcon v-if="icon == 'truck'" />
				<ShipIcon v-if="icon == 'ship'" />
				<AnchorIcon v-if="icon == 'anchor'" />
				<CheckIcon v-if="icon == 'check'" />
			</div>
			<div class="card__info" v-if="statusSuccess">
				<span class="card__info-title">{{ this.$t(title) }}</span>
				<ul class="card__info-list">
					<li v-if="seed.qtyContract || seed.qtyContract == '0'">
						<span>
							{{ $t('modules.load_management.map.contracts') }}
						</span>
						<span>{{ seed.qtyContract }}</span>
					</li>
					<li v-if="seed.qtyProduct || seed.qtyProduct == '0'">
						<span>
							{{ $t('modules.load_management.map.products') }}
						</span>
						<span>{{ seed.qtyProduct }}</span>
					</li>
					<li v-if="seed.qtyLoad || seed.qtyLoad == '0'">
						<span>
							{{ $t('modules.load_management.map.loads') }}
						</span>
						<span>{{ seed.qtyLoad }}</span>
					</li>
					<li v-if="seed.qtyWeight || seed.qtyWeight == '0'">
						<span>
							{{ $t('modules.load_management.map.weigth') }}
						</span>
						<span>{{ roundWeight(seed.qtyWeight) }} t</span>
					</li>
				</ul>
			</div>
		</div>
		<span class="card__info-alert" v-if="statusSuccess && icon == 'truck'">
			{{ $t('modules.load_management.map.awaiting_payment') }}
		</span>
		<div class="card__loader" v-if="statusLoading">
			<SpinnerIcon />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import CogIcon from '@/components/shared/Icons/MapIcons/CogIcon.vue';
import LiftIcon from '@/components/shared/Icons/MapIcons/LiftIcon.vue';
import TruckIcon from '@/components/shared/Icons/MapIcons/TruckIcon.vue';
import ShipIcon from '@/components/shared/Icons/MapIcons/ShipIcon.vue';
import AnchorIcon from '@/components/shared/Icons/MapIcons/AnchorIcon.vue';
import CheckIcon from '@/components/shared/Icons/MapIcons/CheckIcon.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';

export default {
	name: 'MapCard',
	props: {
		icon: {
			type: String,
			default: 'cog',
		},
		title: {
			type: String,
			default: 'cog',
		},
		seed: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	components: {
		CogIcon,
		LiftIcon,
		TruckIcon,
		ShipIcon,
		AnchorIcon,
		CheckIcon,
		SpinnerIcon,
	},
	data: () => ({
		showContent: false,
	}),
	created() {},
	mounted() {},
	beforeDestroy() {},
	methods: {
		handleContent() {
			this.showContent = !this.showContent;
		},
		roundWeight(value) {
			const normalizedValue = value.toFixed(2);
			return normalizedValue.toString().replace('.', ',');
		},
	},
	computed: {
		...mapGetters('loadManagement', ['isLoadingMapStatus']),
		statusSuccess() {
			return this.isLoadingMapStatus === 'success';
		},
		statusLoading() {
			return this.isLoadingMapStatus === 'loading';
		},
	},
};
</script>

<style lang="scss">
@import 'MapCard.scss';
</style>
