<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3.07073 1.83337H11.2291C11.9074 1.83337 12.4666 2.39254 12.4666 3.07087V4.42754C12.4666 4.92254 12.1549 5.53671 11.8524 5.84837L9.19406 8.19504C8.8274 8.50671 8.5799 9.12087 8.5799 9.61587V12.2742C8.5799 12.6409 8.3324 13.1359 8.02073 13.3284L7.15906 13.8875C6.3524 14.3825 5.24323 13.8234 5.24323 12.8334V9.56087C5.24323 9.13004 4.99573 8.57087 4.74823 8.25921L2.40156 5.78421C2.0899 5.47254 1.8424 4.92254 1.8424 4.54671V3.12587C1.83323 2.39254 2.3924 1.83337 3.07073 1.83337Z"
			:stroke="color"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M1.8335 11V13.75C1.8335 18.3333 3.66683 20.1667 8.25016 20.1667H13.7502C18.3335 20.1667 20.1668 18.3333 20.1668 13.75V8.25001C20.1668 5.39001 19.4518 3.59334 17.7927 2.65834C17.3252 2.39251 16.3902 2.19084 15.5377 2.05334M11.9168 11.9167H16.5002M10.0835 15.5833H16.5002"
			:stroke="color"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#B47D4A',
		},
		height: {
			default: '22',
		},
		width: {
			default: '22',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
