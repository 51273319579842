<template>
	<div id="contract-detail-blocks">
		<div class="loader" v-if="isLoadingContractItem">
			<SpinnerIcon />
		</div>
		<div class="contract-detail-blocks" v-else>
			<ContractDetailCard
				:isWeb="isWeb"
				:title="$t('modules.contract_detail.card.title_contract')"
				:data="contractItemList.contract"
				:canApprove="contractItemList.canApprove"
				:canDownload="contractItemList.canDownload"
				:actionInfo="contractItemList.actionInfo"
				:isContract="true"
				:labelFlag="'contract'"
			/>
			<ContractDetailCard
				:isWeb="isWeb"
				:title="$t('modules.contract_detail.card.title_sequence')"
				:data="contractItemList.sequence"
				:labelFlag="'sequence'"
			/>
			<ContractDetailCard
				:isWeb="isWeb"
				:title="$t('modules.contract_detail.card.title_load')"
				:data="contractItemList.load"
				:labelFlag="'load'"
			/>
			<ContractDetailCard
				:isWeb="isWeb"
				:title="$t('modules.contract_detail.card.title_document')"
				:data="contractItemList.documents"
				:labelFlag="'documents'"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Content from '@/components/shared/Content/Content.vue';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import ContractDetailCard from '@/components/modules/ContractDetail/ContractDetailCard/ContractDetailCard.vue';

import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';

export default {
	name: 'ContractDetailContent',
	components: {
		Content,
		ArrowDown,
		ContractDetailCard,
		SpinnerIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({}),
	created() {},
	mounted() {},
	beforeDestroy() {},
	methods: {},
	computed: {
		...mapGetters('contractDetail', [
			'userPreferences',
			'contractItemList',
			'isLoadingContractItem',
		]),
	},
};
</script>

<style lang="scss">
@import 'ContractDetailContent.scss';
</style>
