<template>
	<div class="sidebar" :class="{ open: isOpen, close: !isOpen }">
		<div class="sidebar__content">
			<MenuLogo class="sidebar__logo" />
			<MenuContent :class="!isOpen ? 'sidebar__padding' : ''" />
		</div>
		<div class="sidebar__arrow" @click="openMenu">
			<SidebarArrow />
		</div>
	</div>
</template>

<script>
import SidebarArrow from '@/components/shared/Icons/SidebarArrow.vue';
import MenuLogo from '@/components/shared/Icons/MenuLogo.vue';
import MenuContent from '@/components/shared/MenuContent/MenuContent.vue';

export default {
	name: 'Sidebar',
	components: {
		SidebarArrow,
		MenuLogo,
		MenuContent,
	},
	data: () => ({
		isOpen: false,
	}),
	methods: {
		openMenu() {
			this.isOpen = !this.isOpen;
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'Sidebar.scss';
</style>
