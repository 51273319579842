<template>
	<div class="section-row">
		<div class="section-row__header">
			<div class="section-row__title">
				<h1 class="section-row__title--text">
					{{ title }}
				</h1>
			</div>
			<div v-if="status != 'empty'" class="section-row__icon" @click="handleContent()">
				<ArrowDown :class="showContent ? 'rotate' : ''" :color="'#234057'" />
			</div>
		</div>
		<div class="section-row__body" :class="showContent ? 'closed' : ''">
			<slot name="body"></slot>
		</div>
		<div class="section-row__action" :class="showContent ? 'closed' : ''">
			<slot name="action"></slot>
		</div>
	</div>
</template>

<script>
import LoadRow from '@/components/modules/LoadManagement/LoadRow/LoadRow.vue';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';

export default {
	name: 'SectionRow',
	props: {
		isWeb: {
			required: true,
		},
		title: {
			required: true,
		},
		status: {
			type: String,
			default: 'empty',
		},
	},
	components: {
		LoadRow,
		ArrowDown,
	},
	data: () => ({
		showContent: false,
	}),
	created() {},
	mounted() {},
	beforeDestroy() {},
	methods: {
		handleContent() {
			this.showContent = !this.showContent;
		},
	},
	computed: {},
};
</script>

<style lang="scss">
@import 'SectionRow.scss';
</style>
