<template>
	<div class="mobile-header">
		<div class="mobile-header__logo">
			<MenuLogo />
		</div>
		<div class="mobile-header__icon" @click="handleSidebar()">
			<MobileIcon />
		</div>
	</div>
</template>

<script>
import MenuLogo from '@/components/shared/Icons/MenuLogo.vue';
import MobileIcon from '@/components/shared/Icons/MobileIcon.vue';

export default {
	name: 'MobileHeader',
	components: {
		MenuLogo,
		MobileIcon,
	},
	data: () => ({}),
	mounted() {},
	methods: {
		handleSidebar() {
			this.$emit('handleSidebar');
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'MobileHeader.scss';
</style>
