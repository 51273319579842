<template>
	<MobileModal :zIndex="zIndex">
		<template #body>
			<div class="mobile-step">
				<div class="mobile-step__icon">
					<StepOne v-if="getStep === 1" />
					<StepTwo v-if="getStep === 2" />
					<StepThree v-if="getStep === 3" />
					<StepFour v-if="getStep === 4" />
				</div>

				<span class="mobile-step__text">
					{{ $t('modules.doc_instruction.steps.mobile_step', { step: getStep }) }}
				</span>
			</div>
			<button
				class="mobile-step__btn"
				v-if="getStep != 4"
				:class="{ disabled: !checkGoToNextStep || noContractsSelected }"
				@click="goToStep"
			>
				<div class="mobile-step__btn--label">
					{{ $t('buttons.continue') }} <ArrowDown :color="'#fff'" />
				</div>
			</button>
		</template>
	</MobileModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SET_STEP } from '@/store/modules/docInstruction/actions';

import MobileModal from '@/components/shared/MobileModal/MobileModal.vue';

// Icons
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import StepOne from '@/components/shared/Icons/InstructionStepIcons/StepOne';
import StepTwo from '@/components/shared/Icons/InstructionStepIcons/StepTwo';
import StepThree from '@/components/shared/Icons/InstructionStepIcons/StepThree';
import StepFour from '@/components/shared/Icons/InstructionStepIcons/StepFour';

export default {
	name: 'MobileStep',
	components: {
		MobileModal,
		ArrowDown,
		StepOne,
		StepTwo,
		StepThree,
		StepFour,
	},
	props: {
		zIndex: {
			default: 999,
		},
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions('docInstruction', {
			setStep: SET_STEP,
		}),
		goToStep() {
			if (this.getStep === 4) return;
			this.setStep(this.getStep + 1);
		},
	},
	computed: {
		...mapGetters('selectInstruction', ['selectedTemplateData']),
		...mapGetters('docInstruction', ['getStep', 'selectedContractsSequence']),
		noContractsSelected() {
			return !this.selectedContractsSequence.length && this.getStep === 1;
		},
		checkGoToNextStep() {
			let response = true;

			if (this.getStep === 2 && !this.selectedTemplateData) {
				response = false;
			}

			return response;
		},
	},
};
</script>

<style lang="scss">
@import 'MobileStep.scss';
</style>
