<template>
	<svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.402 13.9227C6.98725 13.9229 6.58909 13.7593 6.29454 13.4671L0.662765 7.85874C0.297462 7.45714 0.16544 6.89535 0.313506 6.3731C0.461834 5.85079 0.869216 5.4421 1.39074 5.29189C1.91252 5.14198 2.47484 5.2719 2.87772 5.63589L7.40203 10.1368L16.372 1.18234V1.18208C16.7736 0.816778 17.3354 0.685019 17.8577 0.833084C18.38 0.98115 18.7887 1.38853 18.9389 1.91032C19.0888 2.4321 18.9588 2.99442 18.5949 3.3973L8.50927 13.4674C8.21471 13.7596 7.81654 13.9232 7.40153 13.9229L7.402 13.9227Z"
			:fill="color"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#FFFFFF',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
