<template>
	<GenericMultiselect
		class="market-multiselect"
		ref="market"
		:options="marketOptions"
		:placeholder="$t('modules.contract_management.filter.market_placeholder')"
		:fullWidth="!isWeb"
		:darkBlue="!isWeb"
		:searchable="true"
		:needType="true"
		:type="'marketId'"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CONTRACTS_FILTER_REQUEST,
	MARKET_FILTER_REQUEST,
	SAVE_MANAGEMENT_MARKET_FILTER,
} from '@/store/common/filters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
export default {
	name: 'MarketInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('filters', {
			getMarketFilter: MARKET_FILTER_REQUEST,
			getContractFilter: CONTRACTS_FILTER_REQUEST,
			saveMakertFilter: SAVE_MANAGEMENT_MARKET_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveMakertFilter(param);
			if (param && param.value) {
				const query = new URLSearchParams();
				query.set('limit', '20');
				query.set('page', '1');
				this.getMarketFilter({ vm: this, params: query });
			} else {
				this.getMarketFilter({ vm: this });
			}
			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);
			this.getMarketFilter({ vm: this, params: query });
		},
		nextPage() {
			const meta = this.marketFilterMeta;
			if (meta.totalPages == this.page) {
				return;
			}
			this.page++;
			let query = this.createQueryString();
			this.getMarketFilter({ vm: this, params: query });
		},
		createQueryString(name) {
			const query = new URLSearchParams();
			if (name) query.set('name', name);
			if (name) this.page = 1;
			query.set('limit', '20');
			query.set('page', this.page);
			return query.toString();
		},
		clearInput() {
			this.$refs.market.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.marketManagementFilterCache.value;
			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}
			this.$refs.market.setValue(cachedFilter);
			this.$emit('filterCachedRequest');
		},
	},
	computed: {
		...mapGetters('filters', ['marketFilter', 'marketFilterMeta', 'marketManagementFilterCache']),
		marketOptions() {
			const rows = this.marketFilter != undefined ? this.marketFilter : [];
			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'MarketInput.scss';
</style>