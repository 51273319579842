<template>
	<div class="end-release-datepicker">
		<MobileDatePicker
			v-if="!isWeb"
			:buttonLabel="$t('modules.contract_management.filter.end_date')"
			:title="'releaseDateEnd'"
			ref="releaseDateEnd"
			@changeInput="changeInput"
		/>
		<GenericDatepicker
			v-else
			:buttonLabel="$t('modules.contract_management.filter.end_date')"
			:title="'releaseDateEnd'"
			:largeLabel="true"
			ref="releaseDateEnd"
			@changeInput="changeInput"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SAVE_RELEASE_END } from '@/store/common/filters/actions';
import MobileDatePicker from '@/components/shared/MobileDatepicker/MobileDatepicker.vue';
import GenericDatepicker from '@/components/shared/GenericDatepicker/GenericDatepicker.vue';

export default {
	name: 'ReleaseEnd',
	components: {
		MobileDatePicker,
		GenericDatepicker,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({}),
	mounted() {
		const dateCached = this.endReleaseDateCache.value;

		if (dateCached.id != undefined) {
			this.$refs.releaseDateEnd.setValue(`${dateCached.id}T00:00:00`);
		}
	},
	methods: {
		...mapActions('filters', { saveEndDate: SAVE_RELEASE_END }),
		changeInput(param) {
			this.saveEndDate(param);
			this.$emit('filterRequest', param);
		},
		clearInput() {
			this.$refs.releaseDateEnd.clearValue();
		},
	},
	computed: {
		...mapGetters('filters', ['endReleaseDateCache']),
	},
};
</script>

<style lang="scss" scoped>
@import 'ReleaseEnd.scss';
</style>
