<template>
	<div id="mobile-filter" class="mobile-filter">
		<div class="mobile-filter__title">
			{{ title }}
		</div>
		<CompanyInput ref="companiesHeader" :isWeb="false" v-if="!hideCompanyInput" />

		<div class="mobile-filter__actions" :class="{ fullWidth: fullWidth }">
			<button class="btn mobile-filter__actions--filter" @click="handleFilter()">
				<FilterMobile /> {{ $t('buttons.filter') }}
			</button>
			<button
				v-if="buttonLabel"
				class="btn mobile-filter__actions--modal"
				:class="[{ isStep: isStep }]"
				@click="handleModal()"
			>
				<PlusIcon v-if="!isStep" /> <StepsIcon v-else />{{ buttonLabel }}
			</button>
		</div>
		<div v-if="isOpen" class="mobile-filter__backdrop" @click="handleFilter()"></div>
		<div
			class="mobile-filter__inputs"
			:class="[{ closed: !isOpen }, { leftPos: !hideCompanyInput }]"
		>
			<transition name="fade">
				<div v-show="isOpen">
					<div class="mobile-filter__header">
						<div class="mobile-filter__arrow" @click="handleFilter()">
							<SidebarArrow />
						</div>
					</div>
					<div class="mobile-filter__inputs-content">
						<slot name="inputs-list"></slot>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SET_SHOW_STEP_LIST } from '@/store/modules/docInstruction/actions';
import {
	HEADER_COMPANY_FILTER_REQUEST,
	DEFINE_DEFAULT_HEADER_COMPANY,
} from '@/store/common/filters/actions';

import FilterMobile from '@/components/shared/Icons/FilterMobile.vue';
import CompanyInput from '@/components/shared/Header/Inputs/CompanyInput/CompanyInput.vue';
import PlusIcon from '@/components/shared/Icons/PlusIcon.vue';
import SidebarArrow from '@/components/shared/Icons/SidebarArrow.vue';
import StepsIcon from '@/components/shared/Icons/StepsIcon.vue';

export default {
	name: 'MobileFilter',
	components: {
		FilterMobile,
		CompanyInput,
		PlusIcon,
		SidebarArrow,
		StepsIcon,
	},
	props: {
		buttonLabel: {
			default: null,
		},
		isStep: {
			default: false,
		},
		fullWidth: {
			default: false,
		},
	},
	data: () => ({
		title: '',
		isOpen: false,
	}),
	mounted() {
		this.refreshFilters();
	},
	methods: {
		...mapActions('filters', {
			getHeaderCompanyFilter: HEADER_COMPANY_FILTER_REQUEST,
			setDefaultCompany: DEFINE_DEFAULT_HEADER_COMPANY,
		}),
		...mapActions('docInstruction', {
			setShowStepList: SET_SHOW_STEP_LIST,
		}),
		handleFilter() {
			this.isOpen = !this.isOpen;
		},
		handleModal() {
			this.$emit('handleModal');
			this.setShowStepList(!this.showStepList);
		},
		refreshFilters() {
			this.getHeaderCompanyFilter({ vm: this }).then((res) => {
				if (this.$refs.companiesHeader !== undefined && res.status == 200) {
					this.$refs.companiesHeader.setCachedInput();
				}
			});
		},
		setCompany() {
			const localStorageCache = localStorage.getItem('headerCompany');

			if (localStorageCache && this.headerCompanyFilterCache.value === null) {
				this.getHeaderCompanyFilter({ vm: this }).then((res) => {
					this.setDefaultCompany();
				});
			}
		},
	},
	computed: {
		...mapGetters('docInstruction', ['showStepList']),
		...mapGetters('filters', ['headerCompanyFilterCache']),
		hideCompanyInput() {
			const routeName = this.$route.name;
			return (
				routeName == 'header.dashboard' ||
				routeName == 'header.user_management' ||
				routeName == 'header.contract_management' ||
				routeName == 'header.contract_details'
			);
		},
	},
	watch: {
		$route: {
			handler(route) {
				this.title = this.$t(`${route.name}`);
				this.setCompany();
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style lang="scss">
@import 'MobileFilter.scss';
</style>
