<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 11 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9 17.5556L2 9.77778L9 2"
			:stroke="color"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#B47D4A',
		},
		height: {
			default: '20',
		},
		width: {
			default: '11',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
