<template>
	<div id="dashboard" class="dashboard">
		<Content>
			<template v-slot:header>
				<div v-if="!isWeb">
					<FilterList
						ref="dashboardFilter"
						:isWeb="isWeb"
						@refreshDashboard="refreshDashboard"
						@setPageOne="setPageOne"
					/>
				</div>
				<WebFilter v-if="isWeb">
					<template v-slot:inputs-list
						><FilterList
							ref="dashboardFilter"
							:isWeb="isWeb"
							@refreshDashboard="refreshDashboard"
							@setPageOne="setPageOne"
					/></template>
				</WebFilter>
			</template>
			<template v-slot:body>
				<div class="chart-container">
					<div class="chart-container__rounded">
						<AnalysisChart :isWeb="isWeb" />
						<FinanceChart :isWeb="isWeb" />
					</div>
					<div class="chart-container__bar web" v-show="isWeb">
						<OperationChart :isWeb="isWeb" />
					</div>
				</div>
				<div class="chart-container__bar__mobile" v-show="!isWeb">
					<OperationChart :isWeb="isWeb" />
				</div>
				<Table
					ref="dashboardTable"
					:isWeb="isWeb"
					@changePage="changePage"
					@sortTable="sortTable"
				/>
			</template>
		</Content>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
	DASHBOARD_CHARTS_REQUEST,
	DASHBOARD_TABLE_REQUEST,
} from '@/store/modules/dashboard/actions';
import {
	CONTRACTS_FILTER_REQUEST,
	COMPANY_FILTER_REQUEST,
	HEADER_COMPANY_FILTER_REQUEST,
	SAVE_HEADER_COMPANY_FILTER,
	MARKET_FILTER_REQUEST,
	PO_FILTER_REQUEST,
} from '@/store/common/filters/actions';
import Content from '@/components/shared/Content/Content.vue';
import MobileFilter from '@/components/shared/MobileFilter/MobileFilter.vue';
import WebFilter from '@/components/shared/WebFilter/WebFilter.vue';
import FilterList from '@/components/modules/Dashboard/Filter/FilterList.vue';
import Table from '@/components/modules/Dashboard/Table/Table.vue';
import AnalysisChart from '@/components/modules/Dashboard/Charts/AnalysisChart/AnalysisChart.vue';
import FinanceChart from '@/components/modules/Dashboard/Charts/FinanceChart/FinanceChart.vue';
import OperationChart from '@/components/modules/Dashboard/Charts/OperationChart/OperationChart.vue';

export default {
	name: 'Dashboard',
	components: {
		Content,
		MobileFilter,
		WebFilter,
		FilterList,
		Table,
		AnalysisChart,
		FinanceChart,
		OperationChart,
	},
	data: () => ({
		windowWidth: window.innerWidth,
		page: 1,
		lastSort: null,
		lastQuery: null,
	}),
	created() {
		const localStorageCache = localStorage.getItem('headerCompany');
		const companyCache = this.companyFilterCache.value;
		const contractCache = this.contractsFilterCache.value;
		const marketCache = this.marketFilterCache.value;
		const purchaseOrderCache = this.poFilterCache.value;

		if (!companyCache.length && !contractCache.length) {
			this.refreshDashboard();
		}

		this.refreshFilters();

		this.getHeaderCompanyFilter({ vm: this }).then((res) => {
			if (res.status == 200 && res.data.items && res.data.items.length) {
				let cachedFilter = null;

				if (localStorageCache) {
					cachedFilter = JSON.parse(localStorageCache);
				} else {
					cachedFilter = { id: res.data.items[0].id, name: res.data.items[0].name };
				}

				localStorage.setItem('headerCompany', JSON.stringify(cachedFilter));
				this.saveCompanyFilter({
					value: { id: cachedFilter.id, name: cachedFilter.name },
				});
			}
		});
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions('dashboard', {
			getTableStatus: DASHBOARD_TABLE_REQUEST,
			getChartsData: DASHBOARD_CHARTS_REQUEST,
		}),
		...mapActions('filters', {
			getContracts: CONTRACTS_FILTER_REQUEST,
			getCompanyFilter: COMPANY_FILTER_REQUEST,
			getHeaderCompanyFilter: HEADER_COMPANY_FILTER_REQUEST,
			saveCompanyFilter: SAVE_HEADER_COMPANY_FILTER,
			getMarketFilter: MARKET_FILTER_REQUEST,
			getPoFilter: PO_FILTER_REQUEST,
		}),
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		changePage(params) {
			let query = this.lastQuery;

			query = query.replace(`&page=${this.page}`, `&page=${params.params}`);
			this.lastQuery = this.lastQuery.replace(`&page=${this.page}`, `&page=${params.params}`);

			this.page = params.params;
			this.getTableStatus({ vm: this, params: query });

			let chartParams = query
				.replace(`&page=${this.page}`, '')
				.replace('limit=10', '')
				.substring(1);

			this.getChartsData({ vm: this, params: chartParams });
		},
		sortTable(sort) {
			this.lastSort = sort;
			this.refreshDashboard('sort', sort);
		},
		setPageOne() {
			this.page = 1;
			this.$refs.dashboardTable.setPageOne();
		},
		refreshDashboard(params, sort) {
			if (params == 'sort') {
				let query = this.lastQuery;
				query += `&${sort}`;

				return this.getTableStatus({ vm: this, params: query });
			}

			let queryString = 'limit=20&';

			if (params) {
				this.setPageOne();
				queryString += `${params}&`;
			}

			queryString += `page=${this.page}`;
			this.lastQuery = queryString;

			if (this.lastSort) {
				queryString += `&${this.lastSort}`;
			}

			this.getTableStatus({ vm: this, params: queryString });

			let chartParams = queryString
				.replace(`&page=${this.page}`, '')
				.replace('limit=20', '')
				.substring(1);

			this.getChartsData({ vm: this, params: chartParams });
		},
		refreshFilters() {
			this.getContracts({ vm: this }).then((res) => {
				if (res.status == 200) {
					this.$refs.dashboardFilter.setCachedContracts();
				}
			});
			this.getCompanyFilter({ vm: this }).then((res) => {
				if (res.status == 200) {
					this.$refs.dashboardFilter.setCachedCompany();
				}
			});
			this.getMarketFilter({ vm: this }).then((res) => {
				if (res.status == 200) {
					this.$refs.dashboardFilter.setCachedMarket();
				}
			});
			this.getPoFilter({ vm: this }).then((res) => {
				if (res.status == 200) {
					this.$refs.dashboardFilter.setCachedPurchaseOrder();
				}
			});
		},
	},
	computed: {
		...mapGetters('filters', [
			'companyFilterCache',
			'contractsFilterCache',
			'marketFilterCache',
			'poFilterCache',
		]),
		isWeb() {
			return this.windowWidth > 992;
		},
	},
};
</script>

<style lang="scss">
@import 'Dashboard.scss';
</style>
