export const SET_STEP = 'SET_STEP';
export const SET_SHOW_STEP_LIST = 'SET_SHOW_STEP_LIST';
export const RESET_STEPS = 'RESET_STEPS';

export const CLEAR_CONTRACT_STATE = 'CLEAR_CONTRACT_STATE';

export const CONTRACT_LIST_REQUEST = 'CONTRACT_LIST_REQUEST';
export const CONTRACT_LIST_REQUEST_SUCCESS = 'CONTRACT_LIST_REQUEST_SUCCESS';
export const CONTRACT_LIST_REQUEST_ERROR = 'CONTRACT_LIST_REQUEST_ERROR';

export const CONTRACT_SEQUENCE_REQUEST = 'CONTRACT_SEQUENCE_REQUEST';
export const CONTRACT_SEQUENCE_REQUEST_SUCCESS = 'CONTRACT_SEQUENCE_REQUEST_SUCCESS';
export const CONTRACT_SEQUENCE_REQUEST_ERROR = 'CONTRACT_SEQUENCE_REQUEST_ERROR';

export const CLEAR_SELECTED_CONTRACTS_SEQUENCES = 'CLEAR_SELECTED_CONTRACTS_SEQUENCES';
export const UPDATE_SELECTED_CONTRACTS_SEQUENCES = 'UPDATE_SELECTED_CONTRACTS_SEQUENCES';

export const SET_SHOW_NEW_MODEL = 'SET_SHOW_NEW_MODEL';
export const SET_HIDE_NEW_MODEL = 'SET_HIDE_NEW_MODEL';

export const TEMPLATE_DEFAULT_REQUEST = 'TEMPLATE_DEFAULT_REQUEST';
export const TEMPLATE_DEFAULT_SUCCESS = 'TEMPLATE_DEFAULT_SUCCESS';
export const TEMPLATE_DEFAULT_ERROR = 'TEMPLATE_DEFAULT_ERROR';

export const CLIENTS_LIST_REQUEST = 'CLIENTS_LIST_REQUEST';
export const CLIENTS_LIST_SUCCESS = 'CLIENTS_LIST_SUCCESS';
export const CLIENTS_LIST_ERROR = 'CLIENTS_LIST_ERROR';
export const ADD_CLIENT = 'ADD_CLIENT';

export const BANK_LIST_REQUEST = 'BANK_LIST_REQUEST';
export const BANK_LIST_SUCCESS = 'BANK_LIST_SUCCESS';
export const BANK_LIST_ERROR = 'BANK_LIST_ERROR';

export const SET_TEMPLATE_INVOICE_DATA = 'SET_TEMPLATE_INVOICE_DATA';
export const SET_TEMPLATE_PACKING_DATA = 'SET_TEMPLATE_PACKING_DATA';
export const SET_TEMPLATE_BILL_LANDING_DATA = 'SET_TEMPLATE_BILL_LANDING_DATA';
export const SET_TEMPLATE_HEALTH_CERTIFICATE_DATA = 'SET_TEMPLATE_HEALTH_CERTIFICATE_DATA';
export const SET_TEMPLATE_CERTIFICATE_ORIGIN_DATA = 'SET_TEMPLATE_CERTIFICATE_ORIGIN_DATA';
export const SET_TEMPLATE_DESTINATION_BANK_DATA = 'SET_TEMPLATE_DESTINATION_BANK_DATA';
export const SET_TEMPLATE_INVOICE_SHIPPING_TYPE = 'SET_TEMPLATE_INVOICE_SHIPPING_TYPE';
export const SET_TEMPLATE_PACKING_SHIPPING_TYPE = 'SET_TEMPLATE_PACKING_SHIPPING_TYPE';
export const SET_TEMPLATE_BILL_LANDING_SHIPPING_TYPE = 'SET_TEMPLATE_BILL_LANDING_SHIPPING_TYPE';
export const SET_TEMPLATE_HEALTH_CERTIFICATE_SHIPPING_TYPE = 'SET_TEMPLATE_HEALTH_CERTIFICATE_SHIPPING_TYPE';
export const SET_TEMPLATE_CERTIFICATE_ORIGIN_SHIPPING_TYPE = 'SET_TEMPLATE_CERTIFICATE_ORIGIN_SHIPPING_TYPE';

export const SET_TEMPLATE_DESTINATION_CUSTOMER_DATA = 'SET_TEMPLATE_DESTINATION_CUSTOMER_DATA';

export const SET_TEMPLATE_NAME_DATA = 'SET_TEMPLATE_NAME_DATA';
export const SET_TEMPLATE_SEQUENCE_SALES_CONTRACT_DATA =
	'SET_TEMPLATE_SEQUENCE_SALES_CONTRACT_DATA';
export const SET_TEMPLATE_SALES_CONTRACT_DATA = 'SET_TEMPLATE_SALES_CONTRACT_DATA';

export const SUBMIT_NEW_TEMPLATE_REQUEST = 'SUBMIT_NEW_TEMPLATE_REQUEST';
export const SUBMIT_NEW_TEMPLATE_SUCCESS = 'SUBMIT_NEW_TEMPLATE_SUCCESS';
export const SUBMIT_NEW_TEMPLATE_ERROR = 'SUBMIT_NEW_TEMPLATE_ERROR';

export const CLEAR_NEW_TEMPLATE_REQUEST = 'CLEAR_NEW_TEMPLATE_REQUEST';

export const TEMPLATE_DETAIL_REQUEST = 'TEMPLATE_DETAIL_REQUEST';
export const TEMPLATE_DETAIL_SUCCESS = 'TEMPLATE_DETAIL_SUCCESS';
export const TEMPLATE_DETAIL_ERROR = 'TEMPLATE_DETAIL_ERROR';

export const COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
export const COUNTRIES_ERROR = 'COUNTRIES_ERROR';

export const STATES_REQUEST = 'STATES_REQUEST';
export const STATES_SUCCESS = 'STATES_SUCCESS';
export const STATES_ERROR = 'STATES_ERROR';

export const CITIES_REQUEST = 'CITIES_REQUEST';
export const CITIES_SUCCESS = 'CITIES_SUCCESS';
export const CITIES_ERROR = 'CITIES_ERROR';

export const EDIT_CONSIGNEE_NOTIFY = 'EDIT_CONSIGNEE_NOTIFY';
export const RESET_CONSIGNEE_NOTIFY = 'RESET_CONSIGNEE_NOTIFY';
