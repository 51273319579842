<template>
	<div class="billBlock-container">
		<TemplateHeaderItem
			:title="$t('modules.templateData.items.billOfLanding')"
			:showBell="false"
			@handleCollapse="handleCollapse"
		/>

		<div class="billBlock-container__content" :class="!isCollapsed ? 'open' : ''">
			<div class="billBlock-container__itens">
				<div class="billBlock-container__itens--item">
					<h5>{{ $t('modules.templateData.items.consignee') }}</h5>

					<div class="billBlock-container__itens--title">{{ templateData.consignee.name }}</div>
					<div class="billBlock-container__itens--info">
						{{ templateData.consignee.address }}
					</div>
					<div class="billBlock-container__actions">
						<div class="billBlock-container__actions--date" @click="handleEdit">
							<EditIcon color="#aac3db" />
							10/10/2023
						</div>
						<div class="billBlock-container__actions--btn" @click="handleEdit">
							<EditIcon />
							{{ $t('modules.templateData.actions.edit') }}
						</div>
					</div>
				</div>
				<div class="billBlock-container__itens--item">
					<h5>{{ $t('modules.templateData.items.notify') }}</h5>

					<div class="billBlock-container__itens--title">{{ templateData.notify.name }}</div>
					<div class="billBlock-container__itens--info">
						{{ templateData.notify.address }}
					</div>
					<div class="billBlock-container__actions">
						<div class="billBlock-container__actions--date" @click="handleEdit">
							<EditIcon color="#aac3db" />
							10/10/2023
						</div>
						<div class="billBlock-container__actions--btn" @click="handleEdit">
							<EditIcon />
							{{ $t('modules.templateData.actions.edit') }}
						</div>
					</div>
				</div>
				<div class="billBlock-container__itens--item">
					<h5>{{ $t('modules.templateData.items.secondNotify') }}</h5>

					<div class="billBlock-container__itens--title">{{ templateData.secondNotify.name }}</div>
					<div class="billBlock-container__itens--info">
						{{ templateData.secondNotify.address }}
					</div>
					<div class="billBlock-container__actions">
						<div class="billBlock-container__actions--date" @click="handleEdit">
							<EditIcon color="#aac3db" />
							10/10/2023
						</div>
						<div class="billBlock-container__actions--btn" @click="handleEdit">
							<EditIcon />
							{{ $t('modules.templateData.actions.edit') }}
						</div>
					</div>
				</div>
			</div>
			<div class="billBlock-container__itens">
				<div class="billBlock-container__itens--item small">
					<h5>{{ $t('modules.templateData.items.telexRelease') }}</h5>
					<div class="billBlock-container__itens--title">Active</div>
				</div>

				<div class="billBlock-container__itens--item">
					<h5>{{ $t('modules.templateData.items.freigtClause') }}</h5>

					<div class="billBlock-container__itens--info">
						{{ templateData.shippingPayment }}
					</div>
					<div class="billBlock-container__actions">
						<div class="billBlock-container__actions--date" @click="handleEdit">
							<EditIcon color="#aac3db" />
							10/10/2023
						</div>
						<div class="billBlock-container__actions--btn" @click="handleEdit">
							<EditIcon />
							{{ $t('modules.templateData.actions.edit') }}
						</div>
					</div>
				</div>
				<div class="billBlock-container__itens--item">
					<h5>{{ $t('modules.templateData.items.observations') }}</h5>
					<div class="billBlock-container__itens--info">
						{{ templateData.observations }}
					</div>
					<div class="billBlock-container__actions">
						<div class="billBlock-container__actions--date" @click="handleEdit">
							<EditIcon color="#aac3db" />
							10/10/2023
						</div>
						<div class="billBlock-container__actions--btn" @click="handleEdit">
							<EditIcon />
							{{ $t('modules.templateData.actions.edit') }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import GenericCheckbox from '@/components/shared/GenericCheckbox/GenericCheckbox';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
import BellTemplateIcon from '@/components/shared/Icons/BellTemplateIcon.vue';
import TemplateHeaderItem from '@/components/modules/DocInstruction/TemplateData/TemplateHeaderItem/TemplateHeaderItem.vue';
import EditIcon from '@/components/shared/Icons/EditIcon.vue';

export default {
	name: 'TemplateBillOfLanding',
	components: {
		GenericMultiselect,
		GenericCheckbox,
		BellTemplateIcon,
		TemplateHeaderItem,
		EditIcon,
	},
	props: {
		templateData: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data: () => ({
		isCollapsed: false,
	}),
	methods: {
		handleCollapse() {
			this.isCollapsed = !this.isCollapsed;
		},
		handleEdit() {
			this.$emit('editData');
		},
	},
	computed: {},
};
</script>

<style lang="scss">
@import 'BillBlock.scss';
</style>
