<template>
	<div id="user-management" class="user-management">
		<Content>
			<template v-slot:header>
				<MobileFilter :buttonLabel="$t('buttons.add')" @handleModal="handleModal()" v-if="!isWeb">
					<template v-slot:inputs-list>
						<FilterList @setPageOne="setPageOne" @refreshUsers="refreshUsers" :isWeb="isWeb" />
					</template>
				</MobileFilter>
				<WebFilter v-else>
					<template v-slot:filter-actions>
						<div class="row filter-actions">
							<button class="btn-filter" @click="handleModal(), clearUser()">
								<PlusIcon /> {{ $t('buttons.add') }}
							</button>
						</div>
					</template>
					<template v-slot:inputs-list>
						<FilterList @setPageOne="setPageOne" @refreshUsers="refreshUsers" :isWeb="isWeb" />
					</template>
				</WebFilter>
			</template>
			<template v-slot:body>
				<Table
					ref="userTable"
					:isWeb="isWeb"
					@editUser="editUser"
					@changePage="changePage"
					@refreshUsers="refreshUsers"
					@sortTable="sortTable"
				/>
			</template>
		</Content>
		<UserModal
			:isWeb="isWeb"
			:selectedUser="selectedUser"
			@handleModal="handleModal()"
			@clearUser="clearUser()"
			@refreshUsers="refreshUsers"
			@refreshFilters="refreshFilters"
			v-show="showUserModal"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { USER_MANAGEMENT_REQUEST } from '@/store/modules/userManagement/actions';
import {
	USER_NAME_FILTER_REQUEST,
	USER_EMAIL_FILTER_REQUEST,
	COMPANY_FILTER_REQUEST_ALL,
} from '@/store/common/filters/actions';
import Content from '@/components/shared/Content/Content.vue';
import MobileFilter from '@/components/shared/MobileFilter/MobileFilter.vue';
import WebFilter from '@/components/shared/WebFilter/WebFilter.vue';
import FilterList from '@/components/modules/UserManagement/Filter/FilterList.vue';
import Table from '@/components/modules/UserManagement/Table/Table.vue';
import UserModal from '@/components/modules/UserManagement/UserModal/UserModal.vue';
import PlusIcon from '@/components/shared/Icons/PlusIcon.vue';

export default {
	name: 'UserManagement',
	components: {
		Content,
		MobileFilter,
		WebFilter,
		FilterList,
		Table,
		UserModal,
		PlusIcon,
	},
	data: () => ({
		windowWidth: window.innerWidth,
		showUserModal: false,
		selectedUser: null,
		page: 1,
		lastSort: null,
		lastQuery: null,
	}),
	created() {
		this.refreshUsers();
		this.refreshFilters();
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions('userManagement', {
			getUsers: USER_MANAGEMENT_REQUEST,
		}),
		...mapActions('filters', {
			getUserNameFilter: USER_NAME_FILTER_REQUEST,
			getUserEmailFilter: USER_EMAIL_FILTER_REQUEST,
			getCompanyFilter: COMPANY_FILTER_REQUEST_ALL,
		}),
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		changePage(params) {
			let query = this.lastQuery;

			query = query.replace(`&page=${this.page}`, `&page=${params.params}`);
			this.lastQuery = this.lastQuery.replace(`&page=${this.page}`, `&page=${params.params}`);

			this.page = params.params;
			this.getUsers({ vm: this, params: query });
		},
		sortTable(sort) {
			this.lastSort = sort;
			this.refreshUsers('sort', sort);
		},
		refreshUsers(params, sort) {
			if (params == 'sort') {
				let query = this.lastQuery;
				query += `&${sort}`;

				return this.getUsers({ vm: this, params: query });
			}

			let queryString = 'limit=10&';

			if (params) {
				this.page = 1;
				queryString += `${params}&`;
			}

			queryString += `page=${this.page}`;
			this.lastQuery = queryString;

			if (this.lastSort) {
				queryString += `&${this.lastSort}`;
			}

			this.getUsers({ vm: this, params: queryString });
		},
		refreshFilters() {
			this.getUserNameFilter({ vm: this });
			this.getUserEmailFilter({ vm: this });
			this.getCompanyFilter({ vm: this });
		},
		setPageOne() {
			this.page = 1;
			this.$refs.userTable.setPageOne();
		},
		editUser(user) {
			this.selectedUser = user;
			this.handleModal();
		},
		clearUser() {
			this.selectedUser = null;
		},
		handleModal() {
			this.showUserModal = !this.showUserModal;
		},
	},
	computed: {
		isWeb() {
			return this.windowWidth > 992;
		},
	},
};
</script>

<style lang="scss">
@import 'UserManagement.scss';
</style>
