import {
	CLEAR_ALL_FILTERS,
	CLEAR_CURRENCY_FILTER,
	CLEAR_END_DATE_FILTER,
	CLEAR_INIT_DATE_FILTER,
	CLEAR_MARKET_FILTER,
	CLEAR_STATUS_FILTER,
	CLEAR_INVOICE_FILTER,
	//currency
	CURRENCY_FILTER_REQUEST,
	CURRENCY_FILTER_REQUEST_ERROR,
	CURRENCY_FILTER_REQUEST_SUCCESS,
	//market
	MARKET_FILTER_REQUEST,
	MARKET_FILTER_REQUEST_ERROR,
	MARKET_FILTER_REQUEST_SUCCESS,
	SAVE_CURRENCY_FILTER,
	//end_date
	SAVE_END_DATE_FILTER,
	//init_date
	SAVE_INIT_DATE_FILTER,
	//last query string
	SAVE_LAST_QUERY_STRING,
	SAVE_MARKET_FILTER,
	SAVE_STATUS_FILTER,
	SAVE_INVOICE_FILTER,
	//status
	STATUS_FILTER_REQUEST,
	STATUS_FILTER_REQUEST_ERROR,
	STATUS_FILTER_REQUEST_SUCCESS,
	//invoice
	INVOICE_FILTER_REQUEST,
	INVOICE_FILTER_REQUEST_ERROR,
	INVOICE_FILTER_REQUEST_SUCCESS,
} from './actions';

import api from '@/utils/api';
import { addUniqueItemsToList } from '@/utils/filtersHelper';

const { http } = api.getInstance();

const defaultState = () => ({
	//market
	marketFilter: [],
	marketFilterMeta: [],
	marketFilterCache: {
		value: [],
	},
	isLoadingMarketFilter: '',
	//currency
	currencyFilter: [],
	currencyFilterMeta: [],
	currencyFilterCache: {
		value: [],
	},
	isLoadingCurrencyFilter: '',
	//status
	statusFilter: [],
	statusFilterMeta: [],
	statusFilterCache: {
		value: [],
	},
	isLoadingStatusFilter: '',
	//invoice
	invoiceFilter: [],
	invoiceFilterMeta: [],
	invoiceFilterCache: {
		value: [],
	},
	isLoadingInvoiceFilter: '',
	//init_date
	initDateFilter: [],
	initDateFilterCache: {
		value: [],
	},
	//end_date
	endDateFilter: [],
	endDateFilterCache: {
		value: [],
	},
	//lastQueryString
	lastQueryString: '',
});

const state = defaultState();

const getters = {
	//market
	marketFilter: (state) => state.marketFilter,
	marketFilterMeta: (state) => state.marketFilterMeta,
	marketFilterCache: (state) => state.marketFilterCache,
	isLoadingMarketFilter: (state) => state.isLoadingMarketFilter === 'loading',
	//currency
	currencyFilter: (state) => state.currencyFilter,
	currencyFilterMeta: (state) => state.currencyFilterMeta,
	currencyFilterCache: (state) => state.currencyFilterCache,
	isLoadingCurrencyFilter: (state) => state.isLoadingCurrencyFilter === 'loading',
	//status
	statusFilter: (state) => state.statusFilter,
	statusFilterMeta: (state) => state.statusFilterMeta,
	statusFilterCache: (state) => state.statusFilterCache,
	isLoadingStatusFilter: (state) => state.isLoadingStatusFilter === 'loading',
	//invoice
	invoiceFilter: (state) => state.invoiceFilter,
	invoiceFilterMeta: (state) => state.invoiceFilterMeta,
	invoiceFilterCache: (state) => state.invoiceFilterCache,
	isLoadingInvoiceFilter: (state) => state.isLoadingInvoiceFilter === 'loading',
	//init_date
	initDateFilter: (state) => state.initDateFilter,
	initDateFilterCache: (state) => state.initDateFilterCache,
	//end_date
	endDateFilter: (state) => state.endDateFilter,
	endDateFilterCache: (state) => state.endDateFilterCache,
	//lastQueryString
	lastQueryString: (state) => state.lastQueryString,
};

const mutations = {
	[CLEAR_ALL_FILTERS]: (state) => {
		state.marketFilterCache = {
			value: [],
		};
		state.currencyFilterCache = {
			value: [],
		};
		state.statusFilterCache = {
			value: [],
		};
		state.invoiceFilterCache = {
			value: [],
		};
		state.initDateFilterCache = {
			value: [],
		};
		state.endDateFilterCache = {
			value: [],
		};
		state.lastQueryString = '';
	},
	//MARKET FILTER
	[MARKET_FILTER_REQUEST]: (state) => {
		state.marketFilterMeta = [];
		state.isLoadingMarketFilter = 'loading';
	},
	[MARKET_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.marketFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.marketFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item.market, name: item.market }));
			addUniqueItemsToList(state, formattedItems, 'marketFilter', 'name');
		}

		state.isLoadingMarketFilter = 'success';
	},
	[MARKET_FILTER_REQUEST_ERROR]: (state) => {
		state.marketFilter = [];
		state.marketFilterMeta = [];
		state.isLoadingMarketFilter = 'error';
	},
	[SAVE_MARKET_FILTER]: (state, data) => {
		state.marketFilterCache = data;
	},
	[CLEAR_MARKET_FILTER]: (state) => {
		state.marketFilter = [];
	},
	//CURRENCY FILTER
	[CURRENCY_FILTER_REQUEST]: (state) => {
		state.currencyFilterMeta = [];
		state.isLoadingCurrencyFilter = 'loading';
	},
	[CURRENCY_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.currencyFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.currencyFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item.currency, name: item.currency }));
			addUniqueItemsToList(state, formattedItems, 'currencyFilter', 'name');
		}

		state.isLoadingCurrencyFilter = 'success';
	},
	[CURRENCY_FILTER_REQUEST_ERROR]: (state) => {
		state.currencyFilter = [];
		state.currencyFilterMeta = [];
		state.isLoadingCurrencyFilter = 'error';
	},
	[SAVE_CURRENCY_FILTER]: (state, data) => {
		state.currencyFilterCache = data;
	},
	[CLEAR_CURRENCY_FILTER]: (state) => {
		state.currencyFilter = [];
	},
	//STATUS FILTER
	[STATUS_FILTER_REQUEST]: (state) => {
		state.statusFilterMeta = [];
		state.isLoadingStatusFilter = 'loading';
	},
	[STATUS_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.statusFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.statusFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item.status, name: item.status }));
			addUniqueItemsToList(state, formattedItems, 'statusFilter', 'name');
		}

		state.isLoadingStatusFilter = 'success';
	},
	[STATUS_FILTER_REQUEST_ERROR]: (state) => {
		state.statusFilter = [];
		state.statusFilterMeta = [];
		state.isLoadingStatusFilter = 'error';
	},
	[SAVE_STATUS_FILTER]: (state, data) => {
		state.statusFilterCache = data;
	},
	[CLEAR_STATUS_FILTER]: (state) => {
		state.statusFilter = [];
	},
	//INVOICE FILTER
	[INVOICE_FILTER_REQUEST]: (state) => {
		state.invoiceFilterMeta = [];
		state.isLoadingInvoiceFilter = 'loading';
	},
	[INVOICE_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.invoiceFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.invoiceFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item.invoice, name: item.invoice }));
			addUniqueItemsToList(state, formattedItems, 'invoiceFilter', 'name');
		}

		const invoiceCodes = data.items.map((item) => item.invoiceCode);
		state.invoiceFilter = invoiceCodes;

		state.isLoadingInvoiceFilter = 'success';
	},
	[INVOICE_FILTER_REQUEST_ERROR]: (state) => {
		state.invoiceFilter = [];
		state.invoiceFilterMeta = [];
		state.isLoadingInvoiceFilter = 'error';
	},
	[SAVE_INVOICE_FILTER]: (state, data) => {
		state.invoiceFilterCache = data;
	},
	[CLEAR_INVOICE_FILTER]: (state) => {
		state.invoiceFilter = [];
	},
	//INIT_DATE
	[SAVE_INIT_DATE_FILTER]: (state, data) => {
		state.initDateFilterCache = data;
	},
	[CLEAR_INIT_DATE_FILTER]: (state) => {
		state.initDateFilter = [];
	},
	//END_DATE
	[SAVE_END_DATE_FILTER]: (state, data) => {
		state.endDateFilterCache = data;
	},
	[CLEAR_END_DATE_FILTER]: (state) => {
		state.endDateFilter = [];
	},
	//LAST QUERY STRING
	[SAVE_LAST_QUERY_STRING]: (state, data) => {
		state.lastQueryString = data;
	},
};

const actions = {
	[CLEAR_ALL_FILTERS]: ({ commit }) => {
		commit(CLEAR_ALL_FILTERS);
	},
	//MARKET FILTER
	[MARKET_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(MARKET_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `/finances/marketsByFilters?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('market');
				url = `/finances/marketsByFilters?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('market');
				url = `/finances/marketsByFilters?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('market');
				url = `/finances/marketsByFilters?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(MARKET_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(MARKET_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	//CURRENCY FILTER
	[CURRENCY_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(CURRENCY_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `/finances/currencies?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('currency');
				url = `/finances/currencies?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('currency');
				url = `/finances/currencies?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('currency');
				url = `/finances/currencies?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CURRENCY_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CURRENCY_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_CURRENCY_FILTER]: ({ commit }, data) => {
		commit(SAVE_CURRENCY_FILTER, data);
	},
	//STATUS FILTER
	[STATUS_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(STATUS_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `/finances/status?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('status');
				url = `/finances/status?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('status');
				url = `/finances/status?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('status');
				url = `/finances/status?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(STATUS_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(STATUS_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_MARKET_FILTER]: ({ commit }, data) => {
		commit(SAVE_MARKET_FILTER, data);
	},
	[SAVE_STATUS_FILTER]: ({ commit }, data) => {
		commit(SAVE_STATUS_FILTER, data);
	},
	//INVOICE FILTER
	[INVOICE_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(INVOICE_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `/finances/invoices?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `/finances/invoices?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `/finances/invoices?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `/finances/invoices?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(INVOICE_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(INVOICE_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_INVOICE_FILTER]: ({ commit }, data) => {
		commit(SAVE_INVOICE_FILTER, data);
	},
	//ETS FILTER
	[SAVE_INIT_DATE_FILTER]: ({ commit }, data) => {
		commit(SAVE_INIT_DATE_FILTER, data);
	},
	//ETA FILTER
	[SAVE_END_DATE_FILTER]: ({ commit }, data) => {
		commit(SAVE_END_DATE_FILTER, data);
	},
	//LAST QUERY STRING
	[SAVE_LAST_QUERY_STRING]: ({ commit }, data) => {
		commit(SAVE_LAST_QUERY_STRING, data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
