<template>
	<ModalStructure
		id="reproval-modal"
		class="reproval-modal"
		:hasTitle="false"
		:hasHeader="false"
		:scroll="false"
		:width="width"
		:height="height"
	>
		<template v-slot:body>
			<div class="body" v-show="!isLoadingDocApproval">
				<div class="body-title">
					<h1 class="body-title__text">
						{{ $t('modules.doc_management.reproval_modal.title') }}: {{ reproveDocument.type }}
					</h1>
				</div>
				<div class="body-content">
					<h2 class="body-content__title">
						{{ $t('modules.doc_management.reproval_modal.subtitle') }}
					</h2>
					<textarea
						class="body-content__textarea"
						:placeholder="$t('modules.doc_management.reproval_modal.comment')"
						v-model="note"
					></textarea>
				</div>
			</div>
			<div class="loader" v-if="isLoadingDocApproval">
				<SpinnerIcon :size="60" />
			</div>
		</template>
		<template v-slot:footer>
			<div class="footer" v-show="!isLoadingDocApproval">
				<button id="cancel-user-modal" class="footer__btn" @click="confirmReproval">
					{{ $t('buttons.disapprove') }}
				</button>
				<button id="confirm-user-modal" class="footer__btn outline" @click="handleModal">
					{{ $t('buttons.cancel') }}
				</button>
			</div>
		</template>
	</ModalStructure>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalStructure from '@/components/shared/Modals/ModalStructure/ModalStructure.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon.vue';

export default {
	name: 'ReprovalModal',
	components: { ModalStructure, SpinnerIcon },
	props: {
		isWeb: {
			required: true,
		},
		reproveDocument: {
			required: true,
		},
	},
	data: () => ({
		note: '',
	}),
	methods: {
		handleModal() {
			this.$emit('handleModal');
			this.note = '';
		},
		confirmReproval() {
			this.$emit('confirmReproval', this.note);
		},
	},
	computed: {
		...mapGetters('docManagement', ['isLoadingDocApproval']),
		height() {
			return this.isWeb ? '410px' : '100%';
		},
		width() {
			return this.isWeb ? '487px' : '100%';
		},
	},
};
</script>
<style lang="scss">
@import 'ReprovalModal.scss';
</style>
