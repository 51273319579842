<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9 0C4.03732 0 0 4.03732 0 9C0 13.9627 4.03732 18 9 18C13.9627 18 18 13.9627 18 9C18 4.03732 13.9627 0 9 0ZM9 16.9811C4.59916 16.9811 1.01888 13.4005 1.01888 9C1.01888 4.59916 4.59934 1.01888 9 1.01888C13.4007 1.01888 16.9811 4.59945 16.9811 9C16.9811 13.4008 13.4007 16.9811 9 16.9811ZM9.57483 5.64351C10.1471 5.64351 10.6132 6.04251 10.6132 6.53249C10.6132 6.81436 10.8416 7.04193 11.1226 7.04193C11.4036 7.04193 11.6321 6.81351 11.6321 6.53249C11.6321 5.48049 10.7091 4.62463 9.57483 4.62463H9.50944V3.90552C9.50944 3.62365 9.28102 3.39608 9 3.39608C8.71898 3.39608 8.49056 3.62365 8.49056 3.90552V4.67223C7.28235 4.89212 6.36794 5.87447 6.36794 7.05212C6.36794 8.22976 7.28235 9.21211 8.49056 9.43201V12.2799C7.86741 12.1993 7.38682 11.7373 7.38682 11.1761C7.38682 10.8942 7.1584 10.6667 6.87738 10.6667C6.59636 10.6667 6.36794 10.8951 6.36794 11.1761C6.36794 12.2952 7.30538 13.2062 8.49056 13.2979V14.0943C8.49056 14.3762 8.71898 14.6038 9 14.6038C9.28102 14.6038 9.50944 14.3754 9.50944 14.0943V13.2682C10.7177 13.0483 11.6321 12.066 11.6321 10.8883C11.6321 9.71067 10.7177 8.72832 9.50944 8.50843V5.64355L9.57483 5.64351ZM8.49056 8.38176C7.85121 8.19413 7.38682 7.67276 7.38682 7.05215C7.38682 6.4314 7.85121 5.91017 8.49056 5.72255V8.38176ZM10.6132 10.8883C10.6132 11.5082 10.1488 12.0303 9.50944 12.2179V9.55871C10.1488 9.74549 10.6132 10.2676 10.6132 10.8883Z"
			fill="#234057"
		/>
	</svg>
</template>

<script>
export default {
	props: {},
};
</script>

<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
