import Vue from 'vue';
import { extend, ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import ar from 'vee-validate/dist/locale/ar.json';
import cn from 'vee-validate/dist/locale/zh_CN.json';
import en from 'vee-validate/dist/locale/en.json';
import es from 'vee-validate/dist/locale/es.json';
import ptbr from 'vee-validate/dist/locale/pt_BR.json';

const locale = {
	'ar-ar': ar,
	'cn-cn': cn,
	'en-us': en,
	'es-es': es,
	'pt-br': ptbr,
};

const custom = {
	phone: extend('phone', {
		validate(value) {
			if (!value.startsWith('+')) {
				return false;
			}

			const digits = value.substring(1);
			return /^\d+$/.test(digits);
		},
		message: '{_field_} must start with + and contain only digits afterwards',
	}),
};

export const validators = (lang = 'en-us', $t) => {
	const messages = locale[lang.toLowerCase()];

	Object.keys({ ...rules, ...custom }).forEach((rule) => {
		extend(rule, {
			...rules[rule],
		});
	});

	localize(lang.toLowerCase(), messages);
};

export const initializeValidatorLib = (lang = 'en-us') => {
	validators(lang);
	Vue.component('ValidationProvider', ValidationProvider);
	Vue.component('ValidationObserver', ValidationObserver);
};
