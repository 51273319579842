<template>
	<ValidationObserver v-slot="{ validate }">
		<div class="templateHeader">
			<!-- INPUT FIELD -->
			<ValidationProvider rules="required" v-slot="{ errors }">
				<div class="templateHeader-field">
					<input
						:class="{ '--error': errors.length }"
						type="text"
						name="name"
						id="name"
						:placeholder="$t('modules.templateData.inputs.template_title')"
						v-model="templateName"
					/>
				</div>
				<ErrorMessage>
					{{ errors[0] && $t('errors.template_name_required') }}
				</ErrorMessage>
			</ValidationProvider>
			<!-- RADIO BUTTONS -->
			<!-- <div class="templateHeader-radio">
			<div class="templateHeader-radio__item">
				<input type="radio" id="default" name="typeTemplate" value="default" />
				<label class="radio-label" for="default">
					{{ $t('modules.templateData.inputs.default') }}
				</label>
			</div>
			<div class="templateHeader-radio__item">
				<input type="radio" id="perOrder" name="typeTemplate" value="perOrder" />
				<label class="radio-label" for="perOrder">
					{{ $t('modules.templateData.inputs.perOrder') }}
				</label>
			</div>
		</div> -->

			<!-- ACTIONS -->
			<div class="templateHeader-actions">
				<button class="templateHeader-actions__btn cancel" @click="handleCancel()">
					{{ $t('buttons.cancel') }}
				</button>
				<button
					v-if="hasConsigneeModal"
					class="templateHeader-actions__btn notify"
					@click="handleConsigneeModal()"
				>
					{{ $t('buttons.notify') }}
				</button>
				<button class="templateHeader-actions__btn save" @click="handleNewTemplate(validate)">
					{{ $t('buttons.save_template') }}
				</button>
			</div>
		</div>
	</ValidationObserver>
</template>

<script>
import { SET_TEMPLATE_NAME_DATA } from '@/store/modules/docInstruction/actions';
import { mapActions, mapGetters } from 'vuex';
import ErrorMessage from '@/components/shared/GenericErrorMessage/GenericErrorMessage.vue';

export default {
	name: 'TemplateHeader',
	components: { ErrorMessage },
	props: {
		hasConsigneeModal: {
			default: false,
		},
	},
	data: () => ({
		templateName: '',
	}),
	created() {
		if (this.$route.params.id) {
			this.templateName = this.templateData.nameTemplate;
		}
	},
	computed: {
		...mapGetters('docInstruction', ['templateData']),
		disabled() {
			if (this.templateData.billLanding.releaseType == null) {
				return true;
			}

			if (this.templateData.nameTemplate == '') {
				return true;
			}

			return false;
		},
	},
	watch: {
		templateData(value) {
			this.templateName = value.nameTemplate;
		},
	},
	methods: {
		...mapActions('docInstruction', {
			setName: SET_TEMPLATE_NAME_DATA,
		}),
		handleCancel() {
			this.$emit('handleCancel');
		},
		handleConsigneeModal() {
			this.$emit('handleModal');
		},
		async handleNewTemplate(validate) {
			const isValid = await validate();
			if (isValid) {
				this.setName(this.templateName);
				this.$emit('handleNewTemplate');
			}
		},
	},
};
</script>

<style lang="scss">
@import 'TemplateHeader.scss';
</style>
