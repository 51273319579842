<template>
	<div class="user-container" @click="openProfileMenu">
		<div class="user-container__name">{{ userInfo.name }}</div>
		<div class="dropdown-container">
			<ArrowDown class="dropdown-container__arrow" :class="isOpen ? 'open' : 'close'" />
			<div v-if="isOpen" class="dropdown-container__list">
				<button class="btn__logout" @click="logout">Logout</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import clearApp from '@/utils/clearApp';

export default {
	name: 'UserInfo',
	components: {
		ArrowDown,
	},
	data: () => ({
		title: '',
		isOpen: false,
	}),
	mounted() {},
	methods: {
		openProfileMenu() {
			this.isOpen = !this.isOpen;
		},
		closeDropdown() {
			this.isOpen = false;
		},
		logout() {
			clearApp();
			this.$router.push('/login');
		},
	},
	computed: {
		...mapGetters('user', ['userInfo']),
	},
};
</script>

<style lang="scss" scoped>
@import 'UserInfo.scss';
</style>
