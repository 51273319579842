export const CLEAR_DOC_STATE = 'CLEAR_FINANCE_STATE';

export const DOC_REQUEST = 'DOC_REQUEST';
export const DOC_REQUEST_SUCCESS = 'DOC_REQUEST_SUCCESS';
export const DOC_REQUEST_ERROR = 'DOC_REQUEST_ERROR';

export const CONTRACT_DETAIL_REQUEST = 'CONTRACT_DETAIL_REQUEST';
export const CONTRACT_DETAIL_REQUEST_SUCCESS = 'CONTRACT_DETAIL_REQUEST_SUCCESS';
export const CONTRACT_DETAIL_REQUEST_ERROR = 'CONTRACT_DETAIL_REQUEST_ERROR';

export const CONTAINER_LIST_REQUEST = 'CONTAINER_LIST_REQUEST';
export const CONTAINER_LIST_REQUEST_SUCCESS = 'CONTAINER_LIST_REQUEST_SUCCESS';
export const CONTAINER_LIST_REQUEST_ERROR = 'CONTAINER_LIST_REQUEST_ERROR';

export const COURIER_LIST_REQUEST = 'COURIER_LIST_REQUEST';
export const COURIER_LIST_REQUEST_SUCCESS = 'COURIER_LIST_REQUEST_SUCCESS';
export const COURIER_LIST_REQUEST_ERROR = 'COURIER_LIST_REQUEST_ERROR';

export const DOC_DOWNLOAD_REQUEST = 'DOC_DOWNLOAD_REQUEST';
export const DOC_DOWNLOAD_REQUEST_SUCCESS = 'DOC_DOWNLOAD_REQUEST_SUCCESS';
export const DOC_DOWNLOAD_REQUEST_ERROR = 'DOC_DOWNLOAD_REQUEST_ERROR';

export const DOCUMENT_APPROVAL_REQUEST = 'DOCUMENT_APPROVAL_REQUEST';
export const DOCUMENT_APPROVAL_REQUEST_SUCCESS = 'DOCUMENT_APPROVAL_REQUEST_SUCCESS';
export const DOCUMENT_APPROVAL_REQUEST_ERROR = 'DOCUMENT_APPROVAL_REQUEST_ERROR';
