<template>
	<div id="docsTable">
		<div class="table" v-if="tableRows.length > 0 && isLoadingContainerList === 'success'">
			<div class="table-header">
				<div class="table-header__column">
					{{ $t('modules.doc_management.doc_table.container') }}
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_management.doc_table.cargo') }}
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_management.doc_table.po') }}
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_management.doc_table.plton') }}
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_management.doc_table.sequence') }}
				</div>
			</div>
			<div class="table-body" @scroll="handleScroll">
				<div v-for="item in tableRows" :key="item.container" class="table-body__row">
					<div class="table-body__column">{{ item.container }}</div>
					<div class="table-body__column">{{ item.loadNumber }}</div>
					<div class="table-body__column">{{ item.purchaseOrder }}</div>
					<div class="table-body__column">{{ item.totalVolume }}</div>
					<div class="table-body__column">{{ item.contractSequence }}</div>
				</div>
			</div>
		</div>
		<div class="loader" v-if="isLoadingContainerList !== 'success'">
			<DefaultLoader :dataStatus="isLoadingContainerList" color="blue" />
		</div>

		<div class="no__data" v-if="tableRows.length == 0 && isLoadingContainerList === 'success'">
			{{ $t('errors.empty_data') }}
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CONTAINER_LIST_REQUEST } from '@/store/modules/docManagement/actions';

import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import DefaultLoader from '@/components/shared/DefaultLoader/DefaultLoader.vue';
import moment from 'moment';

export default {
	name: 'Table',
	components: {
		SpinnerIcon,
		DefaultLoader,
	},
	props: {
		isWeb: {
			required: true,
		},
		invoiceNumber: {
			required: true,
		},
	},
	data: () => ({
		currentMeta: [],
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('docManagement', {
			getContainerList: CONTAINER_LIST_REQUEST,
		}),
		handleScroll(event) {
			const target = event.target;

			if (this.isLoadingContainerList) {
				return;
			}

			if (target.scrollHeight - target.scrollTop === target.clientHeight) {
				this.page += 1;
				this.nextPage();
			}
		},
		nextPage() {
			this.getContainerList({ vm: this, invoiceNumber: this.invoiceNumber, page: this.page });
		},
		formateDate(value) {
			return moment(value).format('DD/MM/YYYY');
		},
	},
	computed: {
		...mapGetters('docManagement', ['containerList', 'isLoadingContainerList']),
		tableRows() {
			const filteredList = this.containerList.filter(
				(item) => item.invoiceNumber == this.invoiceNumber,
			);
			if (filteredList.length > 0) {
				return filteredList[0].data.items;
			}
			return [];
		},
		isLoading() {
			return false;
		},
	},
	watch: {
		isLoadingContainerList(value) {
			if (value === 'success') {
				const filteredList = this.containerList.filter(
					(item) => item.invoiceNumber == this.invoiceNumber,
				);
				if (filteredList.length > 0) {
					this.currentMeta = filteredList[0].data.meta;
				}
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'ContainerTable.scss';
</style>
