<template>
	<div class="initial-release-datepicker">
		<MobileDatePicker
			v-if="!isWeb"
			:buttonLabel="$t('modules.contract_management.filter.start_date')"
			:title="'releaseDateStart'"
			ref="releaseDateStart"
			@changeInput="changeInput"
		/>
		<GenericDatepicker
			v-else
			:buttonLabel="$t('modules.contract_management.filter.start_date')"
			:title="'releaseDateStart'"
			:largeLabel="true"
			ref="releaseDateStart"
			@changeInput="changeInput"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SAVE_RELEASE_INITIAL } from '@/store/common/filters/actions';
import MobileDatePicker from '@/components/shared/MobileDatepicker/MobileDatepicker.vue';
import GenericDatepicker from '@/components/shared/GenericDatepicker/GenericDatepicker.vue';

export default {
	name: 'ReleaseInitial',
	components: {
		MobileDatePicker,
		GenericDatepicker,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({}),
	mounted() {
		const dateCached = this.initialReleaseDateCache.value;

		if (dateCached.id != undefined) {
			this.$refs.releaseDateStart.setValue(`${dateCached.id}T00:00:00`);
		}
	},
	methods: {
		...mapActions('filters', { saveInitialDate: SAVE_RELEASE_INITIAL }),
		changeInput(param) {
			this.saveInitialDate(param);
			this.$emit('filterRequest', param);
		},
		clearInput() {
			this.$refs.releaseDateStart.clearValue();
		},
	},
	computed: {
		...mapGetters('filters', ['initialReleaseDateCache']),
	},
};
</script>

<style lang="scss" scoped>
@import 'ReleaseInitial.scss';
</style>
