<template>
	<ModalStructure id="doc-instruction-modal" :width="width" :height="height">
		<template v-slot:body>
			<div class="body">
				<WarningIcon class="body-icon" :color="'#234057'" />
				<span class="body-text" v-html="$t('modules.doc_instruction.confirm_modal.warning')" />
			</div>
		</template>
		<template v-slot:footer>
			<div class="footer">
				<button class="btn btn-cancel" @click="handleClose()">
					{{ $t('buttons.cancel') }}
				</button>
				<button class="btn btn-confirm" @click="handleConfirm()">
					{{ $t(`buttons.confirm`) }}
				</button>
			</div>
		</template>
	</ModalStructure>
</template>

<script>
import ModalStructure from '@/components/shared/Modals/ModalStructure/ModalStructure.vue';
import WarningIcon from '@/components/shared/Icons/WarningIcon.vue';

export default {
	name: 'DocInstructionModal',
	components: { ModalStructure, WarningIcon },
	props: {
		isWeb: {
			required: true,
		},
	},
	methods: {
		handleConfirm() {
			this.$emit('handleConfirm');
		},
		handleClose() {
			this.$emit('handleClose', false);
		},
	},
	computed: {
		height() {
			return this.isWeb ? '325px' : '100%';
		},
		width() {
			return this.isWeb ? '440px' : '100%';
		},
	},
};
</script>
<style scoped lang="scss">
@import 'DocInstructionModal.scss';
</style>
