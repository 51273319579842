<template>
	<div class="filter-container">
		<div class="filter-row">
			<ContractInput ref="contractInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<PoInput
				ref="poInput"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
			<LoadInput
				ref="loadInput"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
			<TransportInput
				ref="transportInput"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
		</div>
		<div class="filter-row">
			<ContainerInput ref="containerInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<ProductInput ref="productInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<ETSInput ref="etsInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<ETAInput ref="etaInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<button class="btn-outline" type="button" @click="clearCachedFilters()" v-if="isWeb">
				{{ $t('buttons.clear_filter') }}
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
	CLEAR_ALL_FILTERS,
	SAVE_LAST_QUERY_STRING,
} from '@/store/modules/filtersByModules/loadManagementFilters/actions';
import ContractInput from './Inputs/ContractInput/ContractInput.vue';
import PoInput from './Inputs/PoInput/PoInput.vue';
import LoadInput from './Inputs/LoadInput/LoadInput.vue';
import TransportInput from './Inputs/TransportInput/TransportInput.vue';
import ContainerInput from './Inputs/ContainerInput/ContainerInput.vue';
import ProductInput from './Inputs/ProductInput/ProductInput.vue';
import ETSInput from './Inputs/ETSInput/ETSInput.vue';
import ETAInput from './Inputs/ETAInput/ETAInput.vue';
import ClearIcon from '@/components/shared/Icons/ClearIcon.vue';

export default {
	name: 'FilterList',
	components: {
		ContractInput,
		PoInput,
		LoadInput,
		TransportInput,
		ContainerInput,
		ProductInput,
		ETSInput,
		ETAInput,
		ClearIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		filter: {
			contractNumberSequence: null,
			purchaseOrder: null,
			loadNumber: null,
			vessel: null,
			container: null,
			product: null,
			expectedEtbDate: null,
			realAtbDate: null,
		},
		refreshCalendars: true,
	}),
	mounted() {
		this.filterCachedRequest();
	},
	methods: {
		...mapActions('loadManagementFilters', {
			clearStoreFilters: CLEAR_ALL_FILTERS,
			saveLastQueryString: SAVE_LAST_QUERY_STRING,
		}),
		filterRequest(param) {
			if (
				param.type == 'product' ||
				param.type == 'expectedEtbDate' ||
				param.type == 'realAtbDate'
			) {
				this.filter[param.type] = param.value ? param.value.id : null;
			} else {
				this.filter[param.type] = param.value ? param.value.map((item) => item.id) : null;
			}

			let queryString = this.createQueryString();

			let filterObj = {
				queryString: queryString,
				skip: true,
			};

			if (!param.value || !param.value.length) {
				this.$emit('setPageOne');
			}

			this.$emit('refreshFilters', filterObj);
			this.$emit('refreshContracts', queryString);
			this.saveLastQueryString(queryString);
		},
		filterCachedRequest() {
			const contractsFilterCache = this.contractsFilterCache.value;
			const poFilterCache = this.poFilterCache.value;
			const loadFilterCache = this.loadFilterCache.value;
			const transportFilterCache = this.transportFilterCache.value;
			const containerFilterCache = this.containerFilterCache.value;
			const productFilterCache = this.productFilterCache.value;
			const etsFilterCache = this.etsFilterCache.value;
			const etaFilterCache = this.etaFilterCache.value;

			if (contractsFilterCache && Object.keys(contractsFilterCache).length) {
				this.filter['contractNumberSequence'] = contractsFilterCache.map((item) => item.id);
			}

			if (poFilterCache && Object.keys(poFilterCache).length) {
				this.filter['purchaseOrder'] = poFilterCache.map((item) => item.id);
			}

			if (loadFilterCache && Object.keys(loadFilterCache).length) {
				this.filter['loadNumber'] = loadFilterCache.map((item) => item.id);
			}

			if (transportFilterCache && Object.keys(transportFilterCache).length) {
				this.filter['vessel'] = transportFilterCache.map((item) => item.id);
			}

			if (containerFilterCache && Object.keys(containerFilterCache).length) {
				this.filter['container'] = containerFilterCache.map((item) => item.id);
			}

			if (productFilterCache && Object.keys(productFilterCache).length) {
				this.filter['product'] = productFilterCache.id;
			}

			if (etsFilterCache.id != undefined) {
				this.filter['expectedEtbDate'] = etsFilterCache.id;
			}

			if (etaFilterCache.id != undefined) {
				this.filter['realAtbDate'] = etaFilterCache.id;
			}

			let queryString = this.createQueryString();
			this.$emit('refreshContracts', queryString);
		},
		createQueryString() {
			let {
				contractNumberSequence,
				purchaseOrder,
				loadNumber,
				vessel,
				container,
				product,
				expectedEtbDate,
				realAtbDate,
			} = this.filter;

			const query = new URLSearchParams();

			if (contractNumberSequence && contractNumberSequence.length)
				query.set('contractNumberSequence', contractNumberSequence);

			if (purchaseOrder && purchaseOrder.length) query.set('purchaseOrder', purchaseOrder);
			if (loadNumber && loadNumber.length) query.set('loadNumber', loadNumber);
			if (vessel && vessel.length) query.set('vessel', vessel);
			if (container && container.length) query.set('container', container);
			if (product) query.set('product', product);
			if (expectedEtbDate) query.set('expectedEtbDate', expectedEtbDate);
			if (realAtbDate) query.set('realAtbDate', realAtbDate);

			return query.toString();
		},
		clearCachedFilters() {
			this.clearStoreFilters();
			this.clearFilters();
		},
		clearFilters() {
			this.$refs.contractInput.clearInput();
			this.$refs.poInput.clearInput();
			this.$refs.loadInput.clearInput();
			this.$refs.transportInput.clearInput();
			this.$refs.containerInput.clearInput();
			this.$refs.productInput.clearInput();
			this.$refs.etsInput.clearInput();
			this.$refs.etaInput.clearInput();

			this.filter = {
				contractNumberSequence: null,
				purchaseOrder: null,
				loadNumber: null,
				vessel: null,
				container: null,
				product: null,
				expectedEtbDate: null,
				realAtbDate: null,
			};

			this.$emit('refreshContracts', null);
			this.$emit('refreshFilters');
		},
		setCachedContractInput() {
			this.$refs.contractInput.setCachedInput();
		},
		setCachedPoInput() {
			this.$refs.poInput.setCachedInput();
		},
		setCachedLoadInput() {
			this.$refs.loadInput.setCachedInput();
		},
		setCachedTransportInput() {
			this.$refs.transportInput.setCachedInput();
		},
		setCachedContainerInput() {
			this.$refs.containerInput.setCachedInput();
		},
		setCachedProductInput() {
			this.$refs.productInput.setCachedInput();
		},
		setCachedEtsInput() {
			this.$refs.etsInput.setCachedInput();
		},
		setCachedEtaInput() {
			this.$refs.etaInput.setCachedInput();
		},
	},
	computed: {
		...mapGetters('user', ['userLang']),
		...mapGetters('loadManagementFilters', [
			'contractsFilterCache',
			'poFilterCache',
			'loadFilterCache',
			'transportFilterCache',
			'containerFilterCache',
			'productFilterCache',
			'etsFilterCache',
			'etaFilterCache',
		]),
	},
	watch: {
		userLang(newValue) {
			if (newValue) {
				this.refreshCalendars = false;
				setTimeout(() => {
					this.refreshCalendars = true;
				}, 100);
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'FilterList.scss';
</style>
