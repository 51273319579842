<template>
	<div class="contract-management-datepicker">
		<MobileDatePicker
			v-if="!isWeb"
			:buttonLabel="$t('modules.contract_management.filter.contract_end_date')"
			:title="'contractDateEnd'"
			ref="contractDateEnd"
			@changeInput="changeInput"
		/>
		<GenericDatepicker
			v-else
			:buttonLabel="$t('modules.contract_management.filter.contract_end_date')"
			:title="'contractDateEnd'"
			:largeLabel="true"
			ref="contractDateEnd"
			@changeInput="changeInput"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SAVE_CONTRACT_END } from '@/store/common/filters/actions';
import MobileDatePicker from '@/components/shared/MobileDatepicker/MobileDatepicker.vue';
import GenericDatepicker from '@/components/shared/GenericDatepicker/GenericDatepicker.vue';

export default {
	name: 'EndInput',
	components: {
		MobileDatePicker,
		GenericDatepicker,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({}),
	mounted() {
		const dateCached = this.contractEndDateCache.value;

		if (dateCached.id != undefined) {
			this.$refs.contractDateEnd.setValue(`${dateCached.id}T00:00:00`);
		}
	},
	methods: {
		...mapActions('filters', { saveEndDate: SAVE_CONTRACT_END }),
		changeInput(param) {
			this.saveEndDate(param);
			this.$emit('filterRequest', param);
		},
		clearInput() {
			this.$refs.contractDateEnd.clearValue();
		},
	},
	computed: {
		...mapGetters('filters', ['contractEndDateCache']),
	},
};
</script>

<style lang="scss" scoped>
@import 'ContractEnd.scss';
</style>
