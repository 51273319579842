<template>
	<GenericMultiselect
		class="regional-multiselect"
		ref="regional"
		:options="regionalOptions"
		:placeholder="'Regional'"
		:fullWidth="!isWeb"
		:darkBlue="!isWeb"
		:searchable="true"
		:needType="true"
		:infiniteScroll="true"
		:multiple="true"
		:type="'regional'"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CONTRACTS_FILTER_REQUEST,
	COMPANY_FILTER_REQUEST,
	SAVE_COMPANY_FILTER,
} from '@/store/common/filters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
export default {
	name: 'RegionalInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('filters', {
			getRegionalFilter: COMPANY_FILTER_REQUEST,
			getRegionalFilter: CONTRACTS_FILTER_REQUEST,
			saveRegionalFilter: SAVE_COMPANY_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveRegionalFilter(param);
			if (param && param.value.length) {
				this.filterRegional(param.value);
			} else {
				this.getRegionalFilter({ vm: this });
			}
			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);
			this.getRegionalFilter({ vm: this, params: query });
		},
		nextPage() {
			const meta = this.companyFilterMeta;
			if (meta.totalPages == this.page) {
				return;
			}
			this.page++;
			let query = this.createQueryString();
			this.getRegionalFilter({ vm: this, params: query });
		},
		createQueryString(name) {
			const query = new URLSearchParams();
			if (name) query.set('name', name);
			if (name) this.page = 1;
			query.set('limit', '20');
			query.set('page', this.page);
			return query.toString();
		},
		clearInput() {
			this.$refs.regional.setValue(null);
		},
		filterRegional(param) {
			const regionalIds = param.map((item) => item.id);
			const query = new URLSearchParams();
			query.set('regionalId', regionalIds);
			query.set('limit', '20');
			query.set('page', '1');
			this.getRegionalFilter({ vm: this, params: query });
		},
		setCachedInput() {
			const cachedFilter = this.companyFilterCache.value;
			if (!cachedFilter || cachedFilter.length <= 0) {
				return;
			}
			this.$refs.regional.setValue(cachedFilter);
			this.filterRegional(cachedFilter);
			this.$emit('filterCachedRequest');
		},
	},
	computed: {
		...mapGetters('filters', [
			'companyFilter',
			'companyFilterMeta',
			'companyFilterCache',
			'isLoadingCompanyFilter',
		]),
		regionalOptions() {
			const rows = this.companyFilter != undefined ? this.companyFilter : [];
			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'RegionalInput.scss';
</style>