<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 21 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="21" height="3" rx="1.5" :fill="color" />
		<rect y="8" width="17" height="3" rx="1.5" :fill="color" />
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#B47D4A',
		},
		height: {
			default: '11',
		},
		width: {
			default: '21',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
