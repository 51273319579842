import { render, staticRenderFns } from "./CogIcon.vue?vue&type=template&id=abc8bd60&scoped=true"
import script from "./CogIcon.vue?vue&type=script&lang=js"
export * from "./CogIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abc8bd60",
  null
  
)

export default component.exports