<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 35 35"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M29.8742 5.12579C23.0398 -1.7086 11.9602 -1.7086 5.12579 5.12579C-1.7086 11.9602 -1.7086 23.0398 5.12579 29.8742C11.9602 36.7086 23.0398 36.7086 29.8742 29.8742C36.7086 23.0398 36.7086 11.9602 29.8742 5.12579ZM26.0753 14.3172C25.687 14.7055 25.2941 15.0984 24.9058 15.4867C23.2005 17.192 21.4997 18.8928 19.7945 20.598C18.7231 21.6649 17.6562 22.7363 16.5893 23.8032C15.9064 24.4862 14.7457 24.4862 14.0627 23.8032C13.8529 23.5934 13.6431 23.3836 13.4378 23.1737C11.9602 21.6917 10.4826 20.2141 9.00501 18.7321C8.29969 18.0268 8.3488 16.9197 9.00501 16.2054C9.65675 15.4957 10.871 15.5448 11.5316 16.2054C11.7414 16.4152 11.9512 16.6251 12.1566 16.8349C13.2146 17.8928 14.2725 18.9553 15.326 20.0132C17 18.3392 18.6696 16.6697 20.3436 14.9957L23.5487 11.7905C24.254 11.0852 25.3611 11.1343 26.0753 11.7905C26.7851 12.4468 26.736 13.6565 26.0753 14.3172Z"
			:fill="color"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#00B564',
		},
		height: {
			default: '35',
		},
		width: {
			default: '35',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
