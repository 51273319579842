<template>
	<GenericMultiselect
		class="name-multiselect"
		ref="name"
		:options="userNameOptions"
		:placeholder="$t('modules.user_management.name_placeholder')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="false"
		:infiniteScroll="true"
		:type="'name'"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { USER_NAME_FILTER_REQUEST } from '@/store/common/filters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'NameInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('filters', {
			getUserNameFilter: USER_NAME_FILTER_REQUEST,
		}),
		changeInput(param) {
			this.page = 1;
			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getUserNameFilter({ vm: this, params: query });
		},
		nextPage() {
			const meta = this.userNameFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getUserNameFilter({ vm: this, params: query });
		},
		createQueryString(name) {
			const query = new URLSearchParams();

			if (name) query.set('name', name);
			if (name) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.name.setValue(null);
		},
	},
	computed: {
		...mapGetters('filters', ['userNameFilter', 'userNameFilterMeta', 'isLoadingUsersFilter']),
		userNameOptions() {
			const rows = this.userNameFilter != undefined ? this.userNameFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style scoped lang="scss">
@import 'NameInput.scss';
</style>
