<template>
	<div class="password">
		<input
			name="password"
			class="password__input"
			:type="fieldType"
			:value="value"
			@input="emitValue($event.target.value)"
		/>
		<div @click="togglePasswordType">
			<EyeClosed v-if="showPassword" class="eye" />
			<EyeOpened v-else class="eye" />
		</div>
	</div>
</template>

<script>
import EyeOpened from '@/components/shared/Icons/EyeOpened.vue';
import EyeClosed from '@/components/shared/Icons/EyeClosed.vue';

export default {
	name: 'InputPassword',
	components: {
		EyeOpened,
		EyeClosed,
	},
	props: {
		value: {
			type: String,
		},
	},
	data: () => ({
		showPassword: false,
	}),
	methods: {
		togglePasswordType() {
			this.showPassword = !this.showPassword;
		},
		emitValue(newValue) {
			this.$emit('input', newValue);
		},
	},
	computed: {
		fieldType() {
			return this.showPassword ? 'text' : 'password';
		},
	},
};
</script>

<style scoped lang="scss">
@import 'InputPassword.scss';
</style>
