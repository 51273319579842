import {
	CLEAR_ALL_DOC_FILTERS,
	//INIT_DATE
	SAVE_INIT_DATE_FILTER,
	CLEAR_INIT_DATE_FILTER,
	//END_DATE
	SAVE_END_DATE_FILTER,
	CLEAR_END_DATE_FILTER,
	//LAST_QUERY
	SAVE_LAST_QUERY_STRING,
	//INVOICE_FILTER
	DOC_INVOICE_FILTER_REQUEST,
	DOC_INVOICE_FILTER_REQUEST_SUCCESS,
	DOC_INVOICE_FILTER_REQUEST_ERROR,
	SAVE_DOC_INVOICE_FILTER,
	CLEAR_DOC_INVOICE_FILTER,
	//CONTRACT_FILTER
	DOC_CONTRACT_FILTER_REQUEST,
	DOC_CONTRACT_FILTER_REQUEST_SUCCESS,
	DOC_CONTRACT_FILTER_REQUEST_ERROR,
	SAVE_DOC_CONTRACT_FILTER,
	CLEAR_DOC_CONTRACT_FILTER,
	//SEQUENCE_FILTER
	DOC_SEQUENCE_FILTER_REQUEST,
	DOC_SEQUENCE_FILTER_REQUEST_SUCCESS,
	DOC_SEQUENCE_FILTER_REQUEST_ERROR,
	SAVE_DOC_SEQUENCE_FILTER,
	CLEAR_DOC_SEQUENCE_FILTER,
	//PO_FILTER
	DOC_PO_FILTER_REQUEST,
	DOC_PO_FILTER_REQUEST_SUCCESS,
	DOC_PO_FILTER_REQUEST_ERROR,
	SAVE_DOC_PO_FILTER,
	CLEAR_DOC_PO_FILTER,
	//CONTAINER_FILTER
	DOC_CONTAINER_FILTER_REQUEST,
	DOC_CONTAINER_FILTER_REQUEST_SUCCESS,
	DOC_CONTAINER_FILTER_REQUEST_ERROR,
	SAVE_DOC_CONTAINER_FILTER,
	CLEAR_DOC_CONTAINER_FILTER,
	//VESSEL_FILTER
	DOC_VESSEL_FILTER_REQUEST,
	DOC_VESSEL_FILTER_REQUEST_SUCCESS,
	DOC_VESSEL_FILTER_REQUEST_ERROR,
	SAVE_DOC_VESSEL_FILTER,
	CLEAR_DOC_VESSEL_FILTER,
	//POD_FILTER
	DOC_POD_FILTER_REQUEST,
	DOC_POD_FILTER_REQUEST_SUCCESS,
	DOC_POD_FILTER_REQUEST_ERROR,
	SAVE_DOC_POD_FILTER,
	CLEAR_DOC_POD_FILTER,
	//CARGO_FILTER
	DOC_CARGO_FILTER_REQUEST,
	DOC_CARGO_FILTER_REQUEST_SUCCESS,
	DOC_CARGO_FILTER_REQUEST_ERROR,
	SAVE_DOC_CARGO_FILTER,
	CLEAR_DOC_CARGO_FILTER,
	//ACRONYM_FILTER
	DOC_ACRONYM_FILTER_REQUEST,
	DOC_ACRONYM_FILTER_REQUEST_SUCCESS,
	DOC_ACRONYM_FILTER_REQUEST_ERROR,
	SAVE_DOC_ACRONYM_FILTER,
	CLEAR_DOC_ACRONYM_FILTER,
	//DESCRIPTION_FILTER
	DOC_DESCRIPTION_FILTER_REQUEST,
	DOC_DESCRIPTION_FILTER_REQUEST_SUCCESS,
	DOC_DESCRIPTION_FILTER_REQUEST_ERROR,
	SAVE_DOC_DESCRIPTION_FILTER,
	CLEAR_DOC_DESCRIPTION_FILTER,
} from './actions';

import { addUniqueItemsToList } from '@/utils/filtersHelper';
import api from '@/utils/api';

const { http } = api.getInstance();

const BASE_URL = '/invoices';

const defaultState = () => ({
	//init_date
	initDateFilter: [],
	initDateFilterCache: {
		value: [],
	},
	//end_date
	endDateFilter: [],
	endDateFilterCache: {
		value: [],
	},
	//INVOICE_FILTER
	invoiceFilter: [],
	invoiceFilterMeta: [],
	invoiceFilterCache: {
		value: [],
	},
	isLoadingInvoiceFilter: '',
	//CONTRACT_FILTER
	contractFilter: [],
	contractFilterMeta: [],
	contractFilterCache: {
		value: [],
	},
	//SEQUENCE_FILTER
	sequenceFilter: [],
	sequenceFilterMeta: [],
	sequenceFilterCache: {
		value: [],
	},
	isLoadingSequenceFilter: '',
	//SEQUENCE_FILTER
	purchaseFilter: [],
	purchaseFilterMeta: [],
	purchaseFilterCache: {
		value: [],
	},
	isLoadingPurchaseFilter: '',
	//SEQUENCE_FILTER
	containerFilter: [],
	containerFilterMeta: [],
	containerFilterCache: {
		value: [],
	},
	isLoadingContainerFilter: '',
	//VESSEL_FILTER
	vesselFilter: [],
	vesselFilterMeta: [],
	vesselFilterCache: {
		value: [],
	},
	isLoadingVesselFilter: '',
	//POD_FILTER
	podFilter: [],
	podFilterMeta: [],
	podFilterCache: {
		value: [],
	},
	isLoadingpodFilter: '',
	//CARGO_FILTER
	cargoFilter: [],
	cargoFilterMeta: [],
	cargoFilterCache: {
		value: [],
	},
	isLoadingCargoFilter: '',
	//ACRONYM_FILTER
	acronymFilter: [],
	acronymFilterMeta: [],
	acronymFilterCache: {
		value: [],
	},
	isLoadingAcronymFilter: '',
	//DESCRIPTION_FILTER
	descriptionFilter: [],
	descriptionFilterMeta: [],
	descriptionFilterCache: {
		value: [],
	},
	isLoadingDescriptionFilter: '',
	//lastQueryString
	lastQueryString: '',
});

const state = defaultState();

const getters = {
	//init_date
	initDateFilter: (state) => state.initDateFilter,
	initDateFilterCache: (state) => state.initDateFilterCache,
	//end_date
	endDateFilter: (state) => state.endDateFilter,
	endDateFilterCache: (state) => state.endDateFilterCache,
	//INVOICE_FILTER
	invoiceFilter: (state) => state.invoiceFilter,
	invoiceFilterMeta: (state) => state.invoiceFilterMeta,
	invoiceFilterCache: (state) => state.invoiceFilterCache,
	isLoadingInvoiceFilter: (state) => state.isLoadingInvoiceFilter === 'loading',
	//CONTRACT_FILTER
	contractFilter: (state) => state.contractFilter,
	contractFilterMeta: (state) => state.contractFilterMeta,
	contractFilterCache: (state) => state.contractFilterCache,
	isLoadingContractFilter: (state) => state.isLoadingContractFilter === 'loading',
	//SEQUENCE_FILTER
	sequenceFilter: (state) => state.sequenceFilter,
	sequenceFilterMeta: (state) => state.sequenceFilterMeta,
	sequenceFilterCache: (state) => state.sequenceFilterCache,
	isLoadingSequenceFilter: (state) => state.isLoadingSequenceFilter === 'loading',
	//PO_FILTER
	purchaseFilter: (state) => state.purchaseFilter,
	purchaseFilterMeta: (state) => state.purchaseFilterMeta,
	purchaseFilterCache: (state) => state.purchaseFilterCache,
	isLoadingPurchaseFilter: (state) => state.isLoadingPurchaseFilter === 'loading',
	//CONTAINER_FILTER
	containerFilter: (state) => state.containerFilter,
	containerFilterMeta: (state) => state.containerFilterMeta,
	containerFilterCache: (state) => state.containerFilterCache,
	isLoadingContainerFilter: (state) => state.isLoadingContainerFilter === 'loading',
	//VESSEL_FILTER
	vesselFilter: (state) => state.vesselFilter,
	vesselFilterMeta: (state) => state.vesselFilterMeta,
	vesselFilterCache: (state) => state.vesselFilterCache,
	isLoadingVesselFilter: (state) => state.isLoadingVesselFilter === 'loading',
	//POD_FILTER
	podFilter: (state) => state.podFilter,
	podFilterMeta: (state) => state.podFilterMeta,
	podFilterCache: (state) => state.podFilterCache,
	isLoadingPodFilter: (state) => state.isLoadingPodFilter === 'loading',
	//CARGO_FILTER
	cargoFilter: (state) => state.cargoFilter,
	cargoFilterMeta: (state) => state.cargoFilterMeta,
	cargoFilterCache: (state) => state.cargoFilterCache,
	isLoadingCargoFilter: (state) => state.isLoadingCargoFilter === 'loading',
	//ACRONYM_FILTER
	acronymFilter: (state) => state.acronymFilter,
	acronymFilterMeta: (state) => state.acronymFilterMeta,
	acronymFilterCache: (state) => state.acronymFilterCache,
	isLoadingAcronymFilter: (state) => state.isLoadingAcronymFilter === 'loading',
	//DESCRIPTION_FILTER
	descriptionFilter: (state) => state.descriptionFilter,
	descriptionFilterMeta: (state) => state.descriptionFilterMeta,
	descriptionFilterCache: (state) => state.descriptionFilterCache,
	isLoadinDescriptionFilter: (state) => state.isLoadinDescriptionFilter === 'loading',
	//lastQueryString
	lastQueryString: (state) => state.lastQueryString,
};

const mutations = {
	[CLEAR_ALL_DOC_FILTERS]: (state) => {
		state.initDateFilterCache = {
			value: [],
		};
		state.endDateFilterCache = {
			value: [],
		};
		state.invoiceFilterCache = {
			value: [],
		};
		state.contractFilterCache = {
			value: [],
		};
		state.sequenceFilterCache = {
			value: [],
		};
		state.purchaseFilterCache = {
			value: [],
		};
		state.containerFilterCache = {
			value: [],
		};
		state.vesselFilterCache = {
			value: [],
		};
		state.podFilterCache = {
			value: [],
		};
		state.cargoFilterCache = {
			value: [],
		};
		state.acronymFilterCache = {
			value: [],
		};
		state.descriptionFilterCache = {
			value: [],
		};
		state.lastQueryString = '';
	},
	//INIT_DATE
	[SAVE_INIT_DATE_FILTER]: (state, data) => {
		state.initDateFilterCache = data;
	},
	[CLEAR_INIT_DATE_FILTER]: (state) => {
		state.initDateFilter = [];
	},
	//END_DATE
	[SAVE_END_DATE_FILTER]: (state, data) => {
		state.endDateFilterCache = data;
	},
	[CLEAR_END_DATE_FILTER]: (state) => {
		state.endDateFilter = [];
	},
	//INVOICE
	[DOC_INVOICE_FILTER_REQUEST]: (state) => {
		state.invoiceFilterMeta = [];
		state.isLoadingInvoiceFilter = 'loading';
	},
	[DOC_INVOICE_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.invoiceFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.invoiceFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({
				id: item.invoiceNumber,
				name: item.invoiceNumber,
			}));
			addUniqueItemsToList(state, formattedItems, 'invoiceFilter', 'name');
		}

		state.isLoadingInvoiceFilter = 'success';
	},
	[DOC_INVOICE_FILTER_REQUEST_ERROR]: (state) => {
		state.invoiceFilter = [];
		state.invoiceFilterMeta = [];
		state.isLoadingInvoiceFilter = 'error';
	},
	[SAVE_DOC_INVOICE_FILTER]: (state, data) => {
		state.invoiceFilterCache = data;
	},
	[CLEAR_DOC_INVOICE_FILTER]: (state) => {
		state.invoiceFilter = [];
	},
	//CONTRACT_FILTER
	[DOC_CONTRACT_FILTER_REQUEST]: (state) => {
		state.contractFilterMeta = [];
		state.isLoadingContractFilter = 'loading';
	},
	[DOC_CONTRACT_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.contractFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.contractFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({
				id: item.contractNumber,
				name: item.contractNumber,
			}));
			addUniqueItemsToList(state, formattedItems, 'contractFilter', 'name');
		}

		state.isLoadingContractFilter = 'success';
	},
	[DOC_CONTRACT_FILTER_REQUEST_ERROR]: (state) => {
		state.contractFilter = [];
		state.contractFilterMeta = [];
		state.isLoadingContractFilter = 'error';
	},
	[SAVE_DOC_CONTRACT_FILTER]: (state, data) => {
		state.contractFilterCache = data;
	},
	[CLEAR_DOC_CONTRACT_FILTER]: (state) => {
		state.contractFilter = [];
	},
	//SEQUENCE_FILTER
	[DOC_SEQUENCE_FILTER_REQUEST]: (state) => {
		state.sequenceFilterMeta = [];
		state.isLoadingSequenceFilter = 'loading';
	},
	[DOC_SEQUENCE_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.sequenceFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.sequenceFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item.sequence, name: item.sequence }));
			addUniqueItemsToList(state, formattedItems, 'sequenceFilter', 'name');
		}

		state.isLoadingSequenceFilter = 'success';
	},
	[DOC_SEQUENCE_FILTER_REQUEST_ERROR]: (state) => {
		state.sequenceFilter = [];
		state.sequenceFilterMeta = [];
		state.isLoadingSequenceFilter = 'error';
	},
	[SAVE_DOC_SEQUENCE_FILTER]: (state, data) => {
		state.sequenceFilterCache = data;
	},
	[CLEAR_DOC_SEQUENCE_FILTER]: (state) => {
		state.sequenceFilter = [];
	},
	//PO_FILTER
	[DOC_PO_FILTER_REQUEST]: (state) => {
		state.purchaseFilterMeta = [];
		state.isLoadingPurchaseFilter = 'loading';
	},
	[DOC_PO_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.purchaseFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.purchaseFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({
				id: item.purchaseOrder,
				name: item.purchaseOrder,
			}));
			addUniqueItemsToList(state, formattedItems, 'purchaseFilter', 'name');
		}

		state.isLoadingPurchaseFilter = 'success';
	},
	[DOC_PO_FILTER_REQUEST_ERROR]: (state) => {
		state.purchaseFilter = [];
		state.purchaseFilterMeta = [];
		state.isLoadingPurchaseFilter = 'error';
	},
	[SAVE_DOC_PO_FILTER]: (state, data) => {
		state.purchaseFilterCache = data;
	},
	[CLEAR_DOC_PO_FILTER]: (state) => {
		state.purchaseFilter = [];
	},
	//CONTAINER_FILTER
	[DOC_CONTAINER_FILTER_REQUEST]: (state) => {
		state.containerFilterMeta = [];
		state.isLoadingContainerFilter = 'loading';
	},
	[DOC_CONTAINER_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.containerFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.containerFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({
				id: item.container,
				name: item.container,
			}));
			addUniqueItemsToList(state, formattedItems, 'containerFilter', 'name');
		}

		state.isLoadingContainerFilter = 'success';
	},
	[DOC_CONTAINER_FILTER_REQUEST_ERROR]: (state) => {
		state.containerFilter = [];
		state.containerFilterMeta = [];
		state.isLoadingContainerFilter = 'error';
	},
	[SAVE_DOC_CONTAINER_FILTER]: (state, data) => {
		state.containerFilterCache = data;
	},
	[CLEAR_DOC_CONTAINER_FILTER]: (state) => {
		state.containerFilter = [];
	},
	//VESSEL_FILTER
	[DOC_VESSEL_FILTER_REQUEST]: (state) => {
		state.vesselFilterMeta = [];
		state.isLoadingVesselFilter = 'loading';
	},
	[DOC_VESSEL_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.vesselFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.vesselFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item.vehicle, name: item.vehicle }));
			addUniqueItemsToList(state, formattedItems, 'vesselFilter', 'name');
		}

		state.isLoadingVesselFilter = 'success';
	},
	[DOC_VESSEL_FILTER_REQUEST_ERROR]: (state) => {
		state.vesselFilter = [];
		state.vesselFilterMeta = [];
		state.isLoadingVesselFilter = 'error';
	},
	[SAVE_DOC_VESSEL_FILTER]: (state, data) => {
		state.vesselFilterCache = data;
	},
	[CLEAR_DOC_VESSEL_FILTER]: (state) => {
		state.vesselFilter = [];
	},
	//POD_FILTER
	[DOC_POD_FILTER_REQUEST]: (state) => {
		state.podFilterMeta = [];
		state.isLoadingPodFilter = 'loading';
	},
	[DOC_POD_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.podFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.podFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item.pod, name: item.pod }));
			addUniqueItemsToList(state, formattedItems, 'podFilter', 'name');
		}

		state.isLoadingPodFilter = 'success';
	},
	[DOC_POD_FILTER_REQUEST_ERROR]: (state) => {
		state.podFilter = [];
		state.podFilterMeta = [];
		state.isLoadingPodFilter = 'error';
	},
	[SAVE_DOC_POD_FILTER]: (state, data) => {
		state.podFilterCache = data;
	},
	[CLEAR_DOC_POD_FILTER]: (state) => {
		state.podFilter = [];
	},
	//CARGO_FILTER
	[DOC_CARGO_FILTER_REQUEST]: (state) => {
		state.cargoFilterMeta = [];
		state.isLoadingCargoFilter = 'loading';
	},
	[DOC_CARGO_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.cargoFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.cargoFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item.load, name: item.load }));
			addUniqueItemsToList(state, formattedItems, 'cargoFilter', 'name');
		}

		state.isLoadingCargoFilter = 'success';
	},
	[DOC_CARGO_FILTER_REQUEST_ERROR]: (state) => {
		state.cargoFilter = [];
		state.cargoFilterMeta = [];
		state.isLoadingCargoFilter = 'error';
	},
	[SAVE_DOC_CARGO_FILTER]: (state, data) => {
		state.cargoFilterCache = data;
	},
	[CLEAR_DOC_CARGO_FILTER]: (state) => {
		state.cargoFilter = [];
	},
	//ACRONYM_FILTER
	[DOC_ACRONYM_FILTER_REQUEST]: (state) => {
		state.acronymFilterMeta = [];
		state.isLoadingAcronymFilter = 'loading';
	},
	[DOC_ACRONYM_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.acronymFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.acronymFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item.sku, name: item.sku }));
			addUniqueItemsToList(state, formattedItems, 'acronymFilter', 'name');
		}

		state.isLoadingAcronymFilter = 'success';
	},
	[DOC_ACRONYM_FILTER_REQUEST_ERROR]: (state) => {
		state.acronymFilter = [];
		state.acronymFilterMeta = [];
		state.isLoadingAcronymFilter = 'error';
	},
	[SAVE_DOC_ACRONYM_FILTER]: (state, data) => {
		state.acronymFilterCache = data;
	},
	[CLEAR_DOC_ACRONYM_FILTER]: (state) => {
		state.acronymFilter = [];
	},
	//DESCRIPTION_FILTER
	[DOC_DESCRIPTION_FILTER_REQUEST]: (state) => {
		state.descriptionFilterMeta = [];
		state.isLoadingDescriptionFilter = 'loading';
	},
	[DOC_DESCRIPTION_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.descriptionFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.descriptionFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({
				id: item.description,
				name: item.description,
			}));
			addUniqueItemsToList(state, formattedItems, 'descriptionFilter', 'name');
		}

		state.isLoadingDescriptionFilter = 'success';
	},
	[DOC_DESCRIPTION_FILTER_REQUEST_ERROR]: (state) => {
		state.descriptionFilter = [];
		state.descriptionFilterMeta = [];
		state.isLoadingDescriptionFilter = 'error';
	},
	[SAVE_DOC_DESCRIPTION_FILTER]: (state, data) => {
		state.descriptionFilterCache = data;
	},
	[CLEAR_DOC_DESCRIPTION_FILTER]: (state) => {
		state.descriptionFilter = [];
	},
	//LAST QUERY STRING
	[SAVE_LAST_QUERY_STRING]: (state, data) => {
		state.lastQueryString = data;
	},
};

const actions = {
	[CLEAR_ALL_DOC_FILTERS]: ({ commit }) => {
		commit(CLEAR_ALL_DOC_FILTERS);
	},
	//INIT_DATE
	[SAVE_INIT_DATE_FILTER]: ({ commit }, data) => {
		commit(SAVE_INIT_DATE_FILTER, data);
	},
	//END_DATE
	[SAVE_END_DATE_FILTER]: ({ commit }, data) => {
		commit(SAVE_END_DATE_FILTER, data);
	},
	//INVOICE
	[DOC_INVOICE_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_INVOICE_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/invoices?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/invoices?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/invoices?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/invoices?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_INVOICE_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_INVOICE_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DOC_INVOICE_FILTER]: ({ commit }, data) => {
		commit(SAVE_DOC_INVOICE_FILTER, data);
	},
	//CONTRACT_FILTER
	[DOC_CONTRACT_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_CONTRACT_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/contracts?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/contracts?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/contracts?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/contracts?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_CONTRACT_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_CONTRACT_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DOC_CONTRACT_FILTER]: ({ commit }, data) => {
		commit(SAVE_DOC_CONTRACT_FILTER, data);
	},
	//SEQUENCE_FILTER
	[DOC_SEQUENCE_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_SEQUENCE_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/sequences?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/sequences?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/sequences?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/sequences?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_SEQUENCE_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_SEQUENCE_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DOC_SEQUENCE_FILTER]: ({ commit }, data) => {
		commit(SAVE_DOC_SEQUENCE_FILTER, data);
	},
	//PO_FILTER
	[DOC_PO_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_PO_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/purchaseOrders?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/purchaseOrders?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/purchaseOrders?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/purchaseOrders?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_PO_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_PO_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DOC_PO_FILTER]: ({ commit }, data) => {
		commit(SAVE_DOC_PO_FILTER, data);
	},
	//CONTAINER_FILTER
	[DOC_CONTAINER_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_CONTAINER_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/containers?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/containers?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/containers?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/containers?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_CONTAINER_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_CONTAINER_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DOC_CONTAINER_FILTER]: ({ commit }, data) => {
		commit(SAVE_DOC_CONTAINER_FILTER, data);
	},
	//VESSEL_FILTER
	[DOC_VESSEL_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_VESSEL_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/vehicle?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/vehicle?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/vehicle?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/vehicle?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_VESSEL_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_VESSEL_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DOC_VESSEL_FILTER]: ({ commit }, data) => {
		commit(SAVE_DOC_VESSEL_FILTER, data);
	},
	//POD_FILTER
	[DOC_POD_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_POD_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/pod?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/pod?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/pod?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/pod?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_POD_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_POD_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DOC_POD_FILTER]: ({ commit }, data) => {
		commit(SAVE_DOC_POD_FILTER, data);
	},
	//CARGO_FILTER
	[DOC_CARGO_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_CARGO_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/load?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/load?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/load?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/load?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_CARGO_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_CARGO_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DOC_CARGO_FILTER]: ({ commit }, data) => {
		commit(SAVE_DOC_CARGO_FILTER, data);
	},
	//ACRONYM_FILTER
	[DOC_ACRONYM_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_ACRONYM_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/sku?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/sku?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/sku?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/sku?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_ACRONYM_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_ACRONYM_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DOC_ACRONYM_FILTER]: ({ commit }, data) => {
		commit(SAVE_DOC_ACRONYM_FILTER, data);
	},
	//DESCRIPTION_FILTER
	[DOC_DESCRIPTION_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_DESCRIPTION_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/description?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/description?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/description?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/description?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_DESCRIPTION_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_DESCRIPTION_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DOC_DESCRIPTION_FILTER]: ({ commit }, data) => {
		commit(SAVE_DOC_DESCRIPTION_FILTER, data);
	},
	//LAST QUERY STRING
	[SAVE_LAST_QUERY_STRING]: ({ commit }, data) => {
		commit(SAVE_LAST_QUERY_STRING, data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
