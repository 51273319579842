import tableBR from './contractTable/table-br';

export default {
	buttons: {
		cancel: 'Cancelar',
		login: 'Entrar',
		next: 'Próximo',
		ok: 'OK',
		save: 'Salvar',
		save_password: 'Salvar senha',
		filter: 'Filtrar',
		filters: 'Filtros',
		clear_filter: 'Limpar Filtros',
		add: 'Adicionar',
		delete: 'Excluir',
		inative: 'Inativar',
		edit_column: 'Editar colunas',
		confirm: 'Confirmar',
		back: 'Voltar',
		keep_inactive: 'Manter Inativo',
		keep_active: 'Ativar Usuário',
		previous: 'Anterior',
		seeMore: 'Ver mais',
		seeLess: 'Ver menos',
		continue: 'Continuar',
		disable: 'Desativar',
		active: 'Ativar',
		new_template: 'Novo template',
		save_template: 'Salvar template',
		change_template: 'Alteração do template',
		add_bank: 'Adicionar Banco',
		notify: 'Adicionar destinatário/notificar',
		edit: 'Editar',
		disapprove: 'Reprovar',
		remove: 'Remover',
	},
	errors: {
		login_required: 'Preencha o campo login.',
		password_required: 'Preencha o campo senha.',
		empty_data: 'Não há dados para serem exibidos!',
		dashboard_empty_data: 'Nenhuma nova atualização!',
		fill_fields: 'Preencha todos os campos.',
		email_invalid: 'Email inválido.',
		passwords_rules:
			'Verifique se a senha possui 8 caracteres, uma letra maíuscula, uma letra minúscula, um número e um caractere especial.',
		passwords_dismatch: 'As senhas não batem.',
		fixed_column: 'Essa coluna não pode ser desativada.',
		no_options: 'Nenhuma opção encontrada.',
		no_items: 'Não há itens a serem exibidos',
		get_items_error: 'Erro ao carregar Itens',
		documents_error: 'Não há documentos a serem exibidos!',
		template_name_required: 'O nome do template é obrigatório',
	},
	sidebar: {
		user_management: 'Usuários',
		contract_management: 'Contratos',
		contract_details: 'Detalhes do Contrato',
		dashboard: 'Dashboard',
		load_management: 'Gestão de Cargas',
		doc_management: 'Gestão de Doc.',
		financial_management: 'Gestão Financeira',
		doc_instruction: 'Ins. Documentária',
	},
	header: {
		user_management: 'Gestão de Usuários',
		contract_management: 'Gestão de Contratos',
		contract_details: 'Detalhes do Contrato',
		header_contracts: 'Contrato',
		header_sequences: 'Sequência',
		header_loads: 'Carga',
		header_po: 'PO',
		dashboard: 'Dashboard',
		load_management: 'Gestão de Cargas',
		customer_company: 'Empresa cliente',
		doc_management: 'Gestão de Documentos',
		financial_management: 'Gestão Financeira',
		doc_instruction: 'Instrução Documentária',
		new_template: 'Novo Template',
		edit_template: 'Editar Template',
	},
	months: {
		jan: 'Jan',
		feb: 'Fev',
		mar: 'Mar',
		apr: 'Abr',
		may: 'Mai',
		jun: 'Jun',
		jul: 'Jul',
		aug: 'Ago',
		sep: 'Set',
		oct: 'Out',
		nov: 'Nov',
		dec: 'Dez',
	},
	contract_status: {
		contract: 'Contrato',
		load: 'Carga',
		process: 'Processo',
		container: 'Container',
		invoice: 'Invoice',
		finished: 'Finalizado',
	},
	contract_step: {
		creation: 'Criação',
		released_seara_restriction: 'Liberado com Restrição Seara',
		released_customer_restriction: 'Liberado com Restrição Cliente',
		released: 'Liberado',
		programmed: 'Programada',
		planned: 'Planejada',
		billed: 'Faturada',
		issued_document: 'CSI Emitido',
		gate_in: 'Gate In Origin',
		pre_payment_pending: 'Aguardando Pré Pgto',
		rid: 'Desembaraçada',
		draft_bl: 'Draft BL e VGM Enviado',
		boarded: 'Embarcado',
		documentation_issued: 'Documentação Emitida',
		documentation_sended: 'Documentação Enviada',
		documentation_delivered: 'Documentação Entregue',
		shipped_origin: 'Embarcado Origem',
		transshipment_port: 'No Porto Transbordo',
		landed_destination: 'Desembarcado no Destino',
		gate_out: 'Gate Out Destino',
		empty_return: 'Vazio Devolvido',
		opened: 'Aberta',
		opened_delay: 'Aberta em Atraso',
		partial_paid: 'Pago Parcial',
		total_paid: 'Pago Total',
		finished: 'Finalizado',
		processing: 'Em Processamento',
		in_production: 'Em Produção',
		in_loading: 'Em carregamento',
		delivered_in_pod: 'Entregue no POD',
	},
	modules: {
		login: {
			login_label: 'Login',
			password_label: 'Senha',
			forgot_password: 'Esqueceu sua senha?',
			forgot_password_title: 'Esqueci minha senha',
			forgot_password_text:
				'Esqueceu sua senha? Informe seu <strong>e-mail</strong> abaixo para receber um link de reset de senha.',
			email_sended: 'E-mail enviado com o link para criar a nova senha!',
			code_sended: 'Código Enviado',
			verify_email:
				'Por favor, verifique seu e-mail e digite abaixo o código recebido de 6 digitos para fazer o login:',
		},
		resetPassword: {
			new_password_label: 'Nova senha',
			confirm_password_label: 'Confirmar Nova senha',
			new_password_title: 'Nova senha',
			new_password_text: 'Agora você já pode alterar sua senha novamente:',
			password_changed_succesfully: 'Senha cadastrada com sucesso!',
			rules_title: 'Deve possuir os critérios para nova senha:',
			rule_uppercase: 'Possuir pelo menos uma letra maiúscula',
			rule_lowercase: 'Possuir pelo menos uma letra minúscula',
			rule_number: 'Possuir pelo menos um número',
			rule_length: 'Possuir pelo menos 8 caracteres',
			rule_special: 'Possuir pelo menos um caracter especial (!, @, #, $, %, &, *, ., etc)',
		},
		user_management: {
			name_placeholder: 'Digite o nome de usuário',
			email_placeholder: 'Digite o e-mail do usuário',
			company_placeholder: 'Digite o nome da empresa',
			status_placeholder: 'Status',
			profiles: {
				client: 'Cliente',
				intermediary: 'Intermediário',
				internal: 'Interno',
			},
			status: {
				active: 'Ativo',
				suspended: 'Suspenso',
			},
			requests_messages: {
				user_created: 'Usuário criado com sucesso!',
				user_updated: 'Usuário atualizado com sucesso!',
				user_deleted: 'Usuário inativado com sucesso.',
				user_reactived: 'Usuário reativado com sucesso.'
			},
			modal: {
				new_user: 'Cadastro de Usuário',
				edit_user: 'Editar Usuário',
				delete_question: 'Você tem certeza que deseja inativar esse usuário?',
				reactive_question: 'Você tem certeza que deseja reativar esse usuário?',
				name_label: 'Nome',
				name_placeholder: 'Seu nome completo',
				company_label: 'Empresa',
				company_placeholder: 'Nome fantasia da sua empresa',
				email_label: 'E-mail',
				email_placeholder: 'Seu e-mail',
				phone_label: 'Celular',
				phone_placeholder: '+XX (XX) X XXXX - XXXX',
				profile_label: 'Perfil',
				profile_placeholder: 'Selecione o perfil do usuário',
				status_label: 'Status',
				status_placeholder: 'Selecione o status do usuário',
				company_manager_label: 'Empresas que gerencia',
				company_manager_placeholder: 'Selecione as empresas que gerencia',
				confirmation_active:
					'Você deseja ativar esse usuário? <br /> Ao ativar o usuário o mesmo receberá um e-mail de boas vindas.<br/> Deseja prosseguir?',
			},
			table: {
				name: 'Nome',
				email: 'E-mail',
				code: 'Código',
				status: 'Status',
			},
		},
		contract_management: {
			table: tableBR,
			filter: {
				start_date: 'Data de Lançamento Inicial:',
				end_date: 'Data de Lançamento Final:',
				contract_start_date: 'Data Inicial do Contrato:',
				contract_end_date: 'Data Final do Contrato:',
				contract_placeholder: 'Contrato',
				company_placeholder: 'Empresa',
				transport_placeholder: 'Transporte',
				product_placeholder: 'Produto',
				purchaseOrder_placeholder: 'PO',
				market_placeholder: 'Mercado',
			},
			column_modal: {
				title_modal: 'Editar Colunas',
				header_columns: 'Cabeçalho (por Contrato)',
				details_columns: 'Detalhes (por Carga)',
				active_status: 'Ativo',
				disable_status: 'Desabilitado',
				exit_question: 'Deseja sair sem salvar as alterações?',
			},
			requests_messages: {
				preferences_updated: 'Colunas salvas com sucesso.',
			},
		},
		contract_detail: {
			card: {
				title_tracking: 'Tracking do Contrato',
				title_contract: 'Contrato',
				title_sequence: 'Sequência',
				title_load: 'Carga',
				title_document: 'Documentos',
			},
			contract: {
				companyName: 'Cliente',
				competenceMonth: 'Mês de Competência',
				contractDate: 'Data',
				contractNumber: 'Número do contrato',
				incoterm: 'Incoterm',
				market: 'Mercado',
				paymentMethod: 'Modalidade de Pagamento',
				pod: 'País de Destino',
				priceType: 'Moeda',
				purchaseOrder: 'PO',
				purchaseOrderClient: 'PO do Cliente',
				releaseDate: 'Data de criação do contrato',
				totalPendingVolume: 'Volume pendente de carregamento ',
				totalVolume: 'Volume Total',
				traderName: 'Trader',
				approved: 'Aprovado',
				disapproved: 'Reprovado',
				approve: 'Aprovar',
				disapprove: 'Reprovar',
				preposition: 'às',
			},
			sequence: {
				brand: 'Marca do produto',
				cneCode: 'Cod. Carta de Crédito',
				contractSequence: 'Cod. Sequência',
				dsInternationalOfc: 'Ds Internacional Ofc',
				endShipDate: 'Embarque Data fim',
				groupingDescription: 'Agrupamento',
				permissionImt: 'Licença de importação',
				permissionImtDate: 'Dt. Vencimento IMT',
				releaseSequenceDate: 'Data liberação sequência',
				sku: 'Cod. produto',
				startShipDate: 'Embarque Data inicio',
				statusSequence: 'Status Seq',
				totalVolume: 'Volume',
				unitPrice: 'Valor unitário',
				weeksToShip: 'Semana embarque',
				initials: 'Nome do Produto',
			},
			load: {
				invoicedDate: 'Dt. faturado',
				loadNumber: 'Código da carga',
				loadPlace: 'Local carregamento',
				productionDate: 'Data produção',
				totalGrossWeight: 'Peso bruto carregado',
				totalKg: 'Volume/ Quantidade carga',
				totalVolume: 'Volume/ Quantidade vol',
			},
			documents: {
				awbCourier: 'Courier AWB (Cliente)',
				billLanding: 'BL/CRT/AWB',
				billLandingDate: 'BL/CRT/AWB Data',
				blDate: 'BL Data',
				courierDate: 'Courier Envio (Cliente)',
				csiIssuanceDate: 'Data emissão CSI',
				invoiceNumber: 'Invoice',
				courierCompany: 'Courier (Cliente)',
				bankAwbCourier: 'Courier AWB (Banco)',
				bankCourierDate: 'Courier Envio (Banco)',
				bankCourierCompany: 'Courier (Banco)',
			},
			table: {
				title_international: 'Transporte Internacional',
				title_maritime: 'Transporte Marítimo',
				title_mercosul: 'Transporte Mercosul',
			},
			internationalTransport: {
				boardingType: 'Tipo embarque',
				origin: 'Local Origem ',
				destination: 'Local Destino',
				expectedEtdDate: 'ETS POL',
				expectedEtbDate: 'ATS POL',
				realAtdDate: 'ETA POD',
				realAtbDate: 'ETA POD',
			},
			maritimeTransport: {
				provider: 'Transportador',
				vessel: 'Veículo',
				container: 'Contêiner',
				bookingNumber: 'Reserva',
			},
			mercosurTransport: {
				shippingCompany: 'Transportadora',
				vehiclePlate: 'Placa do navio',
				localization: 'Localização',
				borderCrossingDate: 'Dt. Passagem Fronteira',
			},
		},
		dashboard: {
			table: {
				title: 'Últimas atualizações de status',
				contract: 'Contrato',
				sequence: 'Sequência',
				load: 'Carga',
				transport: 'Veículo',
				invoice: 'Invoice',
				code: 'COD.PRO',
				status: 'Status',
				volume: 'Peso líquido',
			},
			charts: {
				analysis_title: 'Em análise',
				finance_title: 'Financeiro',
				rounded: {
					in_process: 'Em processamento',
					release_retrictions: ['Liberado com ', 'restrição'],
					released: 'Liberado',
					open: 'Aberta',
					open_delay: ['Aberta em ', 'atraso'],
					partial_paid: 'Pago parcial',
					paid: 'Pago total',
					finished: 'Finalizado',
				},
			},
			operation_chart: {
				operation_title: 'Em Operação',
				in_production: 'Em produção',
				in_charging: 'Em Carregamento',
				gate_in: 'Gate In Origin',
				wait_prepayment: 'Aguardando Pré Pgto',
				embedded_doc: 'Documentação Entregue',
				sent_doc: 'Documentação Enviada',
				delivered: 'Embarcado',
				delivered_pod: 'Entregue no POD',
				gate_out: 'Gate Out Detino',
				empty_returned: 'Vazio Devolvido',
			},
		},
		load_management: {
			filter: {
				contract: 'Contrato / Sequência',
				po: 'PO',
				load: 'Carga',
				transport: 'Navio / Caminhão',
				container: 'Container',
				product: 'Produto ( Sigla - Desc.)',
				ets: 'ETS / ATS POL',
				eta: 'ETA POD',
			},
			sections: {
				inProduction: 'Em Produção',
				loading: 'Em Carregamento',
				gateInOrigin: 'Gate In Origem',
				shipped: 'Embarcado',
				deliveredToPOD: 'Delivered to POD',
				gateOutDestination: 'Gate Out Destino',
			},
			cargoTable: {
				cargo: 'Carga',
				container: 'Container',
				invoice: 'Invoice',
				vehicle: 'Veículo',
				POD: 'POD',
				gateIn: 'Gate In',
				ets: 'ETS / ATS POL',
				eta: 'ETA POD',
				gateOut: 'Gate Out',
				netWeight: 'Peso Líquido',
			},
			contractTable: {
				sequence: 'Sequência',
				acronym: 'Item',
				description: 'Descrição',
				brand: 'Marca',
				sif: 'SIF',
				volume: 'Número de Caixas',
				weight: 'Peso líquido',
			},
			map: {
				contracts: 'Contratos',
				products: 'Produtos',
				loads: 'Cargas',
				weigth: 'Peso líquido',
				awaiting_payment: 'Aguardando pré-pagamento',
			},
		},
		financial_management: {
			titles: {
				pre_payment: 'Pré-pagamento',
				invoice: 'Invoice',
				overdue: 'Vencido',
				settle: 'A vencer',
				credit: 'Crédito',
				limit: 'Limite atual',
				balance_available: 'Saldo disponível',
			},
			filter: {
				market: 'Mercado',
				init_date: 'De:',
				end_date: 'Até:',
				currency: 'Moeda',
				status: 'Status',
			},
			status: {
				paid: 'PAGO',
				awaiting_paid: 'NÃO PAGO',
				overdue: 'ATRASADO',
			},
			table: {
				due_date: 'Vencimento',
				contract: 'Contrato',
				invoice: 'Invoice',
				total_invoice: 'Total invoice',
				po: 'PO',
				currency: 'Moeda',
				status: 'Status',
				total: 'Total',
				payment: 'Pagamento',
				balance: 'Saldo',
			},
			currency: {
				dolar: 'DÓLAR',
				euro: 'EURO',
				yen: 'YEN',
			},
		},
		doc_management: {
			filter: {
				init_date: 'De:',
				end_date: 'Até:',
				invoice: 'Invoice',
				contract: 'Contrato',
				sequence: 'Sequência',
				po: 'PO',
				container: 'Container',
				transport: 'Navio / Caminhão',
				pod: 'POD',
				load: 'Carga',
				acronym: 'Sigla',
				description: 'Descrição',
			},
			doc_row: {
				invoice: 'Invoice',
				vehicle: 'Veículo',
				pol: 'POL',
				atsPol: 'ATS POL',
				pod: 'POD',
				etaPod: 'ETA POD',
				plTon: 'PL Ton',
				value: 'Valor',
				download: 'Download',
				payment_status: 'Status do Pagamento',
				approval_status: 'Status da Aprovação',
				shipment_status: 'Status do Envio',
			},
			doc_detail: {
				contract: 'Contrato',
				condition: 'Cond. Pgto',
				term: 'Prazo',
				type: 'Tipo',
				publication: 'Dt Publicação',
				responsible: 'Responsável',
				approval: 'Dt. Aprovação',
				approver: 'Aprovador',
				observation: 'Observação',
				download: 'Download',
				status: 'Status',
				not_sended: 'Não Enviado',
				pending: 'Pendente',
				viewed: 'Visualizado',
				approved: 'Aprovado',
				repproved: 'Reprovado',
				cancelled: 'Cancelado',
			},
			doc_table: {
				container: 'Contêiner',
				cargo: 'Carga',
				po: 'PO',
				plton: 'PL Ton',
				sequence: 'Sequência',
				courier: 'Courier',
				awb: 'AWB',
				shipping: 'Dt. Envio',
				destiny: 'Destino',
				delivery: 'Dt. Entrega',
				status: 'Status',
				notSent: 'Não Enviado',
				delivered: 'Entregue',
				inTransit: 'Em trânsito',
			},
			reproval_modal: {
				title: 'Tipo',
				subtitle: 'Deseja adicionar uma observação em sua reprovação?',
				comment: 'Comentário',
				approved: 'Documento aprovado com sucesso!',
				repproved: 'Documento reprovado com sucesso!',
			},
		},
		doc_instruction: {
			filter: {
				pod: 'POD',
				acro_description: 'Sigla / Descrição',
				contract: 'Contrato / Sequência',
			},
			steps: {
				title: 'Passo',
				selectTheContract: 'Selecione o contrato',
				selectDocumentaryInstruction: 'Selecione a instrução documentária',
				confirmTheData: 'Confirme os dados',
				enterDestination: 'Informe o destino',
				notFound: 'Passo não encontrado',
				step_one_description:
					'Selecione os contratos que deseja cadastrar a instrução documentária',
				step_two_description:
					'Selecione a instrução documentária que deseja indicar ou cadastre um nova',
				step_three_description: 'Confirme os dados de sua instrução documentária',
				step_four_description: 'Informe o destino dos documentos',
				step_by_step: 'Passo a Passo',
				mobile_step: 'Passo {step} de 4',
			},
			doc_row: {
				code: 'Código',
				name: 'Nome',
				update_date: 'Data Atualização',
				contract: 'Contrato',
				customer: 'Cliente',
			},
			doc_table: {
				sequence: 'Sequência',
				pod: 'POD',
				acro: 'Sigla',
			},
			inactive_templates_title: 'Templates desativados',
			select_doc_instruction: 'Selecione a instrução documentária',
			selected_contracts_title: 'Contratos selecionados',
			consignee_modal: {
				header: 'Consignatário / Notificar',
				name: 'Nome',
				email: 'E-mail',
				phone: 'Telefone',
				address: 'Endereço',
				country: 'País',
				state: 'Estado',
				city: 'Cidade',
				zipcode: 'CEP',
				tax: 'Tax ID',
				summary: 'Resumo',
				characters: 'Caracteres' 
			},
			documents_package: {
				bank: 'Banco',
				otherRecipient: 'Outro destinatário',
				address: 'Endereço',
				postCode: 'CEP',
				fax: 'FAX',
				taxID: 'TAX ID',
				receiverName: 'Nome do recebedor',
			},
			modal_add_bank: {
				titleEdit: 'Editar banco',
				title: 'Adicionar banco',
				text: 'Entre em contato com o atendimento do CX passando as informações bancárias que deseja associar a instituição de deseja.',
			},
			confirm_modal: {
				warning:
					'Você está prestes a trocar de cliente com uma instrução parcialmente preenchida, caso continue suas alterações não serão salvas. <br /> Deseja continuar?',
			},
			modal_no_data: {
				title: 'Banco',
				text: 'Não foram encontrados bancos para o seu cadastro. Por favor, entre em contato com o time de Customer Experience da Seara.',
			},
		},
		templateData: {
			inputs: {
				default: 'Padrão',
				perOrder: 'Por pedido',
				template_title: 'Nome do Template',
			},

			items: {
				invoice: 'Fatura',
				packing: 'Acondicionamento de Pedidos',
				billOfLanding: 'Conhecimento de Embarque',
				healthCertificate: 'Certificação Sanitária',
				certificateOfOrigin: 'Certificado de Origem',
				consignee: 'Destinatário',
				notify: 'Notificação',
				buyer: 'Comprador',
				adress: 'Endereço',
				observations: 'Observações',
				secondNotify: 'Segunda notificação',
				freigtClause: 'Cláusula de Frete',
				telexRelease: 'Liberação de telex',
				responsable: 'Responsável',
				countriesOfTransit: 'Países de trânsito',
				importer: 'Importador',
				behalf: 'Em nome de',
				both: 'Ambas',
			},
			actions: {
				edit: 'Editar',
			},
			requests_messages: {
				template_status_change: 'Status do template atualizado com sucesso',
				template_error: 'Ao menos uma opção deve estar marcada em Conhecimento de Embarque',
				template_send: 'Instrução enviada com sucesso!',
			},
		},
	},
};
