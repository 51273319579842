/**
 * Adiciona itens a uma lista no estado de uma Vuex store,
 * evitando duplicatas, com base em uma propriedade específica dos itens.
 * @param {Object} state O estado da Vuex store.
 * @param {Array} newItems Os novos itens a serem adicionados.
 * @param {String} listPropName O nome da propriedade no estado onde a lista está armazenada.
 * @param {String} uniquePropName A propriedade dos itens usada para verificar duplicatas (opcional).
 */
export function addUniqueItemsToList(state, newItems, listPropName, uniquePropName = null) {
	const existingItemsSet = new Set(
		state[listPropName].map((item) => (uniquePropName ? item[uniquePropName] : item)),
	);

	newItems.forEach((item) => {
		const itemValue = uniquePropName ? item[uniquePropName] : item;
		if (!existingItemsSet.has(itemValue)) {
			const newItem = uniquePropName ? { id: itemValue, name: itemValue } : item;
			state[listPropName].push(newItem);
		}
	});
}
