<template>
	<div class="financial-info">
		<span class="financial-info__text">
			{{ title }}
		</span>
		<span class="financial-info__text" v-for="(item, index) in infoData" :key="index">
			{{ `${item.prefix} ${formatMoney(item.value)}` }}
		</span>
	</div>
</template>

<script>
export default {
	name: 'FinancialInfo',
	props: {
		title: {
			required: true,
		},
		infoData: {
			required: true,
		},
	},
	data: () => ({}),
	created() {},
	mounted() {},
	beforeDestroy() {},
	methods: {
		formatMoney(param) {
			return parseFloat(param)
				.toLocaleString('pt-br', {
					style: 'currency',
					currency: 'BRL',
				})
				.replace('R$', '')
				.trim();
		},
	},
	computed: {},
};
</script>

<style lang="scss">
@import 'FinancialInfo.scss';
</style>
