export default {
	placeholder: {
		search: 'Buscar',
	},
	header: {
		contractNumber: 'Contrato',
		contractDate: 'Fecha del contrato',
		competenceMonth: 'Mes de la competencia',
		releaseDate: 'Fecha de lanzamiento',
		company: 'Empresa',
		status: 'Escenario',
		totalVolume: 'Volumen Total',
	},
	detail: {
		actualDateOfBoarding: 'Fecha de salida actual',
		awbCourier: 'Correo AWB',
		balance: 'Equilibrio',
		billLanding: 'Aterrizaje de facturas',
		billLandingDate: 'Fecha Aterrizaje de facturas',
		blDate: 'Fecha Bl',
		blDateId: 'Id Fecha Bl',
		bookingNumber: 'Número de la reserva',
		boardingType: 'Tipo de envío',
		borderCrossingDate: 'Fecha de cruce fronterizo',
		borderPost: 'Código de Puesto Fronterizo',
		borderPostsCode: 'Código de Puesto Fronterizo',
		branch: 'Subsidiaria',
		brand: 'Marca',
		brandCode: 'Código de Marca',
		business: 'Negocio',
		closedContractSequence: 'Número de contrato cerrado',
		cneCode: 'Código CNE',
		container: 'Envase',
		contractSequence: 'Número de contacto',
		country: 'País',
		courierDate: 'Fecha de publicación',
		courierCompany: 'Empresa de correos',
		csiIssuanceDate: 'Fecha de emisión CSI',
		destination: 'Destino',
		destinationPort: 'Puerta de Destino',
		destinationPort4: 'Puerta de Destino 4',
		dsInternationalOfc: 'DS Internacional',
		dueSituationCode: 'Código de Caducidad',
		endShipDate: 'Fecha de carga final',
		etaPodDate: 'Fecha ETA Pod ',
		etdPolDate: 'Fecha Pol ETD',
		etdRealDate: 'Fecha Real ETD',
		etsRfsDate: 'Fecha ETS RFS ',
		etsRfsId: 'ETS RFS id',
		expectedEtdDate: 'ETS POL',
		expectedEtbDate: 'ATS POL',
		expectedEtsDate: 'Fecha Ets Esperada',
		gateInDate: 'Fecha de entrada',
		idBlock: 'Id de Bloqueo',
		idStatus: 'Id de Status',
		incoterm: 'Incoterm',
		initials: 'Nombre del producto',
		invoiceNumber: 'Número de factura',
		invoicedDate: 'Fecha de factura',
		invoiceIssuanceDate: 'Fecha de Emisión de la Factura',
		loadNumber: 'Número de Carga',
		loadPlace: 'Ubicación de la carga',
		loadCode: 'Código de Carga',
		loadingDate: 'Fecha de Carga',
		localization: 'Localización',
		market: 'Mercado',
		groupingDescription: 'Agrupación',
		maxGroupingQuantity: 'Cantidad de agrupación Max',
		minGroupingQuantity: 'Cantidad de agrupación Min',
		netWeight: 'Peso neto',
		nfiIssuanceDate: 'Fecha de emisión NFI',
		origin: 'Origen',
		paymentDeadline: 'Tiempo Límite de Pago',
		paymentDeadlineCode: 'Código de Tiempo Límite de Pago',
		paymentMethod: 'Método de pago',
		paymentMode: 'Modo de pago',
		permissionImt: 'Permiso Imt',
		permissionImtDate: 'Fecha de permiso Imt',
		planningCode: 'Código de Planificación',
		plannerLoadingDate: 'Fecha de carga planificada',
		pod: 'POD',
		pol: 'POL',
		priceType: 'Tipo de precio',
		productionMonth: 'Mes de producción',
		productionDate: 'Fecha de producción',
		productName: 'Nombre del producto',
		provider: 'Proveedor',
		purchaseOrder: 'Código de Compra',
		purchaseOrderClient: 'Código de Compra Cliente',
		realAtdDate: 'Fecha Atd Real',
		realAtbDate: 'ETA POD',
		realArrivalDate: 'Fecha Real de Llegada',
		regional: 'Regional',
		releaseSequenceDate: 'Fecha de publicación de la secuencia',
		restritionCode: 'Código de Restricción',
		remarks: 'Comentarios',
		sentDraftDate: 'Fecha de Envío del Borrador',
		scheduledDate: 'Cita agendada',
		shippingCompany: 'Compañía de envios',
		shippingLine: 'Compañía naviera',
		sif: 'Sif Fil',
		sku: 'SKU',
		startShipDate: 'Fecha de carga inicial',
		statusClosure: 'Cierre de Estado',
		statusPrePaid: 'Estado de prepago',
		status: 'Secuencia de estado',
		stockLocation: 'Ubicación de existencias',
		totalGrossWeight: 'Peso Bruto Total',
		totalKg: 'Kg Total',
		totalVolume: 'Volumne Total',
		traderName: 'Nombre del comerciante',
		unitPrice: 'Precio unitario',
		vehiclePlate: 'Matrícula del Vehículo',
		vessel: 'Barco',
		weeksToShip: 'Semanas para cargar',
		stage: 'Status',
	},
};
