<template>
	<div class="filter-container">
		<div class="filter-row">
			<InitialDateInput ref="initialDateInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<EndDateInput ref="endDateInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<InvoiceInput ref="invoiceInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<ContractInput ref="contractInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<SequenceInput ref="sequenceInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<PoInput ref="poInput" :isWeb="isWeb" @filterRequest="filterRequest" />
		</div>
		<div class="filter-row">
			<ContainerInput ref="containerInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<TransportInput ref="transportInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<PodInput ref="podInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<LoadInput ref="loadInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<AcronymInput ref="acronymInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<DescriptionInput ref="descriptionInput" :isWeb="isWeb" @filterRequest="filterRequest" />
		</div>
		<div class="filter-row end">
			<button class="btn" type="button" @click="clearCachedFilters()" v-if="isWeb">
				{{ $t('buttons.ok') }}
			</button>
			<button class="btn btn-outline" type="button" @click="clearCachedFilters()" v-if="isWeb">
				{{ $t('buttons.clear_filter') }}
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
	CLEAR_ALL_DOC_FILTERS,
	SAVE_LAST_QUERY_STRING,
} from '@/store/modules/filtersByModules/docManagementFilters/actions';
import InitialDateInput from './Inputs/InitialDateInput/InitialDateInput.vue';
import EndDateInput from './Inputs/EndDateInput/EndDateInput.vue';
import InvoiceInput from './Inputs/InvoiceInput/InvoiceInput.vue';
import ContractInput from './Inputs/ContractInput/ContractInput.vue';
import SequenceInput from './Inputs/SequenceInput/SequenceInput.vue';
import PoInput from './Inputs/PoInput/PoInput.vue';
import ContainerInput from './Inputs/ContainerInput/ContainerInput.vue';
import TransportInput from './Inputs/TransportInput/TransportInput.vue';
import PodInput from './Inputs/PodInput/PodInput.vue';
import LoadInput from './Inputs/LoadInput/LoadInput.vue';
import AcronymInput from './Inputs/AcronymInput/AcronymInput.vue';
import DescriptionInput from './Inputs/DescriptionInput/DescriptionInput.vue';
import ClearIcon from '@/components/shared/Icons/ClearIcon.vue';

export default {
	name: 'FilterList',
	components: {
		InitialDateInput,
		EndDateInput,
		InvoiceInput,
		ContractInput,
		SequenceInput,
		PoInput,
		ContainerInput,
		TransportInput,
		PodInput,
		LoadInput,
		AcronymInput,
		DescriptionInput,
		ClearIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		filter: {
			fromDate: null,
			toDate: null,
		},
		refreshCalendars: true,
	}),
	mounted() {},
	methods: {
		...mapActions('docManagementFilters', {
			clearStoreFilters: CLEAR_ALL_DOC_FILTERS,
			saveLastQueryString: SAVE_LAST_QUERY_STRING,
		}),
		filterRequest(param) {
			if (
				param.type == 'fromDate' ||
				param.type == 'toDate' ||
				param.type == 'description' ||
				param.type == 'acronym' ||
				param.type == 'transport' ||
				param.type == 'pod'
			) {
				this.filter[param.type] = param.value ? param.value.id : null;
			} else {
				this.filter[param.type] = param.value ? param.value.map((item) => item.id) : null;
			}
			let queryString = this.createQueryString();
			let filterObj = {
				queryString: queryString,
				skip: true,
			};

			if (!param.value || !param.value.length) {
				this.$emit('setPageOne');
			}

			this.$emit('refreshFilters', filterObj);
			//refresh dos outros componentes
			// this.$emit('refreshContracts', queryString);
			this.saveLastQueryString(queryString);
		},
		filterCachedRequest() {
			const fromDateCache = this.initDateFilterCache.value;
			const toDateCache = this.endDateFilterCache.value;

			if (fromDateCache.id != undefined) {
				this.filter['fromDate'] = fromDateCache.id;
			}

			if (toDateCache.id != undefined) {
				this.filter['toDate'] = toDateCache.id;
			}

			let queryString = this.createQueryString();
			this.$emit('refreshContracts', queryString);
		},
		createQueryString() {
			let {
				fromDate,
				toDate,
				invoice,
				contract,
				sequence,
				po,
				container,
				transport,
				pod,
				load,
				acronym,
				description,
			} = this.filter;

			const query = new URLSearchParams();

			if (fromDate) query.set('fromDate', fromDate);
			if (toDate) query.set('toDate', toDate);
			if (invoice && invoice.length) query.set('invoice', invoice);
			if (contract && contract.length) query.set('contract', contract);
			if (sequence && sequence.length) query.set('sequence', sequence);
			if (po && po.length) query.set('po', po);
			if (container && container.length) query.set('container', container);
			if (transport && transport.length) query.set('transport', transport);
			if (pod && pod.length) query.set('pod', pod);
			if (load && load.length) query.set('load', load);
			if (acronym && acronym.length) query.set('acronym', acronym);
			if (description && description.length) query.set('description', description);

			return query.toString();
		},
		clearCachedFilters() {
			this.clearStoreFilters();
			this.clearFilters();
		},
		clearFilters() {
			this.$refs.initialDateInput.clearInput();
			this.$refs.endDateInput.clearInput();
			this.$refs.invoiceInput.clearInput();
			this.$refs.contractInput.clearInput();
			this.$refs.sequenceInput.clearInput();
			this.$refs.poInput.clearInput();
			this.$refs.containerInput.clearInput();
			this.$refs.transportInput.clearInput();
			this.$refs.podInput.clearInput();
			this.$refs.loadInput.clearInput();
			this.$refs.acronymInput.clearInput();
			this.$refs.descriptionInput.clearInput();

			this.filter = {
				fromDate: null,
				toDate: null,
			};

			// refresh dos outros componentes
			// this.$emit('refreshContracts', null);
			this.$emit('refreshFilters');
		},
		setCachedInvoiceInput() {
			this.$refs.invoiceInput.setCachedInput();
		},
		setCachedContractInput() {
			this.$refs.contractInput.setCachedInput();
		},
		setCachedSequenceInput() {
			this.$refs.sequenceInput.setCachedInput();
		},
		setCachedPurchaseInput() {
			this.$refs.poInput.setCachedInput();
		},
		setCachedContainerInput() {
			this.$refs.containerInput.setCachedInput();
		},
		setCachedTransportInput() {
			this.$refs.transportInput.setCachedInput();
		},
		setCachedPodInput() {
			this.$refs.podInput.setCachedInput();
		},
		setCachedLoadInput() {
			this.$refs.loadInput.setCachedInput();
		},
		setCachedAcronymInput() {
			this.$refs.acronymInput.setCachedInput();
		},
		setCachedDescriptionInput() {
			this.$refs.descriptionInput.setCachedInput();
		},
	},
	computed: {
		...mapGetters('user', ['userLang']),
		...mapGetters('docManagementFilters', ['initDateFilterCache', 'endDateFilterCache']),
	},
	watch: {
		userLang(newValue) {
			if (newValue) {
				this.refreshCalendars = false;
				setTimeout(() => {
					this.refreshCalendars = true;
				}, 100);
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'FilterList.scss';
</style>
