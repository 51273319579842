<template>
	<form class="form" id="recover-form" v-on:submit.prevent="validatePassword">
		<div class="row">
			<div class="back-btn" @click="goToLogin()">
				<BackArrow />
			</div>
			<h1 class="form__title">
				{{ $t('modules.resetPassword.new_password_title') }}
			</h1>
		</div>
		<p class="form__text" v-html="$t('modules.resetPassword.new_password_text')"></p>
		<div class="field">
			<label class="field__label" for="login">
				{{ $t('modules.resetPassword.new_password_label') }}
			</label>
			<InputPassword
				:class="passwordRequirements && password ? 'approved' : 'deny'"
				id="password-input"
				v-model="password"
				:min="'8'"
			/>
		</div>
		<div class="field">
			<label class="field__label" for="login">
				{{ $t('modules.resetPassword.confirm_password_label') }}
			</label>
			<InputPassword
				:class="equalPasswords && confirmPassword ? 'approved' : 'deny'"
				id="confirm-password-input"
				v-model="confirmPassword"
				:min="'8'"
			/>
		</div>
		<div class="rules">
			<p class="rules__text">
				<strong>{{ $t('modules.resetPassword.rules_title') }}</strong>
			</p>
			<p class="rules__text" :class="verifyUppercase ? 'approved' : 'deny'">
				{{ $t('modules.resetPassword.rule_uppercase') }}
			</p>
			<p class="rules__text" :class="verifyLowerercase ? 'approved' : 'deny'">
				{{ $t('modules.resetPassword.rule_lowercase') }}
			</p>
			<p class="rules__text" :class="verifyNumber ? 'approved' : 'deny'">
				{{ $t('modules.resetPassword.rule_number') }}
			</p>
			<p class="rules__text" :class="verifyLenght ? 'approved' : 'deny'">
				{{ $t('modules.resetPassword.rule_length') }}
			</p>
			<p class="rules__text" :class="verifySpecial ? 'approved' : 'deny'">
				{{ $t('modules.resetPassword.rule_special') }}
			</p>
		</div>
		<div class="actions variant">
			<button
				id="recover-btn"
				class="actions__btn"
				:disabled="isLoadingEmail"
				type="submit"
				v-if="!isLoadingEmail"
			>
				<p>{{ $t('buttons.save_password') }}</p>
				<LoginArrow />
			</button>
			<SpinnerIcon color="white" v-else />
		</div>
	</form>
</template>

<script>
import { mapActions } from 'vuex';
import { NEW_PASSWORD } from '@/store/common/auth/actions';
import InputPassword from '@/components/shared/InputPassword/InputPassword.vue';
import LoginArrow from '@/components/shared/Icons/LoginArrow.vue';
import BackArrow from '@/components/shared/Icons/BackArrow.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon.vue';

export default {
	name: 'NewPasswordForm',
	components: {
		InputPassword,
		LoginArrow,
		BackArrow,
		SpinnerIcon,
	},
	data: () => ({
		isLoadingEmail: false,
		password: '',
		confirmPassword: '',
	}),
	mounted() {},
	methods: {
		...mapActions('auth', {
			newPassReq: NEW_PASSWORD,
		}),
		goToLogin() {
			this.$router.push('/login');
		},
		validatePassword() {
			if (!this.password) {
				return this.$toastr.e(this.$t('errors.password_required'));
			}
			if (!this.passwordRequirements) {
				return this.$toastr.e(this.$t('errors.passwords_rules'));
			}
			if (!this.equalPasswords) {
				return this.$toastr.e(this.$t('errors.passwords_dismatch'));
			}
			this.recoverPassword();
		},
		recoverPassword() {
			this.isLoadingEmail = true;

			const body = {
				token: this.$route.query.token,
				password: this.password,
			};

			this.newPassReq({ vm: this, body })
				.then((res) => {
					if (res.status == 201) {
						this.isLoadingEmail = false;
						this.$emit('showMessageScreen');
					}
				})
				.catch(() => {
					this.isLoadingEmail = false;
				});
		},
	},
	computed: {
		verifyUppercase() {
			const pass = this.password.toLowerCase();
			return this.password != pass ? true : false;
		},
		verifyLowerercase() {
			const pass = this.password.toUpperCase();
			return this.password != pass ? true : false;
		},
		verifyNumber() {
			let hasNumber = /\d/;
			return hasNumber.test(this.password) ? true : false;
		},
		verifyLenght() {
			return this.password.length > 7 ? true : false;
		},
		verifySpecial() {
			let hasSpecial = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
			return hasSpecial.test(this.password) ? true : false;
		},
		passwordRequirements() {
			const reg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
			if (this.password.match(reg) !== null || this.password == '') {
				return true;
			}

			return false;
		},
		equalPasswords() {
			if (this.password == this.confirmPassword) {
				return true;
			}

			return false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'NewPasswordForm.scss';
</style>
