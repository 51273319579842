<template>
	<div class="doc-showMore">
		<div class="doc-showMore__arrow" @click="handleClick">
			<ArrowDown :class="showContent ? 'rotate' : ''" :color="'#FFFFFF'" />
		</div>
	</div>
</template>

<script>
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
export default {
	name: 'DocShowMore',
	components: {
		ArrowDown,
	},
	props: {
		showContent: {
			require: true,
		},
	},
	methods: {
		handleClick() {
			this.$emit('handleClick');
		},
	},
};
</script>

<style lang="scss">
@import 'DocShowMore.scss';
</style>
