<!-- eslint-disable vue/no-unused-vars -->
<template>
	<ValidationObserver v-slot="{ validate }">
		<ModalStructure id="modal-consignee" :height="height" :width="width">
			<template v-slot:header>
				<div class="header">
					<h1 class="header-text">{{ $t('modules.doc_instruction.consignee_modal.header') }}</h1>
					<div class="header-actions">
						<button id="cancel-user-modal" class="header-actions__btn" @click="handleCancel()">
							{{ $t('buttons.cancel') }}
						</button>
						<button
							id="confirm-user-midal"
							class="header-actions__btn"
							@click="handleConfirm(validate)"
						>
							{{ $t('buttons.save') }}
						</button>
					</div>
				</div>
			</template>
			<template v-slot:body>
				<div class="body">
					<div class="body-form">
						<div class="body-form__container">
							<label class="body-form__container--label">
								{{ $t('modules.doc_instruction.consignee_modal.name') }}
							</label>
							<ValidationProvider
								:name="$t('modules.doc_instruction.consignee_modal.name')"
								rules="required|min:3"
								v-slot="{ errors }"
							>
								<input
									class="body-form__container--input"
									type="text"
									:placeholder="$t('modules.doc_instruction.consignee_modal.name')"
									v-model="form.name"
									maxlength="50"
								/>
								<span class="characters-count">{{ `${$t('modules.doc_instruction.consignee_modal.characters')}: ${50}` }}</span>
								<ErrorMessage>
									{{ errors[0] }}
								</ErrorMessage>
							</ValidationProvider>
						</div>
						<br />
						<div class="row gap-10">
							<div class="body-form__container">
								<label class="body-form__container--label">
									{{ $t('modules.doc_instruction.consignee_modal.email') }}
								</label>
								<ValidationProvider
									:name="$t('modules.doc_instruction.consignee_modal.email')"
									rules="required|email"
									v-slot="{ errors }"
								>
									<input
										:name="$t('modules.doc_instruction.consignee_modal.email')"
										class="body-form__container--input"
										type="text"
										:placeholder="$t('modules.doc_instruction.consignee_modal.email')"
										v-model="form.email"
									/>
									<ErrorMessage>
										{{ errors[0] }}
									</ErrorMessage>
								</ValidationProvider>
							</div>
							<div class="body-form__container">
								<label class="body-form__container--label">
									{{ $t('modules.doc_instruction.consignee_modal.phone') }}
								</label>
								<ValidationProvider
									:name="$t('modules.doc_instruction.consignee_modal.phone')"
									rules="required|phone|min:6"
									v-slot="{ errors }"
								>
									<input
										class="body-form__container--input"
										type="text"
										:placeholder="$t('modules.doc_instruction.consignee_modal.phone')"
										v-model="form.phone"
									/>
									<ErrorMessage>
										{{ errors[0] }}
									</ErrorMessage>
								</ValidationProvider>
							</div>
						</div>
						<div class="body-form__container">
							<label class="body-form__container--label">
								{{ $t('modules.doc_instruction.consignee_modal.address') }}
							</label>
							<ValidationProvider
								:name="$t('modules.doc_instruction.consignee_modal.address')"
								rules="required|min:8"
								v-slot="{ errors }"
							>
								<textarea
									class="body-form__container--textarea"
									:placeholder="$t('modules.doc_instruction.consignee_modal.address')"
									v-model="form.address"
									maxlength="140"
								></textarea>
								<span class="characters-count">{{ `${$t('modules.doc_instruction.consignee_modal.characters')}: ${140}` }}</span>
								<ErrorMessage>
									{{ errors[0] }}
								</ErrorMessage>
							</ValidationProvider>
						</div>
						<br/>
						<div class="row gap-10">
							<div class="body-form__container">
								<label class="body-form__container--label">
									{{ $t('modules.doc_instruction.consignee_modal.country') }}
								</label>
								<ValidationProvider
									:name="$t('modules.doc_instruction.consignee_modal.country')"
									rules="required"
									v-slot="{ errors, validate }"
								>
									<GenericMultiselect
										class="body-form__container--input"
										ref="country"
										:options="listCountries"
										:placeholder="$t('modules.doc_instruction.consignee_modal.country')"
										:fullWidth="!isWeb"
										:searchable="true"
										:showIcon="true"
										:type="'country'"
										:multiple="false"
										:needType="true"
										@changeInput="($v) => changeInput($v, validate)"
									/>
									<ErrorMessage>
										{{ errors[0] }}
									</ErrorMessage>
								</ValidationProvider>
							</div>
							<div class="body-form__container">
								<label class="body-form__container--label">
									{{ $t('modules.doc_instruction.consignee_modal.state') }}
								</label>
								<ValidationProvider
									:name="$t('modules.doc_instruction.consignee_modal.state')"
									rules="required"
									v-slot="{ errors, validate }"
								>
									<GenericMultiselect
										class="body-form__container--input"
										ref="state"
										:options="listStates"
										:placeholder="$t('modules.doc_instruction.consignee_modal.state')"
										:fullWidth="!isWeb"
										:searchable="true"
										:showIcon="true"
										:type="'state'"
										:multiple="false"
										:needType="true"
										@changeInput="($v) => changeInput($v, validate)"
										:disabled="!form.country"
									/>
									<ErrorMessage>
										{{ errors[0] }}
									</ErrorMessage>
								</ValidationProvider>
							</div>
						</div>
						<div class="row gap-10">
							<div class="body-form__container">
								<label class="body-form__container--label">
									{{ $t('modules.doc_instruction.consignee_modal.city') }}
								</label>
								<ValidationProvider
									:name="$t('modules.doc_instruction.consignee_modal.city')"
									rules="required"
									v-slot="{ errors, validate }"
								>
									<GenericMultiselect
										class="body-form__container--input"
										ref="city"
										:options="listCities"
										:placeholder="$t('modules.doc_instruction.consignee_modal.city')"
										:fullWidth="!isWeb"
										:searchable="true"
										:showIcon="true"
										:type="'city'"
										:multiple="false"
										:needType="true"
										@changeInput="($v) => changeInput($v, validate)"
										:disabled="!form.state"
									/>
									<ErrorMessage>
										{{ errors[0] }}
									</ErrorMessage>
								</ValidationProvider>
							</div>
							<div class="body-form__container">
								<label class="body-form__container--label">
									{{ $t('modules.doc_instruction.consignee_modal.zipcode') }}
								</label>
								<ValidationProvider
									:name="$t('modules.doc_instruction.consignee_modal.zipcode')"
									rules="required|min:8"
									v-slot="{ errors }"
								>
									<input
										class="body-form__container--input"
										type="text"
										placeholder="00000"
										v-model="form.zipcode"
									/>
									<ErrorMessage>
										{{ errors[0] }}
									</ErrorMessage>
								</ValidationProvider>
							</div>
						</div>
						<div class="body-form__container">
							<label class="body-form__container--label">
								{{ $t('modules.doc_instruction.consignee_modal.tax') }}
							</label>
							<ValidationProvider
								:name="$t('modules.doc_instruction.consignee_modal.tax')"
								rules="required|min:8"
								v-slot="{ errors }"
							>
								<input
									class="body-form__container--input"
									type="text"
									placeholder="00 00000 0000"
									v-model="form.tax"
								/>
								<ErrorMessage>
									{{ errors[0] }}
								</ErrorMessage>
							</ValidationProvider>
						</div>
					</div>
					<div class="body-resume">
						<div class="body-resume__title">
							<label class="body-resume__title--text">
								{{ $t('modules.doc_instruction.consignee_modal.summary') }}
							</label>
						</div>
						<div class="body-resume__content">
							<p v-if="form.name" class="body-resume__content--text">{{ form.name }}</p>
							<p v-if="form.email" class="body-resume__content--text">{{ form.email }}</p>
							<p v-if="form.phone" class="body-resume__content--text">{{ form.phone }}</p>
							<p v-if="form.address" class="body-resume__content--text">{{ form.address }}</p>
							<p v-if="form.country" class="body-resume__content--text">{{ form.country }}</p>
							<p v-if="form.state" class="body-resume__content--text">{{ form.state }}</p>
							<p v-if="form.city" class="body-resume__content--text">{{ form.city }}</p>
							<p v-if="form.zipcode" class="body-resume__content--text">{{ form.zipcode }}</p>
							<p v-if="form.tax" class="body-resume__content--text">{{ form.tax }}</p>
						</div>
					</div>
				</div>
			</template>
		</ModalStructure>
	</ValidationObserver>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModalStructure from '@/components/shared/Modals/ModalStructure/ModalStructure.vue';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
import ErrorMessage from '@/components/shared/GenericErrorMessage/GenericErrorMessage.vue';
import { STATES_REQUEST, CITIES_REQUEST } from '@/store/modules/docInstruction/actions';

export default {
	name: 'ModalConsignee',
	components: { ModalStructure, GenericMultiselect, ErrorMessage },
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		form: {
			id: '',
			name: null,
			email: null,
			phone: null,
			address: null,
			country: null,
			state: null,
			city: null,
			zipcode: null,
			tax: null,
		},
	}),
	computed: {
		...mapGetters('docInstruction', ['listCountries', 'listStates', 'listCities']),
		height() {
			return this.isWeb ? '700px' : '100%';
		},
		width() {
			return this.isWeb ? '795px' : '100%';
		},
		countryOptions() {
			return [{ id: 1, name: 'Country' }];
		},
		stateOptions() {
			return [{ id: 1, name: 'State' }];
		},
		cityOptions() {
			return [{ id: 1, name: 'City' }];
		},
	},
	methods: {
		...mapActions('docInstruction', {
			getStatesList: STATES_REQUEST,
			getCitiesList: CITIES_REQUEST,
		}),
		handleCancel() {
			this.$emit('cancel');
		},
		async handleConfirm(validate) {
			const isValid = await validate();

			if (isValid) {
				const {
					name,
					email,
					phone,
					address,
					country,
					state,
					city,
					zipcode,
					tax,
				} = this.form;

				const client = {
					id: null,
					name,
					address: [address, city, state, country, zipcode, phone, email, tax].join(", ")
				}

				this.$emit('confirm', client);
			}
		},
		async changeInput(param, validate) {
			await validate(param.value.name);

			const fieldMap = {
				country: 'country',
				state: 'state',
				city: 'city',
			};

			if (param.type === 'country') {
				this.getStatesList({ vm: this, countryId: param.value.id });
			} else if (param.type === 'state') {
				this.getCitiesList({ vm: this, stateId: param.value.id });
			}

			if (fieldMap[param.type]) {
				this.form[fieldMap[param.type]] = param.value.name;
			}
		},
	},
};
</script>
<style scoped lang="scss">
@import 'ModalConsignee.scss';
</style>
