<template>
	<div class="templateInvoice-container">
		<TemplateHeaderItem
			:title="$t('modules.templateData.items.invoice')"
			:showBell="false"
			@handleCollapse="handleCollapse"
		/>
		<div class="templateInvoice-container__content" :class="!isCollapsed ? 'open' : ''">
			<div class="templateInvoice-container__itens">
				<div class="templateInvoice-container__itens--item">
					<h5>{{ $t('modules.templateData.items.buyer') }}</h5>
					<input
						class="no-border-field"
						type="text"
						name="buyer"
						id="buyer"
						:placeholder="$t('modules.templateData.items.buyer')"
						v-model="buyer.name"
						disabled
					/>
					<textarea
						class="no-border-field"
						name="buyerAdress"
						id="buyerAdress"
						:placeholder="$t('modules.templateData.items.adress')"
						v-model="buyer.address"
						disabled
					></textarea>
				</div>
				<div class="templateInvoice-container__itens--item">
					<h5>{{ $t('modules.templateData.items.consignee') }}</h5>

					<GenericMultiselect
						class="consignee-multiselect"
						ref="consignee"
						:options="consigneeOptions"
						:placeholder="$t('modules.templateData.items.consignee')"
						:searchable="true"
						:needType="true"
						:showIcon="true"
						:infiniteScroll="true"
						:type="'consigneeId'"
						:initialValue="selectedConsignee"
						@changeInput="changeInput"
					/>
					<textarea
						name="consigneeAdress"
						id="consigneeAdress"
						:placeholder="$t('modules.templateData.items.adress')"
						:value="currentConsignee.address"
						@input="handleInputConsigneeAddress"
					></textarea>
				</div>
				<div class="templateInvoice-container__itens--item">
					<h5>{{ $t('modules.templateData.items.notify') }}</h5>

					<GenericMultiselect
						class="notify-multiselect"
						ref="notify"
						:options="notifyOptions"
						:placeholder="$t('modules.templateData.items.notify')"
						:searchable="true"
						:needType="true"
						:showIcon="true"
						:infiniteScroll="true"
						:type="'notifyId'"
						:initialValue="selectedNotify"
						@changeInput="changeInput"
					/>
					<textarea
						name="notifyAdress"
						id="notifyAdress"
						:placeholder="$t('modules.templateData.items.adress')"
						@input="handleInputNotifyAddress"
						:value="currentNotify.address"
					></textarea>
				</div>
			</div>
			<div class="templateInvoice-container__itens">
				<div class="templateInvoice-container__itens--item">
					<h5>{{ $t('modules.templateData.items.observations') }}</h5>

					<textarea
						name="observations"
						id="observations"
						:placeholder="$t('modules.templateData.items.observations')"
						v-model="observations"
					></textarea>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TemplateHeaderItem from '../TemplateHeaderItem/TemplateHeaderItem.vue';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
import {
	SET_TEMPLATE_INVOICE_DATA,
	EDIT_CONSIGNEE_NOTIFY,
} from '@/store/modules/docInstruction/actions';

export default {
	name: 'TemplateInvoice',
	components: {
		TemplateHeaderItem,
		GenericMultiselect,
	},
	data: () => ({
		isCollapsed: false,
		buyer: {
			name: '',
			address: '',
		},
		consignee: {
			id: '',
			name: '',
			address: '',
		},
		notify: {
			id: '',
			name: '',
			address: '',
		},
		observations: '',
		shippingType: null,
	}),
	props: {
		templateDefault: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	mounted() {
		if (this.isEdition) {
			this.storeToData();
		}
	},
	watch: {
		templateDefault() {
			if (!this.isEdition) {
				this.copyDefaultDataStore();
				this.storeToData();
			}
		},
	},
	methods: {
		...mapActions('docInstruction', {
			setTemplateInvoice: SET_TEMPLATE_INVOICE_DATA,
			editConsignee: EDIT_CONSIGNEE_NOTIFY,
		}),
		handleCollapse() {
			this.isCollapsed = !this.isCollapsed;
		},
		changeInput(param) {
			if (param.type === 'consigneeId') {
				if (!param.value) {
					this.consignee = {
						id: '',
						name: '',
						address: '',
					};
					return;
				}

				this.consignee.id = param.value.id ?? '';
				this.consignee.name = param.value.name ?? '';
				this.consignee.address = this.getAddress(param.value);
				this.editConsignee(this.consignee);
				return;
			}

			if (param.type === 'notifyId') {
				if (!param.value) {
					this.notify = {
						id: '',
						name: '',
						address: '',
					};
					return;
				}

				this.notify.id = param.value.id ?? '';
				this.notify.name = param.value.name ?? '';
				this.notify.address = this.getAddress(param.value);
				this.editConsignee(this.notify);
				return;
			}
		},
		getAddress({ name }) {
			const client =
				this.editedConsigneeNotify.find((client) => client.name === name) ??
				this.clientsList.find((client) => client.name === name);
			return client?.address;
		},
		storeToData() {
			this.buyer = this.templateData.invoice.buyer;

			this.consignee = { ...this.templateData.invoice.consignee };
			this.notify = { ...this.templateData.invoice.notify };

			this.observations = this.templateData.invoice.observations;
			this.shippingType = this.templateData.invoice.shippingType;
		},
		// esse método é invocado pelo pai
		getForm() {
			const invoice = {
				instructionType: this.templateDefault.instructionType,
				instructionLetter: this.templateDefault.instructionLetter,
				customerType: this.templateDefault.customerType,
				buyer: {
					id: this.buyer.id,
					name: this.buyer.name,
					address: this.buyer.address,
				},
				consignee: {
					id: this.consignee.id < 0 ? null : this.consignee.id,
					name: this.consignee.name,
					address: this.currentConsignee.address,
				},
				notify: {
					id: this.notify.id < 0 ? null : this.notify.id,
					name: this.notify.name,
					address: this.currentNotify.address,
				},
				observations: this.observations,
				shippingType: this.shippingType,
			};

			return invoice;
		},
		copyDefaultDataStore() {
			const invoice = {
				instructionType: this.templateDefault.instructionType,
				instructionLetter: this.templateDefault.instructionLetter,
				customerType: this.templateDefault.customerType,
				buyer: {
					id: this.templateDefault.buyer.id,
					name: this.templateDefault.buyer.name,
					address: this.templateDefault.buyer.address,
				},
				consignee: {
					id: this.templateDefault.consignee.id,
					name: this.templateDefault.consignee.name,
					address: this.templateDefault.consignee.address,
				},
				notify: {
					id: this.templateDefault.notify.id,
					name: this.templateDefault.notify.name,
					address: this.templateDefault.notify.address,
				},
				observations: this.templateDefault.observations,
				shippingType: this.templateDefault.shippingType,
			};

			this.setTemplateInvoice(invoice);
		},
		handleInputNotifyAddress(event) {
			const { value } = event.target;
			this.notify.address = value;
			this.editConsignee(this.notify);
		},
		handleInputConsigneeAddress(event) {
			const { value } = event.target;
			this.consignee.address = value;
			this.editConsignee(this.consignee);
		},
	},
	computed: {
		...mapGetters('docInstruction', [
			'clientsList',
			'isLoadingClients',
			'editedConsigneeNotify',
			'templateData',
		]),
		isEdition() {
			return this.$route.params.id !== undefined;
		},
		consigneeOptions() {
			return (
				this.clientsList.map((item, index) => {
					return { id: item.id || -index - 1, name: item.name };
				}) ?? []
			);
		},
		notifyOptions() {
			return (
				this.clientsList.map((item, index) => {
					return { id: item.id || -index - 1, name: item.name };
				}) ?? []
			);
		},
		defaultClientList() {
			return this.clientsList ?? [];
		},
		selectedConsignee: {
			set({ id, name }) {
				this.consignee.id = id;
				this.consignee.name = name;
			},
			get() {
				return {
					id: this.consignee.id,
					name: this.consignee.name,
				};
			},
		},
		selectedNotify: {
			set({ id, name }) {
				this.notify.id = id;
				this.notify.name = name;
			},
			get() {
				return {
					id: this.notify.id,
					name: this.notify.name,
				};
			},
		},
		currentNotify() {
			return (
				this.editedConsigneeNotify.find((notify) => notify.name === this.notify.name) ?? this.notify
			);
		},
		currentConsignee() {
			return (
				this.editedConsigneeNotify.find((consignee) => consignee.name === this.consignee.name) ??
				this.consignee
			);
		},
	},
};
</script>

<style lang="scss">
@import 'TemplateInvoice.scss';
</style>
