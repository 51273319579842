var render = function render(){var _vm=this,_c=_vm._self._c;return _c('multiselect',{staticClass:"generic-multiselect",class:[
		_vm.longPlaceholder ? 'pt-1' : '',
		_vm.fullWidth ? 'full-width' : '',
		_vm.capitalize ? 'capitalize' : '',
		_vm.multiple ? 'full-height' : '',
		_vm.darkBlue ? 'dark-blue' : '',
	],attrs:{"options":_vm.options,"searchable":_vm.searchable,"close-on-select":true,"show-labels":true,"multiple":_vm.multiple,"select-label":"","selected-label":"","deselect-label":_vm.getTranslatedDeselectLabel(),"track-by":"id","label":"name","open-direction":_vm.direction,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"open":_vm.handleOpen,"input":_vm.changeInput,"change":_vm.changeInput,"search-change":_vm.requestByChange},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('template',{slot:"caret"},[(!_vm.plusIcon && _vm.showIcon)?_c('span',{staticClass:"arrow-caret"},[_c('MultiselectIcon',{attrs:{"color":_vm.darkBlue ? '#fff' : '#234057'}})],1):(_vm.plusIcon && _vm.showIcon)?_c('span',{staticClass:"arrow-caret-plus"},[_c('PlusIcon',{attrs:{"color":'#b47d4a'}})],1):_vm._e()]),_c('template',{slot:"noResult"},[_vm._v(" "+_vm._s(_vm.$t('errors.no_options'))+" ")]),_c('template',{slot:"noOptions"},[_vm._v(" "+_vm._s(_vm.$t('errors.no_options'))+" ")]),(_vm.infiniteScroll)?_c('template',{slot:"afterList"},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.reachedEndOfList),expression:"reachedEndOfList"}]})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }