<template>
	<GenericMultiselect
		class="po-multiselect"
		ref="po"
		:options="poOptions"
		:placeholder="$t('modules.load_management.filter.po')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="false"
		:infiniteScroll="true"
		:type="'purchaseOrder'"
		:multiple="true"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	PO_FILTER_REQUEST,
	SAVE_PO_FILTER,
} from '@/store/modules/filtersByModules/loadManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'PoInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('loadManagementFilters', {
			getPoFilter: PO_FILTER_REQUEST,
			savePoFilter: SAVE_PO_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.savePoFilter(param);

			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getPoFilter({ vm: this, params: query, skip: false, lastQuery: this.lastQueryString });
		},
		nextPage() {
			const meta = this.poFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getPoFilter({ vm: this, params: query, skip: false, lastQuery: this.lastQueryString });
		},
		createQueryString(poNumber) {
			const query = new URLSearchParams();

			if (poNumber) query.set('purchaseOrder', poNumber);
			if (poNumber) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.po.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.poFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.po.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('loadManagementFilters', [
			'poFilter',
			'poFilterMeta',
			'poFilterCache',
			'isLoadingPoFilter',
			'lastQueryString',
		]),
		poOptions() {
			const rows = this.poFilter != undefined ? this.poFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'PoInput.scss';
</style>
