<template>
	<GenericMultiselect
		class="doc-load-multiselect"
		ref="load"
		:options="loadOptions"
		:placeholder="$t('modules.doc_management.filter.load')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="true"
		:infiniteScroll="true"
		:type="'load'"
		:multiple="false"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	DOC_CARGO_FILTER_REQUEST,
	SAVE_DOC_CARGO_FILTER,
} from '@/store/modules/filtersByModules/docManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'LoadInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('docManagementFilters', {
			getLoadFilter: DOC_CARGO_FILTER_REQUEST,
			saveLoadFilter: SAVE_DOC_CARGO_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveLoadFilter(param);

			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getLoadFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		nextPage() {
			const meta = this.cargoFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getLoadFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		createQueryString(loadType) {
			const query = new URLSearchParams();

			if (loadType) query.set('load', loadType);
			if (loadType) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.load.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.cargoFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.load.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('docManagementFilters', [
			'cargoFilter',
			'cargoFilterMeta',
			'cargoFilterCache',
			'isLoadingCargoFilter',
			'lastQueryString',
		]),
		loadOptions() {
			const rows = this.cargoFilter != undefined ? this.cargoFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'LoadInput.scss';
</style>
