import {
	CONTRACT_DETAIL_REQUEST,
	CONTRACT_DETAIL_REQUEST_SUCCESS,
	CONTRACT_DETAIL_REQUEST_ERROR,
} from './actions';
import api from '@/utils/api';

const { http } = api.getInstance();

const defaultState = () => ({
	contracts: '',
	sequences: '',
	loads: '',
	po: '',
	status: '',
	contractItemList: {},
	isLoadingContractItem: '',
	userPreferences: { header: [], detail: [] },
	isLoadingPreferences: '',
});

const state = defaultState();

const getters = {
	contracts: (state) => state.contracts,
	sequences: (state) => state.sequences,
	loads: (state) => state.loads,
	po: (state) => state.po,
	status: (state) => state.status,
	contractItemList: (state) => state.contractItemList,
	isLoadingContractItem: (state) => state.isLoadingContractItem === 'loading',
	userPreferences: (state) => state.contractList,
	isLoadingPreferences: (state) => state.isLoadingPreferences === 'loading',
};

const mutations = {
	[CONTRACT_DETAIL_REQUEST]: (state) => {
		state.contractItemList = [];
		state.contracts = '';
		state.sequences = '';
		state.loads = '';
		state.po = '';
		state.status = '';
		state.isLoadingContractItem = 'loading';
	},
	[CONTRACT_DETAIL_REQUEST_SUCCESS]: (state, data) => {
		state.contractItemList = data;
		state.contracts = data.contract.contractNumber;
		state.sequences = data.sequence.contractSequence;
		state.loads = data.load.loadNumber;
		state.po = data.contract.purchaseOrder;
		state.status = data.sequence.statusSequence;
		state.isLoadingContractItem = 'success';
	},
	[CONTRACT_DETAIL_REQUEST_ERROR]: (state) => {
		state.contractItemList = [];
		state.contracts = '';
		state.sequences = '';
		state.loads = '';
		state.po = '';
		state.status = '';
		state.isLoadingContractItem = 'error';
	},
};

const actions = {
	[CONTRACT_DETAIL_REQUEST]: ({ commit }, { vm, id }) => {
		return new Promise((resolve) => {
			commit(CONTRACT_DETAIL_REQUEST);

			let url = `contract-items/${id}`;

			http({
				method: 'GET',
				url: url,
				headers: {
					'Accept-Language': vm.$i18n.locale,
				},
			})
				.then((response) => {
					commit(CONTRACT_DETAIL_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACT_DETAIL_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
