export const USER_NAME_FILTER_REQUEST = 'USER_NAME_FILTER_REQUEST';
export const USER_NAME_FILTER_REQUEST_SUCCESS = 'USER_NAME_FILTER_REQUEST_SUCCESS';
export const USER_NAME_FILTER_REQUEST_ERROR = 'USER_NAME_FILTER_REQUEST_ERROR';
export const CLEAR_USER_NAME_FILTER = 'CLEAR_USER_NAME_FILTER';

export const USER_EMAIL_FILTER_REQUEST = 'USER_EMAIL_FILTER_REQUEST';
export const USER_EMAIL_FILTER_REQUEST_SUCCESS = 'USER_EMAIL_FILTER_REQUEST_SUCCESS';
export const USER_EMAIL_FILTER_REQUEST_ERROR = 'USER_EMAIL_FILTER_REQUEST_ERROR';
export const CLEAR_USER_EMAIL_FILTER = 'CLEAR_USER_EMAIL_FILTER';

export const MARKET_FILTER_REQUEST_ALL = 'MARKET_FILTER_REQUEST_ALL';
export const MARKET_FILTER_REQUEST = 'MARKET_FILTER_REQUEST';
export const MARKET_FILTER_REQUEST_SUCCESS = 'MARKET_FILTER_REQUEST_SUCCESS';
export const MARKET_FILTER_REQUEST_ERROR = 'MARKET_FILTER_REQUEST_ERROR';
export const CLEAR_MARKET_FILTER = 'CLEAR_MARKET_FILTER';
export const SAVE_MARKET_FILTER = 'SAVE_MARKET_FILTER';
export const SAVE_MANAGEMENT_MARKET_FILTER = 'SAVE_MANAGEMENT_MARKET_FILTER';

export const PO_FILTER_REQUEST_ALL = 'PO_FILTER_REQUEST_ALL';
export const PO_FILTER_REQUEST = 'PO_FILTER_REQUEST';
export const PO_FILTER_REQUEST_SUCCESS = 'PO_FILTER_REQUEST_SUCCESS';
export const PO_FILTER_REQUEST_ERROR = 'PO_FILTER_REQUEST_ERROR';
export const CLEAR_PO_FILTER = 'CLEAR_PO_FILTER';
export const SAVE_PO_FILTER = 'SAVE_PO_FILTER';
export const SAVE_MANAGEMENT_PO_FILTER = 'SAVE_MANAGEMENT_PO_FILTER';

export const COMPANY_FILTER_REQUEST_ALL = 'COMPANY_FILTER_REQUEST_ALL';
export const COMPANY_FILTER_REQUEST = 'COMPANY_FILTER_REQUEST';
export const COMPANY_FILTER_REQUEST_SUCCESS = 'COMPANY_FILTER_REQUEST_SUCCESS';
export const COMPANY_FILTER_REQUEST_ERROR = 'COMPANY_FILTER_REQUEST_ERROR';
export const SAVE_COMPANY_FILTER = 'SAVE_COMPANY_FILTER';
export const SAVE_MANAGEMENT_COMPANY_FILTER = 'SAVE_MANAGEMENT_COMPANY_FILTER';
export const CLEAR_COMPANY_FILTER = 'CLEAR_COMPANY_FILTER';

export const HEADER_COMPANY_FILTER_REQUEST = 'HEADER_COMPANY_FILTER_REQUEST';
export const HEADER_COMPANY_FILTER_REQUEST_SUCCESS = 'HEADER_COMPANY_FILTER_REQUEST_SUCCESS';
export const HEADER_COMPANY_FILTER_REQUEST_ERROR = 'HEADER_COMPANY_FILTER_REQUEST_ERROR';
export const SAVE_HEADER_COMPANY_FILTER = 'SAVE_HEADER_COMPANY_FILTER';
export const CLEAR_HEADER_COMPANY_FILTER = 'CLEAR_HEADER_COMPANY_FILTER';

export const DEFINE_DEFAULT_HEADER_COMPANY = 'DEFINE_DEFAULT_HEADER_COMPANY';

export const CONTRACTS_FILTER_REQUEST = 'CONTRACTS_FILTER_REQUEST';
export const CONTRACTS_FILTER_REQUEST_SUCCESS = 'CONTRACTS_FILTER_REQUEST_SUCCESS';
export const CONTRACTS_FILTER_REQUEST_ERROR = 'CONTRACTS_FILTER_REQUEST_ERROR';
export const SAVE_CONTRACT_FILTER = 'SAVE_CONTRACT_FILTER';
export const SAVE_MANAGEMENT_CONTRACT_FILTER = 'SAVE_MANAGEMENT_CONTRACT_FILTER';
export const CLEAR_CONTRACTS_FILTER = 'CLEAR_CONTRACTS_FILTER';

export const SAVE_RELEASE_INITIAL = 'SAVE_RELEASE_INITIAL';
export const SAVE_RELEASE_END = 'SAVE_RELEASE_END';
export const SAVE_CONTRACT_INITIAL = 'SAVE_CONTRACT_INITIAL';
export const SAVE_CONTRACT_END = 'SAVE_CONTRACT_END';
export const SAVE_CONTRACT_STATUS = 'SAVE_CONTRACT_STATUS';

export const CLEAR_CACHED_FILTERS = 'CLEAR_CACHED_FILTERS';
