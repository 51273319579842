<template>
	<div class="viewer-wrapper">
		<div class="viewer__side-scroll" v-if="!isWeb">
			<MapCard icon="cog" title="modules.load_management.sections.inProduction" :seed="mapStatus.inProduction" />
			<MapCard icon="lift" title="modules.load_management.sections.loading" :seed="mapStatus.inLoading" />
			<MapCard icon="truck" title="modules.load_management.gateInOrigin" :seed="mapStatus.gateInOrigin" />
			<MapCard icon="ship" title="modules.load_management.sections.shipped" :seed="mapStatus.shipped" />
			<MapCard icon="anchor" title="modules.load_management.sections.deliveredToPOD" :seed="mapStatus.deliveredOnPod" />
			<MapCard icon="check" title="modules.load_management.sections.gateOutDestination" :seed="mapStatus.gateOutDestiny" />
		</div>
		<div class="viewer__first-column" v-if="isWeb">
			<MapCard icon="cog" title="modules.load_management.sections.inProduction" :seed="mapStatus.inProduction" />
			<MapCard icon="lift" title="modules.load_management.sections.loading" :seed="mapStatus.inLoading" />
			<MapCard icon="truck" title="modules.load_management.sections.gateInOrigin" :seed="mapStatus.gateInOrigin" />
		</div>
		<div class="viewer__second-column">
			<Map />
		</div>
		<div class="viewer__third-column" v-if="isWeb">
			<MapCard icon="ship" title="modules.load_management.sections.shipped" :seed="mapStatus.shipped" />
			<MapCard icon="anchor" title="modules.load_management.sections.deliveredToPOD" :seed="mapStatus.deliveredOnPod" />
			<MapCard icon="check" title="modules.load_management.sections.gateOutDestination" :seed="mapStatus.gateOutDestiny" />
		</div>
	</div>
</template>

<script>
import MapCard from '@/components/modules/LoadManagement/MapCard/MapCard.vue';
import Map from '@/components/modules/LoadManagement/Map/Map.vue';
import { mapGetters, mapActions } from 'vuex';
import { MAP_STATUS_REQUEST } from '@/store/modules/loadManagement/actions';

export default {
	name: 'MapViewer',
	props: {
		isWeb: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		MapCard,
		Map,
	},
	data: () => ({
		showContent: false,
		cogs: {
			title: 'Em Produção',
			list: [
				{
					label: 'Contratos',
					value: '2',
				},
				{
					label: 'Produtos',
					value: '5',
				},
				{
					label: 'Peso líquido',
					value: '220 t',
				},
			],
		},
		lift: {
			title: 'Em Carregamento',
			list: [
				{
					label: 'Contratos',
					value: '2',
				},
				{
					label: 'Produtos',
					value: '5',
				},
				{
					label: 'Cargas',
					value: '15',
				},
				{
					label: 'Peso líquido',
					value: '520 t',
				},
			],
		},
		truck: {
			title: 'Gate in Origem',
			list: [
				{
					label: 'Contratos',
					value: '2',
				},
				{
					label: 'Produtos',
					value: '5',
				},
				{
					label: 'Cargas',
					value: '15',
				},
				{
					label: 'Peso líquido',
					value: '520 t',
				},
			],
			status: 'Aguardando pré-pagamento',
		}
	}),
	created() {},
	mounted() {
		this.getMapStatus({ vm: this, companyId: this.headerCompanyFilterCache.value.id });
	},
	beforeDestroy() {},
	methods: {
		handleContent() {
			this.showContent = !this.showContent;
		},
		...mapActions('loadManagement', {
			getMapStatus: MAP_STATUS_REQUEST,
		}),
	},
	computed: {
		...mapGetters('filters', ['companyFilter', 'headerCompanyFilterCache']),
		...mapGetters('loadManagement', ['mapStatus']),
		...mapGetters('loadManagementFilters', ['lastQueryString']),
	},
	watch: {
		lastQueryString() {
			this.getMapStatus({ vm: this, companyId: this.headerCompanyFilterCache.value.id });
		},
	},
};
</script>

<style lang="scss">
@import 'MapViewer.scss';
</style>
