import store from '../store/store';

function clearApp() {
	store.commit('auth/CLEAR_LOGIN_STATE');
	store.commit('user/CLEAR_USER_STATE');
	store.commit('userManagement/CLEAR_USER_MANAGEMENT_STATE');
	store.commit('contractManagement/CLEAR_CONTRACT_MANAGEMENT_STATE');
	store.commit('dashboard/CLEAR_DASHBOARD_STATE');
	store.commit('docInstruction/CLEAR_CONTRACT_STATE');
}

export default clearApp;
