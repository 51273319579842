<template>
	<div>
		<div class="doc-thirdStepOption" v-if="isWeb">
			<button class="doc-thirdStepOption__backButton" @click="goToPreviewStep">
				{{ $t('buttons.back') }}
			</button>
			<div class="doc-thirdStepOption__group">
				<button class="doc-thirdStepOption__cancelButton" @click="goToBackStep">
					{{ $t('buttons.cancel') }}
				</button>
				<button
					class="doc-thirdStepOption__steps"
					:class="{ active: showStepList }"
					@click="handleSteps()"
				>
					<StepsIcon />
				</button>
			</div>
		</div>
		<div v-else class="doc-thirdStepOption">
			<CompanyInput ref="companiesHeader" :isWeb="false" />
			<div class="doc-thirdStepOption__wrapper">
				<button class="doc-thirdStepOption__backButton" @click="goToPreviewStep">
					{{ $t('buttons.back') }}
				</button>
				<button class="btn doc-thirdStepOption__steps--modal isStep" @click="handleSteps()">
					<StepsIcon />{{ $t('modules.doc_instruction.steps.step_by_step') }}
				</button>
			</div>
			<StepList v-show="showStepList" />
			<h2 class="doc-thirdStepOption__subtitle">
				{{ $t('modules.doc_instruction.steps.confirmTheData') }}
			</h2>
			<button class="doc-thirdStepOption__change" @click="editTemplate()">
				{{ $t('buttons.change_template') }}
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SET_SHOW_STEP_LIST } from '@/store/modules/docInstruction/actions';
import CompanyInput from '@/components/shared/Header/Inputs/CompanyInput/CompanyInput.vue';
import PlusIcon from '@/components/shared/Icons/PlusIcon.vue';
import StepsIcon from '@/components/shared/Icons/StepsIcon.vue';
import StepList from '@/components/modules/DocInstruction/Steps/StepList/StepList.vue';
export default {
	name: 'ThirdStepOption',
	components: {
		PlusIcon,
		StepsIcon,
		StepList,
		CompanyInput,
	},
	props: {
		isWeb: {
			require: true,
		},
	},
	methods: {
		...mapActions('docInstruction', {
			setShowStepList: SET_SHOW_STEP_LIST,
		}),
		goToBackStep() {
			this.$emit('goToStep');
		},
		goToPreviewStep() {
			this.$emit('goToPreviewStep');
		},
		handleSteps() {
			this.setShowStepList(!this.showStepList);
		},
		editTemplate() {
			const { id = "" } = this.$route.query;
			this.$router.push({
				path: `/main/docInstruction/newTemplate/${id}`,
			});
		},
	},
	computed: {
		...mapGetters('docInstruction', ['showStepList']),
	},
};
</script>

<style lang="scss">
@import 'ThirdStepOption.scss';
</style>
