<template>
	<div id="doc-instruction" class="doc-instruction">
		<Content>
			<template v-slot:header>
				<!-- Mobile Filter -->
				<MobileFilter
					v-if="!isWeb && (getStep === 1 || getStep === 2)"
					:isStep="true"
					:buttonLabel="$t('modules.doc_instruction.steps.step_by_step')"
					:fullWidth="true"
				>
					<template v-slot:inputs-list>
						<FilterList
							ref="filterDocInstruction"
							:isWeb="isWeb"
							@refreshFilters="refreshFilters"
						/>
					</template>
				</MobileFilter>

				<!-- Web Filter -->
				<WebFilter v-if="isWeb && getStep === 1">
					<template v-slot:inputs-list>
						<FilterList
							ref="filterDocInstruction"
							:isWeb="isWeb"
							@refreshFilters="refreshFilters"
						/>
					</template>
				</WebFilter>

				<!-- Step Controller -->
				<HeadOptions :step="getStep" :isWeb="isWeb" />
			</template>
			<template v-slot:body>
				<!-- Step Cards -->
				<StepList
					v-show="showStepList"
					v-if="(getStep !== 2 && getStep !== 3 && !isWeb) || isWeb"
				/>

				<!-- Header Step Selector -->
				<HeaderStep v-if="isWeb" :step="getStep" @updateContractList="updateContractList" />
				<ContractList v-show="getStep === 1" :isWeb="isWeb" @changePage="changePage" />
				<SelectInstruction v-show="getStep === 2" />
				<SelectedTemplate v-show="getStep === 3" />
				<DocumentsPackage v-show="getStep === 4" :isWeb="isWeb" />
				<div class="doc-instruction__holder" v-show="!noContractsSelected && !isWeb"></div>
				<MobileStep v-show="!isWeb" :zIndex="0" />
				<HeaderStep :footer="true" v-if="isWeb && getStep === 3" :step="getStep" @updateContractList="updateContractList" />
			</template>
		</Content>
		<ModalNoData v-show="showModalNoData" @closeModal="handleModalNoData()" />
	</div>
</template>

<script>
// Layout Components
import Content from '@/components/shared/Content/Content.vue';
import MobileFilter from '@/components/shared/MobileFilter/MobileFilter.vue';
import WebFilter from '@/components/shared/WebFilter/WebFilter.vue';

// Filter List
import FilterList from '@/components/modules/DocInstruction/Filter/FilterList.vue';

// Steps
import StepList from '@/components/modules/DocInstruction/Steps/StepList/StepList.vue';
import HeaderStep from '@/components/modules/DocInstruction/Steps/HeaderStep/HeaderStep.vue';
import HeadOptions from '@/components/modules/DocInstruction/Steps/HeadOptions/HeadOptions.vue';
import MobileStep from '@/components/modules/DocInstruction/Steps/MobileStep/MobileStep.vue';

// Step One
import ContractList from '@/components/modules/DocInstruction/StepOne/ContractList/ContractList.vue';

// Step Two
import SelectInstruction from '@/components/modules/DocInstruction/StepTwo/SelectInstruction/SelectInstruction.vue';
import ModalNoData from '@/components/modules/DocInstruction/Modals/ModalNoData/ModalNoData.vue';

// Step Three
import SelectedTemplate from '@/components/modules/DocInstruction/StepThree/SelectedTemplate/SelectedTemplate.vue';

// Step Four
import DocumentsPackage from '@/components/modules/DocInstruction/StepFour/DocumentsPackage/DocumentsPackage.vue';

import { mapGetters, mapActions } from 'vuex';
import {
	DOC_POD_FILTER_REQUEST,
	DOC_DESCRIPTION_FILTER_REQUEST,
	DOC_CONTRACT_FILTER_REQUEST,
	CLEAR_ALL_DOC_FILTERS,
} from '@/store/modules/filtersByModules/docInstructionFilters/actions';
import {
	CLEAR_CONTRACT_STATE,
	CONTRACT_LIST_REQUEST,
	SET_STEP,
	CLEAR_SELECTED_CONTRACTS_SEQUENCES,
	COUNTRIES_REQUEST,
	CLIENTS_LIST_REQUEST,
	BANK_LIST_REQUEST,
} from '@/store/modules/docInstruction/actions';

import {
	GET_ACTIVED_TEMPLATES,
	GET_DISABLED_TEMPLATES,
	CLEAN_ALL_TEMPLATES,
} from '@/store/modules/docInstruction/selectInstruction/actions';

export default {
	name: 'DocInstruction',
	components: {
		// Layout Components
		Content,
		MobileFilter,
		WebFilter,

		// Filter List and Steps
		FilterList,
		StepList,
		HeaderStep,
		HeadOptions,
		MobileStep,

		// Step One
		ContractList,

		// Step Two
		SelectInstruction,
		ModalNoData,

		// Step Three
		SelectedTemplate,

		// Step Four
		DocumentsPackage,
	},
	beforeRouteLeave(_to, _from, next) {
		if (
			window.event.type == 'popstate' &&
			this.getStep > 1 &&
			_to.name != 'header.edit_template' &&
			_to.name != 'header.new_template' &&
			_from.name != 'header.edit_template'
		) {
			this.setStep(this.getStep - 1);
			if (this.getStep == 1) {
				this.clearSelected();
			}
			next(false);
			return;
		}
		next(true);
	},
	data: () => ({
		windowWidth: window.innerWidth,

		// cached filter handlers
		selectedPods: '',
		selectedDescriptions: '',
		selectedContracts: '',
		showModalNoData: false,
	}),
	created() {
		this.refreshFilters();
		if (this.noContractsSelected) {
			this.clearContractFilters();
			this.resetContractStore();
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});

		this.getCountriesList({ vm: this });

		if (this.noContractsSelected) {
			this.updateContractList();
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		...mapActions('docInstructionFilters', {
			getPodFilter: DOC_POD_FILTER_REQUEST,
			getDescriptionFilter: DOC_DESCRIPTION_FILTER_REQUEST,
			getContractFilter: DOC_CONTRACT_FILTER_REQUEST,
			clearContractFilters: CLEAR_ALL_DOC_FILTERS,
		}),
		...mapActions('docInstruction', {
			setStep: SET_STEP,
			getContractList: CONTRACT_LIST_REQUEST,
			resetContractStore: CLEAR_CONTRACT_STATE,
			clearSelected: CLEAR_SELECTED_CONTRACTS_SEQUENCES,
			getCountriesList: COUNTRIES_REQUEST,
			getClients: CLIENTS_LIST_REQUEST,
			getBank: BANK_LIST_REQUEST,
		}),
		...mapActions('selectInstruction', {
			getEnabledTemplateList: GET_ACTIVED_TEMPLATES,
			getDisabledTemplateList: GET_DISABLED_TEMPLATES,
			cleanTemplateList: CLEAN_ALL_TEMPLATES,
		}),
		updateContractList() {
			this.normalizeFilters();
			this.getContractList({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				contractDateStart: this.initDateFilterCache.value.id,
				contractDateEnd: this.endDateFilterCache.value.id,
				pod: this.selectedPods,
				contractNumber: this.selectedContracts,
				searchParam: this.selectedDescriptions,
			});
		},
		normalizeFilters() {
			// Maps para montar lista de ids do filtro para a requisição de docs
			this.selectedPods = this.podFilterCache.value.map((item) => item.id).join(',');

			this.selectedDescriptions = this.descriptionFilterCache.value
				.map((item) => item.id)
				.join(',');

			this.selectedContracts = this.contractFilterCache.value.map((item) => item.id).join(',');
		},
		refreshFilters(param) {
			const filterObject = { vm: this };

			if (param != undefined && param != null) {
				filterObject['params'] = param.queryString;
				filterObject['skip'] = param.skip;
			} else {
				filterObject['params'] = null;
				filterObject['skip'] = false;
				filterObject['lastQuery'] = this.lastQueryString;
			}

			this.getPodFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocInstruction.setCachedPodInput();
				}
			});
			this.getDescriptionFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocInstruction.setCachedDescriptionInput();
				}
			});
			this.getContractFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterDocInstruction.setCachedContractInput();
				}
			});
		},
		changePage(param) {
			this.getContractList({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				limit: 10,
				page: param.params,
				contractDateStart: this.initDateFilterCache.value.id,
				contractDateEnd: this.endDateFilterCache.value.id,
				pod: this.selectedPods,
				contractNumber: this.selectedContracts,
				searchParam: this.selectedDescriptions,
			});
		},
		handleModalNoData() {
			this.showModalNoData = !this.showModalNoData;
		},
	},
	computed: {
		isWeb() {
			return this.windowWidth > 992;
		},
		...mapGetters('filters', ['companyFilter', 'headerCompanyFilterCache']),
		...mapGetters('docInstructionFilters', [
			'initDateFilterCache',
			'endDateFilterCache',
			'podFilterCache',
			'descriptionFilterCache',
			'contractFilterCache',
			'lastQueryString',
		]),
		...mapGetters('docInstruction', [
			'getStep',
			'showStepList',
			'contractListPage',
			'contactListMax',
			'selectedContractsSequence',
			'isLoadingContracts',
		]),
		noContractsSelected() {
			return !this.selectedContractsSequence.length;
		},
		pagination() {
			const pagination =
				this.isLoadingContracts != 'empty'
					? { currentPage: this.contractListPage, totalPages: this.contactListMax }
					: { currentPage: 1, totalPages: 1 };

			return pagination;
		},
		loadIsDone() {
			return this.isLoadingContracts === 'success';
		},
	},
	watch: {
		//Watch para verificar o filtro de empresa localizado no header
		headerCompanyFilterCache(value) {
			if (value.value !== null) {
				this.clearContractFilters();
				this.resetContractStore();
				this.refreshFilters();
				this.normalizeFilters();
				this.getContractList({
					vm: this,
					companyId: this.headerCompanyFilterCache.value.id,
					contractDateStart: this.initDateFilterCache.value.id,
					contractDateEnd: this.endDateFilterCache.value.id,
					pod: this.selectedPods,
					contractNumber: this.selectedContracts,
					searchParam: this.selectedDescriptions,
				});

				setTimeout(() => {
					this.$refs.filterDocInstruction.clearCachedFilters();
				}, 500);
			}
		},
		lastQueryString() {
			this.updateContractList();
		},
		getStep(value) {
			if (value == 2) {
				this.cleanTemplateList();
				this.getClients({ vm: this, companyId: this.headerCompanyFilterCache.value.id }).then(
					(res) => {
						if (res.response.data.statusCode === 404) {
							this.showModalNoData = true;
						}
					},
				);
				this.getBank({ vm: this, companyId: this.headerCompanyFilterCache.value.id }).then(
					(res) => {
						if (res.response.data.statusCode === 404) {
							this.showModalNoData = true;
						}
					},
				);
				this.getEnabledTemplateList({
					vm: this,
					companyId: this.headerCompanyFilterCache.value.id,
				});
				this.getDisabledTemplateList({
					vm: this,
					companyId: this.headerCompanyFilterCache.value.id,
				});
			}
		},
	},
};
</script>

<style lang="scss">
@import 'DocInstruction.scss';
</style>
