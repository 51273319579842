<template>
	<div id="steps" class="steps">
		<StepCard
			:title="`${$t('modules.doc_instruction.steps.title')}`"
			:description="$t('modules.doc_instruction.steps.step_one_description')"
			:stepNumber="1"
		/>
		<StepCard
			:title="`${$t('modules.doc_instruction.steps.title')}`"
			:description="$t('modules.doc_instruction.steps.step_two_description')"
			:stepNumber="2"
		/>
		<StepCard
			:title="`${$t('modules.doc_instruction.steps.title')}`"
			:description="$t('modules.doc_instruction.steps.step_three_description')"
			:stepNumber="3"
		/>
		<StepCard
			:title="`${$t('modules.doc_instruction.steps.title')}`"
			:description="$t('modules.doc_instruction.steps.step_four_description')"
			:stepNumber="4"
		/>
	</div>
</template>

<script>
import StepCard from './StepCard/StepCard.vue';
export default {
	name: 'StepList',
	components: {
		StepCard,
	},
};
</script>

<style lang="scss" scoped>
@import 'StepList.scss';
</style>
