<template>
	<form class="form" id="two-way-form" v-on:submit.prevent="sendToken">
		<div class="row">
			<div class="back-btn" @click="handleTwoWay()">
				<BackArrow />
			</div>
			<div class="form__title">
				<SuccessIcon :width="15" :height="15" />
				<h1 class="form__title-text">
					{{ $t('modules.login.code_sended') }}
				</h1>
			</div>
		</div>
		<p class="form__text" v-html="$t('modules.login.verify_email')"></p>
		<div class="field">
			<input
				v-for="(input, index) in inputList"
				:key="input.id"
				class="field__input"
				type="tel"
				name="token"
				:id="input.id"
				v-model="inputList[index].model"
				maxlength="1"
				@keydown.backspace="backInput(input, index)"
				@input="nextInput(input, index)"
				@click="focusOnFirstEmpty(input, index)"
			/>
		</div>
		<div class="actions variant">
			<button
				id="token-btn"
				class="actions__btn"
				:disabled="isLoadingAuth"
				type="submit"
				v-if="!isLoadingAuth"
			>
				<p>{{ $t('buttons.login') }}</p>
				<LoginArrow />
			</button>
			<SpinnerIcon color="white" v-else />
		</div>
	</form>
</template>

<script>
import { mapActions } from 'vuex';
import { TWO_WAY_REQUEST } from '@/store/common/auth/actions';
import { USER_DATA_REQUEST } from '@/store/common/user/actions';
import LoginArrow from '@/components/shared/Icons/LoginArrow.vue';
import BackArrow from '@/components/shared/Icons/BackArrow.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon.vue';
import SuccessIcon from '@/components/shared/Icons/SuccessIcon.vue';
import {
	COMPANY_FILTER_REQUEST,
	HEADER_COMPANY_FILTER_REQUEST,
	SAVE_HEADER_COMPANY_FILTER,
	DEFINE_DEFAULT_HEADER_COMPANY,
} from '@/store/common/filters/actions';
import { isValidJWT } from '@/utils/jwt';

export default {
	name: 'TwoWayForm',
	components: {
		LoginArrow,
		BackArrow,
		SpinnerIcon,
		SuccessIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
		hashCode: {
			default: null,
		},
	},
	data: () => ({
		isLoadingAuth: false,
		backspaceAgain: false,
		inputList: [
			{ id: 'token-input-0', model: null },
			{ id: 'token-input-1', model: null },
			{ id: 'token-input-2', model: null },
			{ id: 'token-input-3', model: null },
			{ id: 'token-input-4', model: null },
			{ id: 'token-input-5', model: null },
		],
	}),
	computed: {
		publicKey() {
			return atob(process.env.VUE_APP_AUTH_PUBLIC_KEY);
		},
	},
	mounted() {},
	methods: {
		...mapActions('auth', { getTwoWay: TWO_WAY_REQUEST }),
		...mapActions('user', { getUserData: USER_DATA_REQUEST }),
		...mapActions('filters', {
			getCompanyFilter: COMPANY_FILTER_REQUEST,
			getHeaderCompanyFilter: HEADER_COMPANY_FILTER_REQUEST,
			setDefaultCompany: DEFINE_DEFAULT_HEADER_COMPANY,
		}),
		handleTwoWay() {
			this.$emit('handleTwoWay');
		},
		focusOnFirstEmpty(param, index) {
			if (!param.model && index != 0) {
				const filteredList = this.inputList.filter((input) => !input.model);
				const firstEmptyId = filteredList[0].id;
				document.getElementById(firstEmptyId).focus();
			}
		},
		backInput(param, index) {
			if (!param.model && index != 0) {
				document.getElementById(`token-input-${index - 1}`).focus();
				this.backspaceAgain = !this.backspaceAgain;
				return;
			}
		},
		nextInput(param, index) {
			if (!param.model) {
				return;
			}

			if (param.model && index != 5) {
				document.getElementById(`token-input-${index + 1}`).focus();
				return;
			}
		},
		sendToken() {
			let token = this.inputList.map((value) => value.model).join('');

			if (token.length != 6) {
				return this.$toastr.e(this.$t('errors.fill_fields'));
			}

			const body = {
				token: token,
				hashCode: this.hashCode,
			};

			this.isLoadingAuth = true;

			this.getTwoWay({ vm: this, body: body })
				.then((response) => {
					isValidJWT(response.data.token, this.publicKey)
						.then((isValid) => {
							if (!isValid) {
								throw new Error('Invalid token');
							}

							this.getUserData({ vm: this }).then(() => {
								this.isLoadingAuth = false;
								this.$router.push({ path: '/main/dashboard' });
								this.getHeaderCompanyFilter({ vm: this }).then((res) => {
									this.setDefaultCompany();
								});
							});
						})
						.catch(() => {
							this.isLoadingAuth = false;
						});
				})
				.catch(() => {
					this.isLoadingAuth = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'TwoWayForm.scss';
</style>
