<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.499999 8C0.499999 3.85786 3.85786 0.5 8 0.5C12.1421 0.500001 15.5 3.85787 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.499998 12.1421 0.499999 8Z"
			:stroke="color"
		/>
		<path
			d="M5.81818 5.818L8 7.99982L5.81818 10.1816"
			:stroke="color"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.1818 5.818L8 7.99982L10.1818 10.1816"
			:stroke="color"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#AAC3DB',
		},
		height: {
			default: '16',
		},
		width: {
			default: '16',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
