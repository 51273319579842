<template>
	<div id="dashboardTable">
		<div class="title">
			<span>{{ $t('modules.dashboard.table.title') }}</span>
		</div>
		<div class="table-container" v-if="tableRows.length != 0 && !isLoadingTable">
			<table class="table">
				<thead class="table__header">
					<tr>
						<th v-for="(header, index) in tableHeader" :key="index">
							<div class="row">
								{{ header.label }}
								<div class="header-icon" @click="sortTable(header.filter)">
									<FilterTable />
								</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="(row, index) in tableRows">
						<tr
							:key="row.id"
							:class="[index % 2 == 0 ? 'stripped' : '']"
							@click="goToDetail(row.id)"
						>
							<td>
								{{ row.contract.contractNumber }}
							</td>
							<td>
								{{ row.contractSequence }}
							</td>
							<td>
								{{ row.loadNumber }}
							</td>
							<td>
								{{ row.vessel }}
							</td>
							<td>
								{{ row.invoiceNumber }}
							</td>
							<td>
								{{ row.sku }}
							</td>
							<td class="white-bg" :style="{ color: getColor(row.status) }">
								{{ getStatusTranslation(row.status) }}
							</td>
							<td>
								{{ formatTons(row.totalKg) }}
							</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<div class="loader" v-if="isLoadingTable">
			<SpinnerIcon />
		</div>
		<div class="no__data" v-if="tableRows.length == 0 && !isLoadingTable">
			{{ $t('errors.dashboard_empty_data') }}
		</div>
		<Pagination
			ref="pagination"
			v-if="tableRows.length != 0 && !isLoadingTable"
			:totalPages="pagination.totalPages"
			:currentFromReq="pagination.currentPage"
			@tableRequest="tableRequest"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterTable from '@/components/shared/Icons/FilterTable.vue';
import EditIcon from '@/components/shared/Icons/EditIcon.vue';
import DeleteIcon from '@/components/shared/Icons/DeleteIcon.vue';
import Pagination from '@/components/shared/Pagination/Pagination';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';

export default {
	name: 'Table',
	components: {
		FilterTable,
		EditIcon,
		DeleteIcon,
		Pagination,
		SpinnerIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		sort: {
			sortContractNumber: null,
			sortContractSequence: null,
			sortLoadNumber: null,
			sortVessel: null,
			sortInvoiceNumber: null,
			sortSku: null,
			sortStatusSequence: null,
			sortTotalVolume: null,
		},
	}),
	mounted() {},
	methods: {
		tableRequest(param) {
			this.$emit('changePage', param);
		},
		sortTable(filter) {
			if (this.sort[filter] == null) {
				this.sort = {
					sortContractNumber: null,
					sortContractSequence: null,
					sortLoadNumber: null,
					sortVessel: null,
					sortInvoiceNumber: null,
					sortSku: null,
					sortStatusSequence: null,
					sortTotalVolume: null,
				};
			}

			this.sort[filter] == 'ASC' ? (this.sort[filter] = 'DESC') : (this.sort[filter] = 'ASC');
			const queryString = this.createQueryString();
			this.$emit('sortTable', queryString);
		},
		createQueryString() {
			const query = new URLSearchParams();
			let {
				sortContractNumber,
				sortContractSequence,
				sortLoadNumber,
				sortVessel,
				sortInvoiceNumber,
				sortSku,
				sortStatusSequence,
				sortTotalVolume,
			} = this.sort;

			if (sortContractNumber) query.set('sortContractNumber', sortContractNumber);
			if (sortContractSequence) query.set('sortContractSequence', sortContractSequence);
			if (sortLoadNumber) query.set('sortLoadNumber', sortLoadNumber);
			if (sortVessel) query.set('sortVessel', sortVessel);
			if (sortInvoiceNumber) query.set('sortInvoiceNumber', sortInvoiceNumber);
			if (sortSku) query.set('sortSku', sortSku);
			if (sortStatusSequence) query.set('sortStatusSequence', sortStatusSequence);
			if (sortTotalVolume) query.set('sortTotalVolume', sortTotalVolume);

			return query.toString();
		},
		formatTons(param) {
			if (!param) {
				return '0t';
			}
			const tons = parseInt(param) / 1000;

			return `${tons.toFixed(3)}t`;
		},
		getColor(param) {
			return {
				Contrato: '#234057',
				Carga: '#B47D4A',
				Processo: '#803423',
				Container: '#678AA3',
				Invoice: '#AAC3DB',
				Contrato2: '#00B564',
			}[param];
		},
		setPageOne() {
			if (this.tableRows.length != 0 && !this.isLoadingTable) {
				this.$refs.pagination.setPageOne();
			}
		},
		goToDetail(id) {
			this.$router.push({
				path: '/main/contractDetail/',
				query: { id: id },
			});
		},
		getStatusTranslation(status) {
			return {
				'EM PROCESSAMENTO': this.$t('contract_step.processing'),
				'RESTRIÇÕES CLIENTE': this.$t('contract_step.released_customer_restriction'),
				'EM PRODUÇÃO': this.$t('contract_step.in_production'),
				'EM CARREGAMENTO': this.$t('contract_step.in_loading'),
				'GATE IN ORIGEM': this.$t('contract_step.gate_in'),
				'AGUARDA PRÉ PGTO': this.$t('contract_step.pre_payment_pending'),
				'EMBARCADO': this.$t('contract_step.boarded'),
				'DOCUMENTAÇÃO ENVIADA': this.$t('contract_step.documentation_sended'),
				'DOCUMENTAÇÃO ENTREGUE': this.$t('contract_step.documentation_delivered'),
				'ENTREGUE NO POD': this.$t('contract_step.delivered_in_pod'),
				'GATE OUT DESTINO': this.$t('contract_step.gate_out'),
				'VAZIO DEVOLVIDO': this.$t('contract_step.empty_return'),
				'ABERTA': this.$t('contract_step.opened'),
				'ABERTA EM ATRASO': this.$t('contract_step.opened_delay'),
				'PAGO PARCIAL': this.$t('contract_step.partial_paid'),
				'PAGO TOTAL': this.$t('contract_step.total_paid'),
				'FINALIZADO': this.$t('contract_step.finished'),
			}[status].toUpperCase();
		},
	},
	computed: {
		...mapGetters('dashboard', ['dashboardTable', 'isLoadingTable']),
		tableHeader() {
			return [
				{
					field: 'contract',
					filter: 'sortContractNumber',
					label: this.$t('modules.dashboard.table.contract'),
				},
				{
					field: 'sequence',
					filter: 'sortContractSequence',
					label: this.$t('modules.dashboard.table.sequence'),
				},
				{
					field: 'load',
					filter: 'sortLoadNumber',
					label: this.$t('modules.dashboard.table.load'),
				},
				{
					field: 'transport',
					filter: 'sortVessel',
					label: this.$t('modules.dashboard.table.transport'),
				},
				{
					field: 'invoice',
					filter: 'sortInvoiceNumber',
					label: this.$t('modules.dashboard.table.invoice'),
				},
				{
					field: 'code',
					filter: 'sortSku',
					label: this.$t('modules.dashboard.table.code'),
				},
				{
					field: 'status',
					filter: 'sortStatusSequence',
					label: this.$t('modules.dashboard.table.status'),
				},
				{
					field: 'volume',
					filter: 'sortTotalVolume',
					label: this.$t('modules.dashboard.table.volume'),
				},
			];
		},
		tableRows() {
			const rows = this.dashboardTable.items != undefined ? this.dashboardTable.items : [];
			return !rows.length ? [] : rows;
		},
		pagination() {
			const pagination =
				this.dashboardTable.meta != undefined
					? this.dashboardTable.meta
					: { currentPage: 1, totalPages: 1 };

			return pagination;
		},
	},
};
</script>

<style scoped lang="scss">
@import 'Table.scss';
</style>
