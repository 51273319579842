<template>
	<div id="docsTable">
		<div class="table" v-if="tableRows.length > 0 && isLoadingCourierList === 'success'">
			<div class="table-header">
				<div class="table-header__column">
					{{ $t('modules.doc_management.doc_table.courier') }}
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_management.doc_table.awb') }}
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_management.doc_table.shipping') }}
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_management.doc_table.destiny') }}
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_management.doc_table.delivery') }}
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_management.doc_table.status') }}
				</div>
			</div>
			<div class="table-body" @scroll="handleScroll">
				<div v-for="item in tableRows" :key="item.awbCourier" class="table-body__row">
					<div class="table-body__column">{{ item.courierCompany }}</div>
					<div class="table-body__column">{{ item.awbCourier }}</div>
					<div class="table-body__column">{{ formateDate(item.courierDate) }}</div>
					<div class="table-body__column">{{ item.destination }}</div>
					<div class="table-body__column">
						{{
							!isNaN(Date.parse(item.deliveredDate))
								? formateDate(item.deliveredDate)
								: item.deliveredDate
						}}
					</div>
					<div class="table-body__column" :class="getCourierStatusClass(item.status)">
						{{ getCourierStatus(item.itemStatus) }}
					</div>
				</div>
			</div>
		</div>
		<div class="loader" v-if="isLoadingCourierList !== 'success'">
			<DefaultLoader :dataStatus="isLoadingCourierList" color="blue" />
		</div>

		<div class="no__data" v-if="tableRows.length == 0 && isLoadingCourierList === 'success'">
			{{ $t('errors.empty_data') }}
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { COURIER_LIST_REQUEST } from '@/store/modules/docManagement/actions';

import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import DefaultLoader from '@/components/shared/DefaultLoader/DefaultLoader.vue';
import moment from 'moment';

export default {
	name: 'CourrierTable',
	components: {
		SpinnerIcon,
		DefaultLoader,
	},
	props: {
		isWeb: {
			required: true,
		},
		invoiceNumber: {
			required: true,
		},
	},
	data: () => ({
		currentMeta: [],
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('docManagement', {
			getCourierList: COURIER_LIST_REQUEST,
		}),
		handleScroll(event) {
			const target = event.target;

			if (this.isLoadingCourierList) {
				return;
			}

			if (target.scrollHeight - target.scrollTop === target.clientHeight) {
				this.page += 1;
				this.nextPage();
			}
		},
		nextPage() {
			this.getCourierList({ vm: this, invoiceNumber: this.invoiceNumber, page: this.page });
		},
		formateDate(value) {
			return moment(value).format('DD/MM/YYYY');
		},
		getCourierStatusClass(status) {
			switch (status) {
				case 0:
					return 'inTransit';
				case 1:
					return 'delivered';

				default:
					return 'notSent';
			}
		},
		getCourierStatus(status) {
			switch (status) {
				case 0:
					return this.$t('modules.doc_management.doc_table.delivered');
				case 1:
					return this.$t('modules.doc_management.doc_table.delivered');

				default:
					return this.$t('modules.doc_management.doc_table.notSent');
			}
		},
	},
	computed: {
		...mapGetters('docManagement', ['courierList', 'isLoadingCourierList']),
		tableRows() {
			const filteredList = this.courierList.filter(
				(item) => item.invoiceNumber == this.invoiceNumber,
			);
			if (filteredList.length > 0) {
				return filteredList[0].data.items;
			}
			return [];
		},
		isLoading() {
			return false;
		},
	},
	watch: {
		isLoadingCourierList(value) {
			if (value === 'success') {
				const filteredList = this.courierList.filter(
					(item) => item.invoiceNumber == this.invoiceNumber,
				);
				if (filteredList.length > 0) {
					this.currentMeta = filteredList[0].data.meta;
				}
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'CourrierTable.scss';
</style>
