<template>
	<div class="doc-selectedContracts">
		<h5>{{ $t('modules.doc_instruction.selected_contracts_title') }}</h5>
		<div class="doc-selectedContracts__itens">
			<SelectedContractBadge v-for="contract in contracts" :key="contract" :contractId="contract" />
		</div>
	</div>
</template>

<script>
import SelectedContractBadge from './SelectedContractBadge/SelectedContractBadge.vue';
export default {
	name: 'SelectedContracts',
	components: {
		SelectedContractBadge,
	},
	props: {
		contracts: {
			require: true,
		},
	},
	mounted() {
	}
};
</script>

<style lang="scss">
@import 'SelectedContracts.scss';
</style>