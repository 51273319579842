<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 16 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.25 12.7422C14.7891 11.9453 14.2344 10.4297 14.2344 7.75V7.19531C14.2344 3.73438 11.461 0.898438 8.0469 0.875H8.00002C7.18028 0.876025 6.36878 1.0385 5.61183 1.35315C4.85489 1.66779 4.16733 2.12845 3.58841 2.70882C3.0095 3.28918 2.55056 3.97789 2.23781 4.73562C1.92506 5.49335 1.76462 6.30526 1.76564 7.125V7.75C1.76564 10.4297 1.21096 11.9453 0.75002 12.7422C0.638544 12.9321 0.579209 13.1481 0.578021 13.3683C0.576833 13.5885 0.633833 13.8051 0.743254 13.9961C0.852674 14.1872 1.01063 14.346 1.20114 14.4565C1.39165 14.5669 1.60795 14.625 1.82814 14.625H4.87502C4.87502 15.4538 5.20426 16.2487 5.79031 16.8347C6.37636 17.4208 7.17122 17.75 8.00002 17.75C8.82882 17.75 9.62368 17.4208 10.2097 16.8347C10.7958 16.2487 11.125 15.4538 11.125 14.625H14.1719C14.3921 14.6264 14.6086 14.5693 14.7994 14.4594C14.9903 14.3496 15.1484 14.1911 15.2578 14C15.3663 13.8079 15.4226 13.5907 15.4212 13.3701C15.4199 13.1494 15.3608 12.933 15.25 12.7422ZM8.00002 16.5C7.50337 16.4979 7.02765 16.2997 6.67647 15.9486C6.32528 15.5974 6.12708 15.1216 6.12502 14.625H9.87502C9.87296 15.1216 9.67476 15.5974 9.32357 15.9486C8.97239 16.2997 8.49667 16.4979 8.00002 16.5Z"
			:fill="color"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#234057',
		},
		height: {
			default: '18',
		},
		width: {
			default: '16',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
