<template>
	<GenericMultiselect
		class="transport-multiselect"
		ref="transport"
		:options="transportOptions"
		:placeholder="$t('modules.load_management.filter.transport')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="false"
		:infiniteScroll="true"
		:type="'vessel'"
		:multiple="true"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	TRANSPORT_FILTER_REQUEST,
	SAVE_TRANSPORT_FILTER,
} from '@/store/modules/filtersByModules/loadManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'TransportInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('loadManagementFilters', {
			getTransportFilter: TRANSPORT_FILTER_REQUEST,
			saveTransportFilter: SAVE_TRANSPORT_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveTransportFilter(param);

			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getTransportFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		nextPage() {
			const meta = this.transportFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getTransportFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		createQueryString(transportNumber) {
			const query = new URLSearchParams();

			if (transportNumber) query.set('vessel', transportNumber);
			if (transportNumber) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.transport.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.transportFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.transport.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('loadManagementFilters', [
			'transportFilter',
			'transportFilterMeta',
			'transportFilterCache',
			'isLoadingTransportFilter',
			'lastQueryString',
		]),
		transportOptions() {
			const rows = this.transportFilter != undefined ? this.transportFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'TransportInput.scss';
</style>
