import {
	CLEAR_LOGIN_STATE,
	LOGIN_SUCCESS,
	LOGIN_REQUEST,
	LOGIN_FAILED,
	TWO_WAY_SUCCESS,
	TWO_WAY_REQUEST,
	TWO_WAY_FAILED,
	RECOVER_PASSWORD,
	NEW_PASSWORD,
	LOGOUT,
} from './actions';
import api from '@/utils/api';

const { http } = api.getInstance();
const AUTH_URL = '/auth';

const defaultState = () => ({
	token: sessionStorage.getItem(`token`) || null,
	status: '',
});

const state = defaultState();

const getters = {
	isLogged: (state) => !!state.token,
	token: (state) => state.token,
	isLoginLoading: (state) => state.status === 'loading',
};

const mutations = {
	[CLEAR_LOGIN_STATE]: (state) => {
		sessionStorage.removeItem('token');
		Object.assign(state, defaultState());
	},
	[LOGIN_REQUEST]: (state) => {
		state.status = 'loading';
	},
	[LOGIN_SUCCESS]: (state) => {
		state.status = 'success';
	},
	[LOGIN_FAILED]: (state) => {
		state.status = 'error';
	},
	[TWO_WAY_SUCCESS]: (state, { token }) => {
		sessionStorage.setItem(`token`, token);
		state.token = token;
		state.status = 'success';
	},
	[TWO_WAY_REQUEST]: (state) => {
		state.status = 'loading';
	},
	[TWO_WAY_FAILED]: (state) => {
		state.token = null;
		state.status = 'error';
	},
	[LOGOUT]: (state) => {
		state.token = null;
		state.status = '';
	},
};

const actions = {
	[LOGIN_REQUEST]({ commit }, { login, vm }) {
		return new Promise((resolve, reject) => {
			commit(LOGIN_REQUEST);

			http({
				method: 'post',
				url: `${AUTH_URL}/login`,
				data: login,
			})
				.then((response) => {
					const { data } = response;
					commit(LOGIN_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(LOGIN_FAILED);
					reject(error);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[TWO_WAY_REQUEST]({ commit }, { body, vm }) {
		return new Promise((resolve, reject) => {
			commit(TWO_WAY_REQUEST);

			http({
				method: 'post',
				url: `${AUTH_URL}/mfa-login`,
				data: body,
			})
				.then((response) => {
					const { data } = response;
					http.defaults.headers.common.Authorization = `Bearer ${data.token}`;
					commit(TWO_WAY_SUCCESS, data);
					resolve(response);
				})
				.catch((error) => {
					commit(TWO_WAY_FAILED);
					sessionStorage.removeItem('token');
					reject(error);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[RECOVER_PASSWORD]({ commit }, { vm, body }) {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `${AUTH_URL}/password/recover`,
				data: body,
			})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[NEW_PASSWORD]({ commit }, { vm, body }) {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `${AUTH_URL}/password/reset`,
				data: body,
			})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[LOGOUT]({ commit }) {
		return new Promise((resolve, reject) => {
			http.auth = null;
			http.defaults.headers.common.Authorization = null;
			commit(LOGOUT);
			resolve();
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
