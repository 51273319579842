<template>
	<svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1 12.1067C1 13.8267 2.32 15.2133 3.96 15.2133H7.30667C8.73333 15.2133 9.89333 14 9.89333 12.5067C9.89333 10.88 9.18667 10.3067 8.13333 9.93333L2.76 8.06667C1.70667 7.69333 1 7.12 1 5.49333C1 4 2.16 2.78667 3.58667 2.78667H6.93333C8.57333 2.78667 9.89333 4.17333 9.89333 5.89333M5.43733 1V17"
			:stroke="stroke"
			stroke-width="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		stroke: {
			type: String,
			default: '#FFF',
		},
	},
};
</script>
