<template>
	<div class="contract">
		<div class="contract-container">
			<div class="contract-row">
				<div
					class="field"
					:class="[
						item.name == 'company' ? (!isWeb ? 'w-100' : '') : '',
						item.name == 'status' ? 'status' : '',
						item.name == 'status' && !isWeb ? 'w-100' : '',
						item.name == 'contractNumber' && !isWeb ? 'w-100' : '',
					]"
					v-for="(item, index) in userPreferences.header"
					:key="index"
				>
					<div class="field__label" v-if="item.active">
						<span>{{ $t(`modules.contract_management.table.header.${item.name}`) }}</span>
					</div>
					<div
						class="field__value"
						:class="[
							!isWeb ? 'bold' : '',
							item.name == 'company' ? (!isWeb ? 'wrap' : 'w-50') : '',
						]"
						v-if="data[item.name] != undefined && item.active"
					>
						<span v-if="item.name != 'status'">
							{{
								item.name == 'company'
									? data[item.name].name
									: verifyDate(data[item.name], item.name)
							}}
						</span>
						<StatusChart :data="data['status']" v-if="item.name == 'status'" :isWeb="isWeb" />
					</div>
				</div>
				<div class="field" :class="isWeb ? 'ml' : ''">
					<div class="field__value">
						<div class="field__label bell row">
							<BellIcon />
							<SwitchIcon :data="data" @handleNotifications="handleNotifications" />
						</div>
					</div>
				</div>
				<div class="open-table" :class="!isWeb ? 't-3' : ''">
					<button class="btn-row" @click="handleTable">
						<ArrowDown :class="showTable ? 'rotate' : ''" :stroke="'2'" :color="'#234057'" />
					</button>
				</div>
			</div>
			<div class="table-container" v-if="showTable">
				<Table :contractId="contractId" :isWeb="isWeb" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CONTRACT_TABLE_MANAGEMENT_REQUEST,
	SAVE_LAST_ROW_OPENED,
	CONTRACT_NOTIFICATION_REQUEST,
} from '@/store/modules/contractManagement/actions';
import StatusChart from '../Charts/StatusChart/StatusChart.vue';
import Table from '../Table/Table.vue';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import BellIcon from '@/components/shared/Icons/BellIcon.vue';
import SwitchIcon from '@/components/shared/SwitchIcon/SwitchIcon.vue';
import moment from 'moment';

export default {
	name: 'ContractRow',
	components: {
		StatusChart,
		Table,
		ArrowDown,
		BellIcon,
		SwitchIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
		data: {
			required: true,
		},
		page: {
			required: true,
		},
		contractId: {
			required: true,
		},
	},
	data: () => ({
		showTable: false,
	}),
	methods: {
		...mapActions('contractManagement', {
			getTable: CONTRACT_TABLE_MANAGEMENT_REQUEST,
			saveLastRow: SAVE_LAST_ROW_OPENED,
			handleNotificationRequest: CONTRACT_NOTIFICATION_REQUEST,
		}),
		verifyDate(param, name) {
			let value = param;

			if (name == 'totalVolume') {
				return this.formatTons(param);
			}

			if (
				param.toString().includes('-') &&
				new Date(param) !== 'Invalid Date' &&
				!isNaN(new Date(param))
			) {
				value = moment(param).format('DD/MM/YYYY');
			}
			return value;
		},
		formatTons(param) {
			if (!param) {
				return '0t';
			}
			const tons = parseInt(param) / 1000;

			return `${tons.toFixed(3)}t`;
		},
		handleTable() {
			const lastRow = {
				id: this.data.id,
				page: this.page,
			};

			this.showTable = !this.showTable;
			this.saveLastRow(lastRow);
			this.$emit('closeAllTables', this.data.id);
			this.getTable({ vm: this, id: this.data.id });
		},
		openTable() {
			this.showTable = true;
		},
		closeTable() {
			this.showTable = false;
		},
		handleNotifications() {
			const body = {
				contractId: this.data.id,
			};

			this.handleNotificationRequest({ vm: this, body: body });
		},
	},
	computed: {
		...mapGetters('contractManagement', ['userPreferences']),
	},
};
</script>

<style scoped lang="scss">
@import 'ContractRow.scss';
</style>
