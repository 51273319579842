<template>
	<GenericMultiselect
		class="load-contract-multiselect"
		ref="contract"
		:options="contractOptions"
		:placeholder="$t('modules.load_management.filter.contract')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="false"
		:infiniteScroll="true"
		:type="'contractNumberSequence'"
		:multiple="true"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CONTRACTS_FILTER_REQUEST,
	SAVE_CONTRACTS_FILTER,
} from '@/store/modules/filtersByModules/loadManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'ContractInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('loadManagementFilters', {
			getContractFilter: CONTRACTS_FILTER_REQUEST,
			saveContractFilter: SAVE_CONTRACTS_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveContractFilter(param);

			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getContractFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		nextPage() {
			const meta = this.contractsFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getContractFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		createQueryString(contractNumber) {
			const query = new URLSearchParams();

			if (contractNumber) query.set('contractNumberSequence', contractNumber);
			if (contractNumber) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.contract.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.contractsFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.contract.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('loadManagementFilters', [
			'contractsFilter',
			'contractsFilterMeta',
			'contractsFilterCache',
			'isLoadingContractsFilter',
			'lastQueryString',
		]),
		contractOptions() {
			const rows = this.contractsFilter != undefined ? this.contractsFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'ContractInput.scss';
</style>
