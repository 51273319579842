<template>
	<GenericMultiselect
		class="market-multiselect"
		ref="market"
		:options="marketOptions"
		:placeholder="$t('modules.financial_management.filter.market')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="false"
		:infiniteScroll="true"
		:type="'market'"
		:multiple="false"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	MARKET_FILTER_REQUEST,
	SAVE_MARKET_FILTER,
} from '@/store/modules/filtersByModules/financialManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'MarketInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('financialManagementFilters', {
			getMarketFilter: MARKET_FILTER_REQUEST,
			saveMarketFilter: SAVE_MARKET_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveMarketFilter(param);

			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getMarketFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		nextPage() {
			const meta = this.marketFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getMarketFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		createQueryString(marketName) {
			const query = new URLSearchParams();

			if (marketName) query.set('market', marketName);
			if (marketName) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.market.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.marketFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.market.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('financialManagementFilters', [
			'marketFilter',
			'marketFilterMeta',
			'marketFilterCache',
			'isLoadingMarketFilter',
			'lastQueryString',
		]),
		marketOptions() {
			const rows = this.marketFilter != undefined ? this.marketFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'MarketInput.scss';
</style>
