<template>
	<div class="status">
		<div class="status-container">
			<div class="status-container__icon" :style="`background: ${getColor(status)}`"></div>
			<span class="status-container__title">{{ getStatusTranslation(status) }}</span>
		</div>
		<button class="btn-status" @click="goBack()" v-if="isWeb">{{ $t('buttons.back') }}</button>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'ContractStatus',
	components: {},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		//keep this to get references about status and step;
		// getColor(param) {
		// 	return {
		// 		contract: '#234057',
		// 		load: '#b47d4a',
		// 		process: '#803423',
		// 		container: '#678aa3',
		// 		invoice: '#aac3db',
		// 		finished: '#00b564',
		// 	}[param];
		// },
		getColor(status) {
			return {
				CRIAÇÃO: '#234057',
				'LIBERADO COM RESTRIÇÃO SEARA': '#234057',
				'LIBERADO COM RESTRIÇÃO CLIENTE': '#234057',
				LIBERADO: '#234057',
				PROGRAMADA: '#b47d4a',
				PLANEJADA: '#b47d4a',
				FATURADA: '#b47d4a',
				'CSI EMITIDO': '#b47d4a',
				'GATE IN': '#b47d4a',
				'PRÉ PAGAMENTO PENDENTE': '#b47d4a',
				DESEMBARAÇADA: '#b47d4a',
				'DRAFT BL E VGM ENVIADO': '#803423',
				EMBARCADO: '#803423',
				'DOCUMENTAÇÃO EMITIDA': '#803423',
				'DOCUMENTAÇÃO ENVIADA': '#803423',
				'DOCUMENTAÇÃO ENTREGUE': '#803423',
				'EMBARCADO ORIGEM': '#678aa3',
				'NO PORTO TRANSBORDO': '#678aa3',
				'DESEMBARCADO NO DESTINO': '#678aa3',
				'GATE OUT DESTINO': '#678aa3',
				'DEVOLUÇÃO CTN VAZIO': '#678aa3',
				ABERTA: '#aac3db',
				'ABERTA EM ATRASO': '#aac3db',
				'PAGO PARCIAL': '#aac3db',
				'PAGO TOTAL': '#aac3db',
				FINALIZADO: '#00b564',
			}[status];
		},
	}),
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getStatusTranslation(status) {
			return {
				'EM PROCESSAMENTO': this.$t('contract_step.processing'),
				'RESTRIÇÕES CLIENTE': this.$t('contract_step.released_customer_restriction'),
				'EM PRODUÇÃO': this.$t('contract_step.in_production'),
				'EM CARREGAMENTO': this.$t('contract_step.in_loading'),
				'GATE IN ORIGEM': this.$t('contract_step.gate_in'),
				'AGUARDA PRÉ PGTO': this.$t('contract_step.pre_payment_pending'),
				'EMBARCADO': this.$t('contract_step.boarded'),
				'DOCUMENTAÇÃO ENVIADA': this.$t('contract_step.documentation_sended'),
				'DOCUMENTAÇÃO ENTREGUE': this.$t('contract_step.documentation_delivered'),
				'ENTREGUE NO POD': this.$t('contract_step.delivered_in_pod'),
				'GATE OUT DESTINO': this.$t('contract_step.gate_out'),
				'VAZIO DEVOLVIDO': this.$t('contract_step.empty_return'),
				'ABERTA': this.$t('contract_step.opened'),
				'ABERTA EM ATRASO': this.$t('contract_step.opened_delay'),
				'PAGO PARCIAL': this.$t('contract_step.partial_paid'),
				'PAGO TOTAL': this.$t('contract_step.total_paid'),
				'FINALIZADO': this.$t('contract_step.finished'),
			}[status];
		},
	},
	computed: {
		...mapGetters('contractDetail', ['status']),
	},
};
</script>

<style scoped lang="scss">
@import 'ContractStatus.scss';
</style>
