<template>
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.5981 0.796401C14.134 1.33223 14.134 2.20063 13.5981 2.73648L10.1065 6.22814C9.57064 6.76397 9.57064 7.63237 10.1065 8.16783L13.5981 11.6599C14.134 12.1953 14.134 13.0641 13.5981 13.5996C13.0623 14.1354 12.1939 14.1354 11.6581 13.5996L8.16639 10.1079C7.63056 9.57207 6.76216 9.57207 6.2267 10.1079L2.73465 13.5996C2.19921 14.1354 1.33042 14.1354 0.794965 13.5996C0.259139 13.0641 0.259139 12.1953 0.794965 11.6599L4.28663 8.16783C4.82245 7.63239 4.82245 6.76399 4.28663 6.22814L0.794965 2.73648C0.259139 2.20065 0.259139 1.33225 0.794965 0.796401C1.3304 0.260575 2.19919 0.260575 2.73465 0.796401L6.2267 4.28807C6.76214 4.82389 7.63054 4.82389 8.16639 4.28807L11.6581 0.796401C12.1939 0.260575 13.0623 0.260575 13.5981 0.796401Z"
			:fill="color"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#FFFFFF',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
