<template>
	<ModalStructure
		id="warning-modal"
		:width="width"
		:height="height"
		:class="{ center: !hasCancelButton }"
	>
		<template v-slot:body>
			<div class="body">
				<WarningIcon class="body-icon" :color="'#234057'" />
				<span class="body-text">
					{{ title }}
				</span>
			</div>
		</template>
		<template v-slot:footer>
			<!-- <div class="footer"> -->
			<button v-if="hasCancelButton" class="btn btn-cancel" @click="closeWarning()">
				{{ $t('buttons.cancel') }}
			</button>
			<button class="btn btn-confirm" @click="confirmWarning()">
				{{ $t(`buttons.${btnLabel}`) }}
			</button>
			<!-- </div> -->
		</template>
	</ModalStructure>
</template>

<script>
import ModalStructure from '@/components/shared/Modals/ModalStructure/ModalStructure.vue';
import WarningIcon from '@/components/shared/Icons/WarningIcon.vue';

export default {
	name: 'WarningModal',
	components: { ModalStructure, WarningIcon },
	props: {
		title: {
			required: true,
		},
		btnLabel: {
			default: 'delete',
		},
		isWeb: {
			required: true,
		},
		hasCancelButton: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		confirmWarning() {
			this.$emit('confirmWarning');
		},
		closeWarning() {
			this.$emit('closeWarning');
		},
	},
	computed: {
		height() {
			return this.isWeb ? '290px' : '100%';
		},
		width() {
			return this.isWeb ? '440px' : '100%';
		},
	},
};
</script>
<style scoped lang="scss">
@import 'WarningModal.scss';
</style>
