<template>
	<GenericMultiselect
		class="invoice-multiselect"
		ref="invoice"
		:options="invoiceOptions"
		:placeholder="$t('modules.doc_management.filter.invoice')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="true"
		:infiniteScroll="true"
		:type="'invoice'"
		:multiple="true"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	INVOICE_FILTER_REQUEST,
	SAVE_INVOICE_FILTER,
} from '@/store/modules/filtersByModules/financialManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'InvoiceInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {
		this.getInvoiceFilter({
			vm: this,
			params: this.createQueryString(),
			skip: false,
			lastQuery: this.lastQueryString,
		});
	},
	methods: {
		...mapActions('financialManagementFilters', {
			getInvoiceFilter: INVOICE_FILTER_REQUEST,
			saveInvoiceFilter: SAVE_INVOICE_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveInvoiceFilter(param);

			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getInvoiceFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		nextPage() {
			const meta = this.invoiceFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getInvoiceFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		createQueryString(invoiceType) {
			const query = new URLSearchParams();

			if (invoiceType) query.set('invoice', invoiceType);
			if (invoiceType) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.invoice.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.invoiceFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.invoice.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('financialManagementFilters', [
			'invoiceFilter',
			'invoiceFilterMeta',
			'invoiceFilterCache',
			'isLoadingInvoiceFilter',
			'lastQueryString',
		]),
		invoiceOptions() {
			const invoiceItems = this.invoiceFilter.map((invoice) => {
				return { id: invoice, name: invoice };
			});
			return !invoiceItems.length ? [] : invoiceItems;
		},
	},
};
</script>

<style lang="scss">
@import 'InvoiceInput.scss';
</style>
