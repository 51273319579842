<template>
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.4167 14H0.583333C0.261333 14 0 13.7387 0 13.4167V0.583333C0 0.261333 0.261333 0 0.583333 0H5.25C5.572 0 5.83333 0.261333 5.83333 0.583333C5.83333 0.905333 5.572 1.16667 5.25 1.16667H1.16667V12.8333H12.8333V8.75C12.8333 8.428 13.0947 8.16667 13.4167 8.16667C13.7387 8.16667 14 8.428 14 8.75V13.4167C14 13.7387 13.7387 14 13.4167 14Z"
			fill="#234057"
		/>
		<path
			d="M11.0833 8.16634H6.41659C6.09459 8.16634 5.83325 7.90501 5.83325 7.58301V2.91634C5.83325 2.59434 6.09459 2.33301 6.41659 2.33301C6.73859 2.33301 6.99992 2.59434 6.99992 2.91634V6.99967H11.0833C11.4053 6.99967 11.6666 7.26101 11.6666 7.58301C11.6666 7.90501 11.4053 8.16634 11.0833 8.16634Z"
			fill="#234057"
		/>
		<path
			d="M6.41663 8.16677C6.2673 8.16677 6.11797 8.1096 6.00363 7.99643C5.77613 7.76893 5.77613 7.3991 6.00363 7.1716L13.0036 0.171602C13.2311 -0.0558984 13.601 -0.0558984 13.8285 0.171602C14.056 0.399102 14.056 0.768935 13.8285 0.996435L6.82847 7.99643C6.7153 8.1096 6.56597 8.16677 6.41663 8.16677Z"
			fill="#234057"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#234057',
		},
	},
};
</script>
<style lang="scss" scoped></style>
