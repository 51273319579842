import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/modules/Login/Login';
import RecoverPassword from '../views/modules/RecoverPassword/RecoverPassword';
import Structure from '../views/Structure/Structure';
import UserManagement from '../views/modules/UserManagement/UserManagement';
import ContractManagement from '../views/modules/ContractManagement/ContractManagement';
import ContractDetail from '../views/modules/ContractDetail/ContractDetail';
import Dashboard from '../views/modules/Dashboard/Dashboard';
import LoadManagement from '../views/modules/LoadManagement/LoadManagement';
import DocManagement from '../views/modules/DocManagement/DocManagement';
import FinancialManagement from '../views/modules/FinancialManagement/FinancialManagement';
import DocInstruction from '../views/modules/DocInstruction/DocInstruction';
import NewTemplate from '../views/modules/NewTemplate/NewTemplate';

import auth from './middleware/auth';

Vue.use(VueRouter);

export const routes = [
	{
		path: '/main',
		name: 'Structure',
		component: Structure,

		children: [
			{
				path: '/main/dashboard',
				name: 'header.dashboard',
				menuLabel: 'sidebar.dashboard',
				component: Dashboard,
				showOnMenu: true,
				meta: {
					middleware: [auth],
				},
			},
			{
				path: '/main/userManagement',
				name: 'header.user_management',
				menuLabel: 'sidebar.user_management',
				component: UserManagement,
				showOnMenu: true,
				meta: {
					middleware: [auth],
				},
			},
			{
				path: '/main/contractManagement',
				name: 'header.contract_management',
				menuLabel: 'sidebar.contract_management',
				component: ContractManagement,
				showOnMenu: true,
				meta: {
					middleware: [auth],
				},
			},
			{
				path: '/main/contractDetail',
				name: 'header.contract_details',
				menuLabel: 'sidebar.contract_details',
				component: ContractDetail,
				showOnMenu: false,
				meta: {
					middleware: [auth],
				},
			},
			{
				path: '/main/financialManagement',
				name: 'header.financial_management',
				menuLabel: 'sidebar.financial_management',
				component: FinancialManagement,
				showOnMenu: true,
				meta: {
					middleware: [auth],
				},
			},
			{
				path: '/main/loadManagement',
				name: 'header.load_management',
				menuLabel: 'sidebar.load_management',
				component: LoadManagement,
				showOnMenu: true,
				meta: {
					middleware: [auth],
				},
			},
			{
				path: '/main/docManagement',
				name: 'header.doc_management',
				menuLabel: 'sidebar.doc_management',
				component: DocManagement,
				showOnMenu: true,
				meta: {
					middleware: [auth],
				},
			},
			{
				path: '/main/docInstruction',
				name: 'header.doc_instruction',
				menuLabel: 'sidebar.doc_instruction',
				component: DocInstruction,
				showOnMenu: true,
				meta: {
					middleware: [auth],
				},
			},
			{
				path: '/main/docInstruction/newTemplate',
				name: 'header.new_template',
				component: NewTemplate,
				showOnMenu: false,
				meta: {
					middleware: [auth],
				},
			},
			{
				path: '/main/docInstruction/newTemplate/:id',
				name: 'header.edit_template',
				component: NewTemplate,
				showOnMenu: false,
				meta: {
					middleware: [auth],
				},
			},
		],
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/recoverPassword',
		name: 'RecoverPassword',
		component: RecoverPassword,
	},
	{
		path: '*',
		redirect: '/login',
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (!to.meta.middleware) {
		return next();
	}
	const middleware = to.meta.middleware;

	const context = {
		to,
		from,
		next,
	};
	return middleware[0]({
		...context,
	});
});

export default router;
