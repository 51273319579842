<template>
	<div v-if="status" class="doc-row" :class="showContent ? 'open' : ''">
		<div class="doc-row__header" v-if="isWeb">
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_management.doc_row.invoice') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ document.invoiceNumber }}</span>
			</div>
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_management.doc_row.vehicle') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ document.vessel }} </span>
			</div>
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_management.doc_row.pol') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ document.origin }} </span>
			</div>
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_management.doc_row.atsPol') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ formateDate(document.expectedEtbDate) }} </span>
			</div>
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_management.doc_row.pod') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ document.destination }} </span>
			</div>
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_management.doc_row.etaPod') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ formateDate(document.realAtbDate) }} </span>
			</div>
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_management.doc_row.plTon') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ kiloToTonne(document.totalVolume) }} </span>
			</div>
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_management.doc_row.value') }}
				</span>
				<span class="doc-row__column--subtitle"> USD {{ formatMoney(document.totalValue) }} </span>
			</div>
			<div class="doc-row__status">
				<div
					class="doc-row__status-card"
					:class="selectDownloadStatus(document.downloadStatus)"
					:title="$t('modules.doc_management.doc_row.download')"
				>
					<CloudIcon :color="selectDownloadStatus(document.downloadStatus)" />
				</div>
				<div
					v-if="hasRedirectAction"
					class="doc-row__status-card clickable"
					:class="selectStatus(document.paymentStatus)"
					@click="handleRedirect(document.contractId)"
				>
					<CashIcon :color="selectStatus(document.paymentStatus)" />
				</div>
				<div
					v-else
					class="doc-row__status-card"
					:class="selectStatus(document.paymentStatus)"
					:title="$t('modules.doc_management.doc_row.payment_status')"
				>
					<CashIcon :color="selectStatus(document.paymentStatus)" />
				</div>
				<div
					class="doc-row__status-card"
					:class="selectTranckingStatus(document.approvalStatus)"
					:title="$t('modules.doc_management.doc_row.approval_status')"
				>
					<PathIcon :color="selectTranckingStatus(document.approvalStatus)" />
				</div>
				<div
					class="doc-row__status-card"
					:class="selectStatus(document.shipmentStatus)"
					:title="$t('modules.doc_management.doc_row.shipment_status')"
				>
					<PlaneIcon :color="selectStatus(document.shipmentStatus)" />
				</div>
			</div>
			<div class="doc-row__icon" @click="handleContent()">
				<ArrowDown :class="showContent ? 'rotate' : ''" :color="'#234057'" />
			</div>
		</div>

		<div class="doc-row__header" v-if="!isWeb">
			<div class="doc-row__column">
				<span class="doc-row__column--title">
					{{ $t('modules.doc_management.doc_row.invoice') }}
				</span>
				<span class="doc-row__column--subtitle"> {{ document.invoiceNumber }}</span>
			</div>
			<div class="doc-row__header--wrapper">
				<span class="doc-row__column--subtitle"> {{ document.vessel }}</span>
				<div
					class="doc-row__status-card"
					:class="selectDownloadStatus(document.downloadStatus)"
					:title="$t('modules.doc_management.doc_row.download')"
				>
					<CloudIcon :color="selectDownloadStatus(document.downloadStatus)" />
				</div>
				<div class="doc-row__icon" @click="handleContent()">
					<ArrowDown :class="showContent ? 'rotate' : ''" :color="'#234057'" />
				</div>
			</div>
		</div>

		<div class="doc-row__body m-scroll">
			<slot name="body"></slot>
		</div>
		<div class="doc-row__footer m-scroll">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import CloudIcon from '@/components/shared/Icons/DocManagementIcons/CloudIcon.vue';
import CashIcon from '@/components/shared/Icons/DocManagementIcons/CashIcon.vue';
import PathIcon from '@/components/shared/Icons/DocManagementIcons/PathIcon.vue';
import PlaneIcon from '@/components/shared/Icons/DocManagementIcons/PlaneIcon.vue';

import DollarIcon from '@/components/shared/Icons/DollarIcon.vue';

import {
	CONTRACT_DETAIL_REQUEST,
	CONTAINER_LIST_REQUEST,
	COURIER_LIST_REQUEST,
} from '@/store/modules/docManagement/actions';

export default {
	name: 'DocManagementRow',
	props: {
		isWeb: {
			required: true,
		},
		document: {
			type: Object,
			default: () => ({}),
		},
		status: {
			required: true,
			type: Boolean,
		},
	},
	components: {
		ArrowDown,
		DollarIcon,
		CloudIcon,
		CashIcon,
		PathIcon,
		PlaneIcon,
	},
	data: () => ({
		labels: [
			'Carga',
			'Container',
			'Invoice',
			'Veículo',
			'POD',
			'Gate In',
			'ETS / ATS POL',
			'ETA POD',
			'Gate Out',
			'Peso Líquido',
		],
		showContent: false,
	}),
	created() {},
	mounted() {},
	beforeDestroy() {},
	methods: {
		...mapActions('docManagement', {
			getContracts: CONTRACT_DETAIL_REQUEST,
			getContainerList: CONTAINER_LIST_REQUEST,
			getCourierList: COURIER_LIST_REQUEST,
		}),
		selectStatus(val) {
			switch (val) {
				case '0':
					return 'green';

				case '1':
					return 'orange';

				case '2':
					return 'red';

				default:
					return 'gray';
			}
		},
		selectTranckingStatus(val) {
			switch (val) {
				case '0':
					return 'gray';

				case '1':
					return 'gray';

				case '2':
					return 'orange';

				case '3':
					return 'orange';

				case '4':
					return 'green';

				default:
					return 'gray';
			}
		},
		selectDownloadStatus(val) {
			//STATUS
			// 0 = Todos os docs sem arquivo
			// 1 = Se houver docs mas sem registros de download
			// 2 = Envio disponível mas com downloads parciais
			// 3 = Envio disponível mas sem nenhum download
			// 4 = Todos os arquivos baixados

			switch (val) {
				case '0':
					return 'gray';

				case '1':
					return 'red';

				case '2':
					return 'orange';

				case '3':
					return 'orange';

				case '4':
					return 'green';

				default:
					return 'gray';
			}
		},
		handleContent() {
			this.showContent = !this.showContent;
			this.$emit('closeAllTables', this.document.invoiceNumber);
			if (this.showContent === true) {
				const companyId = this.headerCompanyFilterCache.value.id;

				this.getContracts({
					vm: this,
					invoiceNumber: this.document.invoiceNumber,
					companyId: companyId,
				});

				this.getContainerList({
					vm: this,
					invoiceNumber: this.document.invoiceNumber,
					companyId: companyId,
				});

				this.getCourierList({
					vm: this,
					invoiceNumber: this.document.invoiceNumber,
					companyId: companyId,
				});
			}
		},
		closeTable() {
			this.showContent = false;
		},
		handleRedirect(contractId) {
			this.$router.push({
				path: '/main/financialManagement',
				query: { contractId: contractId },
			});
		},
		kiloToTonne(value) {
			let newValue = value / 1000.0;
			const normalizedValue = newValue.toFixed(3);
			return normalizedValue.toString().replace('.', ',');
		},
		formateDate(value) {
			return value && value !== null ? moment(value).format('DD/MM/YYYY') : '';
		},
		formatMoney(value) {
			return value
				.toLocaleString('pt-br', {
					style: 'currency',
					currency: 'BRL',
				})
				.replace('R$', '');
		},
	},
	computed: {
		hasRedirectAction() {
			return this.document.paymentStatus === '2';
		},
		...mapGetters('filters', ['companyFilter', 'headerCompanyFilterCache']),
	},
};
</script>

<style lang="scss">
@import 'DocManagementRow.scss';
</style>
