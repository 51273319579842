<template>
	<div class="templateHeaderItem-container">
		<div class="templateHeaderItem-container__title" :class="!isCollapsed ? 'open' : ''">
			<h4>
				{{ title }}
			</h4>
			<BellTemplateIcon v-if="showBell" :color="colorBell" />
			<button
				v-if="!isWeb"
				class="templateHeaderItem-container__colapse"
				@click="handleCollapse"
				:class="!isCollapsed ? 'open' : ''"
			>
				<ArrowDown :stroke="'2'" :color="'#234057'" />
			</button>
		</div>
	</div>
</template>

<script>
import BellTemplateIcon from '@/components/shared/Icons/BellTemplateIcon.vue';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
export default {
	name: 'TemplateHeaderItem',
	components: {
		BellTemplateIcon,
		ArrowDown,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		showBell: {
			type: Boolean,
			default: () => {
				false;
			},
		},
		activedBell: {
			type: Boolean,
			default: () => {
				false;
			},
		},
	},
	data: () => ({
		isCollapsed: false,
		windowWidth: window.innerWidth,
	}),
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	computed: {
		colorBell() {
			return this.activedBell ? '#234057' : '#AAC3DB';
		},
		isWeb() {
			return this.windowWidth > 992;
		},
	},
	methods: {
		handleCollapse() {
			this.isCollapsed = !this.isCollapsed;
			this.$emit('handleCollapse');
		},
		onResize() {
			this.windowWidth = window.innerWidth;
		},
	},
};
</script>

<style lang="scss">
@import 'TemplateHeaderItem.scss';
</style>
