<template>
	<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
		<line x1="0.5" y1="0.5" x2="9.5" y2="0.5" :stroke="color" stroke-linecap="round" />
		<line x1="0.5" y1="3.5" x2="9.5" y2="3.5" :stroke="color" stroke-linecap="round" />
		<line x1="0.5" y1="6.5" x2="9.5" y2="6.5" :stroke="color" stroke-linecap="round" />
	</svg>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: '#678AA3',
		},
	},
};
</script>
