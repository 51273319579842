<template>
	<div class="initial-doc-datepicker">
		<MobileDatePicker
			v-if="!isWeb"
			:buttonLabel="$t('modules.doc_management.filter.init_date')"
			:title="'fromDate'"
			ref="fromDate"
			@changeInput="changeInput"
		/>
		<GenericDatepicker
			v-else
			:buttonLabel="$t('modules.doc_management.filter.init_date')"
			:title="'fromDate'"
			:largeLabel="false"
			ref="fromDate"
			@changeInput="changeInput"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SAVE_INIT_DATE_FILTER } from '@/store/modules/filtersByModules/docManagementFilters/actions';
import MobileDatePicker from '@/components/shared/MobileDatepicker/MobileDatepicker.vue';
import GenericDatepicker from '@/components/shared/GenericDatepicker/GenericDatepicker.vue';

export default {
	name: 'InitialDateInput',
	components: {
		MobileDatePicker,
		GenericDatepicker,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({}),
	mounted() {
		const dateCached = this.initDateFilterCache.value;

		if (dateCached && dateCached.id != undefined) {
			this.$refs.fromDate.setValue(`${dateCached.id}T00:00:00`);
		}
	},
	methods: {
		...mapActions('docManagementFilters', { saveInitDate: SAVE_INIT_DATE_FILTER }),
		changeInput(param) {
			this.saveInitDate(param);
			this.$emit('filterRequest', param);
		},
		clearInput() {
			this.$refs.fromDate.clearValue();
		},
	},
	computed: {
		...mapGetters('docManagementFilters', ['initDateFilterCache']),
	},
};
</script>

<style lang="scss">
@import 'InitialDateInput.scss';
</style>
