<template>
	<div class="doc-detail">
		<div v-if="currentContract" class="doc-detail__header">
			<div class="doc-detail__header--left">
				<span class="doc-detail__header--title">
					{{ $t('modules.doc_management.doc_detail.contract') }}
				</span>
				<span class="doc-detail__header--text"> {{ currentContract.contractSequence }} </span>
			</div>
			<div class="doc-detail__header--right">
				<div class="">
					<span class="doc-detail__header--title">
						{{ $t('modules.doc_management.doc_detail.condition') }}
					</span>
					<span class="doc-detail__header--text">{{ currentContract.paymentMethod }}</span>
				</div>
				<!-- <div class="">
					<span class="doc-detail__header--title">
						{{ $t('modules.doc_management.doc_detail.term') }}
					</span>
					<span class="doc-detail__header--text">{{ currentContract.paymentDeadline }}</span>
				</div> -->
			</div>
		</div>
		<div v-if="currentContract && currentContract.documents.length > 0" class="doc-detail__body">
			<div class="doc-detail__body-row">
				<div class="doc-detail__column">
					<div class="doc-detail__column--header">
						<span>
							{{ $t('modules.doc_management.doc_detail.type') }}
						</span>
						<span>
							{{ $t('modules.doc_management.doc_detail.publication') }}
						</span>
					</div>
				</div>
				<div class="doc-detail__column">
					<div class="doc-detail__column--header">
						<span>
							{{ $t('modules.doc_management.doc_detail.responsible') }}
						</span>
						<span>
							{{ $t('modules.doc_management.doc_detail.approval') }}
						</span>
					</div>
				</div>
				<div class="doc-detail__column">
					<div class="doc-detail__column--header">
						<span>
							{{ $t('modules.doc_management.doc_detail.approver') }}
						</span>
						<span>
							{{ $t('modules.doc_management.doc_detail.observation') }}
						</span>
					</div>
				</div>
				<div class="doc-detail__column">
					<div class="doc-detail__column--header">
						<span>
							{{ $t('modules.doc_management.doc_detail.download') }}
						</span>
						<span>
							{{ $t('modules.doc_management.doc_detail.status') }}
						</span>
					</div>
				</div>
			</div>
			<div
				v-for="document in currentContract.documents"
				:key="document.idActionPoa"
				class="doc-detail__body-row"
			>
				<div class="doc-detail__column">
					<div class="doc-detail__column--text">
						<span>{{ document.type }}</span>
						<span>{{ formateDate(document.sendDateSrp) }}</span>
					</div>
				</div>
				<div class="doc-detail__column">
					<div class="doc-detail__column--text">
						<span>{{ document.emailUserSrp }}</span>
						<span>{{ formateDate(document.dtApprovalPoa) }}</span>
					</div>
				</div>
				<div class="doc-detail__column">
					<div class="doc-detail__column--text" :class="{ fullText: showFullText }">
						<span class="ellipsis">{{ document.nmUserActionPoa }}</span>
						<div class="doc-detail__column--variant">
							<span :class="{ comment: !showFullText }">
								{{ document.obsSrp }}

								<span
									class="more"
									v-if="document.obsSrp && document.obsSrp.length >= 8 && showFullText"
									@click="showFullText = !showFullText"
								>
									{{ $t('buttons.seeLess') }}
								</span>
							</span>
							<span
								class="more"
								v-if="document.obsSrp && !showFullText"
								@click="showFullText = !showFullText"
							>
								{{ $t('buttons.seeMore') }}
							</span>
						</div>
					</div>
				</div>
				<div class="doc-detail__column">
					<div class="doc-detail__column--download">
						<span @click="handleDownload(document.code, document.type)">
							<CloudIcon />
						</span>

						<div v-if="document.idActionPoa === 3">
							<button class="doc-detail__column--btn" @click="handleApprovement(true, document)">
								{{ $t('modules.contract_detail.contract.approve') }}
							</button>
							<button class="doc-detail__column--btn" @click="handleApprovement(false, document)">
								{{ $t('modules.contract_detail.contract.disapprove') }}
							</button>
						</div>
						<span v-else :class="getFormattedStatus(document.idActionPoa, false)">
							{{ getFormattedStatus(document.idActionPoa, true) }}
						</span>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="doc-detail__alert">
			<DefaultLoader :dataStatus="isLoadingContractDetail" color="blue" />
			<div v-if="isLoadingContractDetail === 'success'" class="">
				{{ $t('errors.documents_error') }}
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
	CONTRACT_DETAIL_REQUEST,
	DOC_DOWNLOAD_REQUEST,
	DOCUMENT_APPROVAL_REQUEST,
} from '@/store/modules/docManagement/actions';

import CloudIcon from '@/components/shared/Icons/DocManagementIcons/CloudIcon.vue';
import DefaultLoader from '@/components/shared/DefaultLoader/DefaultLoader.vue';
import moment from 'moment';

export default {
	name: 'DocManagementDetail',
	components: {
		CloudIcon,
		DefaultLoader,
	},
	props: {
		isWeb: {
			required: true,
		},
		invoiceNumber: {
			required: true,
		},
	},
	data: () => ({
		currentContract: null,
		showFullText: false,
	}),
	mounted() {},
	methods: {
		...mapActions('docManagement', {
			getContracts: CONTRACT_DETAIL_REQUEST,
			downloadDoc: DOC_DOWNLOAD_REQUEST,
			documentApproval: DOCUMENT_APPROVAL_REQUEST,
		}),
		// API METHODS
		getCurrentContract() {
			const filteredList = this.contractDetail.filter(
				(item) => item.invoiceNumber == this.invoiceNumber,
			);
			if (filteredList.length > 0) {
				this.currentContract = filteredList[0].data;
			}
		},
		getFormattedStatus(status, isLabel) {
			const selectedStatus = {
				0: { label: this.$t('modules.doc_management.doc_detail.not_sended'), class: 'gray' },
				1: { label: this.$t('modules.doc_management.doc_detail.pending'), class: 'orange' },
				2: { label: this.$t('modules.doc_management.doc_detail.viewed'), class: 'gray' },
				4: { label: this.$t('modules.doc_management.doc_detail.approved'), class: 'green' },
				5: { label: this.$t('modules.doc_management.doc_detail.repproved'), class: 'red' },
				6: { label: this.$t('modules.doc_management.doc_detail.cancelled'), class: 'red' },
				7: { label: this.$t('modules.doc_management.doc_detail.cancelled'), class: 'red' },
			}[parseInt(status)];

			return isLabel ? selectedStatus.label : selectedStatus.class;
		},
		handleDownload(code, name) {
			this.downloadDoc({ vm: this, code: code, name: name }).then(() => {
				this.getContracts({
					vm: this,
					invoiceNumber: this.invoiceNumber,
				});
			});
		},
		handleApprovement(approved, document) {
			const code = document.code;

			if (approved) {
				const body = {
					type: 'approved',
					note: '',
				};

				this.documentApproval({ vm: this, body: body, code: code }).then(() => {
					this.getContracts({
						vm: this,
						invoiceNumber: this.invoiceNumber,
					});

					this.$toastr.s(this.$t('modules.doc_management.reproval_modal.approved'));
				});

				return;
			}

			this.$emit('documentToReprove', { document: document, invoiceNumber: this.invoiceNumber });
		},

		// HELPERS
		formateDate(value) {
			return value && value !== null ? moment(value).format('DD/MM/YYYY') : '';
		},
	},
	computed: {
		...mapGetters('docManagement', ['contractDetail', 'isLoadingContractDetail']),
	},
	watch: {
		isLoadingContractDetail(value) {
			if (value === 'success') {
				this.getCurrentContract();
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'DocManagementDetail.scss';
</style>
