<template>
	<div id="financialTable">
		<div class="table-container" v-if="tableRows.length != 0 && isLoadingInvoices === 'success'">
			<table class="table">
				<thead class="table__header">
					<tr>
						<th v-for="(header, index) in tableHeader" :key="index">
							<div class="row space-between">
								{{ header.label }}
								<div class="header-icon" @click="sortTable(header.filter)">
									<FilterTable v-if="header.field != 'edit' && header.field != 'delete'" />
								</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(row, index) in tableRows"
						:key="row.id"
						:class="[index % 2 == 0 ? 'stripped' : '', row.isInvalid ? 'invalid' : '']"
					>
						<td :title="formatDate(row.dueDate)">
							{{ formatDate(row.dueDate) }}
						</td>
						<td :title="row.invoiceCode">
							{{ row.invoiceCode }}
						</td>
						<td :title="row.purchaseOrder">
							{{ row.purchaseOrder }}
						</td>
						<td :class="getPaymentClass(row.status)" :title="row.status">
							{{ getPaymentStatus(row.status) }}
						</td>
						<td :title="row.currency">
							{{ row.currency }}
						</td>
						<td :title="formatMoney(row.invoiceValue, row.currency)">
							{{ formatMoney(row.invoiceValue, row.currency) }}
						</td>
						<td :title="formatMoney(row.paidValue, row.currency)">
							{{ formatMoney(row.paidValue, row.currency) }}
						</td>
						<td :title="formatMoney(row.invoiceBalanceValue, row.currency)">
							{{ formatMoney(row.invoiceBalanceValue, row.currency) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="loader" v-if="isLoadingInvoices === 'loading'">
			<SpinnerIcon />
		</div>
		<div class="no__data" v-if="tableRows.length == 0 && isLoadingInvoices === 'empty'">
			{{ $t('errors.empty_data') }}
		</div>
		<Pagination
			ref="pagination"
			v-if="tableRows.length != 0 && isLoadingInvoices == 'success'"
			:totalPages="pagination.totalPages"
			:currentFromReq="pagination.currentPage"
			@tableRequest="tableRequest"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DELETE_USER_MANAGEMENT } from '@/store/modules/userManagement/actions';
import FilterTable from '@/components/shared/Icons/FilterTable.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import Pagination from '@/components/shared/Pagination/Pagination';
import moment from 'moment';

export default {
	name: 'TableInvoice',
	components: {
		FilterTable,
		SpinnerIcon,
		Pagination,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		openWarning: false,
		selectedUser: null,
		sort: {
			sortName: null,
			sortEmail: null,
			sortCompanies: null,
			sortActive: null,
		},
	}),
	mounted() {},
	methods: {
		...mapActions('userManagement', {
			deleteUser: DELETE_USER_MANAGEMENT,
		}),
		tableRequest(param) {
			this.$emit('changePage', param);
		},
		sortTable(filter) {
			if (this.sort[filter] == null) {
				this.sort = {
					sortDueDate: null,
					sortInvoice: null,
					sortPo: null,
					sortCurrency: null,
					sortStatus: null,
					sortTotalInvoice: null,
					sortPayment: null,
					sortBalance: null,
				};
			}

			this.sort[filter] == 'ASC' ? (this.sort[filter] = 'DESC') : (this.sort[filter] = 'ASC');
			const queryString = this.createQueryString();
			this.$emit('sortTable', queryString);
		},
		createQueryString() {
			const query = new URLSearchParams();
			let {
				sortDueDate,
				sortInvoice,
				sortPo,
				sortCurrency,
				sortStatus,
				sortTotalInvoice,
				sortPayment,
				sortBalance,
			} = this.sort;

			if (sortDueDate) query.set('sortDueDate', sortDueDate);
			if (sortInvoice) query.set('sortInvoice', sortInvoice);
			if (sortPo) query.set('sortPo', sortPo);
			if (sortCurrency) query.set('sortCurrency', sortCurrency);
			if (sortStatus) query.set('sortStatus', sortStatus);
			if (sortTotalInvoice) query.set('sortTotalInvoice', sortTotalInvoice);
			if (sortPayment) query.set('sortPayment', sortPayment);
			if (sortBalance) query.set('sortBalance', sortBalance);

			return query.toString();
		},
		formatMoney(param, currency = 'BRL') {
			return parseFloat(param).toLocaleString('pt-br', {
				style: 'currency',
				currency: currency,
			});
			// .replace('R$', '')
			// .trim()
		},
		formatDate(value) {
			return value && value !== null ? moment(value).format('DD/MM/YYYY') : '';
		},
		getPaymentStatus(status) {
			switch (status) {
				case 'PAGO':
					return this.$t(`modules.financial_management.status.paid`);
				case 'NÃO PAGO':
					return this.$t(`modules.financial_management.status.awaiting_paid`);
				case 'NAO PAGO':
					return this.$t(`modules.financial_management.status.awaiting_paid`);
				default:
					return this.$t(`modules.financial_management.status.overdue`);
			}
		},
		getPaymentClass(status) {
			switch (status) {
				case 'PAGO':
					return 'active';
				case 'NÃO PAGO':
					return 'waiting';
				case 'NAO PAGO':
					return 'waiting';
				default:
					return 'delayed';
			}
		},
	},
	computed: {
		...mapGetters('financialManagement', [
			'invoices',
			'invoicesMax',
			'invoicesPage',
			'isLoadingInvoices',
		]),
		tableHeader() {
			return [
				{
					field: 'vencimento',
					filter: 'sortDueDate',
					label: this.$t('modules.financial_management.table.due_date'),
				},
				{
					field: 'invoice',
					filter: 'sortInvoice',
					label: this.$t('modules.financial_management.table.invoice'),
				},
				{
					field: 'po',
					filter: 'sortPo',
					label: this.$t('modules.financial_management.table.po'),
				},
				{
					field: 'status',
					filter: 'sortStatus',
					label: this.$t('modules.financial_management.table.status'),
				},
				{
					field: 'moeda',
					filter: 'sortCurrency',
					label: this.$t('modules.financial_management.table.currency'),
				},
				{
					field: 'total',
					filter: 'sortTotalInvoice',
					label: this.$t('modules.financial_management.table.total_invoice'),
				},
				{
					field: 'pagamento',
					filter: 'sortPayment',
					label: this.$t('modules.financial_management.table.payment'),
				},
				{
					field: 'balance',
					filter: 'sortBalance',
					label: this.$t('modules.financial_management.table.balance'),
				},
			];
		},
		tableRows() {
			return this.invoices;
		},
		pagination() {
			const pagination =
				this.isLoadingInvoices != 'empty'
					? { currentPage: this.invoicesPage, totalPages: this.invoicesMax }
					: { currentPage: 1, totalPages: 1 };

			return pagination;
		},
	},
};
</script>

<style scoped lang="scss">
@import 'TableInvoice.scss';
</style>
