<template>
	<div class="mobile-datepicker-container" @focusout="closePicker">
		<div class="mobile-datepicker">
			<div class="mobile-datepicker__action">
				<button class="btn-action" @click="handleMobilePicker">
					{{ selectedDate || buttonLabel }}
					<ArrowDown :class="showMobilePicker ? 'rotate' : ''" :color="'#fff'" />
				</button>
			</div>
			<div class="mobile-datepicker__picker" :class="!showMobilePicker ? 'hide' : ''">
				<smooth-picker
					:ref="title"
					:id="title"
					:data="mobileData"
					style="width: 100vw; z-index: 9999"
				/>
				<button class="btn-action" @click="confirm">
					{{ $t('buttons.confirm') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import 'vue-smooth-picker/dist/css/style.css';
import { SmoothPicker } from 'vue-smooth-picker';
import { mapGetters } from 'vuex';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';

export default {
	name: 'MobileDatepicker',
	components: {
		SmoothPicker,
		ArrowDown,
	},
	props: {
		title: {
			required: true,
		},
		buttonLabel: {
			required: true,
		},
	},
	data: () => ({
		value: null,
		selectedDate: null,
		showMobilePicker: false,
		mobileData: [
			{
				flex: 1,
				list: [],
				textAlign: 'center',
				className: 'row-group',
			},
			{
				flex: 1,
				list: [1, 2, 3, 4],
				textAlign: 'center',
				className: 'row-group',
			},
			{
				flex: 1,
				list: [],
				textAlign: 'center',
				className: 'row-group',
			},
		],
	}),
	mounted() {
		this.mobileData[0].list = this.getDays;
		this.mobileData[1].list = this.getMonths;
		this.mobileData[2].list = this.getYears;
	},
	methods: {
		handleMobilePicker() {
			this.showMobilePicker = !this.showMobilePicker;
			this.$emit('closeOtherPicker');
		},
		closePicker() {
			this.showMobilePicker = false;
		},
		confirm() {
			const ciList = this.$refs[this.title].getCurrentIndexList();

			const day = this.mobileData[0].list[ciList[0]];
			const month = this.mobileData[0].list[ciList[1]];
			const year = this.mobileData[2].list[ciList[2]];

			this.value = `${year}-${month}-${day}`;

			//just to keep with same generic multiselect return
			const body = {
				type: this.title,
				value: {
					id: this.value,
				},
			};

			const formattedDate = {
				'pt-br': `${day}/${month}/${year}`,
				'es-es': `${day}/${month}/${year}`,
				'en-us': `${month}/${day}/${year}`,
				'ar-ar': `${day}/${month}/${year}`,
				'cn-cn': `${year}/${month}/${day}`,
			}[this.userLang.toLowerCase()];

			this.$emit('changeInput', body);
			this.$emit('closeOtherPicker');
			this.closePicker();
			this.selectedDate = formattedDate;
		},
		getValue() {
			return this.value;
		},
		setValue(value) {
			const [year, month, day] = value.split('-').map(Number);

			const dayIndex = this.mobileData[0].list.indexOf(day);
			const monthIndex = month - 1;
			const yearIndex = this.mobileData[2].list.indexOf(year);

			if (dayIndex !== -1 && monthIndex !== -1 && yearIndex !== -1) {
				this.$refs[this.title].currentIndexList[0] = dayIndex;
				this.$refs[this.title].currentIndexList[1] = monthIndex;
				this.$refs[this.title].currentIndexList[2] = yearIndex;

				this.selectedDate = {
					'pt-br': `${day}/${month}/${year}`,
					'es-es': `${day}/${month}/${year}`,
					'en-us': `${month}/${day}/${year}`,
					'ar-ar': `${day}/${month}/${year}`,
					'cn-cn': `${year}/${month}/${day}`,
				}[this.userLang.toLowerCase()];
			}
		},
		clearValue() {
			this.mobileData[0].list = this.getDays;
			this.mobileData[1].list = this.getMonths;
			this.mobileData[2].list = this.getYears;
			this.selectedDate = null;
		},
	},
	computed: {
		...mapGetters('user', ['userLang']),
		getDays() {
			const days = [];

			for (let i = 1; i <= 31; i++) {
				days.push(i);
			}

			return days;
		},
		getMonths() {
			const months = [
				this.$t('months.jan'),
				this.$t('months.feb'),
				this.$t('months.mar'),
				this.$t('months.apr'),
				this.$t('months.may'),
				this.$t('months.jun'),
				this.$t('months.jul'),
				this.$t('months.aug'),
				this.$t('months.sep'),
				this.$t('months.oct'),
				this.$t('months.nov'),
				this.$t('months.dec'),
			];

			return months;
		},
		getYears() {
			const today = new Date();
			const years = [];

			for (let i = 1900; i <= today.getFullYear(); i++) {
				years.push(i);
			}

			return years.reverse();
		},
	},
	watch: {
		//NÃO MEXER
		showMobilePicker() {
			document.querySelector(`#${this.title}`).style.zIndex = 9999;

			setTimeout(() => {
				document.querySelector(`#${this.title}`).style.zIndex = 10000;
			}, 2000);
		},
	},
};
</script>

<style lang="scss">
@import 'MobileDatepicker.scss';
</style>
