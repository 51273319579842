<template>
	<div class="lang-container" @click="openLangMenu">
		<div class="lang-container__name">{{ selectedLang }}</div>
		<div class="dropdown-container">
			<ArrowDown class="dropdown-container__arrow" :class="isOpen ? 'open' : 'close'" />
			<div v-if="isOpen" class="dropdown-container__list">
				<button
					v-for="lang in langs"
					:key="lang"
					class="btn__lang"
					:class="lang == selectedLang ? 'selected' : ''"
					@click="selectLang(lang, true)"
				>
					{{ lang }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CHANGE_USER_LANG } from '@/store/common/user/actions';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';

export default {
	name: 'LangSelector',
	components: {
		ArrowDown,
	},
	props: {
		isLogin: {
			default: false,
		},
	},
	data: () => ({
		langs: ['pt', 'en', 'es', 'ar', 'cn'],
		selectedLang: '',
		isOpen: false,
	}),
	created() {
		this.selectLang(this.userLang.split('-')[0], false);
	},
	mounted() {},
	methods: {
		...mapActions('user', { langRequest: CHANGE_USER_LANG }),
		openLangMenu() {
			this.isOpen = !this.isOpen;
		},
		closeDropdown() {
			this.isOpen = false;
		},
		selectLang(lang, request) {
			const fileLang = this.getFileLang(lang);
			const id = this.userInfo.uuid;
			const body = {
				language: fileLang.toLowerCase(),
			};

			this.selectedLang = lang;
			this.$i18n.locale = fileLang;

			if (request && !this.isLogin) this.langRequest({ vm: this, id, body, fileLang });
		},
		getFileLang(lang) {
			return {
				en: 'en-US',
				pt: 'pt-BR',
				es: 'es-ES',
				ar: 'ar-AR',
				cn: 'cn-CN',
			}[lang];
		},
	},
	computed: {
		...mapGetters('user', ['userLang', 'userInfo']),
	},
};
</script>

<style lang="scss" scoped>
@import 'LangSelector.scss';
</style>
