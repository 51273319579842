<template>
	<div class="templateData-container">
		<TemplateInvoice ref="template_invoice" :templateDefault="templateInfo.invoice" />
		<TemplatePacking ref="template_packing" :templateDefault="templateInfo.packing" />
		<TemplateBillOfLanding
			ref="template_bill_of_landing"
			:templateDefault="templateInfo.billLanding"
			:isWeb="isWeb"
		/>
		<TemplateHealthCertificate
			ref="template_health_certificate"
			:templateDefault="templateInfo.healthCertificate"
		/>
		<TemplateCertificateOfOrigin
			ref="template_certificate_of_origin"
			:templateDefault="templateInfo.certificateOrigin"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TemplateInvoice from './TemplateInvoice/TemplateInvoice.vue';
import TemplatePacking from './TemplatePacking/TemplatePacking.vue';
import TemplateBillOfLanding from './TemplateBillOfLanding/TemplateBillOfLanding.vue';
import TemplateHealthCertificate from './TemplateHealthCertificate/TemplateHealthCertificate.vue';
import TemplateCertificateOfOrigin from './TemplateCertificateOfOrigin/TemplateCertificateOfOrigin.vue';
import {
	TEMPLATE_DEFAULT_REQUEST,
	CLIENTS_LIST_REQUEST,
} from '@/store/modules/docInstruction/actions';

export default {
	name: 'TemplateData',
	components: {
		TemplateInvoice,
		TemplatePacking,
		TemplateBillOfLanding,
		TemplateHealthCertificate,
		TemplateCertificateOfOrigin,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	mounted() {
		if (this.$route.params.id == undefined) {
			this.getTemplateDefault({ companyId: this.headerCompanyFilterCache.value.id });
		}
	},
	methods: {
		...mapActions('docInstruction', {
			getTemplateDefault: TEMPLATE_DEFAULT_REQUEST,
			getClients: CLIENTS_LIST_REQUEST,
		}),
		restoreForm() {
			this.$refs['template_invoice'].storeToData();
			this.$refs['template_packing'].storeToData();
			this.$refs['template_bill_of_landing'].storeToData();
			this.$refs['template_health_certificate'].storeToData();
			this.$refs['template_certificate_of_origin'].storeToData();
		},
		// esse método é invocado pelo pai
		getForm() {
			const invoice = this.$refs['template_invoice'].getForm();
			const packing = this.$refs['template_packing'].getForm();
			const billOfLanding = this.$refs['template_bill_of_landing'].getForm();
			const healthCertificate = this.$refs['template_health_certificate'].getForm();
			const certificateOfOrigin = this.$refs['template_certificate_of_origin'].getForm();

			const form = {
				invoice,
				packing,
				billOfLanding,
				healthCertificate,
				certificateOfOrigin,
			};

			return form;
		},
	},
	computed: {
		...mapGetters('filters', ['headerCompanyFilterCache']),
		...mapGetters('docInstruction', [
			'templateDefault',
			'isLoadingTemplateDefault',
			'templateData',
		]),
		templateInfo() {
			if (this.$route.params.id == undefined) {
				return this.templateDefault;
			}
			return this.templateData;
		},
	},
};
</script>

<style lang="scss">
@import 'TemplateData.scss';
</style>
