<template>
	<div class="success">
		<div class="success-icon">
			<SuccessIcon />
		</div>
		<div class="success-text">
			<p>{{ text }}</p>
		</div>
	</div>
</template>

<script>
import SuccessIcon from '@/components/shared/Icons/SuccessIcon.vue';

export default {
	name: 'SuccessMessage',
	components: {
		SuccessIcon,
	},
	props: {
		text: {
			default: '',
		},
	},
	data: () => ({}),
	mounted() {},
	methods: {},
	computed: {},
};
</script>

<style lang="scss" scoped>
@import 'SuccessMessage.scss';
</style>
