<template>
	<div class="pagination" v-if="!isLoading">
		<button
			class="big-btn"
			v-if="totalPages > 1 && showPages.length && showPages[0] != 1"
			@click="backPages"
		>
			<ChevronLeftIcon color="inherit" />
		</button>
		<button
			v-if="showFirstPageButton"
			@click="tableRequest(1)"
		>
			1
		</button>
		<span
			class="dots"
			v-if="showFirstPageButton"
		>
			...
		</span>
		<button
			v-for="btn in showPages"
			:class="currentPage == btn ? 'active' : ''"
			:key="btn"
			@click="tableRequest(btn)"
		>
			{{ btn }}
		</button>
		<span
			class="dots"
			v-if="showLastPageButton"
		>
			...
		</span>
		<button
			v-if="showLastPageButton"
			@click="tableRequest(totalPages)"
		>
			{{ totalPages }}
		</button>
		<button
			class="big-btn"
			v-if="totalPages > 1 && showPages.length && showPages[showPages.length - 1] != totalPages"
			@click="morePages"
		>
			<ChevronRightIcon color="inherit" />
		</button>
	</div>
</template>

<script>
import ChevronLeftIcon from '@/components/shared/Icons/ChevronLeft';
import ChevronRightIcon from '@/components/shared/Icons/ChevronRight';

export default {
	name: 'Pagination',
	computed: {
		showFirstPageButton() {
			return (
				this.totalPages > 1 &&
				this.alwaysShowFirst &&
				this.currentPage != 1 &&
				this.currentPage - this.maxPageView >= 1 &&
				this.showPages[0] != 1
			);
    	},
		showLastPageButton() {
			return (
				this.totalPages > 1 &&
				this.alwaysShowLast &&
				this.currentPage != this.totalPages &&
				this.currentPage + this.maxPageView <= this.totalPages &&
				this.showPages[this.showPages.length - 1] != this.totalPages
			)
		}
	},
	components: {
		ChevronLeftIcon,
		ChevronRightIcon,
	},
	props: {
		isLoading: {
			default: false,
		},
		totalPages: {
			default: 5,
		},
		currentFromReq: {
			default: 1,
		},
		currentFromStore: {
			default: 1,
		},
		alwaysShowLast: {
			default: true,
		},
		alwaysShowFirst: {
			default: true,
		},
		maxPageView: {
			default: 3
		},
	},
	data: () => ({
		showPages: [],
		newTotalPages: null,
		currentPage: 1,
	}),
	mounted() {
		this.configPagination(this.totalPages);
	},
	methods: {
		backPages() {
			let firstIndex = this.showPages[0];
			this.showPages.pop();
			if (firstIndex != 1) {
				this.showPages.unshift(firstIndex - 1);
			}
		},
		tableRequest(page) {
			this.currentPage = page;

			const params = {
				params: this.currentPage,
				type: 'pagination',
			};
			this.$emit('tableRequest', params);
		},
		morePages() {
			let lastIndex = this.showPages[2];
			this.showPages.shift();

			if (this.newTotalPages != null) {
				if (lastIndex != this.newTotalPages) {
					this.showPages.push(lastIndex + 1);
				}
			} else if (lastIndex != this.totalPages) {
				this.showPages.push(lastIndex + 1);
			}
		},
		setPageOne() {
			this.currentPage = 1;
		},
		getCurrentPage() {
			return this.currentPage;
		},
		configPagination(param) {
			this.showPages = [];

			if (this.currentFromReq != 1) {
				this.currentPage = this.currentFromReq;
			}

			if (this.currentFromStore != 1) {
				this.currentPage = this.currentFromStore;
			}

			if (param > 6 && this.currentFromReq != param) {
				for (let i = this.currentPage; i <= param && i; i++) {
					if (i == this.currentPage + this.maxPageView) {
						return;
					} else {
						this.showPages.push(i);
					}
				}
				if (this.showPages.length < this.maxPageView) {
					for (let i = this.showPages[this.showPages.length - 1]; i >= 1; i--) {
						if (this.showPages.length != this.maxPageView && !this.showPages.includes(i)) {
							this.showPages.push(i);
						}
					}
				}
				this.showPages.sort((a, b) => {
					return a - b;
				});
			} else if (param > 6 && this.currentFromReq == param) {
				for (let i = this.currentPage; i <= param; i--) {
					if (i == this.currentPage - this.maxPageView) {
						return;
					} else {
						this.showPages.push(i);
						this.showPages.sort((a, b) => {
							return a - b;
						});
					}
				}
			} else {
				for (let i = 1; i <= param; i++) {
					this.showPages.push(i);
				}
			}
		},
	},
	watch: {
		totalPages(newValue) {
			if (newValue) {
				this.newTotalPages = newValue;
				this.configPagination(newValue);
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'Pagination.scss';
</style>
