<template>
	<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.3353 4.12641L10.7597 0.645181C10.3353 0.230424 9.75818 -0.00184478 9.15682 1.1036e-05H3.13948C2.30688 1.1036e-05 1.50826 0.321966 0.919606 0.895244C0.330804 1.46851 0 2.24588 0 3.05665V14.9434C0 15.7541 0.330826 16.5315 0.919606 17.1048C1.50826 17.678 2.30685 18 3.13948 18H11.8605C12.6931 18 13.4917 17.678 14.0804 17.1048C14.6692 16.5315 15 15.7541 15 14.9434V5.68864C15.0019 5.10231 14.7625 4.53971 14.3354 4.12633L14.3353 4.12641ZM13.9533 14.9436C13.9533 15.4841 13.7327 16.0025 13.3403 16.3846C12.9477 16.7668 12.4154 16.9814 11.8604 16.9814H3.13933C2.58424 16.9814 2.05195 16.7668 1.65936 16.3846C1.26693 16.0025 1.04634 15.4841 1.04634 14.9436V3.05695C1.04634 2.5165 1.26694 1.99811 1.65936 1.61603C2.05195 1.2338 2.58424 1.01918 3.13933 1.01918H9.15667C9.48063 1.01833 9.79159 1.14356 10.0201 1.36729L13.5957 4.84852V4.84837C13.8253 5.07083 13.9541 5.37361 13.9532 5.68902L13.9533 14.9436ZM10.486 7.89627V7.89641C10.5842 7.99193 10.6394 8.12156 10.6394 8.25674C10.6394 8.39206 10.5842 8.52169 10.486 8.61721L6.84325 12.1638C6.74514 12.2595 6.61199 12.3132 6.473 12.3132C6.33416 12.3132 6.20102 12.2595 6.10291 12.1638L4.51388 10.6133C4.30949 10.4143 4.30949 10.0915 4.51388 9.89252C4.71842 9.69352 5.04984 9.69352 5.25435 9.89252L6.47531 11.0813L9.74635 7.89643C9.95074 7.69771 10.2816 7.69771 10.486 7.89643L10.486 7.89627Z"
			fill="#234057"
		/>
	</svg>
</template>

<script>
export default {
	props: {},
};
</script>

<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
