<template>
	<GenericMultiselect
		class="currency-multiselect"
		ref="currency"
		:options="currencyOptions"
		:placeholder="$t('modules.financial_management.filter.currency')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="true"
		:infiniteScroll="true"
		:type="'currency'"
		:multiple="true"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CURRENCY_FILTER_REQUEST,
	SAVE_CURRENCY_FILTER,
} from '@/store/modules/filtersByModules/financialManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'CurrencyInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('financialManagementFilters', {
			getCurrencyFilter: CURRENCY_FILTER_REQUEST,
			saveCurrencyFilter: SAVE_CURRENCY_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveCurrencyFilter(param);

			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getCurrencyFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		nextPage() {
			const meta = this.currencyFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getCurrencyFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		createQueryString(currencyType) {
			const query = new URLSearchParams();

			if (currencyType) query.set('currency', currencyType);
			if (currencyType) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.currency.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.currencyFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.currency.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('financialManagementFilters', [
			'currencyFilter',
			'currencyFilterMeta',
			'currencyFilterCache',
			'isLoadingCurrencyFilter',
			'lastQueryString',
		]),
		currencyOptions() {
			const rows = this.currencyFilter != undefined ? this.currencyFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'CurrencyInput.scss';
</style>
