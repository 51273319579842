<template>
	<transition name="mobileModal">
		<div class="modal-mask" :style="{ zIndex: zIndex }">
			<div class="wrapper">
				<div class="container">
					<slot name="header"> </slot>
					<slot name="body"> </slot>
					<slot name="footer"> </slot>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'MobileModal',
	props: {
		zIndex: {
			default: 999,
		},
	},
};
</script>

<style lang="scss">
@import 'MobileModal.scss';
</style>
