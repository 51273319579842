<template>
	<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.56975 16.9281L7.47611 15.3245C7.76247 15.0791 8.23702 15.0791 8.52338 15.3245L10.4297 16.9281C10.8716 17.1491 11.4116 16.9281 11.5752 16.4536L11.9352 15.3654C12.0252 15.1036 11.9352 14.7191 11.7388 14.5227L9.88157 12.6572C9.74248 12.5263 9.63611 12.2645 9.63611 12.0763V9.74451C9.63611 9.40087 9.88975 9.23724 10.2088 9.36815L14.2261 11.1027C14.8561 11.3727 15.3716 11.0372 15.3716 10.35V9.29451C15.3716 8.74633 14.9625 8.11633 14.4552 7.9036L9.88157 5.93178C9.81147 5.89579 9.75196 5.84216 9.70892 5.77616C9.66588 5.71016 9.64078 5.63408 9.63611 5.55542V3.10087C9.63611 2.33178 9.07157 1.4236 8.38429 1.07178C8.13884 0.949055 7.85248 0.949055 7.60702 1.07178C6.91975 1.4236 6.3552 2.33996 6.3552 3.10905V5.5636C6.3552 5.71087 6.24066 5.88269 6.10975 5.93996L1.54429 7.91178C1.03702 8.11633 0.62793 8.74633 0.62793 9.29451V10.35C0.62793 11.0372 1.14338 11.3727 1.77338 11.1027L5.79066 9.36815C6.10157 9.22905 6.36338 9.40087 6.36338 9.74451V12.0763C6.36338 12.2645 6.25702 12.5263 6.12611 12.6572L4.26884 14.5227C4.07247 14.7191 3.98248 15.0954 4.07248 15.3654L4.43247 16.4536C4.57975 16.9281 5.11975 17.1572 5.56975 16.9281V16.9281Z"
			:stroke="stroke"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#B47D4A',
		},
	},
	computed: {
		stroke() {
			switch (this.color) {
				case 'red':
					return '#c8a1a1';

				case 'green':
					return '#96c695';

				case 'orange':
					return '#ab896a';

				case 'gray':
					return '#678aa3';

				default:
					return '#96C695';
			}
		},
	},
};
</script>
<style lang="scss" scoped></style>
