<template>
	<div id="web-filter" class="web-filter">
		<div class="web-filter__inputs-actions">
			<slot name="filter-actions"></slot>
		</div>
		<div class="web-filter__inputs-content">
			<slot name="inputs-list"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'WebFilter',
	components: {},
	data: () => ({}),
	mounted() {},
	methods: {},
	computed: {},
};
</script>

<style lang="scss">
@import 'WebFilter.scss';
</style>
