<template>
	<div
		class="web-datepicker-container"
		:class="largeLabel ? 'large-label' : ''"
		v-click-outside="closePicker"
	>
		<span class="label" @click="openPicker" v-if="!hideLabel">
			{{ buttonLabel }}
			<ArrowDown :class="showDatepicker ? 'rotate' : ''" :color="'#234057'" />
		</span>
		<Datepicker
			:ref="title"
			:language="selectedLang"
			:value="value"
			:format="formatDate"
			@selected="changeDate"
		>
		</Datepicker>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { en, ptBR, zh, ar, es } from 'vuejs-datepicker/dist/locale';
import Vue from 'vue';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

Vue.directive('click-outside', {
	bind(el, binding, vnode) {
		el.clickOutsideEvent = (event) => {
			if (!(el === event.target || el.contains(event.target))) {
				vnode.context[binding.expression](event);
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent);
	},
	unbind(el) {
		document.body.removeEventListener('click', el.clickOutsideEvent);
	},
});

export default {
	name: 'GenericDatepicker',
	components: {
		ArrowDown,
		Datepicker,
	},
	props: {
		title: {
			required: true,
		},
		buttonLabel: {
			required: true,
		},
		largeLabel: {
			default: false,
		},
	},
	data: () => ({
		selectedLang: null,
		en: en,
		ptBR: ptBR,
		zh: zh,
		ar: ar,
		es: es,
		showDatepicker: false,
		hideLabel: false,
		value: null,
	}),
	created() {
		switch (this.userLang) {
			case 'pt-BR':
				this.selectedLang = this.ptBR;
				break;
			case 'cn-CN':
				this.selectedLang = this.zh;
				break;
			case 'en-US':
				this.selectedLang = this.en;
				break;
			case 'es-ES':
				this.selectedLang = this.es;
				break;
			case 'ar-AR':
				this.selectedLang = this.ar;
				break;

			default:
				this.selectedLang = this.en;
				break;
		}
	},
	methods: {
		openPicker() {
			this.showDatepicker = true;
			this.$refs[`${this.title}`].showCalendar();
		},
		closePicker() {
			this.showDatepicker = false;
			this.$refs[`${this.title}`].close();
		},
		changeDate(param) {
			this.hideLabel = true;
			this.value = new Date(param);

			//just to keep with same generic multiselect return
			const body = {
				type: this.title,
				value: {
					id: this.getValue(),
				},
			};

			this.$emit('changeInput', body);
		},
		formatDate(date) {
			if (!date) {
				return;
			}
			return moment(date).format('DD/MM/YYYY');
		},
		getValue() {
			return moment(this.value).format('YYYY-MM-DD');
		},
		setValue(param) {
			this.hideLabel = true;
			this.value = new Date(param);
		},
		clearValue() {
			this.hideLabel = false;
			this.value = null;
		},
	},
	computed: {
		...mapGetters('user', ['userLang', 'userInfo']),
	},
	watch: {
		userLang() {
			switch (this.userLang) {
				case 'pt-BR':
					this.selectedLang = this.ptBR;
					break;
				case 'cn-CN':
					this.selectedLang = this.zh;
					break;
				case 'en-US':
					this.selectedLang = this.en;
					break;
				case 'es-ES':
					this.selectedLang = this.es;
					break;
				case 'ar-AR':
					this.selectedLang = this.ar;
					break;
				default:
					this.selectedLang = this.en;
					break;
			}
		},
	},
};
</script>

<style lang="scss">
@import 'GenericDatepicker.scss';
</style>
