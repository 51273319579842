<template>
	<DoughnutChart v-bind="doughnutChartProps" />
</template>

<script>
import { DoughnutChart, useDoughnutChart } from 'vue-chart-3';
import { ref, computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
	name: 'RoundChart',
	components: {
		DoughnutChart,
	},
	props: {
		data: {
			default: () => [],
		},
		labels: {
			default: () => [],
		},
		colors: {
			default: () => [],
		},
	},
	setup(props) {
		const data = ref(props.data);
		const labels = ref(props.labels);
		const colors = ref(props.colors);

		const options = computed(() => ({
			responsive: true,
			maintainAspectRatio: true,
			cutout: 45,
			elements: {
				arc: {
					borderWidth: 0,
				},
			},
			scales: {
				x: {
					display: false,
				},
				y: {
					display: false,
				},
			},
			plugins: {
				legend: { display: false },
				tooltip: {
					bodyFont: {size: 9},
					yAlign: 'bottom',
					callbacks: {
						labelTextColor: function () {
							return '#000';
						},
					},
					backgroundColor: '#fff',
				},
			},
		}));

		const chartData = computed(() => ({
			labels: labels.value,
			datasets: [
				{
					data: data.value,
					backgroundColor: colors.value,
				},
			],
		}));

		const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
			chartData,
			options,
		});

		return { doughnutChartProps, doughnutChartRef };
	},
});
</script>

<style scoped lang="scss">
@import 'RoundChart.scss';
</style>
