<template>
	<GenericMultiselect
		class="contract-status-multiselect"
		ref="status"
		:options="statusOptions"
		:placeholder="$t('modules.user_management.status_placeholder')"
		:fullWidth="!isWeb"
		:darkBlue="!isWeb"
		:searchable="true"
		:needType="true"
		:type="'status'"
		@changeInput="changeInput"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SAVE_CONTRACT_STATUS } from '@/store/common/filters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'StatusInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({}),
	mounted() {
		const statusCached = this.contractStatusCache.value;

		if (statusCached.id != undefined) {
			this.$refs.status.setValue(statusCached);
		}
	},
	methods: {
		...mapActions('filters', { saveFilter: SAVE_CONTRACT_STATUS }),
		changeInput(param) {
			this.saveFilter(param);
			this.$emit('filterRequest', param);
		},
		clearInput() {
			this.$refs.status.setValue(null);
		},
	},
	computed: {
		...mapGetters('filters', ['contractStatusCache']),
		statusOptions() {
			return [
				{
					id: 'Em processamento',
					name: this.$t('contract_step.processing'),
				},
				{
					id: 'Restrições cliente',
					name: this.$t('contract_step.released_customer_restriction'),
				},
				{
					id: 'Em produção',
					name: this.$t('contract_step.in_production'),
				},
				{
					id: 'Em carregamento',
					name: this.$t('contract_step.in_loading'),
				},
				{
					id: 'Gate in origem',
					name: this.$t('contract_step.gate_in'),
				},
				{
					id: 'Aguarda pré pgto',
					name: this.$t('contract_step.pre_payment_pending'),
				},
				{
					id: 'Embarcado',
					name: this.$t('contract_step.boarded'),
				},
				{
					id: 'Documentação enviada',
					name: this.$t('contract_step.documentation_sended'),
				},
				{
					id: 'Documentação entregue',
					name: this.$t('contract_step.documentation_delivered'),
				},
				{
					id: 'Entregue no POD',
					name: this.$t('contract_step.delivered_in_pod'),
				},
				{
					id: 'Gate out destino',
					name: this.$t('contract_step.gate_out'),
				},
				{
					id: 'Vazio devolvido',
					name: this.$t('contract_step.empty_return'),
				},
				{
					id: 'Aberta',
					name: this.$t('contract_step.opened'),
				},
				{
					id: 'Aberta em atraso',
					name: this.$t('contract_step.opened_delay'),
				},
				{
					id: 'Pago parcial',
					name: this.$t('contract_step.partial_paid'),
				},
				{
					id: 'Pago total',
					name: this.$t('contract_step.total_paid'),
				},
				{
					id: 'Finalizado',
					name: this.$t('contract_step.finished'),
				},
			];
		},
	},
};
</script>

<style lang="scss">
@import 'StatusInput.scss';
</style>
