<template>
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.18164 9.27275C6.18164 9.9782 6.7271 10.5455 7.39619 10.5455H8.76346C9.34528 10.5455 9.818 10.0509 9.818 9.43275C9.818 8.77093 9.5271 8.53093 9.098 8.3782L6.90891 7.61457C6.47982 7.46184 6.18891 7.22911 6.18891 6.56002C6.18891 5.94911 6.66164 5.44729 7.24346 5.44729H8.61073C9.27982 5.44729 9.82528 6.01457 9.82528 6.72002M7.99982 4.72729V11.2727"
			:stroke="stroke"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.2725 8.00002C15.2725 12.0146 12.0143 15.2727 7.99978 15.2727C3.98523 15.2727 0.727051 12.0146 0.727051 8.00002C0.727051 3.98548 3.98523 0.727295 7.99978 0.727295"
			:stroke="stroke"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.6362 1.45457V4.36366H14.5453M15.2726 0.727295L11.6362 4.36366"
			:stroke="stroke"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#B47D4A',
		},
	},
	computed: {
		stroke() {
			switch (this.color) {
				case 'red':
					return '#c8a1a1';

				case 'green':
					return '#96c695';

				case 'orange':
					return '#ab896a';

				case 'gray':
					return '#678aa3';

				default:
					return '#96C695';
			}
		},
	},
};
</script>
<style lang="scss" scoped></style>
