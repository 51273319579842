<template>
	<div class="selectedTemplate__wrapper">
		<InvoiceBlock @editData="handleEditData" :template-data="templateData.invoice" />
		<PackingBlock @editData="handleEditData" :template-data="templateData.packing" />
		<BillBlock @editData="handleEditData" :template-data="templateData.billLanding" />
		<HealthBlock @editData="handleEditData" :template-data="templateData.healthCertificate" />
		<CertificateBlock @editData="handleEditData" :template-data="templateData.certificateOrigin" />
	</div>
</template>

<script>
import InvoiceBlock from '@/components/modules/DocInstruction/StepThree/InvoiceBlock/InvoiceBlock.vue';
import PackingBlock from '@/components/modules/DocInstruction/StepThree/PackingBlock/PackingBlock.vue';
import BillBlock from '@/components/modules/DocInstruction/StepThree/BillBlock/BillBlock.vue';
import HealthBlock from '@/components/modules/DocInstruction/StepThree/HealthBlock/HealthBlock.vue';
import CertificateBlock from '@/components/modules/DocInstruction/StepThree/CertificateBlock/CertificateBlock.vue';

import { mapGetters, mapActions } from 'vuex';

import { TEMPLATE_DETAIL_REQUEST } from '@/store/modules/docInstruction/actions';

export default {
	name: 'SelectedTemplate',
	components: {
		InvoiceBlock,
		PackingBlock,
		BillBlock,
		HealthBlock,
		CertificateBlock,
	},
	computed: {
		...mapGetters('filters', ['headerCompanyFilterCache']),
		...mapGetters('docInstruction', ['templateData', 'selectedContractsSequence']),
		...mapGetters('selectInstruction', ['selectedTemplateData']),
		contract() {
			return this.selectedContractsSequence[0].contractNumber;
		},
		contractSeq() {
			return this.selectedContractsSequence.map((item) => {
				return item.seq;
			});
		},
	},
	watch: {
		selectedTemplateData(value) {
			if (value.cdTemplate) {
				this.getTemplateInfo({
					vm: this,
					cdInstruction: this.selectedTemplateData.cdTemplate,
					companyId: this.headerCompanyFilterCache.value.id,
					contract: this.contract,
					contractSeq: this.contractSeq,
				});
			}
		},
	},
	data: () => ({}),
	created() {},
	methods: {
		...mapActions('docInstruction', {
			getTemplateInfo: TEMPLATE_DETAIL_REQUEST,
		}),
		handleEditData() {
			//TODO PEGAR ID DO TEMPLATE E PASSAR NA ROTA
			this.$router.push({
				path: `/main/docInstruction/newTemplate/${this.selectedTemplateData.cdTemplate}`,
			});
		},
	},
};
</script>

<style lang="scss">
@import 'SelectedTemplate.scss';
</style>
