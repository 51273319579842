<template>
	<div class="generic-checkbox" :class="{ 'is-table': isTable }">
		<input
			:id="id"
			type="checkbox"
			class="generic-checkbox__input"
			:checked="value"
			:disabled="disabled"
			@change="emitValue($event.target.checked)"
		/>
		<label :for="id" class="generic-checkbox__label"></label>
	</div>
</template>

<script>
export default {
	name: 'GenericCheckbox',
	props: {
		id: {
			type: [String, Number],
			required: true,
		},
		value: {
			required: true,
			type: Boolean,
		},
		isTable: {
			required: false,
			type: Boolean,
		},
		emitObject: {
			required: false,
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue'],
	methods: {
		emitValue(newValue) {
			if (!this.emitObject) return this.$emit('input', newValue);

			this.$emit('input', { id: this.id, value: newValue });
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'GenericCheckbox.scss';
</style>
