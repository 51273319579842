export const CLEAR_CARGO_MANAGEMENT_STATE = 'CLEAR_CARGO_MANAGEMENT_STATE';

export const CARGO_IN_PRODUCTION_STATE_REQUEST = 'CARGO_IN_PRODUCTION_STATE_REQUEST';

export const CARGO_IN_PRODUCTION_REQUEST = 'CARGO_IN_PRODUCTION_REQUEST';
export const CARGO_IN_PRODUCTION_REQUEST_SUCCESS = 'CARGO_IN_PRODUCTION_REQUEST_SUCCESS';
export const CARGO_IN_PRODUCTION_REQUEST_ERROR = 'CARGO_IN_PRODUCTION_REQUEST_ERROR';

export const CARGO_IN_LOADING_REQUEST = 'CARGO_IN_LOADING_REQUEST';
export const CARGO_IN_LOADING_REQUEST_SUCCESS = 'CARGO_IN_LOADING_REQUEST_SUCCESS';
export const CARGO_IN_LOADING_REQUEST_ERROR = 'CARGO_IN_LOADING_REQUEST_ERROR';

export const CARGO_GATE_IN_REQUEST = 'CARGO_GATE_IN_REQUEST';
export const CARGO_GATE_IN_REQUEST_SUCCESS = 'CARGO_GATE_IN_REQUEST_SUCCESS';
export const CARGO_GATE_IN_REQUEST_ERROR = 'CARGO_GATE_IN_REQUEST_ERROR';

export const CARGO_SHIPPED_CARGO_REQUEST = 'CARGO_SHIPPED_CARGO_REQUEST';
export const CARGO_SHIPPED_CARGO_REQUEST_SUCCESS = 'CARGO_SHIPPED_CARGO_REQUEST_SUCCESS';
export const CARGO_SHIPPED_CARGO_REQUEST_ERROR = 'CARGO_SHIPPED_CARGO_REQUEST_ERROR';

export const CARGO_DELIVERED_REQUEST = 'CARGO_DELIVERED_REQUEST';
export const CARGO_DELIVERED_REQUEST_SUCCESS = 'CARGO_DELIVERED_REQUEST_SUCCESS';
export const CARGO_DELIVERED_REQUEST_ERROR = 'CARGO_DELIVERED_REQUEST_ERROR';

export const CARGO_GAT_OUT_REQUEST = 'CARGO_GAT_OUT_REQUEST';
export const CARGO_GAT_OUT_REQUEST_SUCCESS = 'CARGO_GAT_OUT_REQUEST_SUCCESS';
export const CARGO_GAT_OUT_REQUEST_ERROR = 'CARGO_GAT_OUT_REQUEST_ERROR';

export const CARGO_CONTRACTS_REQUEST = 'CARGO_CONTRACTS_REQUEST';
export const CARGO_CONTRACTS_REQUEST_SUCCESS = 'CARGO_CONTRACTS_REQUEST_SUCCESS';
export const CARGO_CONTRACTS_REQUEST_ERROR = 'CARGO_CONTRACTS_REQUEST_ERROR';

export const MAP_MARKERS_REQUEST = 'MAP_MARKERS_REQUEST';
export const MAP_MARKERS_REQUEST_SUCCESS = 'MAP_MARKERS_REQUEST_SUCCESS';
export const MAP_MARKERS_REQUEST_ERROR = 'MAP_MARKERS_REQUEST_ERROR';

export const MARKERS_DETAIL_REQUEST = 'MARKERS_DETAIL_REQUEST';
export const MARKERS_DETAIL_REQUEST_SUCCESS = 'MARKERS_DETAIL_REQUEST_SUCCESS';
export const MARKERS_DETAIL_REQUEST_ERROR = 'MARKERS_DETAIL_REQUEST_ERROR';

export const MAP_STATUS_REQUEST = 'MAP_STATUS_REQUEST';
export const MAP_STATUS_REQUEST_SUCCESS = 'MAP_STATUS_REQUEST_SUCCESS';
export const MAP_STATUS_REQUEST_ERROR = 'MAP_STATUS_REQUEST_ERROR';
