<template>
	<label class="switch-container">
		<input
			class="switch-container__input"
			type="checkbox"
			v-model="notifications"
			:checked="notifications"
			@change="changeInput"
		/>
		<span class="switch-container__effect round"></span>
	</label>
</template>

<script>
export default {
	name: 'SwitchIcon',
	props: {
		data: {
			required: true,
		},
	},
	data: () => ({
		notifications: false,
	}),
	mounted() {
		this.notifications = this.data.emailNotification;
	},
	methods: {
		changeInput() {
			this.$emit('handleNotifications');
		},
	},
};
</script>

<style></style>

<style lang="scss">
@import 'SwitchIcon.scss';
</style>
