<template>
	<GenericMultiselect
		class="users-status-multiselect"
		ref="status"
		:options="statusOptions"
		:placeholder="$t('modules.user_management.status_placeholder')"
		:fullWidth="!isWeb"
		:darkBlue="!isWeb"
		:searchable="true"
		:needType="true"
		:type="'active'"
		@changeInput="changeInput"
	/>
</template>

<script>
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'StatusInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({}),
	mounted() {},
	methods: {
		changeInput(param) {
			this.$emit('filterRequest', param);
		},
		clearInput() {
			this.$refs.status.setValue(null);
		},
	},
	computed: {
		statusOptions() {
			return [
				{
					id: true,
					name: this.$t('modules.user_management.status.active'),
				},
				{
					id: false,
					name: this.$t('modules.user_management.status.suspended'),
				},
			];
		},
	},
};
</script>

<style lang="scss">
@import 'StatusInput.scss';
</style>
