<template>
	<div>
		<div class="doc-headerContainer">
			<div class="doc-title">
				<h1 v-if="!footer"  class="doc-title__text">
					<span>{{ $t('modules.doc_instruction.steps.title') }} {{ step }}</span> -
					{{ $t(titleStep) }}
				</h1>
			</div>

			<div class="doc-nextButton">
				<button
					v-if="step == 3"
					class="doc-nextButton__change"
					:class="{ disabled: blockStepBtn }"
					@click="editTemplate()"
				>
					{{ $t('buttons.change_template') }}
				</button>

				<SelectedContracts v-if="step == 4" :contracts="sequencesList" />

				<button
					v-if="step == 4"
					class="doc-nextButton__change"
					:class="{ disabled: blockStepBtn }"
					@click="toggleModalAddBank()"
				>
					{{ $t('buttons.add_bank') }}
				</button>
				<button
					v-if="step != 4"
					class="doc-nextButton__next"
					:class="{ disabled: blockStepBtn }"
					@click="goToStep('next')"
				>
					{{ $t('buttons.continue') }}
				</button>
				<button
					v-if="step == 4"
					class="doc-nextButton__next"
					:class="{ disabled: disabledConfirm }"
					@click="handleSubmitTemplate"
				>
					{{ $t('buttons.confirm') }}
				</button>
			</div>
		</div>
		<ModalBank v-show="modalAddBankOpened" @closeModal="toggleModalAddBank()" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	SET_STEP,
	SUBMIT_NEW_TEMPLATE_REQUEST,
	CLEAR_CONTRACT_STATE,
	CLEAR_NEW_TEMPLATE_REQUEST,
	CLEAR_SELECTED_CONTRACTS_SEQUENCES,
} from '@/store/modules/docInstruction/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
import ModalBank from '@/components/modules/DocInstruction/Modals/ModalBank/ModalBank.vue';
import SelectedContracts from '@/components/modules/DocInstruction/StepTwo/SelectedContracts/SelectedContracts.vue';

export default {
	name: 'HeaderStep',
	props: {
		step: {
			require: true,
		},
		footer: {
			require: false,
		}
	},
	components: {
		GenericMultiselect,
		ModalBank,
		SelectedContracts,
	},
	data: () => ({
		countryOptions: [{ id: 1, name: 'City' }],
		modalAddBankOpened: false,
	}),
	computed: {
		...mapGetters('selectInstruction', ['selectedTemplateData']),
		...mapGetters('docInstruction', ['selectedContractsSequence', 'templateData']),
		titleStep() {
			if (this.step === 1) {
				return 'modules.doc_instruction.steps.selectTheContract';
			} else if (this.step === 2) {
				return 'modules.doc_instruction.steps.selectDocumentaryInstruction';
			} else if (this.step === 3) {
				return 'modules.doc_instruction.steps.confirmTheData';
			} else if (this.step === 4) {
				return 'modules.doc_instruction.steps.enterDestination';
			} else {
				return 'modules.doc_instruction.steps.notFound';
			}
		},
		checkGoToNextStep() {
			let response = true;
			if (this.step === 2 && !this.selectedTemplateData) {
				response = false;
			}

			return response;
		},
		noContractsSelected() {
			return !this.selectedContractsSequence.length && this.step === 1;
		},
		blockStepBtn() {
			return !this.checkGoToNextStep || this.noContractsSelected;
		},
		sequencesList() {
			const list = this.selectedContractsSequence.map((item) => {
				return `${item.contractNumber}.${item.seq}`;
			});

			return list;
		},
		disabledConfirm() {
			if (
				this.templateData.invoice.shippingType == null ||
				this.templateData.packing.shippingType == null ||
				this.templateData.billLanding.shippingType == null ||
				this.templateData.certificateOrigin.shippingType == null ||
				this.templateData.healthCertificate.shippingType == null
			) {
				return true;
			}
			return false;
		},
	},
	methods: {
		...mapActions('docInstruction', {
			setStep: SET_STEP,
			submitTemplate: SUBMIT_NEW_TEMPLATE_REQUEST,
			clearContract: CLEAR_CONTRACT_STATE,
			clearNewTemplate: CLEAR_NEW_TEMPLATE_REQUEST,
			clearSelectContract: CLEAR_SELECTED_CONTRACTS_SEQUENCES,
		}),
		goToStep(action) {
			if (this.blockStepBtn) return;

			if (this.step > 1 && action === 'back') {
				this.setStep(this.step - 1);
			}
			if (this.checkGoToNextStep && this.step < 4 && action === 'next') {
				this.setStep(this.step + 1);
			}
		},
		editTemplate() {
			if (this.blockStepBtn) return;
			
			this.$router.push({
				path: `/main/docInstruction/newTemplate/${this.selectedTemplateData.cdTemplate}`,
			});
		},
		toggleModalAddBank() {
			this.modalAddBankOpened = !this.modalAddBankOpened;
		},
		handleSubmitTemplate() {
			if (!this.disabledConfirm) {
				this.submitTemplate({ vm: this, data: this.templateData }).then(() => {
					this.setStep(1);
					this.clearNewTemplate();
					this.clearSelectContract();
					this.$emit('updateContractList');
				});
			}
		},
	},
};
</script>

<style lang="scss">
@import 'HeaderStep.scss';
</style>
