<template>
	<div class="structure">
		<Sidebar v-if="isWeb" />
		<MobileSidebar :isOpen="openMobileSidebar" @handleSidebar="handleSidebar()" v-else />
		<div class="structure__page">
			<Header v-if="isWeb" :isWeb="isWeb" />
			<MobileHeader
				:class="openMobileSidebar ? 'fade' : ''"
				@handleSidebar="handleSidebar()"
				v-else
			/>
			<div class="structure__holder">
				<transition name="mask">
					<div class="structure__holder-mask" v-show="openMobileSidebar"></div>
				</transition>
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>
<script>
import Sidebar from '@/components/shared/Sidebar/Sidebar.vue';
import MobileSidebar from '@/components/shared/MobileSidebar/MobileSidebar.vue';
import Header from '@/components/shared/Header/Header.vue';
import MobileHeader from '@/components/shared/MobileHeader/MobileHeader.vue';

export default {
	name: 'Structure',
	components: { Sidebar, MobileSidebar, Header, MobileHeader },
	data: () => ({
		windowWidth: window.innerWidth,
		openMobileSidebar: false,
	}),
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth;
			if (this.isWeb) {
				this.openMobileSidebar = false;
			}
		},
		handleSidebar() {
			this.openMobileSidebar = !this.openMobileSidebar;
		},
	},
	computed: {
		isWeb() {
			return this.windowWidth > 992;
		},
	},
};
</script>
<style lang="scss">
@import 'Structure.scss';
</style>
