<template>
	<div id="doc-selectInstruction" class="doc-selectInstruction">
		<SelectedContracts :contracts="sequencesList" />

		<h4 class="doc-selectInstruction__inactivedTitle">
			{{ $t('modules.doc_instruction.select_doc_instruction') }}
		</h4>
		<div class="doc-selectInstruction__itens" v-if="isLoadingTemplateActivedList !== 'empty'">
			<DocTemplateRow
				v-for="docs in getTemplateActivedListData"
				:ref="`document-row-${docs.code}`"
				:key="docs.code"
				:document="docs"
				@changeStatus="changeStatus"
			/>
		</div>

		<div class="loader" v-if="isLoadingTemplateActivedList !== 'success'">
			<DefaultLoader :dataStatus="isLoadingTemplateActivedList" />
		</div>

		<DocShowMore
			:showContent="loadMoreActivedTemplates"
			v-if="isLoadingTemplateActivedList === 'success'"
			@handleClick="loadMoreEnabledTemplates"
		/>

		<h4 class="doc-selectInstruction__inactivedTitle">
			{{ $t('modules.doc_instruction.inactive_templates_title') }}
		</h4>
		<div class="doc-selectInstruction__itens" v-if="isLoadingTemplateDisabledList !== 'empty'">
			<DocTemplateRow
				v-for="docs in getTemplateDisabledListData"
				:ref="`document-row-${docs.code}`"
				:key="docs.code"
				:document="docs"
				@changeStatus="changeStatus"
			/>
		</div>
		<DocShowMore
			:showContent="loadMoreDeactivatedTemplates"
			v-if="isLoadingTemplateDisabledList === 'success'"
			@handleClick="loadMoreDisabledTemplates"
		/>

		<div class="loader" v-if="isLoadingTemplateDisabledList !== 'success'">
			<DefaultLoader :dataStatus="isLoadingTemplateDisabledList" />
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	GET_ACTIVED_TEMPLATES,
	GET_DISABLED_TEMPLATES,
	CLEAN_ALL_TEMPLATES,
	CHANGE_TEMPLATE_STATUS,
} from '@/store/modules/docInstruction/selectInstruction/actions';
import { RESET_STEPS } from '@/store/modules/docInstruction/actions';
import DefaultLoader from '@/components/shared/DefaultLoader/DefaultLoader.vue';
import DocTemplateRow from '@/components/modules/DocInstruction/StepTwo/DocTemplateRow/DocTemplateRow.vue';
import SelectedContracts from '../SelectedContracts/SelectedContracts.vue';
import DocShowMore from '../DocShowMore/DocShowMore.vue';

export default {
	name: 'SelectInstruction',
	components: {
		DocTemplateRow,
		DefaultLoader,
		SelectedContracts,
		DocShowMore,
	},
	data: () => ({
		windowWidth: window.innerWidth,
		contracts: [],
		loadMoreActivedTemplates: false,
		loadMoreDeactivatedTemplates: false,
	}),
	created() {
		if (this.noContractsSelected) {
			this.setResetSteps();
			this.clearTemplate();
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions('selectInstruction', {
			getActivedTemplates: GET_ACTIVED_TEMPLATES,
			getDisabledTemplates: GET_DISABLED_TEMPLATES,
			clearTemplate: CLEAN_ALL_TEMPLATES,
			changeTemplateStatus: CHANGE_TEMPLATE_STATUS,
		}),
		...mapActions('docInstruction', {
			setResetSteps: RESET_STEPS,
		}),
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		async changeStatus(statusData) {
			if (statusData) {
				//TODO - Falta integrar backend
				const requestStatus = await this.changeTemplateStatus(statusData);
				if (requestStatus) {
					this.clearTemplate();
					this.getActivedTemplates({
						vm: this,
						companyId: this.headerCompanyFilterCache.value.id,
						page: 1,
					});
					this.getDisabledTemplates({
						vm: this,
						companyId: this.headerCompanyFilterCache.value.id,
						page: 1,
					});
				}
			}
		},
		loadMoreEnabledTemplates() {
			if (this.templateActivedListPage >= this.templateActivedListMax) {
				return;
			}
			this.getActivedTemplates({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				page: this.templateActivedListPage + 1,
			});
		},
		loadMoreDisabledTemplates() {
			if (this.templateDisabledListPage >= this.templateDisabledListMax) {
				return;
			}
			this.getDisabledTemplates({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				page: this.templateDisabledListPage + 1,
			});
		},
	},
	computed: {
		...mapGetters('filters', ['headerCompanyFilterCache']),
		...mapGetters('selectInstruction', [
			'getTemplateActivedListData',
			'templateActivedListPage',
			'templateActivedListMax',
			'getTemplateDisabledListData',
			'templateDisabledListPage',
			'templateDisabledListMax',
			'isLoadingTemplateActivedList',
			'isLoadingTemplateDisabledList',
		]),
		...mapGetters('docInstruction', ['selectedContractsSequence', 'templateList']),
		noContractsSelected() {
			return !this.selectedContractsSequence.length;
		},
		sequencesList() {
			const list = this.selectedContractsSequence.map((item) => {
				return `${item.contractNumber}.${item.seq}`;
			});

			return list;
		},
	},
};
</script>

<style lang="scss">
@import 'SelectInstruction.scss';
</style>
