import {
	CLEAR_CONTRACT_MANAGEMENT_STATE,
	CONTRACT_MANAGEMENT_REQUEST,
	CONTRACT_MANAGEMENT_REQUEST_SUCCESS,
	CONTRACT_MANAGEMENT_REQUEST_ERROR,
	CONTRACT_TABLE_MANAGEMENT_REQUEST,
	CONTRACT_TABLE_MANAGEMENT_REQUEST_SUCCESS,
	CONTRACT_TABLE_MANAGEMENT_REQUEST_ERROR,
	CONTRACT_PREFERENCES_REQUEST,
	CONTRACT_PREFERENCES_REQUEST_SUCCESS,
	CONTRACT_PREFERENCES_REQUEST_ERROR,
	CONTRACT_NOTIFICATION_REQUEST,
	CONTRACT_NOTIFICATION_REQUEST_SUCCESS,
	CONTRACT_NOTIFICATION_REQUEST_ERROR,
	CONTRACT_DOWNLOAD_REQUEST,
	CONTRACT_DOWNLOAD_REQUEST_SUCCESS,
	CONTRACT_DOWNLOAD_REQUEST_ERROR,
	CONTRACT_APPROVE_REQUEST,
	CONTRACT_APPROVE_RESET,
	CONTRACT_APPROVE_REQUEST_SUCCESS,
	CONTRACT_APPROVE_REQUEST_ERROR,
	UPDATE_PREFERENCES_REQUEST,
	SAVE_LAST_ROW_OPENED,
} from './actions';
import api from '@/utils/api';

const { http } = api.getInstance();
const CONTRACT_MANAGEMENT_URL = '/contracts';

const defaultState = () => ({
	contractList: [],
	isLoadingContracts: '',
	contractTable: [],
	isLoadingContractTable: '',
	userPreferences: { header: [], detail: [] },
	isLoadingPrefereces: '',
	isLoadingNotification: '',
	isLoadingDownload: '',
	lastRowOpened: {},
	isApprovingStatus: '',
});

const state = defaultState();

const getters = {
	contractList: (state) => state.contractList,
	isLoadingContracts: (state) => state.isLoadingContracts === 'loading',
	contractTable: (state) => state.contractTable,
	isLoadingContractTable: (state) => state.isLoadingContractTable === 'loading',
	userPreferences: (state) => state.userPreferences,
	isLoadingPrefereces: (state) => state.isLoadingUsersFilter === 'loading',
	isLoadingNotification: (state) => state.isLoadingNotification === 'loading',
	isLoadingDownload: (state) => state.isLoadingDownload === 'loading',
	lastRowOpened: (state) => state.lastRowOpened,
	isApprovingStatus: (state) => state.isApprovingStatus,
};

const mutations = {
	[CLEAR_CONTRACT_MANAGEMENT_STATE]: (state) => {
		Object.assign(state, defaultState());
	},
	[CONTRACT_MANAGEMENT_REQUEST]: (state) => {
		state.contractList = [];
		state.isLoadingContracts = 'loading';
	},
	[CONTRACT_MANAGEMENT_REQUEST_SUCCESS]: (state, data) => {
		state.contractList = data;
		state.isLoadingContracts = 'success';
	},
	[CONTRACT_MANAGEMENT_REQUEST_ERROR]: (state) => {
		state.contractList = [];
		state.isLoadingContracts = 'error';
	},
	[CONTRACT_TABLE_MANAGEMENT_REQUEST]: (state) => {
		state.contractTable = [];
		state.isLoadingContractTable = 'loading';
	},
	[CONTRACT_TABLE_MANAGEMENT_REQUEST_SUCCESS]: (state, data) => {
		state.contractTable = data;
		state.isLoadingContractTable = 'success';
	},
	[CONTRACT_TABLE_MANAGEMENT_REQUEST_ERROR]: (state) => {
		state.contractTable = [];
		state.isLoadingContractTable = 'error';
	},
	[CONTRACT_PREFERENCES_REQUEST]: (state) => {
		state.userPreferences = { header: [], detail: [] };
		state.isLoadingPrefereces = 'loading';
	},
	[CONTRACT_PREFERENCES_REQUEST_SUCCESS]: (state, data) => {
		state.userPreferences = {
			header: data.header,
			detail: data.detail,
		};
		state.isLoadingPrefereces = 'success';
	},
	[CONTRACT_PREFERENCES_REQUEST_ERROR]: (state) => {
		state.userPreferences = { header: [], detail: [] };
		state.isLoadingPrefereces = 'error';
	},
	[CONTRACT_NOTIFICATION_REQUEST]: (state) => {
		state.isLoadingNotification = 'loading';
	},
	[CONTRACT_NOTIFICATION_REQUEST_SUCCESS]: (state) => {
		state.isLoadingNotification = 'success';
	},
	[CONTRACT_NOTIFICATION_REQUEST_ERROR]: (state) => {
		state.isLoadingNotification = 'error';
	},
	[CONTRACT_DOWNLOAD_REQUEST]: (state) => {
		state.isLoadingDownload = 'loading';
	},
	[CONTRACT_DOWNLOAD_REQUEST_SUCCESS]: (state) => {
		state.isLoadingDownload = 'success';
	},
	[CONTRACT_DOWNLOAD_REQUEST_ERROR]: (state) => {
		state.isLoadingDownload = 'error';
	},
	[CONTRACT_APPROVE_RESET]: (state) => {
		state.isApprovingStatus = '';
	},
	[CONTRACT_APPROVE_REQUEST]: (state) => {
		state.isApprovingStatus = 'loading';
	},
	[CONTRACT_APPROVE_REQUEST_SUCCESS]: (state) => {
		state.isApprovingStatus = 'success';
	},
	[CONTRACT_APPROVE_REQUEST_ERROR]: (state) => {
		state.isApprovingStatus = 'error';
	},
	[SAVE_LAST_ROW_OPENED]: (state, data) => {
		state.lastRowOpened = data;
	},
};

const actions = {
	[CONTRACT_MANAGEMENT_REQUEST]: ({ commit }, { vm, params, withoutLoading }) => {
		return new Promise((resolve) => {
			if (!withoutLoading) {
				commit(CONTRACT_MANAGEMENT_REQUEST);
			}

			let url = `${CONTRACT_MANAGEMENT_URL}`;

			if (params) {
				url = `${CONTRACT_MANAGEMENT_URL}?${params}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CONTRACT_MANAGEMENT_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACT_MANAGEMENT_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CONTRACT_TABLE_MANAGEMENT_REQUEST]: ({ commit }, { vm, id, withoutLoading }) => {
		return new Promise((resolve) => {
			if (!withoutLoading) {
				commit(CONTRACT_TABLE_MANAGEMENT_REQUEST);
			}

			let url = `contract-items?contractIds=${id}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CONTRACT_TABLE_MANAGEMENT_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACT_TABLE_MANAGEMENT_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CONTRACT_PREFERENCES_REQUEST]: ({ commit }, { vm }) => {
		return new Promise((resolve) => {
			commit(CONTRACT_PREFERENCES_REQUEST);

			let url = '/preferences';

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CONTRACT_PREFERENCES_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACT_PREFERENCES_REQUEST_ERROR);
				});
		});
	},
	[CONTRACT_NOTIFICATION_REQUEST]: ({ commit }, { vm, body }) => {
		return new Promise((resolve) => {
			commit(CONTRACT_NOTIFICATION_REQUEST);

			let url = '/contracts/user';

			http({
				method: 'PUT',
				data: body,
				url: url,
			})
				.then((response) => {
					commit(CONTRACT_NOTIFICATION_REQUEST_SUCCESS);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACT_NOTIFICATION_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CONTRACT_DOWNLOAD_REQUEST]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			commit(CONTRACT_DOWNLOAD_REQUEST);

			let query = params;
			query = query.split('page=')[0];
			query = query.replace('limit=10&', '').replace('&limit=10', '');

			let url = `/contracts/download?${query}`;

			http({
				method: 'GET',
				url: url,
				responseType: 'blob',
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					const type = 'Contracts.xlsx';
					link.href = url;
					link.setAttribute('download', type);
					document.body.appendChild(link);
					link.click();
					link.remove();

					commit(CONTRACT_DOWNLOAD_REQUEST_SUCCESS);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACT_DOWNLOAD_REQUEST_ERROR);
				});
		});
	},
	[CONTRACT_APPROVE_REQUEST]: ({ commit }, { vm, id, action, note }) => {
		return new Promise((resolve) => {
			commit(CONTRACT_APPROVE_REQUEST);

			let url = `/contracts/approveDisapproveContract?contractId=${id}`;

			http({
				method: 'POST',
				url: url,
				data: {
					status: action,
					note: note,
				},
			})
				.then((response) => {
					commit(CONTRACT_APPROVE_REQUEST_SUCCESS);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACT_APPROVE_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CONTRACT_APPROVE_RESET]: ({ commit }) => {
		return new Promise((resolve) => {
			commit(CONTRACT_APPROVE_RESET);
		});
	},
	[UPDATE_PREFERENCES_REQUEST]({ commit }, { vm, body }) {
		return new Promise((resolve, reject) => {
			let url = '/preferences';

			http({
				method: 'PATCH',
				url: url,
				data: body,
			})
				.then((response) => {
					commit(CONTRACT_PREFERENCES_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACT_PREFERENCES_REQUEST_ERROR);
				});
		});
	},
	[SAVE_LAST_ROW_OPENED]({ commit }, data) {
		commit(SAVE_LAST_ROW_OPENED, data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
