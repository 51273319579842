<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.5 12.75C8.5 13.72 9.25 14.5 10.17 14.5H12.05C12.85 14.5 13.5 13.82 13.5 12.97C13.5 12.06 13.1 11.73 12.51 11.52L9.5 10.47C8.91 10.26 8.51 9.94 8.51 9.02C8.51 8.18 9.16 7.49 9.96 7.49H11.84C12.76 7.49 13.51 8.27 13.51 9.24M11 6.5V15.5"
			:stroke="color"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 11C21 16.52 16.52 21 11 21C5.48 21 1 16.52 1 11C1 5.48 5.48 1 11 1"
			:stroke="color"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M16 2V6H20M21 1L16 6" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: '#B47D4A',
		},
		height: {
			default: '22',
		},
		width: {
			default: '22',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
