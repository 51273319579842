<template>
	<div class="columns-modal">
		<div class="btn-save" v-if="isWeb">
			<button class="btn-save" @click="saveColumns()">{{ $t('buttons.save') }}</button>
		</div>
		<div class="modal-label">
			<span> {{ $t('modules.contract_management.column_modal.title_modal') }}</span>
		</div>
		<div class="drag-div header">
			<span class="title-drag">
				{{ $t('modules.contract_management.column_modal.header_columns') }}
			</span>
			<input
				class="header__input"
				type="text"
				:placeholder="$t('modules.contract_management.table.placeholder.search')"
				v-model="headerListFilter"
				@input="searchOnHeader()"
			/>
			<draggable class="drag-list" v-bind="dragOptions" :list="headerList" v-if="headerList.length">
				<transition-group type="transition" :name="!drag ? 'flip-list' : null">
					<div
						class="list-components"
						v-for="(element, index) in headerList"
						:key="`${element.name}${index}`"
					>
						<div class="move-name">
							<MoveIcon />
							<span>{{ element.name }}</span>
						</div>
						<span
							class="status-btn"
							:class="[element.active ? 'active' : 'denied']"
							@click="changeStatus(headerList[index])"
						>
							{{
								element.active
									? $t('modules.contract_management.column_modal.active_status')
									: $t('modules.contract_management.column_modal.disable_status')
							}}
						</span>
					</div>
				</transition-group>
			</draggable>
		</div>
		<div class="drag-div details">
			<span class="title-drag">
				{{ $t('modules.contract_management.column_modal.details_columns') }}
			</span>
			<input
				class="details__input"
				type="text"
				:placeholder="$t('modules.contract_management.table.placeholder.search')"
				v-model="detailListFilter"
				@input="searchOnDetails()"
			/>
			<draggable
				class="drag-list"
				v-bind="dragOptions"
				:list="detailsList"
				v-if="detailsList.length"
			>
				<transition-group type="transition" :name="!drag ? 'flip-list' : null">
					<div
						class="list-components"
						v-for="(element, index) in detailsList"
						:key="`${element.name}${index}`"
					>
						<div class="move-name">
							<MoveIcon :color="'#678AA3'" />
							<span>{{ element.name }}</span>
						</div>
						<span
							class="status-btn"
							:class="[element.active ? 'active' : 'denied']"
							@click="changeStatus(detailsList[index])"
						>
							{{
								element.active
									? $t('modules.contract_management.column_modal.active_status')
									: $t('modules.contract_management.column_modal.disable_status')
							}}
						</span>
					</div>
				</transition-group>
			</draggable>
		</div>
		<div class="modal-footer">
			<div class="mobile-footer" v-if="!isWeb">
				<button
					id="cancel-user-modal"
					class="mobile-footer__btn outline"
					@click="handleWarningModal()"
				>
					{{ $t('buttons.cancel') }}
				</button>
				<button id="confirm-user-modal" class="mobile-footer__btn" @click="saveColumns()">
					{{ $t('buttons.save') }}
				</button>
			</div>
			<span @click="handleWarningModal()" v-else>
				<ArrowDown :color="'#678AA3'" />
			</span>
		</div>
		<WarningModal
			:title="$t('modules.contract_management.column_modal.exit_question')"
			:btnLabel="'confirm'"
			:isWeb="isWeb"
			v-show="openWarning"
			class="exit"
			@closeWarning="handleWarningModal"
			@confirmWarning="confirmWarning"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CONTRACT_MANAGEMENT_REQUEST,
	CONTRACT_PREFERENCES_REQUEST,
	CONTRACT_TABLE_MANAGEMENT_REQUEST,
	UPDATE_PREFERENCES_REQUEST,
} from '@/store/modules/contractManagement/actions';
import tableBR from '@/lang/language/contractTable/table-br.js';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import MoveIcon from '@/components/shared/Icons/MoveIcon.vue';
import WarningModal from '@/components/shared/Modals/WarningModal/WarningModal';
import draggable from 'vuedraggable';

export default {
	name: 'ColumnsModal',
	components: { draggable, ArrowDown, MoveIcon, WarningModal },
	data: () => ({
		dragging: false,
		drag: false,
		isLoading: false,
		openWarning: false,
		detailsList: [],
		cachedDetailsList: [],
		detailListFilter: null,
		headerList: [],
		cachedHeaderList: [],
		headerListFilter: null,
	}),
	props: {
		isWeb: {
			require: true,
		},
		selectedTable: {
			default: null,
		},
	},
	created() {
		if (!this.userPreferences.header.length || !this.userPreferences.detail.length) {
			return this.getDefaultList();
		} else {
			return this.formatStore();
		}
	},
	methods: {
		...mapActions('contractManagement', {
			getContracts: CONTRACT_MANAGEMENT_REQUEST,
			getTable: CONTRACT_TABLE_MANAGEMENT_REQUEST,
			refreshPreferences: CONTRACT_PREFERENCES_REQUEST,
			updatePreferences: UPDATE_PREFERENCES_REQUEST,
		}),
		getDefaultList() {
			const headerKeys = Object.keys(tableBR.header);
			const detailKeys = Object.keys(tableBR.detail);

			headerKeys.forEach((key) => {
				this.headerList.push({
					name: this.$t(`modules.contract_management.table.header.${key}`),
					key: key,
					active: true,
					isFixed: key == 'contractNumber' ? true : false,
				});
			});

			detailKeys.forEach((key) => {
				this.detailsList.push({
					name: this.$t(`modules.contract_management.table.detail.${key}`),
					key: key,
					active: true,
					isFixed: false,
				});
			});
		},
		formatStore() {
			this.headerList = this.userPreferences.header.map((item) => {
				let obj = {};

				obj['name'] = this.$t(`modules.contract_management.table.header.${item.name}`);
				obj['active'] = item.active;
				obj['isFixed'] = item.name == 'contractNumber' ? true : false;
				obj['key'] = item.name;

				return obj;
			});

			this.cachedHeaderList = this.headerList;

			this.detailsList = this.userPreferences.detail.map((item) => {
				let obj = {};

				obj['name'] = this.$t(`modules.contract_management.table.detail.${item.name}`);
				obj['active'] = item.active;
				obj['isFixed'] = false;
				obj['key'] = item.name;

				return obj;
			});

			this.cachedDetailsList = this.detailsList;
		},
		searchOnHeader() {
			if (!this.headerListFilter) {
				this.headerList = this.cachedHeaderList;
			} else {
				let filteredList = this.headerList.filter((item) =>
					item.name.toLowerCase().includes(this.headerListFilter.toLowerCase()),
				);
				if (filteredList.length) {
					this.headerList = filteredList;
				}
			}
		},
		searchOnDetails() {
			if (!this.detailListFilter) {
				this.detailsList = this.cachedDetailsList;
			} else {
				let filteredList = this.detailsList.filter((item) =>
					item.name.toLowerCase().includes(this.detailListFilter.toLowerCase()),
				);
				if (filteredList.length) {
					this.detailsList = filteredList;
				}
			}
		},
		saveColumns() {
			this.headerList = this.cachedHeaderList;
			this.headerListFilter = null;

			this.detailsList = this.cachedDetailsList;
			this.detailListFilter = null;

			const body = {
				header: this.headerList.map((item) => {
					let obj = {};

					obj['name'] = item.key;
					obj['active'] = item.active;

					return obj;
				}),
				detail: this.detailsList.map((item) => {
					let obj = {};

					obj['name'] = item.key;
					obj['active'] = item.active;

					return obj;
				}),
			};

			this.updatePreferences({ vm: this, body: body }).then((res) => {
				if (res.status == 200) {
					this.refreshPreferences({ vm: this }).then((res) => {
						this.$emit('refreshContracts', { params: null, withoutLoading: true });

						if (this.selectedTable) {
							this.getTable({ vm: this, id: this.selectedTable, withoutLoading: true });
						}
					});
					this.$emit('closeModal');
					this.$toastr.s(
						this.$t('modules.contract_management.requests_messages.preferences_updated'),
					);
				}
			});
		},
		confirmWarning() {
			this.closeModal();
		},
		handleWarningModal() {
			this.openWarning = !this.openWarning;
		},
		closeModal() {
			this.$emit('closeModal');
		},
		changeStatus(item) {
			if (!item.isFixed) {
				item.active = !item.active;
			} else {
				this.$toastr.e(this.$t('errors.fixed_column'));
			}
		},
	},
	computed: {
		...mapGetters('contractManagement', ['userPreferences', 'isLoadingPrefereces']),
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			};
		},
	},
};
</script>
<style scoped lang="scss">
@import 'ColumnsModal.scss';
</style>
