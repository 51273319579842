import {
	CLEAR_FINANCE_STATE,
	LIMIT_REQUEST,
	LIMIT_REQUEST_SUCCESS,
	LIMIT_REQUEST_ERROR,
	PRE_PAYMENT_REQUEST,
	PRE_PAYMENT_REQUEST_SUCCESS,
	PRE_PAYMENT_REQUEST_ERROR,
	INVOICE_REQUEST,
	INVOICE_REQUEST_SUCCESS,
	INVOICE_REQUEST_ERROR,
	FINANCIAL_REPORT_REQUEST,
	FINANCIAL_REPORT_REQUEST_SUCCESS,
	FINANCIAL_REPORT_REQUEST_ERROR,
} from './actions';

import api from '@/utils/api';

const FINANCE_MANAGEMENT_URL = '/finances';

const { http } = api.getInstance();

const defaultState = () => ({
	limit: {},
	isLoadingLimit: '',

	prePayments: [],
	prePaymentsDue: [],
	prePaymentsOverDue: [],
	prePaymentsPage: 1,
	prePaymentsMax: 0,
	isLoadingPrePayments: '',

	invoices: [],
	invoiceDue: [],
	invoiceFilter: [],
	invoiceOverDue: [],
	invoicesPage: 1,
	invoicesMax: 0,
	isLoadingInvoices: '',

	isLoadingDownload: '',
});

const state = defaultState();

const getters = {
	limit: (state) => state.limit,
	isLoadingLimit: (state) => state.isLoadingLimit,

	prePayments: (state) => state.prePayments,
	prePaymentsDue: (state) => state.prePaymentsDue,
	prePaymentsOverDue: (state) => state.prePaymentsOverDue,
	prePaymentsPage: (state) => state.prePaymentsPage,
	prePaymentsMax: (state) => state.prePaymentsMax,
	isLoadingPrePayments: (state) => state.isLoadingPrePayments,

	invoices: (state) => state.invoices,
	invoiceDue: (state) => state.invoiceDue,
	invoiceOverDue: (state) => state.invoiceOverDue,
	invoicesPage: (state) => state.invoicesPage,
	invoicesMax: (state) => state.invoicesMax,
	isLoadingInvoices: (state) => state.isLoadingInvoices,

	isLoadingDownload: (state) => state.isLoadingDownload,
};

const mutations = {
	[CLEAR_FINANCE_STATE]: (state) => {
		Object.assign(state, defaultState());
	},

	[LIMIT_REQUEST]: (state) => {
		state.isLoadingLimit = 'loading';
	},
	[LIMIT_REQUEST_SUCCESS]: (state, data) => {
		state.limit = data;

		state.isLoadingLimit = 'success';
		if (data.length == 0) {
			state.isLoadingLimit = 'empty';
		}
	},
	[LIMIT_REQUEST_ERROR]: (state) => {
		state.limit = [];
		state.isLoadingLimit = 'error';
	},

	[PRE_PAYMENT_REQUEST]: (state) => {
		state.isLoadingPrePayments = 'loading';
	},
	[PRE_PAYMENT_REQUEST_SUCCESS]: (state, data) => {
		state.prePaymentsPage = data.meta.currentPage;
		state.prePaymentsMax = data.meta.totalPages;
		state.prePayments = data.items;
		state.prePaymentsDue = data.due;
		state.prePaymentsOverDue = data.overDue;

		state.isLoadingPrePayments = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingPrePayments = 'empty';
		}
	},
	[PRE_PAYMENT_REQUEST_ERROR]: (state) => {
		state.prePayments = [];
		state.isLoadingPrePayments = 'error';
	},

	[INVOICE_REQUEST]: (state) => {
		state.isLoadingInvoices = 'loading';
	},
	[INVOICE_REQUEST_SUCCESS]: (state, data) => {
		state.invoicesPage = data.meta.currentPage;
		state.invoicesMax = data.meta.totalPages;
		state.invoices = data.items;
		state.invoiceDue = data.due;
		state.invoiceOverDue = data.overDue;

		state.isLoadingInvoices = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingInvoices = 'empty';
		}
	},
	[INVOICE_REQUEST_ERROR]: (state) => {
		state.invoices = [];
		state.isLoadingInvoices = 'error';
	},

	[FINANCIAL_REPORT_REQUEST]: (state) => {
		state.isLoadingDownload = 'loading';
	},
	[FINANCIAL_REPORT_REQUEST_SUCCESS]: (state) => {
		state.isLoadingDownload = 'success';
	},
	[FINANCIAL_REPORT_REQUEST_ERROR]: (state) => {
		state.isLoadingDownload = 'error';
	},
};

const actions = {
	[CLEAR_FINANCE_STATE]: ({ commit }) => {
		commit(CLEAR_FINANCE_STATE);
	},

	[LIMIT_REQUEST]: ({ commit }, { vm, companyId }) => {
		return new Promise((resolve) => {
			commit(LIMIT_REQUEST);

			let url = `${FINANCE_MANAGEMENT_URL}/limit?companyId=${companyId}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(LIMIT_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(LIMIT_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},

	[PRE_PAYMENT_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			page = 1,
			limit = 10,
			market = '',
			contract = '',
			currency = '',
			status = '',
			fromDate = '',
			toDate = '',
			sort = '',
		},
	) => {
		return new Promise((resolve) => {
			commit(PRE_PAYMENT_REQUEST);

			let url = `${FINANCE_MANAGEMENT_URL}/prePayments?companyId=${companyId}&page=${page}&limit=${limit}&market=${market}&currency=${currency}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&contract=${contract}&${sort}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(PRE_PAYMENT_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(PRE_PAYMENT_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},

	[INVOICE_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			invoiceIds,
			page = 1,
			limit = 10,
			market = '',
			contract = '',
			currency = '',
			status = '',
			fromDate = '',
			toDate = '',
			sort = '',
		},
	) => {
		return new Promise((resolve) => {
			commit(INVOICE_REQUEST);

			let url = `${FINANCE_MANAGEMENT_URL}/invoices?companyId=${companyId}&page=${page}&limit=${limit}&market=${market}&currency=${currency}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&contract=${contract}&${sort}&invoiceIds=${invoiceIds}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(INVOICE_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(INVOICE_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},

	[FINANCIAL_REPORT_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			page = 1,
			limit = 10,
			market = '',
			currency = '',
			status = '',
			fromDate = '',
			toDate = '',
			sortIPrePayment = '',
			sortInvoice = '',
		},
	) => {
		return new Promise((resolve) => {
			commit(FINANCIAL_REPORT_REQUEST);

			let url = `${FINANCE_MANAGEMENT_URL}/download?companyId=${companyId}&page=${page}&limit=${limit}&market=${market}&currency=${currency}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&${sortIPrePayment}&${sortInvoice}`;

			http({
				method: 'GET',
				url: url,
				responseType: 'blob',
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					const type = 'financial_report.xlsx';
					link.href = url;
					link.setAttribute('download', type);
					document.body.appendChild(link);
					link.click();
					link.remove();

					commit(FINANCIAL_REPORT_REQUEST_SUCCESS);
					resolve(response);
				})
				.catch((error) => {
					commit(FINANCIAL_REPORT_REQUEST_ERROR);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
