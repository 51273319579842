import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './common/auth/index.js';
import user from './common/user/index.js';
import filters from './common/filters/index.js';
import userManagement from './modules/userManagement/index.js';
import contractManagement from './modules/contractManagement/index.js';
import contractDetail from './modules/contractDetail/index.js';
import dashboard from './modules/dashboard/index.js';
import loadManagementFilters from './modules/filtersByModules/loadManagementFilters/index.js';
import loadManagement from './modules/loadManagement/index.js';
import financialManagement from './modules/financialManagement/index.js';
import financialManagementFilters from './modules/filtersByModules/financialManagementFilters/index.js';
import docManagement from './modules/docManagement/index.js';
import docManagementFilters from './modules/filtersByModules/docManagementFilters/index.js';
import docInstructionFilters from './modules/filtersByModules/docInstructionFilters/index.js';
import docInstruction from './modules/docInstruction/index.js';
import selectInstruction from './modules/docInstruction/selectInstruction/index.js';

Vue.use(Vuex);

const dataState = createPersistedState({ storage: window.sessionStorage });

const store = new Vuex.Store({
	modules: {
		auth,
		user,
		filters,
		userManagement,
		contractManagement,
		contractDetail,
		dashboard,
		loadManagementFilters,
		loadManagement,
		financialManagement,
		financialManagementFilters,
		docManagement,
		docManagementFilters,
		docInstructionFilters,
		docInstruction,
		selectInstruction
	},
	plugins: [dataState],
});

export default store;
