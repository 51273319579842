<template>
	<div id="docsContractTable">
		<div class="table" v-if="tableRows.length > 0 && isLoadingContractsSequences === 'success'">
			<div class="table-header">
				<div class="table-header__column checkbox">
					<GenericCheckbox id="checkAllItems" :value="checkAll" @input="updateCheckAll" />
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_instruction.doc_table.sequence') }}
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_instruction.doc_table.pod') }}
				</div>
				<div class="table-header__column">
					{{ $t('modules.doc_instruction.doc_table.acro') }}
				</div>
			</div>
			<div class="table-body">
				<div
					v-for="item in tableRows"
					:key="item.seq"
					class="table-body__row"
					:class="{ checked: item.checked }"
				>
					<div class="table-body__column">
						<GenericCheckbox
							:id="item.seq"
							:value="item.checked"
							@input="updateSelectedRow(item)"
						/>
					</div>
					<div class="table-body__column">{{ item.seq }}</div>
					<div class="table-body__column">{{ item.pod }}</div>
					<div class="table-body__column">{{ item.acronym }}</div>
				</div>
			</div>
		</div>
		<div class="loader" v-if="isLoadingContractsSequences !== 'success'">
			<DefaultLoader color="gray" />
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	UPDATE_SELECTED_CONTRACTS_SEQUENCES,
	CLEAR_SELECTED_CONTRACTS_SEQUENCES,
} from '@/store/modules/docInstruction/actions';

import GenericCheckbox from '@/components/shared/GenericCheckbox/GenericCheckbox';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import DefaultLoader from '@/components/shared/DefaultLoader/DefaultLoader.vue';

export default {
	name: 'Table',
	components: {
		GenericCheckbox,
		SpinnerIcon,
		DefaultLoader,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		checkAll: false,
	}),
	computed: {
		...mapGetters('docInstruction', [
			'isLoadingContractsSequences',
			'contractSequenceList',
			'selectedContractsSequence',
		]),
		tableRows() {
			return this.contractSequenceList ?? [];
		},
	},
	watch: {
		selectedContractsSequence(value) {
			if (value.length == 0) {
				this.tableRows.forEach((item) => {
					item.checked = false;
				});
				this.checkAll = false;
			}
		},
	},
	methods: {
		...mapActions('docInstruction', {
			updateSelectedContracts: UPDATE_SELECTED_CONTRACTS_SEQUENCES,
			clearSelectedContracts: CLEAR_SELECTED_CONTRACTS_SEQUENCES,
		}),
		updateCheckAll(newValue) {
			this.checkAll = newValue;

			this.tableRows.forEach((item) => {
				item.checked = this.checkAll;
				this.updateSelectedContracts(item);
			});

			if (!this.checkAll) this.clearSelectedContracts();
		},
		updateSelectedRow(selectedRow) {
			const row = this.tableRows.find((item) => item.seq === selectedRow.seq);

			if (row) {
				row.checked = !row.checked;
			}

			this.updateSelectedContracts(row);

			this.checkAll = this.tableRows.every((obj) => obj.checked);
		},
	},
};
</script>

<style scoped lang="scss">
@import 'Table.scss';
</style>
