import {
	CLEAR_ALL_DOC_FILTERS,

	//INIT_DATE
	SAVE_INIT_DATE_FILTER,
	CLEAR_INIT_DATE_FILTER,

	//END_DATE
	SAVE_END_DATE_FILTER,
	CLEAR_END_DATE_FILTER,

	//LAST_QUERY
	SAVE_LAST_QUERY_STRING,

	//POD_FILTER
	DOC_POD_FILTER_REQUEST,
	DOC_POD_FILTER_REQUEST_SUCCESS,
	DOC_POD_FILTER_REQUEST_ERROR,
	SAVE_POD_CONTRACT_FILTER,
	CLEAR_POD_CONTRACT_FILTER,

	//POD_FILTER
	DOC_DESCRIPTION_FILTER_REQUEST,
	DOC_DESCRIPTION_FILTER_REQUEST_SUCCESS,
	DOC_DESCRIPTION_FILTER_REQUEST_ERROR,
	SAVE_DESCRIPTION_CONTRACT_FILTER,
	CLEAR_DESCRIPTION_CONTRACT_FILTER,

	//CONTRACT_FILTER
	DOC_CONTRACT_FILTER_REQUEST,
	DOC_CONTRACT_FILTER_REQUEST_SUCCESS,
	DOC_CONTRACT_FILTER_REQUEST_ERROR,
	SAVE_DOC_CONTRACT_FILTER,
	CLEAR_DOC_CONTRACT_FILTER,
} from './actions';

import { addUniqueItemsToList } from '@/utils/filtersHelper';
import api from '@/utils/api';

const { http } = api.getInstance();

const BASE_URL = '/contracts';

const defaultState = () => ({
	//init_date
	initDateFilter: [],
	initDateFilterCache: {
		value: [],
	},

	//end_date
	endDateFilter: [],
	endDateFilterCache: {
		value: [],
	},

	//POD_FILTER
	podFilter: [],
	podFilterMeta: [],
	podFilterCache: {
		value: [],
	},
	isLoadingPodFilter: '',

	//DESCRIPTION_FILTER
	descriptionFilter: [],
	descriptionFilterMeta: [],
	descriptionFilterCache: {
		value: [],
	},
	isLoadingDescriptionFilter: '',

	//CONTRACT_FILTER
	contractFilter: [],
	contractFilterMeta: [],
	contractFilterCache: {
		value: [],
	},
	isLoadingContractFilter: '',

	//lastQueryString
	lastQueryString: '',
});

const state = defaultState();

const getters = {
	//init_date
	initDateFilter: (state) => state.initDateFilter,
	initDateFilterCache: (state) => state.initDateFilterCache,

	//end_date
	endDateFilter: (state) => state.endDateFilter,
	endDateFilterCache: (state) => state.endDateFilterCache,

	//POD_FILTER
	podFilter: (state) => state.podFilter,
	podFilterMeta: (state) => state.podFilterMeta,
	podFilterCache: (state) => state.podFilterCache,
	isLoadingPodFilter: (state) => state.isLoadingPodFilter === 'loading',

	//DESCRIPTION_FILTER
	descriptionFilter: (state) => state.descriptionFilter,
	descriptionFilterMeta: (state) => state.descriptionFilterMeta,
	descriptionFilterCache: (state) => state.descriptionFilterCache,
	isLoadingDescriptionFilter: (state) => state.isLoadingDescriptionFilter === 'loading',

	//CONTRACT_FILTER
	contractFilter: (state) => state.contractFilter,
	contractFilterMeta: (state) => state.contractFilterMeta,
	contractFilterCache: (state) => state.contractFilterCache,
	isLoadingContractFilter: (state) => state.isLoadingContractFilter === 'loading',

	//lastQueryString
	lastQueryString: (state) => state.lastQueryString,
};

const mutations = {
	[CLEAR_ALL_DOC_FILTERS]: (state) => {
		state.initDateFilterCache = {
			value: [],
		};
		state.endDateFilterCache = {
			value: [],
		};
		state.podFilterCache = {
			value: [],
		};
		state.descriptionFilterCache = {
			value: [],
		};
		state.contractFilterCache = {
			value: [],
		};
		state.lastQueryString = '';
	},
	//INIT_DATE
	[SAVE_INIT_DATE_FILTER]: (state, data) => {
		state.initDateFilterCache = data;
	},
	[CLEAR_INIT_DATE_FILTER]: (state) => {
		state.initDateFilter = [];
	},
	//END_DATE
	[SAVE_END_DATE_FILTER]: (state, data) => {
		state.endDateFilterCache = data;
	},
	[CLEAR_END_DATE_FILTER]: (state) => {
		state.endDateFilter = [];
	},
	//POD_FILTER
	[DOC_POD_FILTER_REQUEST]: (state) => {
		state.podFilterMeta = [];
		state.isLoadingPodFilter = 'loading';
	},
	[DOC_POD_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.podFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.podFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item, name: item }));
			addUniqueItemsToList(state, formattedItems, 'podFilter', 'name');
		}

		state.isLoadingPodFilter = 'success';
	},
	[DOC_POD_FILTER_REQUEST_ERROR]: (state) => {
		state.podFilter = [];
		state.podFilterMeta = [];
		state.isLoadingPodFilter = 'error';
	},
	[SAVE_POD_CONTRACT_FILTER]: (state, data) => {
		state.podFilterCache = data;
	},
	[CLEAR_POD_CONTRACT_FILTER]: (state) => {
		state.podFilter = [];
	},
	//DESCRIPTION_FILTER
	[DOC_DESCRIPTION_FILTER_REQUEST]: (state) => {
		state.descriptionFilterMeta = [];
		state.isLoadingDescriptionFilter = 'loading';
	},
	[DOC_DESCRIPTION_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.descriptionFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.descriptionFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item, name: item }));
			addUniqueItemsToList(state, formattedItems, 'descriptionFilter', 'name');
		}

		state.isLoadingDescriptionFilter = 'success';
	},
	[DOC_DESCRIPTION_FILTER_REQUEST_ERROR]: (state) => {
		state.descriptionFilter = [];
		state.descriptionFilterMeta = [];
		state.isLoadingDescriptionFilter = 'error';
	},
	[SAVE_DESCRIPTION_CONTRACT_FILTER]: (state, data) => {
		state.descriptionFilterCache = data;
	},
	[CLEAR_DESCRIPTION_CONTRACT_FILTER]: (state) => {
		state.descriptionFilter = [];
	},
	//CONTRACT_FILTER
	[DOC_CONTRACT_FILTER_REQUEST]: (state) => {
		state.contractFilterMeta = [];
		state.isLoadingContractFilter = 'loading';
	},
	[DOC_CONTRACT_FILTER_REQUEST_SUCCESS]: (state, data) => {
		state.contractFilterMeta = data.meta;

		if (data.meta.currentPage === 1) {
			state.contractFilter = [];
		}

		if (data.items && data.items.length) {
			const formattedItems = data.items.map((item) => ({ id: item, name: item }));
			addUniqueItemsToList(state, formattedItems, 'contractFilter', 'name');
		}

		state.isLoadingContractFilter = 'success';
	},
	[DOC_CONTRACT_FILTER_REQUEST_ERROR]: (state) => {
		state.contractFilter = [];
		state.contractFilterMeta = [];
		state.isLoadingContractFilter = 'error';
	},
	[SAVE_DOC_CONTRACT_FILTER]: (state, data) => {
		state.contractFilterCache = data;
	},
	[CLEAR_DOC_CONTRACT_FILTER]: (state) => {
		state.contractFilter = [];
	},
	//LAST QUERY STRING
	[SAVE_LAST_QUERY_STRING]: (state, data) => {
		state.lastQueryString = data;
	},
};

const actions = {
	[CLEAR_ALL_DOC_FILTERS]: ({ commit }) => {
		commit(CLEAR_ALL_DOC_FILTERS);
	},
	//INIT_DATE
	[SAVE_INIT_DATE_FILTER]: ({ commit }, data) => {
		commit(SAVE_INIT_DATE_FILTER, data);
	},
	//END_DATE
	[SAVE_END_DATE_FILTER]: ({ commit }, data) => {
		commit(SAVE_END_DATE_FILTER, data);
	},
	//POD_FILTER
	[DOC_POD_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_POD_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/withoutInstruction/filter/pod?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/withoutInstruction/filter/pod?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/withoutInstruction/filter/pod?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/withoutInstruction/filter/pod?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_POD_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_POD_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_POD_CONTRACT_FILTER]: ({ commit }, data) => {
		commit(SAVE_POD_CONTRACT_FILTER, data);
	},
	//DESCRIPTION_FILTER
	[DOC_DESCRIPTION_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_DESCRIPTION_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/withoutInstruction/filter/sku-description?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('invoice');
				url = `${BASE_URL}/withoutInstruction/filter/sku-description?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/withoutInstruction/filter/sku-description?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('invoice');
				url = `${BASE_URL}/withoutInstruction/filter/sku-description?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_DESCRIPTION_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_DESCRIPTION_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DESCRIPTION_CONTRACT_FILTER]: ({ commit }, data) => {
		commit(SAVE_DESCRIPTION_CONTRACT_FILTER, data);
	},
	//CONTRACT_FILTER
	[DOC_CONTRACT_FILTER_REQUEST]: ({ commit }, { vm, params, skip, lastQuery }) => {
		return new Promise((resolve) => {
			commit(DOC_CONTRACT_FILTER_REQUEST);

			const headerCompanyIdStored = vm.$store.state.filters.headerCompanyFilterCache.value.id;

			let url = `${BASE_URL}/withoutInstruction/filter/contract-sequence?companyId=${headerCompanyIdStored}&page=1&limit=20`;

			if (skip) {
				let newQuery = new URLSearchParams(params);
				newQuery.delete('contract');
				url = `${BASE_URL}/withoutInstruction/filter/contract-sequence?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			} else if (!skip && params) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('contract');
				url = `${BASE_URL}/withoutInstruction/filter/contract-sequence?companyId=${headerCompanyIdStored}&${params}&${newQuery.toString()}`;
			} else if (!skip && !params && lastQuery) {
				let newQuery = new URLSearchParams(lastQuery);
				newQuery.delete('contract');
				url = `${BASE_URL}/withoutInstruction/filter/contract-sequence?companyId=${headerCompanyIdStored}&page=1&limit=20&${newQuery.toString()}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_CONTRACT_FILTER_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_CONTRACT_FILTER_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[SAVE_DOC_CONTRACT_FILTER]: ({ commit }, data) => {
		commit(SAVE_DOC_CONTRACT_FILTER, data);
	},
	//LAST QUERY STRING
	[SAVE_LAST_QUERY_STRING]: ({ commit }, data) => {
		commit(SAVE_LAST_QUERY_STRING, data);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
