export default {
	placeholder: {
		search: 'Search',
	},
	header: {
		contractNumber: 'Contract',
		contractDate: 'Contract Date',
		competenceMonth: 'Competence Month',
		releaseDate: 'Release Date',
		company: 'Company',
		status: 'Stage',
		totalVolume: 'Total Volume',
	},
	detail: {
		actualDateOfBoarding: 'Current Loading Date',
		awbCourier: 'AWB Courier',
		balance: 'Balance',
		billLanding: 'Bill Landing',
		billLandingDate: 'Bill Landing Date',
		blDate: 'Bl Date',
		blDateId: 'Bl Date id',
		bookingNumber: 'Booking Number',
		boardingType: 'Boarding Type',
		borderCrossingDate: 'Border Crossing Date',
		borderPost: 'Border Post',
		borderPostsCode: 'Border Post Code',
		branch: 'Branch',
		brand: 'Brand',
		brandCode: 'Brand Code',
		business: 'Business',
		closedContractSequence: 'Closed Contract Sequence',
		cneCode: 'CNE Code',
		container: 'Container',
		contractSequence: 'Contract Sequence',
		country: 'Country',
		courierDate: 'Post Date',
		courierCompany: 'Courier Company',
		csiIssuanceDate: 'CSI Issuing Date',
		destination: 'Destination',
		destinationPort: 'Destination Port',
		destinationPort4: 'Destination Port 4',
		dsInternationalOfc: 'DS International',
		dueSituationCode: 'Expiration Code',
		endShipDate: 'Last loading Date',
		etaPodDate: 'eta Pod Date',
		etdPolDate: 'etd Pol Date',
		etdRealDate: 'ETD Real Date',
		etsRfsDate: 'ETS RFS Date',
		etsRfsId: 'ETS RFS id',
		expectedEtdDate: 'ETS POL',
		expectedEtbDate: 'ATS POL',
		expectedEtsDate: 'Expected Ets Date',
		gateInDate: 'Gate In Date',
		idBlock: 'Block Id',
		idStatus: 'Status Id',
		incoterm: 'Incoterm',
		initials: 'Product Code',
		invoiceNumber: 'Invoice Number',
		invoicedDate: 'Invoiced Date',
		invoiceIssuanceDate: 'Invoice Issuing Date',
		loadNumber: 'Loading Number',
		loadPlace: 'Load Place',
		loadCode: 'Loading Code',
		loadingDate: 'Loading Date',
		localization: 'Localization',
		market: 'Market',
		groupingDescription: 'Grouping',
		maxGroupingQuantity: 'Max Grouping Quantity',
		minGroupingQuantity: 'Min Grouping Quantity',
		netWeight: 'Net Weitght',
		nfiIssuanceDate: 'NFI Issuing Date',
		origin: 'Origin',
		paymentDeadline: 'Payment Deadline',
		paymentDeadlineCode: 'Payment Deadline Code',
		paymentMethod: 'Payment Method',
		paymentMode: 'Payment Mode',
		permissionImt: 'Permission Imt',
		permissionImtDate: 'Permission Imt Date',
		planningCode: 'Planning Code',
		plannerLoadingDate: 'Loading Planning Date',
		pod: 'POD',
		pol: 'POL',
		priceType: 'Price Type',
		productionMonth: 'Production Month',
		productionDate: 'Production Date',
		productName: 'Product Name',
		provider: 'Provider',
		purchaseOrder: 'Purchase Order',
		purchaseOrderClient: 'Purchase Order Client',
		realAtdDate: 'Real Atd Date',
		realAtbDate: 'ETA POD',
		realArrivalDate: 'Real Arrival Date',
		regional: 'Regional',
		releaseSequenceDate: 'Release Sequence Date',
		restritionCode: 'Restriction Code',
		remarks: 'Remarks',
		sentDraftDate: 'Draft Sending Date',
		scheduledDate: 'Scheduled Date',
		shippingCompany: 'Shipping Company',
		shippingLine: 'Shipping Line',
		sif: 'Sif Fil',
		sku: 'SKU',
		startShipDate: 'Initial Loading Date',
		statusClosure: 'Closing Status',
		statusPrePaid: 'Status Pre Payment',
		status: 'Status Sequence',
		stockLocation: 'Stock Location',
		totalGrossWeight: 'Total Gross Weight',
		totalKg: 'Total Kg',
		totalVolume: 'Total Volume',
		traderName: 'Trader Name',
		unitPrice: 'Unit Price',
		vehiclePlate: 'Vehicle Plate',
		vessel: 'Vessel',
		weeksToShip: 'Weeks To Ship',
		stage: 'Status',
	},
};
