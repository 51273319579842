import { render, staticRenderFns } from "./CloudIcon.vue?vue&type=template&id=20f5fa90&scoped=true"
import script from "./CloudIcon.vue?vue&type=script&lang=js"
export * from "./CloudIcon.vue?vue&type=script&lang=js"
import style0 from "./CloudIcon.vue?vue&type=style&index=0&id=20f5fa90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f5fa90",
  null
  
)

export default component.exports