<template>
	<div id="contract-detail" class="contract-detail">
		<Content>
			<template v-slot:header v-if="!isWeb">
				<div class="mobile-header-detail">
					<div class="mobile-header-detail__info">
						<span> {{ $t('header.header_contracts') }} </span>
						<span> {{ values.contracts }} </span>
						<div class="mobile-header-detail__info--back" @click="goBack()">
							<BackArrow />
						</div>
					</div>
					<div class="mobile-header-detail__info-line">
						<div>
							<span class="title"> {{ $t('header.header_sequences') }}: </span>
							<span class="value"> {{ values.sequences }} </span>
						</div>
						<div>
							<span class="title"> {{ $t('header.header_loads') }}: </span>
							<span class="value"> {{ values.loads }} </span>
						</div>
						<div>
							<span class="title"> {{ $t('header.header_po') }}: </span>
							<span class="value"> {{ values.po }} </span>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:body>
				<ContractStatus :isWeb="isWeb" />
				<ContractDetailContent :isWeb="isWeb" />
				<TrackingCard :isWeb="isWeb" />
			</template>
		</Content>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CONTRACT_DETAIL_REQUEST } from '@/store/modules/contractDetail/actions';
import BackArrow from '@/components/shared/Icons/BackArrow.vue';
import Content from '@/components/shared/Content/Content.vue';
import ContractStatus from '@/components/modules/ContractDetail/ContractStatus/ContractStatus.vue';
import ContractDetailContent from '@/components/modules/ContractDetail/ContractDetailContent/ContractDetailContent.vue';
import TrackingCard from '@/components/modules/ContractDetail/TrackingCard/TrackingCard.vue';

export default {
	name: 'ContractDetail',
	components: {
		BackArrow,
		Content,
		ContractStatus,
		TrackingCard,
		ContractDetailContent,
	},
	data: () => ({
		windowWidth: window.innerWidth,
		showEditHeaderModal: false,
	}),
	created() {
		this.getContractBlocks({ vm: this, id: this.contractId });
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions('contractDetail', {
			getContractBlocks: CONTRACT_DETAIL_REQUEST,
		}),
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		goBack() {
			this.$router.push('/main/contractManagement');
		},
	},
	computed: {
		...mapGetters('contractDetail', ['contracts', 'sequences', 'loads', 'po']),
		contractId() {
			return this.$route.query.id;
		},
		isWeb() {
			return this.windowWidth > 992;
		},
		values() {
			return {
				contracts: this.contracts,
				sequences: this.sequences,
				loads: this.loads,
				po: this.po,
			};
		},
	},
};
</script>

<style lang="scss">
@import 'ContractDetail.scss';
</style>
