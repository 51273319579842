<template>
	<div class="templateHealthCertificate-container">
		<TemplateHeaderItem
			:title="$t('modules.templateData.items.healthCertificate')"
			:showBell="false"
			@handleCollapse="handleCollapse"
		/>

		<div class="templateHealthCertificate-container__content" :class="!isCollapsed ? 'open' : ''">
			<div class="templateHealthCertificate-container__itens">
				<div class="templateHealthCertificate-container__itens--item">
					<h5>{{ $t('modules.templateData.items.consignee') }}</h5>

					<GenericMultiselect
						class="consignee-multiselect"
						ref="consignee"
						:options="consigneeOptions"
						:placeholder="$t('modules.templateData.items.consignee')"
						:searchable="true"
						:needType="true"
						:showIcon="true"
						:infiniteScroll="true"
						:type="'consigneeId'"
						:initialValue="selectedConsignee"
						@changeInput="changeInput"
					/>
					<textarea
						name="consigneeAdress"
						id="consigneeAdress"
						:placeholder="$t('modules.templateData.items.adress')"
						:value="currentConsignee.address"
						@input="handleInputConsigneeAddress"
					></textarea>
				</div>
				<div class="templateHealthCertificate-container__itens--item">
					<h5>{{ $t('modules.templateData.items.responsable') }}</h5>

					<GenericMultiselect
						class="responsable-multiselect"
						ref="responsable"
						:options="responsableOptions"
						:placeholder="$t('modules.templateData.items.responsable')"
						:searchable="true"
						:needType="true"
						:showIcon="true"
						:infiniteScroll="true"
						:type="'responsableId'"
						:initialValue="selectedResponsable"
						@changeInput="changeInput"
					/>
					<textarea
						name="responsableAdress"
						id="responsableAdress"
						:placeholder="$t('modules.templateData.items.adress')"
						v-model="currentResponsable.address"
						@input="handleInputResponsableAddress"
					></textarea>
				</div>
				<!-- <div class="templateHealthCertificate-container__itens--item">
					<h5>{{ $t('modules.templateData.items.countriesOfTransit') }}</h5>

					<textarea
						name="countriesOfTransit"
						id="countriesOfTransit"
						:placeholder="$t('modules.templateData.items.countriesOfTransit')"
					></textarea>
				</div> -->
				<div class="templateHealthCertificate-container__itens--item">
					<h5>{{ $t('modules.templateData.items.observations') }}</h5>

					<textarea
						name="observations"
						id="observations"
						:placeholder="$t('modules.templateData.items.observations')"
						v-model="observations"
					></textarea>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TemplateHeaderItem from '../TemplateHeaderItem/TemplateHeaderItem.vue';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
import {
	SET_TEMPLATE_HEALTH_CERTIFICATE_DATA,
	EDIT_CONSIGNEE_NOTIFY,
} from '@/store/modules/docInstruction/actions';

export default {
	name: 'TemplateHealthCertificate',
	components: {
		TemplateHeaderItem,
		GenericMultiselect,
	},
	data: () => ({
		consigneePage: 1,
		responsablePage: 1,
		isCollapsed: false,
		consignee: {
			id: '',
			name: '',
			address: '',
		},
		responsable: {
			id: '',
			name: '',
			address: '',
		},
		observations: '',
		shippingType: null,
	}),
	props: {
		templateDefault: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	mounted() {
		if (this.isEdition) {
			this.storeToData();
		}
	},
	watch: {
		templateDefault() {
			if (!this.isEdition) {
				this.copyDefaultDataStore();
				this.storeToData();
			}
		},
	},
	methods: {
		...mapActions('docInstruction', {
			setTemplateHealthCertificate: SET_TEMPLATE_HEALTH_CERTIFICATE_DATA,
			editConsignee: EDIT_CONSIGNEE_NOTIFY,
		}),
		handleCollapse() {
			this.isCollapsed = !this.isCollapsed;
		},
		changeInput(param) {
			if (param.type === 'consigneeId') {
				if (!param.value) {
					this.consignee = {
						id: '',
						name: '',
						address: '',
					};
					return;
				}

				this.consignee.id = param.value.id ?? '';
				this.consignee.name = param.value.name ?? '';
				this.consignee.address = this.getAddress(param.value);
				this.editConsignee(this.consignee);
				return;
			}

			if (param.type === 'responsableId') {
				if (!param.value) {
					this.responsable = {
						id: '',
						name: '',
						address: '',
					};
					return;
				}

				this.responsable.id = param.value.id ?? '';
				this.responsable.name = param.value.name ?? '';
				this.responsable.address = this.getAddress(param.value);
				this.editConsignee(this.responsable);
				return;
			}
		},
		getAddress({ name }) {
			const client =
				this.editedConsigneeNotify.find((client) => client.name === name) ??
				this.clientsList.find((client) => client.name === name);
			return client?.address;
		},
		storeToData() {
			this.consignee = this.templateData.healthCertificate.consignee;
			this.responsable = this.templateData.healthCertificate.responsable;
			this.observations = this.templateData.healthCertificate.observations;
			this.shippingType = this.templateData.healthCertificate.shippingType;
		},
		handleInputConsigneeAddress(event) {
			const { value } = event.target;
			this.consignee.address = value;
			this.editConsignee(this.consignee);
		},
		handleInputResponsableAddress(event) {
			const { value } = event.target;
			this.responsable.address = value;
			this.editConsignee(this.responsable);
		},
		// esse método é invocado pelo pai
		getForm() {
			const healthCertificate = {
				instructionType: this.templateDefault.instructionType,
				instructionLetter: this.templateDefault.instructionLetter,
				customerType: this.templateDefault.customerType,
				consignee: {
					id: this.consignee.id < 0 ? null : this.consignee.id,
					name: this.consignee.name,
					address: this.currentConsignee.address,
				},
				responsable: {
					id: this.responsable.id < 0 ? null : this.responsable.id,
					name: this.responsable.name,
					address: this.currentResponsable.address,
				},
				observations: this.observations,
				shippingType: this.shippingType,
			};

			return healthCertificate;
		},
		copyDefaultDataStore() {
			const healthCertificate = {
				instructionType: this.templateDefault.instructionType,
				instructionLetter: this.templateDefault.instructionLetter,
				customerType: this.templateDefault.customerType,
				consignee: this.templateDefault.consignee,
				responsable: this.templateDefault.responsable,
				observations: this.templateDefault.observations,
				shippingType: this.templateDefault.shippingType,
			};

			this.setTemplateHealthCertificate(healthCertificate);
		},
	},
	computed: {
		...mapGetters('docInstruction', [
			'clientsList',
			'isLoadingClients',
			'editedConsigneeNotify',
			'templateData',
		]),
		isEdition() {
			return this.$route.params.id !== undefined;
		},
		consigneeOptions() {
			return (
				this.clientsList.map((item, index) => {
					return { id: item.id || -index - 1, name: item.name };
				}) ?? []
			);
		},
		responsableOptions() {
			return (
				this.clientsList.map((item, index) => {
					return { id: item.id || -index - 1, name: item.name };
				}) ?? []
			);
		},
		selectedConsignee: {
			set({ id, name }) {
				this.consignee.id = id;
				this.consignee.name = name;
			},
			get() {
				return {
					id: this.consignee.id,
					name: this.consignee.name,
				};
			},
		},
		selectedResponsable: {
			set({ id, name }) {
				this.responsable.id = id;
				this.responsable.name = name;
			},
			get() {
				return {
					id: this.consignee.id,
					name: this.responsable.name,
				};
			},
		},
		currentConsignee() {
			return (
				this.editedConsigneeNotify.find((consignee) => consignee.name === this.consignee.name) ??
				this.consignee
			);
		},
		currentResponsable() {
			return (
				this.editedConsigneeNotify.find((consignee) => consignee.name === this.responsable.name) ??
				this.responsable
			);
		},
	},
};
</script>

<style lang="scss">
@import 'TemplateHealthCertificate.scss';
</style>
