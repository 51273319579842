<template>
	<div class="templateBillOfLanding-container">
		<TemplateHeaderItem
			:title="$t('modules.templateData.items.billOfLanding')"
			:showBell="false"
			@handleCollapse="handleCollapse"
		/>

		<div class="templateBillOfLanding-container__content" :class="!isCollapsed ? 'open' : ''">
			<div class="templateBillOfLanding-container__itens">
				<div class="templateBillOfLanding-container__itens--item">
					<div class="behalf">
						<GenericCheckbox id="checkBehalf" :value="behalf" @input="updateBehalf" />
						<span>{{ $t('modules.templateData.items.behalf') }}</span>
					</div>
				</div>
			</div>
			<div class="templateBillOfLanding-container__itens">
				<div class="templateBillOfLanding-container__itens--item">
					<h5>{{ $t('modules.templateData.items.consignee') }}</h5>

					<GenericMultiselect
						class="consignee-multiselect"
						ref="consignee"
						:options="consigneeOptions"
						:placeholder="$t('modules.templateData.items.consignee')"
						:searchable="true"
						:needType="true"
						:showIcon="true"
						:infiniteScroll="true"
						:type="'consigneeId'"
						:initialValue="selectedConsignee"
						@changeInput="changeInput"
					/>
					<textarea
						name="consigneeAdress"
						id="consigneeAdress"
						:placeholder="$t('modules.templateData.items.adress')"
						:value="currentConsignee.address"
						@input="handleInputConsigneeAddress"
					></textarea>
				</div>
				<div class="templateBillOfLanding-container__itens--item">
					<h5>{{ $t('modules.templateData.items.notify') }}</h5>

					<GenericMultiselect
						class="notify-multiselect"
						ref="notify"
						:options="notifyOptions"
						:placeholder="$t('modules.templateData.items.notify')"
						:searchable="true"
						:needType="true"
						:showIcon="true"
						:infiniteScroll="true"
						:type="'notifyId'"
						:initialValue="selectedNotify"
						@changeInput="changeInput"
					/>
					<textarea
						name="notifyAdress"
						id="notifyAdress"
						:placeholder="$t('modules.templateData.items.adress')"
						@input="handleInputNotifyAddress"
						:value="currentNotify.address"
					></textarea>
				</div>
				<div class="templateBillOfLanding-container__itens--item">
					<h5>{{ $t('modules.templateData.items.secondNotify') }}</h5>

					<GenericMultiselect
						class="notify-multiselect"
						ref="secondNotify"
						:options="secondNotifyOptions"
						:placeholder="$t('modules.templateData.items.secondNotify')"
						:searchable="true"
						:needType="true"
						:showIcon="true"
						:infiniteScroll="true"
						:type="'secondNotifyId'"
						:initialValue="selectedSecondNotify"
						@changeInput="changeInput"
					/>
					<textarea
						name="notifyAdress"
						id="notifyAdress"
						:placeholder="$t('modules.templateData.items.adress')"
						@input="handleInputSecondNotifyAddress"
						:value="currentSecondNotify.address"
					></textarea>
				</div>
			</div>
			<div class="templateBillOfLanding-container__itens">
				<div class="templateBillOfLanding-container__itens--item">
					<h5>{{ $t('modules.templateData.items.freigtClause') }}</h5>
					<div class="checkbox">
						<GenericCheckbox id="checkAllItems" :value="telexRelease" @input="updateTelexRelease" />
						<span>{{ $t('modules.templateData.items.telexRelease') }}</span>
					</div>

					<textarea
						name="freigtClause"
						id="freigtClause"
						:placeholder="$t('modules.templateData.items.freigtClause')"
						v-model="shippingPayment"
					></textarea>
				</div>
				<div class="templateBillOfLanding-container__itens--item">
					<h5>{{ $t('modules.templateData.items.observations') }}</h5>

					<textarea
						name="observations"
						id="observations"
						:placeholder="$t('modules.templateData.items.observations')"
						v-model="observations"
					></textarea>
				</div>
			</div>
		</div>
		<WarningModal
			:title="$t('modules.templateData.requests_messages.template_error')"
			:btnLabel="'confirm'"
			:isWeb="isWeb"
			:hasCancelButton="false"
			v-show="openWarning"
			class="exit"
			@confirmWarning="confirmWarning"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import GenericCheckbox from '@/components/shared/GenericCheckbox/GenericCheckbox';
import TemplateHeaderItem from '../TemplateHeaderItem/TemplateHeaderItem.vue';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
import {
	SET_TEMPLATE_BILL_LANDING_DATA,
	EDIT_CONSIGNEE_NOTIFY,
} from '@/store/modules/docInstruction/actions';
import WarningModal from '@/components/shared/Modals/WarningModal/WarningModal.vue';

export default {
	name: 'TemplateBillOfLanding',
	components: {
		TemplateHeaderItem,
		GenericMultiselect,
		GenericCheckbox,
		WarningModal,
	},
	props: {
		isWeb: {
			required: true,
		},
		templateDefault: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data: () => ({
		consigneePage: 1,
		notifyPage: 1,
		secondNotifyPage: 1,
		checkAll: false,
		isCollapsed: false,
		consignee: {
			id: '',
			name: '',
			address: '',
		},
		notify: {
			id: '',
			name: '',
			address: '',
		},
		secondNotify: {
			id: '',
			name: '',
			address: '',
		},
		shippingPayment: '',
		observations: '',
		shippingType: null,
		releaseType: null,
		behalf: false,
		telexRelease: false,
		openWarning: false,
	}),
	mounted() {
		if (this.isEdition) {
			this.storeToData();
		}
	},
	watch: {
		templateDefault(value) {
			this.storeToData();
			if (!this.isEdition) {
				this.copyDefaultDataStore();
			}
		},
		telexRelease(newValue) {
			this.telexRelease = newValue;
			this.releaseType = null;

			if (this.behalf) {
				this.releaseType = 1;
			}

			if (this.telexRelease) {
				this.releaseType = 2;
			}

			if (this.telexRelease && this.behalf) {
				this.releaseType = 99;
			}

			if (!this.telexRelease && !this.behalf) {
				this.releaseType = null;
			}
		},
		behalf(newValue) {
			this.behalf = newValue;
			this.releaseType = null;

			if (this.behalf) {
				this.releaseType = 1;
			}

			if (this.telexRelease) {
				this.releaseType = 2;
			}

			if (this.telexRelease && this.behalf) {
				this.releaseType = 99;
			}

			if (!this.telexRelease && !this.behalf) {
				this.releaseType = null;
			}
		},
	},
	methods: {
		...mapActions('docInstruction', {
			setTemplateBillLanding: SET_TEMPLATE_BILL_LANDING_DATA,
			editConsignee: EDIT_CONSIGNEE_NOTIFY,
		}),
		handleCollapse() {
			this.isCollapsed = !this.isCollapsed;
		},
		changeInput(param) {
			if (param.type === 'consigneeId') {
				if (!param.value) {
					this.consignee = {
						id: '',
						name: '',
						address: '',
					};
					return;
				}

				this.consignee.id = param.value.id ?? '';
				this.consignee.name = param.value.name ?? '';
				this.consignee.address = this.getAddress(param.value);
				this.editConsignee(this.consignee);
				return;
			} else if (param.type === 'notifyId') {
				if (!param.value) {
					this.notify = {
						id: '',
						name: '',
						address: '',
					};
					return;
				}

				this.notify.id = param.value.id ?? '';
				this.notify.name = param.value.name ?? '';
				this.notify.address = this.getAddress(param.value);
				this.editConsignee(this.notify);
				return;
			} else if (param.type === 'secondNotifyId') {
				if (!param.value) {
					this.secondNotify = {
						id: '',
						name: '',
						address: '',
					};
					return;
				}

				this.secondNotify.id = param.value.id ?? '';
				this.secondNotify.name = param.value.name ?? '';
				this.secondNotify.address = this.getAddress(param.value);
				this.editConsignee(this.secondNotify);
				return;
			}
		},
		updateBehalf(value) {
			this.behalf = value;
		},
		updateTelexRelease(value) {
			this.telexRelease = value;
		},
		getAddress({ name }) {
			const client =
				this.editedConsigneeNotify.find((client) => client.name === name) ??
				this.clientsList.find((client) => client.name === name);
			return client?.address;
		},
		storeToData() {
			this.consignee = this.templateDefault.consignee;
			this.notify = this.templateDefault.notify;
			this.secondNotify = this.templateDefault.secondNotify;

			if (this.templateDefault.releaseType == 1) {
				this.behalf = true;
			}

			if (this.templateDefault.releaseType == 2) {
				this.telexRelease = true;
			}

			if (this.templateDefault.releaseType == 99) {
				this.telexRelease = true;
				this.behalf = true;
			}

			this.observations = this.templateDefault.observations;
			this.shippingType = this.templateDefault.shippingType;
		},
		handleInputNotifyAddress(event) {
			const { value } = event.target;
			this.notify.address = value;
			this.editConsignee(this.notify);
		},
		handleInputConsigneeAddress(event) {
			const { value } = event.target;
			this.consignee.address = value;
			this.editConsignee(this.consignee);
		},
		handleInputSecondNotifyAddress(event) {
			const { value } = event.target;
			this.secondNotify.address = value;
			this.editConsignee(this.secondNotify);
		},
		// esse método é invocado pelo pai
		getForm() {
			const billLanding = {
				instructionType: this.templateDefault.instructionType,
				instructionLetter: this.templateDefault.instructionLetter,
				customerType: this.templateDefault.customerType,
				consignee: {
					id: this.consignee.id < 0 ? null : this.consignee.id,
					name: this.consignee.name,
					address: this.currentConsignee.address,
				},
				notify: {
					id: this.notify.id < 0 ? null : this.notify.id,
					name: this.notify.name,
					address: this.currentNotify.address,
				},
				secondNotify: {
					id: this.secondNotify.id < 0 ? null : this.secondNotify.id,
					name: this.secondNotify.name,
					address: this.currentSecondNotify.address,
				},
				releaseType: this.releaseType,
				shippingPayment: this.shippingPayment,
				observations: this.observations,
				shippingType: this.shippingType,
			};

			return billLanding;
		},
		copyDefaultDataStore() {
			const billLanding = {
				instructionType: this.templateDefault.instructionType,
				instructionLetter: this.templateDefault.instructionLetter,
				customerType: this.templateDefault.customerType,
				consignee: this.templateDefault.consignee,
				notify: this.templateDefault.notify,
				secondNotify: this.templateDefault.secondNotify,
				releaseType: this.templateDefault.releaseType,
				shippingPayment: this.templateDefault.shippingPayment,
				observations: this.templateDefault.observations,
				shippingType: this.templateDefault.shippingType,
			};

			this.setTemplateBillLanding(billLanding);
		},
		confirmWarning() {
			this.openWarning = false;
		},
	},
	computed: {
		...mapGetters('docInstruction', ['clientsList', 'isLoadingClients', 'editedConsigneeNotify']),
		isEdition() {
			return this.$route.params.id !== undefined;
		},
		consigneeOptions() {
			return (
				this.clientsList.map((item, index) => {
					return { id: item.id || -index - 1, name: item.name };
				}) ?? []
			);
		},
		notifyOptions() {
			return (
				this.clientsList.map((item, index) => {
					return { id: item.id || -index - 1, name: item.name };
				}) ?? []
			);
		},
		secondNotifyOptions() {
			return (
				this.clientsList.map((item, index) => {
					return { id: item.id || -index - 1, name: item.name };
				}) ?? []
			);
		},
		selectedConsignee: {
			set({ id, name }) {
				this.consignee.id = id;
				this.consignee.name = name;
			},
			get() {
				return {
					id: this.consignee.id,
					name: this.consignee.name,
				};
			},
		},
		selectedNotify: {
			set({ id, name }) {
				this.notify.id = id;
				this.notify.name = name;
			},
			get() {
				return {
					id: this.notify.id,
					name: this.notify.name,
				};
			},
		},
		selectedSecondNotify: {
			set({ id, name }) {
				this.secondNotify.id = id;
				this.secondNotify.name = name;
			},
			get() {
				return {
					id: this.secondNotify.id,
					name: this.secondNotify.name,
				};
			},
		},
		currentNotify() {
			return (
				this.editedConsigneeNotify.find((notify) => notify.name === this.notify.name) ??
				this.clientsList.find((notify) => notify.name === this.notify.name) ??
				this.notify
			);
		},
		currentConsignee() {
			return (
				this.editedConsigneeNotify.find((consignee) => consignee.name === this.consignee.name) ??
				this.clientsList.find((consignee) => consignee.name === this.consignee.name) ??
				this.consignee
			);
		},
		currentSecondNotify() {
			return (
				this.editedConsigneeNotify.find((secondNotify) => secondNotify.name === this.secondNotify.name) ??
				this.clientsList.find((secondNotify) => secondNotify.name === this.secondNotify.name) ??
				this.secondNotify
			);
		},
	},
};
</script>

<style lang="scss">
@import 'TemplateBillOfLanding.scss';
</style>
