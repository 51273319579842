<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 16 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.59996 0C0.72543 0 0 0.72543 0 1.59996V19.8857C0 20.7602 0.72543 21.4857 1.59996 21.4857H14.4C15.2745 21.4857 16 20.7602 16 19.8857V1.59996C16 0.72543 15.2745 0 14.4 0H1.59996ZM1.59996 1.37151H14.4C14.5385 1.37151 14.6286 1.46159 14.6286 1.60015V19.8859C14.6286 20.0243 14.5385 20.1145 14.4 20.1145H1.59996C1.46141 20.1145 1.37133 20.0245 1.37133 19.8859V1.60015C1.37133 1.4616 1.46141 1.37151 1.59996 1.37151ZM3.42858 3.42858C3.04991 3.42858 2.74282 3.73566 2.74282 4.11433C2.74282 4.493 3.0499 4.80009 3.42858 4.80009H12.5715C12.9501 4.80009 13.2572 4.49317 13.2572 4.11433C13.2572 3.73567 12.9501 3.42858 12.5715 3.42858H3.42858ZM3.42858 7.54287C3.04991 7.54287 2.74282 7.84995 2.74282 8.22863C2.74282 8.60731 3.0499 8.91439 3.42858 8.91439H12.5715C12.9501 8.91439 13.2572 8.60747 13.2572 8.22863C13.2572 7.84996 12.9501 7.54287 12.5715 7.54287H3.42858ZM3.42858 11.6572C3.04991 11.6572 2.74282 11.9642 2.74282 12.3429C2.74282 12.7216 3.0499 13.0287 3.42858 13.0287H12.5715C12.9501 13.0287 13.2572 12.7218 13.2572 12.3429C13.2572 11.9643 12.9501 11.6572 12.5715 11.6572H3.42858ZM13.4498 13.4786C13.2744 13.4893 13.1042 13.5704 12.9855 13.7001L10.4354 16.4501L9.09965 15.4501C8.81409 15.2275 8.35046 15.2882 8.13169 15.5767C7.91294 15.8652 7.97974 16.3279 8.27104 16.5429L10.0997 17.9144C10.367 18.1164 10.7851 18.0805 11.0139 17.8358L13.9853 14.6358C14.1733 14.4381 14.2251 14.123 14.1107 13.8753C13.996 13.6277 13.7221 13.4633 13.4498 13.4788L13.4498 13.4786ZM3.42858 15.7713C3.04991 15.7713 2.74282 16.0784 2.74282 16.4571C2.74282 16.8358 3.0499 17.1429 3.42858 17.1429H6.85716C7.23582 17.1429 7.54291 16.8359 7.54291 16.4571C7.54291 16.0784 7.23583 15.7713 6.85716 15.7713H3.42858Z"
			:fill="color"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#B47D4A',
		},
		height: {
			default: '22',
		},
		width: {
			default: '16',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
