<template>
	<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.73418 4.1318C2.33418 1.5368 6.24168 1.5368 6.83418 4.1318C7.18668 5.6543 6.21918 6.9443 5.37918 7.7468C4.76418 8.3318 3.79668 8.3243 3.18168 7.7468C2.34918 6.9443 1.38168 5.6543 1.73418 4.1318ZM11.4842 13.1318C12.0842 10.5368 16.0142 10.5368 16.6142 13.1318C16.9667 14.6543 15.9992 15.9443 15.1517 16.7468C14.5367 17.3318 13.5617 17.3243 12.9467 16.7468C12.0992 15.9443 11.1317 14.6543 11.4842 13.1318Z"
			:stroke="stroke"
		/>
		<path
			d="M9.18142 4.43176H11.1914C12.5789 4.43176 13.2239 6.14926 12.1814 7.06426L6.18892 12.3068C5.14642 13.2143 5.79142 14.9318 7.17142 14.9318H9.18142"
			:stroke="stroke"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M4.2959 4.80676H4.3049M14.0459 13.8068H14.0549"
			:stroke="stroke"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#B47D4A',
		},
	},
	computed: {
		stroke() {
			switch (this.color) {
				case 'red':
					return '#c8a1a1';

				case 'green':
					return '#96c695';

				case 'orange':
					return '#ab896a';

				case 'gray':
					return '#678aa3';

				default:
					return '#96C695';
			}
		},
	},
};
</script>
<style lang="scss" scoped></style>
