<template>
	<div class="version">
		<span> v0.0.92 </span>
	</div>
</template>

<script>
export default {
	name: 'Version',
	components: {},
	data: () => ({}),
	mounted() {},
	methods: {},
	computed: {},
};
</script>

<style lang="scss" scoped>
@import 'Version.scss';
</style>
