<template>
	<ModalStructure
		id="user-modal"
		:scroll="true"
		:width="!showActiveConfirmation ? width : smallWidth"
		:height="!showActiveConfirmation ? height : smallHeight"
	>
		<template v-if="!isWeb" class="title" v-slot:title>
			<h1 class="title__text">
				{{
					selectedUser
						? $t('modules.user_management.modal.edit_user')
						: $t('modules.user_management.modal.new_user')
				}}
			</h1>
		</template>
		<template v-slot:header>
			<div class="row header" v-show="isWeb && !isLoading && !showActiveConfirmation">
				<button id="cancel-user-modal" class="header__btn" @click="handleModal()">
					{{ $t('buttons.cancel') }}
				</button>
				<button id="confirm-user-midal" class="header__btn" @click="selectAction(true)">
					{{ $t('buttons.save') }}
				</button>
			</div>
		</template>
		<template v-slot:body>
			<div class="body" v-show="!isLoading && !showActiveConfirmation">
				<div class="info-container">
					<label class="info-container__label" for="user-name">
						{{ $t('modules.user_management.modal.name_label') }}
					</label>
					<input
						class="info-container__input"
						id="user-name"
						type="text"
						:placeholder="$t('modules.user_management.modal.name_placeholder')"
						v-model="form.name"
					/>
				</div>
				<div class="info-container">
					<label class="info-container__label" for="user-company">
						{{ $t('modules.user_management.modal.company_label') }}
					</label>
					<input
						class="info-container__input"
						id="user-company"
						type="text"
						:placeholder="$t('modules.user_management.modal.company_placeholder')"
						v-model="form.companyName"
					/>
				</div>
				<div class="info-container">
					<label class="info-container__label" for="user-email">
						{{ $t('modules.user_management.modal.email_label') }}
					</label>
					<input
						class="info-container__input"
						id="user-email"
						type="text"
						:placeholder="$t('modules.user_management.modal.email_placeholder')"
						v-model="form.email"
					/>
				</div>
				<div class="info-container">
					<label class="info-container__label" for="user-number">
						{{ $t('modules.user_management.modal.phone_label') }}
					</label>
					<input
						class="info-container__input"
						id="user-number"
						type="text"
						:placeholder="$t('modules.user_management.modal.phone_placeholder')"
						v-model="form.phoneNumber"
						v-mask="'+## (##) #####-####'"
					/>
				</div>
				<div
					class="info-container"
					v-show="selectedUser == null || (selectedUser != null && selectedUser.type != 'internal')"
				>
					<label class="info-container__label" for="user-profile">
						{{ $t('modules.user_management.modal.profile_label') }}
					</label>
					<GenericMultiselect
						class="profile-multiselect"
						ref="type"
						:options="typeOptions"
						:placeholder="$t('modules.user_management.modal.profile_placeholder')"
						:fullWidth="true"
						:searchable="true"
						:needType="true"
						:showIcon="true"
						:type="'type'"
						@changeInput="changeInput"
					/>
				</div>
				<div class="info-container" v-show="isNotInternal">
					<label class="info-container__label" for="user-status">
						{{ $t('modules.user_management.modal.status_label') }}
					</label>
					<GenericMultiselect
						class="profile-multiselect"
						ref="statusMultiselect"
						:options="statusOptions"
						:placeholder="$t('modules.user_management.modal.status_placeholder')"
						:fullWidth="true"
						:searchable="true"
						:needType="true"
						:showIcon="true"
						:type="'active'"
						@changeInput="changeInput"
					/>
				</div>
				<div class="info-container">
					<label class="info-container__label" for="user-company-manager">
						{{ $t('modules.user_management.modal.company_manager_label') }}
					</label>
					<CompanyInput
						class="profile-multiselect"
						ref="companyMultiselect"
						:isMultiple="true"
						:isWeb="isWeb"
						:isFullwidth="true"
						:isModal="true"
						@changeInput="changeInput"
					/>
				</div>
			</div>
			<div class="body warning" v-show="!isLoading && showActiveConfirmation">
				<WarningIcon class="warning-icon" :color="'#234057'" />
				<span
					class="warning-text"
					v-html="$t('modules.user_management.modal.confirmation_active')"
				/>
			</div>
			<div class="loader" v-if="isLoading">
				<SpinnerIcon :size="60" />
			</div>
		</template>
		<template v-slot:footer>
			<div class="footer" v-show="!isWeb && !isLoading && !showActiveConfirmation">
				<button id="cancel-user-modal" class="footer__btn outline" @click="handleModal()">
					{{ $t('buttons.cancel') }}
				</button>
				<button id="confirm-user-modal" class="footer__btn" @click="selectAction(true)">
					{{ $t('buttons.save') }}
				</button>
			</div>
			<div class="footer warning" v-show="!isLoading && showActiveConfirmation">
				<button id="keep-inactive" class="footer__btn outline" @click="handleActive()">
					{{ $t('buttons.keep_inactive') }}
				</button>
				<button id="keep-active" class="footer__btn primary" @click="selectAction(false)">
					{{ $t('buttons.keep_active') }}
				</button>
			</div>
		</template>
	</ModalStructure>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CREATE_USER_MANAGEMENT,
	UPDATE_USER_MANAGEMENT,
} from '@/store/modules/userManagement/actions';
import { COMPANY_FILTER_REQUEST_ALL } from '@/store/common/filters/actions';
import { mask } from 'vue-the-mask';

import ModalStructure from '@/components/shared/Modals/ModalStructure/ModalStructure.vue';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
import CompanyInput from '@/components/modules/UserManagement/Filter/Inputs/CompanyInput/CompanyInput.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon.vue';
import WarningIcon from '@/components/shared/Icons/WarningIcon.vue';

export default {
	name: 'UserModal',
	components: { ModalStructure, GenericMultiselect, CompanyInput, SpinnerIcon, WarningIcon },
	directives: { mask },
	props: {
		isWeb: {
			required: true,
		},
		selectedUser: {
			required: false,
		},
	},
	data: () => ({
		page: 1,
		isLoading: false,
		triggerActiveConfirmation: false,
		showActiveConfirmation: false,
		form: {
			name: null,
			companyName: null,
			email: null,
			phoneNumber: null,
			type: null,
			active: false,
			companies: [],
		},
	}),
	mounted() {
		this.$refs.statusMultiselect.setValue({
			id: false,
			name: this.$t('modules.user_management.status.suspended'),
		});
	},
	methods: {
		...mapActions('userManagement', {
			saveUserReq: CREATE_USER_MANAGEMENT,
			editUserReq: UPDATE_USER_MANAGEMENT,
		}),
		...mapActions('filters', {
			getCompanyFilter: COMPANY_FILTER_REQUEST_ALL,
		}),
		handleModal() {
			this.$emit('handleModal');
			this.$emit('clearUser');
			this.clearModal();
		},
		handleActive() {
			this.triggerActiveConfirmation = false;
			this.showActiveConfirmation = false;
			this.form.active = false;
			this.$refs.statusMultiselect.setValue({
				id: false,
				name: this.$t('modules.user_management.status.suspended'),
			});
		},
		clearModal() {
			this.triggerActiveConfirmation = false;
			this.showActiveConfirmation = false;
			this.form.name = null;
			this.form.companyName = null;
			this.form.email = null;
			this.form.phoneNumber = null;
			this.form.type = null;
			this.form.active = null;
			this.$refs.statusMultiselect.setValue({
				id: false,
				name: this.$t('modules.user_management.status.suspended'),
			});
			this.$refs.type.setValue(null);
			this.$refs.companyMultiselect.setValue(null);
		},
		selectAction(verifyUpdate) {
			if (!this.validateForm()) {
				return this.$toastr.e(this.$t('errors.fill_fields'));
			}

			if (!this.validEmail(this.form.email)) {
				return this.$toastr.e(this.$t('errors.email_invalid'));
			}

			if (this.triggerActiveConfirmation && verifyUpdate) {
				this.showActiveConfirmation = true;
				return;
			}

			this.selectedUser ? this.editUser() : this.saveUser();
			this.showActiveConfirmation = false;
			this.isLoading = true;
		},
		saveUser() {
			const body = this.form;

			this.saveUserReq({ vm: this, body })
				.then((res) => {
					this.isLoading = false;

					if (res.status == 201) {
						this.handleModal();
						this.$emit('refreshUsers');
						this.$emit('refreshFilters');
					}
				})
				.catch(() => {
					this.isLoading = false;
				});
		},
		editUser() {
			const id = this.selectedUser.uuid;
			const body = this.form;

			if (this.selectedUser.type == 'internal') {
				delete body.type;
			}

			this.editUserReq({ vm: this, id, body })
				.then((res) => {
					this.isLoading = false;

					if (res.status == 200) {
						this.handleModal();
						this.$emit('refreshUsers', 'sort');
						this.$emit('refreshFilters');
					}
				})
				.catch(() => {
					this.isLoading = false;
				});
		},
		changeInput(param) {
			this.page = 1;

			if (param.type == 'company') {
				return (this.form['companies'] = param.value ? param.value.map((item) => item.id) : []);
			}

			if (
				param.type == 'active' &&
				param.value.id != this.form['active'] &&
				param.value.id == true
			) {
				this.triggerActiveConfirmation = true;
			}

			this.form[param.type] = param.value ? param.value.id : null;
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getCompanyFilter({ vm: this, params: query });
		},
		nextPage() {
			const meta = this.companyFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getCompanyFilter({ vm: this, params: query });
		},
		createQueryString(name) {
			const query = new URLSearchParams();

			if (name) query.set('name', name);
			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		validateForm() {
			if (
				!this.form.name ||
				!this.form.companyName ||
				!this.form.email ||
				!this.form.phoneNumber ||
				!this.form.type ||
				this.form.active == null
			) {
				return false;
			}

			return true;
		},
		validEmail(param) {
			const reg = /\S+@\S+\.\S+/;
			return reg.test(param);
		},
	},
	computed: {
		...mapGetters('filters', ['companyFilter', 'companyFilterMeta', 'isLoadingCompanyFilter']),
		height() {
			return this.isWeb && this.selectedUser != null && this.selectedUser.type == 'internal'
				? '540px'
				: this.isWeb
				? '640px'
				: '100%';
		},
		width() {
			return this.isWeb ? '640px' : '100%';
		},
		smallHeight() {
			return this.isWeb ? '290px' : '100%';
		},

		smallWidth() {
			return this.isWeb ? '570px' : '100%';
		},
		typeOptions() {
			return [
				{
					id: 'client',
					name: this.$t('modules.user_management.profiles.client'),
				},
				{
					id: 'intermediary',
					name: this.$t('modules.user_management.profiles.intermediary'),
				},
				{
					id: 'internal',
					name: this.$t('modules.user_management.profiles.internal'),
				},
			];
		},
		statusOptions() {
			return [
				{
					id: true,
					name: this.$t('modules.user_management.status.active'),
				},
				{
					id: false,
					name: this.$t('modules.user_management.status.suspended'),
				},
			];
		},
		isNotInternal() {
			return this.selectedUser === null || this.selectedUser?.type !== 'internal';
		},
	},
	watch: {
		selectedUser(newValue, oldValue) {
			if (newValue) {
				this.form.name = newValue.name;
				this.form.companyName = newValue.companyName;
				this.form.email = newValue.email ? newValue.email : oldValue;
				this.form.phoneNumber = newValue.phoneNumber;
				this.form.type = newValue.type;
				this.form.active = newValue.active;
				this.form.companies = newValue.companies;
				this.$refs.statusMultiselect.setValue(
					this.statusOptions.filter((item) => {
						return item.id == newValue.active;
					}),
				);
				this.$refs.type.setValue(
					this.typeOptions.filter((item) => {
						return item.id == newValue.type;
					}),
				);
				this.$refs.companyMultiselect.setValue(newValue.companies);
			} else {
				this.clearModal();
			}
		},
	},
};
</script>
<style scoped lang="scss">
@import 'UserModal.scss';
</style>
