<template>
	<transition name="modal">
		<div class="container">
			<div class="wrapper">
				<div class="modal">
					<div class="modal__body">
						<WarningIcon class="modal__body-icon" :color="'#234057'" />
						<span class="modal__body-text">
							{{ title }}
						</span>
					</div>

					<div class="modal__footer">
						<button class="btn btn-cancel" @click="$emit('closeDelete')">Cancelar</button>
						<button class="btn btn-confirm" @click="$emit('confirmDelete')">Reativar</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import WarningIcon from '@/components/shared/Icons/WarningIcon.vue';
export default {
	name: 'ActiveModal',
	components: { WarningIcon },
	props: {
		title: {
			required: true,
		},
	},
	computed: {},
};
</script>
<style scoped lang="scss">
@import 'ActiveModal.scss';
</style>