<template>
	<div class="chart-container holder">
		<template v-for="status in data">
			<div
				v-if="status.amount != 0"
				class="chart-container"
				:key="status.phase"
				:class="[
					status.phase == 'Contrato' ? 'chart-container__contract' : '',
					status.phase == 'Carga' ? 'chart-container__load' : '',
					status.phase == 'Processo' ? 'chart-container__process' : '',
					status.phase == 'Container' ? 'chart-container__container' : '',
					status.phase == 'Invoice' ? 'chart-container__invoice' : '',
					status.phase == 'Contrato*' ? 'chart-container__finished' : '',
				]"
				:style="{ width: `${status.percentage}%` }"
				v-tooltip.top-center="{
					content: getTemplate(status.phase, status.amount),
				}"
			/>
		</template>
	</div>
</template>

<script>
export default {
	name: 'StatusChart',
	components: {},
	props: {
		isWeb: {
			required: true,
		},
		data: {
			required: true,
		},
	},
	data: () => ({}),
	methods: {
		getTemplate(param, amount) {
			const label = this.getText(param);
			const color = this.getColor(param);

			const template = `
			<div class="custom-tooltip">
					<span class="custom-tooltip__text" style="color: ${color}">
					${this.$t(`contract_status.${label}`)} (${amount})</span>
			</div>`;

			return template;
		},
		getText(param) {
			return {
				Contrato: 'contract',
				Carga: 'load',
				Processo: 'process',
				Container: 'container',
				Invoice: 'invoice',
				'Contrato*': 'finished',
			}[param];
		},
		getColor(param) {
			return {
				Contrato: '#234057',
				Carga: '#B47D4A',
				Processo: '#803423',
				Container: '#678AA3',
				Invoice: '#AAC3DB',
				'Contrato*': '#00B564',
			}[param];
		},
	},
};
</script>

<style scoped lang="scss">
@import 'StatusChart.scss';
</style>
