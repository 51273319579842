<template>
	<GenericMultiselect
		class="load-management-multiselect"
		ref="load"
		:options="loadOptions"
		:placeholder="$t('modules.load_management.filter.load')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="false"
		:infiniteScroll="true"
		:type="'loadNumber'"
		:multiple="true"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	LOAD_FILTER_REQUEST,
	SAVE_LOAD_FILTER,
} from '@/store/modules/filtersByModules/loadManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'LoadInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('loadManagementFilters', {
			getLoadFilter: LOAD_FILTER_REQUEST,
			saveLoadFilter: SAVE_LOAD_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveLoadFilter(param);
			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getLoadFilter({ vm: this, params: query, skip: false, lastQuery: this.lastQueryString });
		},
		nextPage() {
			const meta = this.loadFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getLoadFilter({ vm: this, params: query, skip: false, lastQuery: this.lastQueryString });
		},
		createQueryString(loadNumber) {
			const query = new URLSearchParams();

			if (loadNumber) query.set('loadNumber', loadNumber);
			if (loadNumber) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.load.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.loadFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.load.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('loadManagementFilters', [
			'loadFilter',
			'loadFilterMeta',
			'loadFilterCache',
			'isLoadingLoadFilter',
			'lastQueryString',
		]),
		loadOptions() {
			const rows = this.loadFilter != undefined ? this.loadFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'LoadInput.scss';
</style>
