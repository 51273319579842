<template>
	<div class="filter-container">
		<div class="filter-row">
			<div class="filter-row__container" v-if="!isWeb">
				<div class="filter-row__text">{{ $t('buttons.filters') }}:</div>
				<button class="btn-outline" @click="clearFilters()">
					<ClearIcon /> {{ $t('buttons.clear_filter') }}
				</button>
			</div>
			<NameInput ref="name" :isWeb="isWeb" @filterRequest="filterRequest" />
			<EmailInput ref="email" :isWeb="isWeb" @filterRequest="filterRequest" />
			<CompanyInput ref="company" :isWeb="isWeb" @filterRequest="filterRequest" />
			<StatusInput ref="status" :isWeb="isWeb" @filterRequest="filterRequest" />
		</div>
		<div class="selected-filters" v-if="selectedFilters && isWeb">
			<div class="row">
				<div class="selected-filters__label" v-if="selectedFilters.name">
					{{ selectedFilters.name }}
				</div>
				<div class="selected-filters__label" v-if="selectedFilters.email">
					{{ selectedFilters.email }}
				</div>
				<div class="selected-filters__label" v-if="selectedFilters.company">
					{{ selectedFilters.company }}
				</div>
				<div class="selected-filters__label" v-if="selectedFilters.active">
					{{ selectedFilters.active }}
				</div>
			</div>
			<button class="btn-outline clear" @click="clearFilters()">
				<ClearIcon /> {{ $t('buttons.clear_filter') }}
			</button>
		</div>
	</div>
</template>

<script>
import NameInput from './Inputs/NameInput/NameInput.vue';
import EmailInput from './Inputs/EmailInput/EmailInput.vue';
import CompanyInput from './Inputs/CompanyInput/CompanyInput.vue';
import StatusInput from './Inputs/StatusInput/StatusInput.vue';
import ClearIcon from '@/components/shared/Icons/ClearIcon.vue';

export default {
	name: 'FilterList',
	components: {
		NameInput,
		EmailInput,
		CompanyInput,
		StatusInput,
		ClearIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		filter: {
			name: null,
			email: null,
			company: null,
			active: null,
		},
		selectedFilters: {
			name: null,
			email: null,
			company: null,
			active: null,
		},
	}),
	methods: {
		filterRequest(param) {
			this.filter[param.type] = param.value ? param.value.id : null;
			this.selectedFilters[param.type] = param.value ? param.value.name : null;

			let queryString = this.createQueryString();

			this.$emit('refreshUsers', queryString);
		},
		createQueryString() {
			const query = new URLSearchParams();
			let { name, email, company, active } = this.filter;

			if (name) query.set('name', name);
			if (email) query.set('email', email);
			if (company) query.set('companies', company);
			if (active != null) query.set('active', active);

			return query.toString();
		},
		clearFilters() {
			this.$refs.name.clearInput();
			this.$refs.email.clearInput();
			this.$refs.company.clearInput();
			this.$refs.status.clearInput();
			this.filter = {
				name: null,
				email: null,
				company: null,
				active: null,
			};
			this.selectedFilters = {
				name: null,
				email: null,
				company: null,
				active: null,
			};
			this.$emit('setPageOne');

			setTimeout(() => {
				this.$emit('refreshUsers', null);
			}, 200);
		},
	},
};
</script>

<style scoped lang="scss">
@import 'FilterList.scss';
</style>
