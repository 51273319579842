<template>
	<div>
		<div class="doc-secondStepOption" v-if="isWeb">
			<button class="doc-secondStepOption__cancelButton" @click="goToBackStep">
				{{ $t('buttons.cancel') }}
			</button>
			<button class="doc-secondStepOption__buttonRegisterTemplate" @click="goToNewModel">
				<PlusIcon :color="'#ffff'" /> {{ $t('buttons.new_template') }}
			</button>
			<button
				class="doc-secondStepOption__steps"
				:class="{ active: showStepList }"
				@click="handleSteps()"
			>
				<StepsIcon />
			</button>
		</div>
		<div v-else>
			<StepList v-show="showStepList" />
			<button class="doc-secondStepOption__buttonRegisterTemplate --mobile" @click="goToNewModel">
				<PlusIcon :color="'#ffff'" /> {{ $t('buttons.new_template') }}
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
	SET_SHOW_STEP_LIST,
	CLEAR_SELECTED_CONTRACTS_SEQUENCES,
} from '@/store/modules/docInstruction/actions';
import PlusIcon from '@/components/shared/Icons/PlusIcon.vue';
import StepsIcon from '@/components/shared/Icons/StepsIcon.vue';
import StepList from '@/components/modules/DocInstruction/Steps/StepList/StepList.vue';

export default {
	name: 'SecondStepOption',
	components: {
		PlusIcon,
		StepsIcon,
		StepList,
	},
	props: {
		isWeb: {
			require: true,
		},
	},
	methods: {
		...mapActions('docInstruction', {
			setShowStepList: SET_SHOW_STEP_LIST,
			clearSelected: CLEAR_SELECTED_CONTRACTS_SEQUENCES,
		}),
		goToBackStep() {
			this.$emit('goToStep');
		},
		goToNewModel() {
			this.$emit('goToNewModel', this.templateData.salesContract, this.selectedContractsSequence);
		},
		handleSteps() {
			this.setShowStepList(!this.showStepList);
		},
	},
	computed: {
		...mapGetters('docInstruction', ['showStepList', 'templateData', 'selectedContractsSequence']),
	},
};
</script>

<style lang="scss">
@import 'SecondStepOption.scss';
</style>
