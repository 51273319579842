<template>
	<GenericMultiselect
		class="email-multiselect"
		ref="email"
		:options="userNameOptions"
		:placeholder="$t('modules.user_management.email_placeholder')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="false"
		:infiniteScroll="true"
		:type="'email'"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { USER_EMAIL_FILTER_REQUEST } from '@/store/common/filters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'EmailInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('filters', {
			getUserEmailFilter: USER_EMAIL_FILTER_REQUEST,
		}),
		changeInput(param) {
			this.page = 1;
			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getUserEmailFilter({ vm: this, params: query });
		},
		nextPage() {
			const meta = this.userEmailFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getUserEmailFilter({ vm: this, params: query });
		},
		createQueryString(email) {
			const query = new URLSearchParams();

			if (email) query.set('email', email);
			if (email) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.email.setValue(null);
		},
	},
	computed: {
		...mapGetters('filters', ['userEmailFilter', 'userEmailFilterMeta', 'isLoadingEmailFilter']),
		userNameOptions() {
			const rows = this.userEmailFilter != undefined ? this.userEmailFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style scoped lang="scss">
@import 'EmailInput.scss';
</style>
