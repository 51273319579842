import router from '../router/router';
import axios from 'axios';
class Http {
	constructor() {
		this.http = axios.create({
			baseURL: process.env.VUE_APP_BASE_URL,
		});

		this.http.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response.status === 401) {
					if (sessionStorage.getItem('token')) {
						sessionStorage.removeItem('token');
					}
					router.push('/login');
					return Promise.reject(error);
				}
				return Promise.reject(error);
			},
		);
	}
}

export default class SingletonHttp {
	constructor() {
		throw new Error('Use SingletonHttp.getInstance()');
	}

	static getInstance() {
		if (!SingletonHttp.instance) {
			SingletonHttp.instance = new Http();

			if (sessionStorage.getItem('token')) {
				SingletonHttp.instance.http.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
					'token',
				)}`;
			}
		}

		return SingletonHttp.instance;
	}
}
