<template>
	<svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.9084 0.000476244C12.5823 0.000476244 14.2606 0.633053 15.5372 1.90959C16.5278 2.90017 17.118 4.13965 17.3401 5.42459C19.8749 5.83617 21.8175 8.01987 21.8175 10.6668C21.8175 13.6066 19.4239 16 16.4843 16H4.60591C2.0627 16 0 13.9374 0 11.3941C0 8.91819 1.95881 6.91588 4.40895 6.81069C4.3381 5.04668 4.94365 3.2534 6.28759 1.90912C7.56326 0.633443 9.23477 0 10.9087 0L10.9084 0.000476244ZM10.9084 0.970055C9.48021 0.970055 8.06013 1.50779 6.96912 2.59879C5.70817 3.85974 5.18178 5.57203 5.37827 7.21206C5.39568 7.35089 5.3524 7.49039 5.25956 7.59472C5.16673 7.69923 5.03331 7.75858 4.89347 7.75756H4.60567C2.58279 7.75756 0.969423 9.37093 0.969423 11.3938C0.969423 13.4167 2.58279 15.0301 4.60567 15.0301H16.4841C18.8996 15.0301 20.8476 13.0822 20.8476 10.6666C20.8476 8.38564 19.1097 6.52016 16.8857 6.31821H16.8855C16.6572 6.29843 16.4739 6.12139 16.4462 5.89395C16.3064 4.68914 15.781 3.52424 14.8553 2.5986C13.765 1.50825 12.3366 0.969869 10.9085 0.969869L10.9084 0.970055ZM10.9084 5.81838C11.1762 5.81838 11.3932 6.0355 11.3932 6.30317V11.5151L13.2492 9.82587C13.4374 9.65407 13.7675 9.66726 13.9386 9.85614C14.1097 10.045 14.0999 10.3721 13.9007 10.5456L11.2341 12.9697C11.1647 13.0331 11.0379 13.0884 10.9084 13.091C10.7797 13.091 10.6894 13.0673 10.5827 12.9697L7.91603 10.5456C7.7263 10.3753 7.69147 10.0432 7.87815 9.85614C8.05824 9.67571 8.37935 9.65407 8.56757 9.82587L10.4236 11.5151V6.30317C10.4236 6.03532 10.6405 5.81838 10.9084 5.81838H10.9084Z"
			:fill="stroke"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '',
		},
	},
	computed: {
		stroke() {
			switch (this.color) {
				case 'red':
					return '#c8a1a1';

				case 'green':
					return '#96c695';

				case 'orange':
					return '#ab896a';

				case 'gray':
					return '#678aa3';

				default:
					return '#234057';
			}
		},
	},
};
</script>
<style lang="scss" scoped></style>
