<template>
	<svg
		class="anim-rotate-infinite"
		:width="size"
		:height="size"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="-32 -32 64 64"
	>
		<path d="M -32,0 A 32 32 0 1 1 0,32 L 0,24 A 24 24 0 1 0 -24,0 z" :fill="color" />
	</svg>
</template>

<script>
export default {
	props: {
		size: {
			type: Number,
			default: 32,
		},
		color: {
			type: String,
			default: '#234057',
		},
	},
};
</script>

<style>
.anim-rotate-infinite {
	animation: rotation 1s linear infinite;
}
</style>
