<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.3075 9.31969H17.1016C16.8541 9.31969 16.6256 9.45149 16.502 9.66541C16.3784 9.87932 16.3784 10.1429 16.502 10.3568C16.6256 10.5707 16.8542 10.7026 17.1016 10.7026H17.5862C17.4216 12.4728 16.6423 14.1296 15.3833 15.3865C14.1242 16.6433 12.4647 17.4205 10.6923 17.5838V6.46756C11.5744 6.24114 12.2894 5.5977 12.6068 4.74515C12.924 3.89275 12.8032 2.93893 12.2835 2.19211C11.7637 1.44544 10.9107 1 10 1C9.08933 1 8.23634 1.4454 7.71648 2.19211C7.19678 2.93893 7.07596 3.89275 7.3932 4.74515C7.7106 5.5977 8.42563 6.24113 9.3077 6.46756V17.5838C7.53554 17.42 5.87667 16.6425 4.61787 15.3859C3.35908 14.1292 2.58022 12.4725 2.41581 10.7027H2.90042C3.14767 10.7027 3.37621 10.5709 3.49983 10.357C3.62361 10.1431 3.62361 9.87947 3.49983 9.66557C3.37621 9.45165 3.14766 9.31985 2.90042 9.31985H1.6923C1.50872 9.31985 1.33255 9.3927 1.20274 9.52234C1.07294 9.65198 1 9.82793 1 10.0113C1 12.3952 1.94821 14.6814 3.636 16.3673C5.32378 18.053 7.61295 19 10 19C12.387 19 14.676 18.053 16.364 16.3673C18.0518 14.6816 19 12.3953 19 10.0113C19 9.82793 18.9271 9.65198 18.7973 9.52234C18.6675 9.3927 18.4913 9.31985 18.3077 9.31985L18.3075 9.31969ZM8.60345 3.78824C8.60329 3.4183 8.75025 3.06347 9.01202 2.80187C9.2738 2.54011 9.62892 2.39302 9.99932 2.39286C10.3697 2.39286 10.725 2.53963 10.9869 2.80108C11.2489 3.06268 11.3961 3.41736 11.3961 3.7873C11.3963 4.15724 11.2492 4.51207 10.9872 4.77367C10.7253 5.03512 10.3702 5.18221 9.99979 5.18221C9.62969 5.18175 9.2749 5.03482 9.01313 4.77351C8.75135 4.51222 8.60392 4.15786 8.60349 3.7882L8.60345 3.78824Z"
			fill="#234057"
			stroke="white"
			stroke-width="0.3"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#234057',
		},
	},
};
</script>
<style lang="scss" scoped></style>
