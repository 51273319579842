<template>
	<div class="contract-list">
		<div class="contract-list__title" v-if="!isWeb && hasContractList">
			<h1 class="contract-list__title--text">
				{{ $t('modules.doc_instruction.steps.selectTheContract') }}
			</h1>
		</div>
		<div class="contract-list__body" v-if="isLoadingContracts !== 'loading'">
			<ContractRow
				v-for="(contract, index) in contractListItems"
				:ref="`contract-row-${contract.id}`"
				:key="index"
				:contractData="contract"
				:isWeb="isWeb"
				@closeAllTables="closeAllTables"
			/>
		</div>
		<div class="loader">
			<DefaultLoader :dataStatus="isLoadingContracts" />
		</div>
		<Pagination
			v-if="hasContractList && isLoadingContracts == 'success'"
			ref="pagination"
			:totalPages="pagination.totalPages"
			:currentFromReq="pagination.currentPage"
			@tableRequest="changePage"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import ContractRow from '../ContractRow/ContractRow.vue';
import Pagination from '@/components/shared/Pagination/Pagination';
import DefaultLoader from '@/components/shared/DefaultLoader/DefaultLoader.vue';

export default {
	name: 'ContractList',
	components: {
		ContractRow,
		Pagination,
		DefaultLoader,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({}),
	methods: {
		closeAllTables(id) {
			if (this.contractList && this.contractList.length) {
				this.contractList.forEach((contract) => {
					if (id != contract.id) {
						this.$refs[`contract-row-${contract.id}`][0].closeTable();
					}
				});
			}
		},
		changePage(param) {
			this.$emit('changePage', param);
		},
	},
	computed: {
		...mapGetters('docInstruction', [
			'contractList',
			'contractListPage',
			'contractListMax',
			'isLoadingContracts',
		]),
		pagination() {
			const pagination =
				this.isLoadingContracts != 'empty'
					? { currentPage: this.contractListPage, totalPages: this.contractListMax }
					: { currentPage: 1, totalPages: 1 };

			return pagination;
		},
		contractListItems() {
			const list = this.contractList ?? [];

			return list;
		},
		hasContractList() {
			return this.contractListItems.length > 0;
		},
	},
};
</script>

<style scoped lang="scss">
@import 'ContractList.scss';
</style>
