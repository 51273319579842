import {
	SET_STEP,
	SET_SHOW_STEP_LIST,
	RESET_STEPS,
	CLEAR_CONTRACT_STATE,

	//CONTRACTS
	CONTRACT_LIST_REQUEST,
	CONTRACT_LIST_REQUEST_SUCCESS,
	CONTRACT_LIST_REQUEST_ERROR,

	//CONTRACTS SEQUENCE
	CONTRACT_SEQUENCE_REQUEST,
	CONTRACT_SEQUENCE_REQUEST_SUCCESS,
	CONTRACT_SEQUENCE_REQUEST_ERROR,
	CLEAR_SELECTED_CONTRACTS_SEQUENCES,
	UPDATE_SELECTED_CONTRACTS_SEQUENCES,

	//NEW MODEL
	SET_SHOW_NEW_MODEL,
	SET_HIDE_NEW_MODEL,

	//TEMPLATE DEFAULT
	TEMPLATE_DEFAULT_REQUEST,
	TEMPLATE_DEFAULT_SUCCESS,
	TEMPLATE_DEFAULT_ERROR,

	//CLIENTS LIST
	CLIENTS_LIST_REQUEST,
	CLIENTS_LIST_SUCCESS,
	CLIENTS_LIST_ERROR,
	ADD_CLIENT,

	//BANK
	BANK_LIST_REQUEST,
	BANK_LIST_SUCCESS,
	BANK_LIST_ERROR,

	//SET TEMPLATE
	SET_TEMPLATE_INVOICE_DATA,
	SET_TEMPLATE_CERTIFICATE_ORIGIN_DATA,
	SET_TEMPLATE_PACKING_DATA,
	SET_TEMPLATE_BILL_LANDING_DATA,
	SET_TEMPLATE_HEALTH_CERTIFICATE_DATA,
	SET_TEMPLATE_NAME_DATA,
	SET_TEMPLATE_SEQUENCE_SALES_CONTRACT_DATA,
	SET_TEMPLATE_SALES_CONTRACT_DATA,
	SET_TEMPLATE_DESTINATION_BANK_DATA,
	SET_TEMPLATE_DESTINATION_CUSTOMER_DATA,
	SET_TEMPLATE_INVOICE_SHIPPING_TYPE,
	SET_TEMPLATE_PACKING_SHIPPING_TYPE,
	SET_TEMPLATE_BILL_LANDING_SHIPPING_TYPE,
	SET_TEMPLATE_HEALTH_CERTIFICATE_SHIPPING_TYPE,
	SET_TEMPLATE_CERTIFICATE_ORIGIN_SHIPPING_TYPE,
	CLEAR_NEW_TEMPLATE_REQUEST,

	//new template
	SUBMIT_NEW_TEMPLATE_REQUEST,
	SUBMIT_NEW_TEMPLATE_SUCCESS,
	SUBMIT_NEW_TEMPLATE_ERROR,

	//template detail
	TEMPLATE_DETAIL_REQUEST,
	TEMPLATE_DETAIL_SUCCESS,
	TEMPLATE_DETAIL_ERROR,

	// ADDRESSES
	COUNTRIES_REQUEST,
	COUNTRIES_SUCCESS,
	COUNTRIES_ERROR,
	STATES_REQUEST,
	STATES_SUCCESS,
	STATES_ERROR,
	CITIES_REQUEST,
	CITIES_SUCCESS,
	CITIES_ERROR,
	EDIT_CONSIGNEE_NOTIFY,
	RESET_CONSIGNEE_NOTIFY,
} from './actions';

import api from '@/utils/api';

const { http } = api.getInstance();
const CONTRACT_LIST = '/contracts';
const INSTRUCTION_TEMPLATE = '/instruction-templates';
const ADDRESS_URL = '/addresses';

const defaultState = () => ({
	step: 1,

	contractList: [],
	contractListPage: 1,
	contactListMax: 1,
	isLoadingContracts: '',

	contractSequenceList: [],
	contractSequenceListPage: 1,
	contactSequenceListMax: 1,
	isLoadingContractsSequences: '',
	selectedContractsSequence: [],
	showStepList: false,
	countries: [],
	isLoadingCountries: '',
	states: [],
	isLoadingStates: '',
	cities: [],
	isLoadingCities: '',

	//NEW MODEL
	showNewModel: false,

	templateDefault: {},
	isLoadingTemplateDefault: '',
	clientsList: [],
	clientsListLocal: [],
	isLoadingClients: '',

	bankList: [],
	isLoadingBank: '',

	templateData: {
		nameTemplate: '',
		healthCertificate: {
			instructionType: '',
			instructionLetter: '',
			customerType: null,
			consignee: {
				id: null,
				name: '',
				address: '',
			},
			responsable: {
				id: null,
				name: '',
				address: '',
			},
			observations: '',
			shippingType: null,
		},
		billLanding: {
			instructionType: '',
			instructionLetter: '',
			customerType: null,
			consignee: {
				id: null,
				name: '',
				address: '',
			},
			notify: {
				id: null,
				name: '',
				address: '',
			},
			secondNotify: {
				id: null,
				name: '',
				address: '',
			},
			releaseType: null,
			shippingPayment: '',
			observations: '',
			shippingType: null,
		},
		certificateOrigin: {
			instructionType: '',
			instructionLetter: '',
			customerType: null,
			buyer: {
				id: null,
				name: '',
				address: '',
			},
			consignee: {
				id: null,
				name: '',
				address: '',
			},
			observations: '',
			shippingType: null,
		},
		invoice: {
			instructionType: '',
			instructionLetter: '',
			customerType: null,
			buyer: {
				id: null,
				name: '',
				address: '',
			},
			consignee: {
				id: null,
				name: '',
				address: '',
			},
			notify: {
				id: null,
				name: '',
				address: '',
			},
			observations: '',
			shippingType: null,
		},
		packing: {
			instructionType: '',
			instructionLetter: '',
			customerType: null,
			buyer: {
				id: null,
				name: '',
				address: '',
			},
			consignee: {
				id: null,
				name: '',
				address: '',
			},
			notify: {
				id: null,
				name: '',
				address: '',
			},
			observations: '',
			shippingType: null,
		},
		companyId: null,
		salesContract: null,
		seqSalesContract: [''],
		status: null,
		destinationBank: null,
		destinationCustomer: null,
	},

	editedConsigneeNotify: [],

	//new template
	isLoadingNewTemplate: '',
});

const state = defaultState();

const getters = {
	getStep: (state) => state.step,
	showStepList: (state) => state.showStepList,

	// Contract List
	contractList: (state) => state.contractList,
	contractListPage: (state) => state.contractListPage,
	contractListMax: (state) => state.contractListMax,
	isLoadingContracts: (state) => state.isLoadingContracts,

	// Contract Sequences
	contractSequenceList: (state) => state.contractSequenceList,
	contractSequenceListPage: (state) => state.contractSequenceListPage,
	contactSequenceListMax: (state) => state.contactSequenceListMax,
	isLoadingContractsSequences: (state) => state.isLoadingContractsSequences,
	selectedContractsSequence: (state) => state.selectedContractsSequence,

	//NEW MODEL
	showNewModel: (state) => state.showNewModel,

	//Template Default
	templateDefault: (state) => state.templateDefault,
	isLoadingTemplateDefault: (state) => state.isLoadingTemplateDefault,

	//LIST CLIENTS
	clientsList: (state) => state.clientsList.concat(state.clientsListLocal),
	isLoadingClients: (state) => state.isLoadingClients,

	//LIST CLIENTS
	bankList: (state) => state.bankList,
	isLoadingBank: (state) => state.isLoadingBank,

	templateData: (state) => state.templateData,

	//new template
	isLoadingNewTemplate: (state) => state.isLoadingNewTemplate,

	// Addresses
	listCountries: (state) => state.countries,
	listStates: (state) => state.states,
	listCities: (state) => state.cities,
	editedConsigneeNotify: (state) => state.editedConsigneeNotify,
};

const mutations = {
	[EDIT_CONSIGNEE_NOTIFY]: (state, data) => {
		const editClientIndex = state.editedConsigneeNotify.findIndex(
			(_client) => _client.name === data.name,
		);
		if (editClientIndex === -1) {
			const client = [...state.clientsList, ...state.clientsListLocal].find(
				(_client) => _client.name === data.name,
			);
			const newClient = {
				...client,
				...data,
			};
			state.editedConsigneeNotify = [...state.editedConsigneeNotify, newClient];
		} else {
			const newClient = {
				...state.editedConsigneeNotify[editClientIndex],
				...data,
			};

			state.editedConsigneeNotify = state.editedConsigneeNotify.map((client) => {
				if (client.name === newClient.name) {
					return newClient;
				}
				return client;
			});
		}
	},
	[RESET_CONSIGNEE_NOTIFY]: (state) => {
		state.editedConsigneeNotify = [];
	},
	[SET_STEP]: (state, data) => {
		state.step = data;
		state.showStepList = false;
	},
	[SET_SHOW_STEP_LIST]: (state, data) => {
		state.showStepList = data;
	},
	[CLEAR_CONTRACT_STATE]: (state) => {
		Object.assign(state, defaultState());
	},

	// =========================================
	//     Contract List
	// =========================================
	[CONTRACT_LIST_REQUEST]: (state) => {
		state.isLoadingContracts = 'loading';
	},
	[CONTRACT_LIST_REQUEST_SUCCESS]: (state, data) => {
		state.contractListPage = data.meta.currentPage;
		state.contractListMax = data.meta.totalPages;
		state.contractList = [];

		state.contractList = data.items || [];

		state.isLoadingContracts = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingContracts = 'empty';
			state.contractList = [];
		}
	},
	[CONTRACT_LIST_REQUEST_ERROR]: (state) => {
		state.contractList = [];
		state.isLoadingContracts = 'error';
	},

	// =========================================
	//     Contract Sequence List
	// =========================================
	[CONTRACT_SEQUENCE_REQUEST]: (state) => {
		state.isLoadingContractsSequences = 'loading';
	},
	[CONTRACT_SEQUENCE_REQUEST_SUCCESS]: (state, data) => {
		state.contractSequenceList = data.data.items.map((items) => {
			return {
				seq: items.contractSequence,
				pod: items.pod,
				acronym: items.nmCustomerShort,
				contractNumber: data.contractNumber,
				nmCustomer: items.nmCustomer,
				checked: false,
			};
		});

		state.isLoadingContractsSequences = 'success';
		if (data.data.items.length == 0) {
			state.isLoadingContractsSequences = 'empty';
		}
	},
	[CONTRACT_SEQUENCE_REQUEST_ERROR]: (state) => {
		state.contractSequenceList = [];
		state.isLoadingContractsSequences = 'error';
	},
	[CLEAR_SELECTED_CONTRACTS_SEQUENCES]: (state) => {
		state.selectedContractsSequence = [];
	},
	[UPDATE_SELECTED_CONTRACTS_SEQUENCES]: (state, data) => {
		let index = state.selectedContractsSequence.findIndex((item) => item.seq === data.seq);

		if (index !== -1) {
			state.selectedContractsSequence.splice(index, 1);
		} else {
			state.selectedContractsSequence.push(data);
		}
	},

	// =========================================
	//     New Model
	// =========================================
	[SET_SHOW_NEW_MODEL]: (state) => {
		state.showNewModel = true;
	},
	[SET_HIDE_NEW_MODEL]: (state) => {
		state.showNewModel = false;
	},

	// =========================================
	//     Template Default
	// =========================================
	[TEMPLATE_DEFAULT_REQUEST]: (state) => {
		state.isLoadingTemplateDefault = 'loading';
	},
	[TEMPLATE_DEFAULT_SUCCESS]: (state, data) => {
		state.templateDefault = data;

		state.isLoadingTemplateDefault = 'success';
	},
	[TEMPLATE_DEFAULT_ERROR]: (state) => {
		state.templateDefault = {};
		state.isLoadingTemplateDefault = 'error';
	},

	// =========================================
	//     List Clients
	// =========================================
	[CLIENTS_LIST_REQUEST]: (state) => {
		state.isLoadingClients = 'loading';
	},
	[CLIENTS_LIST_SUCCESS]: (state, data) => {
		state.clientsList = data.customerList;

		state.isLoadingClients = 'success';
	},
	[CLIENTS_LIST_ERROR]: (state) => {
		state.clientsList = [];
		state.isLoadingClients = 'error';
	},
	[ADD_CLIENT]: (state, data) => {
		state.clientsListLocal.push(data);
	},

	// =========================================
	//     List Bank
	// =========================================
	[BANK_LIST_REQUEST]: (state) => {
		state.isLoadingBank = 'loading';
	},
	[BANK_LIST_SUCCESS]: (state, data) => {
		state.bankList = data;
		state.isLoadingBank = 'success';
	},
	[BANK_LIST_ERROR]: (state) => {
		state.bankList = [];
		state.isLoadingBank = 'error';
	},

	/// SET TEMPLATE
	[SET_TEMPLATE_INVOICE_DATA]: (state, data) => {
		state.templateData.invoice = data;
	},
	[SET_TEMPLATE_PACKING_DATA]: (state, data) => {
		state.templateData.packing = data;
	},
	[SET_TEMPLATE_BILL_LANDING_DATA]: (state, data) => {
		state.templateData.billLanding = data;
	},
	[SET_TEMPLATE_HEALTH_CERTIFICATE_DATA]: (state, data) => {
		state.templateData.healthCertificate = data;
	},
	[SET_TEMPLATE_CERTIFICATE_ORIGIN_DATA]: (state, data) => {
		state.templateData.certificateOrigin = data;
	},
	[SET_TEMPLATE_SALES_CONTRACT_DATA]: (state, data) => {
		state.templateData.salesContract = data;
	},
	[SET_TEMPLATE_SEQUENCE_SALES_CONTRACT_DATA]: (state, data) => {
		state.templateData.seqSalesContract = data;
	},
	[SET_TEMPLATE_NAME_DATA]: (state, data) => {
		state.templateData.nameTemplate = data;
	},
	[SET_TEMPLATE_DESTINATION_BANK_DATA]: (state, data) => {
		state.templateData.destinationBank = data;
	},
	[SET_TEMPLATE_DESTINATION_CUSTOMER_DATA]: (state, data) => {
		state.templateData.destinationCustomer = data;
	},
	[SET_TEMPLATE_INVOICE_SHIPPING_TYPE]: (state, data) => {
		state.templateData.invoice.shippingType = data;
	},
	[SET_TEMPLATE_PACKING_SHIPPING_TYPE]: (state, data) => {
		state.templateData.packing.shippingType = data;
	},
	[SET_TEMPLATE_HEALTH_CERTIFICATE_SHIPPING_TYPE]: (state, data) => {
		state.templateData.healthCertificate.shippingType = data;
	},
	[SET_TEMPLATE_BILL_LANDING_SHIPPING_TYPE]: (state, data) => {
		state.templateData.billLanding.shippingType = data;
	},
	[SET_TEMPLATE_CERTIFICATE_ORIGIN_SHIPPING_TYPE]: (state, data) => {
		state.templateData.certificateOrigin.shippingType = data;
	},

	[CLEAR_NEW_TEMPLATE_REQUEST]: (state) => {
		state.templateData = {
			nameTemplate: '',
			healthCertificate: {
				instructionType: '',
				instructionLetter: '',
				customerType: null,
				consignee: {
					id: null,
					name: '',
					address: '',
				},
				responsable: {
					id: null,
					name: '',
					address: '',
				},
				observations: '',
				shippingType: null,
			},
			billLanding: {
				instructionType: '',
				instructionLetter: '',
				customerType: null,
				consignee: {
					id: null,
					name: '',
					address: '',
				},
				notify: {
					id: null,
					name: '',
					address: '',
				},
				secondNotify: {
					id: null,
					name: '',
					address: '',
				},
				releaseType: null,
				shippingPayment: '',
				observations: '',
				shippingType: null,
			},
			certificateOrigin: {
				instructionType: '',
				instructionLetter: '',
				customerType: null,
				buyer: {
					id: null,
					name: '',
					address: '',
				},
				consignee: {
					id: null,
					name: '',
					address: '',
				},
				observations: '',
				shippingType: null,
			},
			invoice: {
				instructionType: '',
				instructionLetter: '',
				customerType: null,
				buyer: {
					id: null,
					name: '',
					address: '',
				},
				consignee: {
					id: null,
					name: '',
					address: '',
				},
				notify: {
					id: null,
					name: '',
					address: '',
				},
				observations: '',
				shippingType: null,
			},
			packing: {
				instructionType: '',
				instructionLetter: '',
				customerType: null,
				buyer: {
					id: null,
					name: '',
					address: '',
				},
				consignee: {
					id: null,
					name: '',
					address: '',
				},
				notify: {
					id: null,
					name: '',
					address: '',
				},
				observations: '',
				shippingType: null,
			},
			companyId: null,
			salesContract: null,
			seqSalesContract: [''],
			status: null,
			destinationBank: null,
			destinationCustomer: null,
		};
	},

	// =========================================
	//     new template
	// =========================================
	[SUBMIT_NEW_TEMPLATE_REQUEST]: (state) => {
		state.isLoadingNewTemplate = 'loading';
	},
	[SUBMIT_NEW_TEMPLATE_SUCCESS]: (state, data) => {
		state.isLoadingNewTemplate = 'success';
	},
	[SUBMIT_NEW_TEMPLATE_ERROR]: (state) => {
		state.isLoadingNewTemplate = 'error';
	},

	// =========================================
	//     template detail
	// =========================================
	[TEMPLATE_DETAIL_REQUEST]: (state) => {
		state.isLoadingTemplateDefault = 'loading';
	},
	[TEMPLATE_DETAIL_SUCCESS]: (state, data) => {
		state.templateData.nameTemplate = data.instructionLetter;
		state.templateData.healthCertificate = data.healthCertificate;
		state.templateData.billLanding = data.billLanding;
		state.templateData.certificateOrigin = data.certificateOrigin;
		state.templateData.invoice = data.invoice;
		state.templateData.packing = data.packing;
		// MUDAR PRA PEGAR ID DO FILTRO
		state.templateData.companyId = data.companyId;
		state.templateData.salesContract = data.salesContract;
		state.templateData.seqSalesContract = data.seqSalesContract;
		state.templateData.status = data.status;
		state.isLoadingTemplateDefault = 'success';
	},
	[TEMPLATE_DETAIL_ERROR]: (state) => {
		state.templateDefault = {};
		state.isLoadingTemplateDefault = 'error';
	},
	[COUNTRIES_REQUEST]: (state) => {
		state.isLoadingCountries = 'loading';
	},
	[COUNTRIES_SUCCESS]: (state, data) => {
		state.countries = data;

		state.isLoadingCountries = 'success';
		if (data.length == 0) {
			state.isLoadingCountries = 'empty';
		}
	},
	[COUNTRIES_ERROR]: (state) => {
		state.countries = [];
		state.isLoadingCountries = 'error';
	},
	[STATES_REQUEST]: (state) => {
		state.isLoadingStates = 'loading';
	},
	[STATES_SUCCESS]: (state, data) => {
		state.states = data;

		state.isLoadingStates = 'success';
		if (data.length == 0) {
			state.isLoadingStates = 'empty';
		}
	},
	[STATES_ERROR]: (state) => {
		state.cities = [];
		state.isLoadingStates = 'error';
	},
	[CITIES_REQUEST]: (state) => {
		state.isLoadingCities = 'loading';
	},
	[CITIES_SUCCESS]: (state, data) => {
		state.cities = data;

		state.isLoadingCities = 'success';
		if (data.length == 0) {
			state.isLoadingCities = 'empty';
		}
	},
	[CITIES_ERROR]: (state) => {
		state.cities = [];
		state.isLoadingCities = 'error';
	},
};

const actions = {
	[EDIT_CONSIGNEE_NOTIFY]: ({ commit }, data) => {
		commit(EDIT_CONSIGNEE_NOTIFY, data);
	},
	[RESET_CONSIGNEE_NOTIFY]: ({ commit }, data) => {
		commit(RESET_CONSIGNEE_NOTIFY);
	},
	[SET_STEP]: ({ commit }, data) => {
		if (data) {
			commit(SET_STEP, data);
		}
	},
	[SET_SHOW_STEP_LIST]: ({ commit }, data) => {
		commit(SET_SHOW_STEP_LIST, data);
	},
	[RESET_STEPS]: ({ commit }) => {
		commit(SET_STEP, 1);
	},
	[CLEAR_CONTRACT_STATE]: ({ commit }) => {
		commit(CLEAR_CONTRACT_STATE);
	},

	// =========================================
	//     Contract List
	// =========================================
	[CONTRACT_LIST_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			page = 1,
			contractNumber = '',
			contractDateStart = '',
			contractDateEnd = '',
			pod = '',
			limit = 10,
			searchParam,
			...args
		},
	) => {
		return new Promise((resolve) => {
			commit(CONTRACT_LIST_REQUEST);

			let url = `${CONTRACT_LIST}/withoutInstruction?
				companyId=${companyId}&
				page=${page}&
				limit=${limit}&
				contractNumber=${contractNumber}&
				contractDateStart=${contractDateStart}&
				contractDateEnd=${contractDateEnd}&
				pod=${pod}&
				searchParam=${searchParam}
			`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CONTRACT_LIST_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACT_LIST_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},

	// =========================================
	//     Contract Sequence List
	// =========================================
	[CONTRACT_SEQUENCE_REQUEST]: ({ commit }, { vm, contractNumber }) => {
		return new Promise((resolve) => {
			commit(CONTRACT_SEQUENCE_REQUEST);

			let url = `${CONTRACT_LIST}/withoutInstructionFilters/${contractNumber}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CONTRACT_SEQUENCE_REQUEST_SUCCESS, {
						data: response.data,
						contractNumber: contractNumber,
					});
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACT_SEQUENCE_REQUEST_ERROR);
				});
		});
	},
	[CLEAR_SELECTED_CONTRACTS_SEQUENCES]: ({ commit }, data) => {
		commit(CLEAR_SELECTED_CONTRACTS_SEQUENCES);
	},
	[UPDATE_SELECTED_CONTRACTS_SEQUENCES]: ({ commit }, data) => {
		commit(UPDATE_SELECTED_CONTRACTS_SEQUENCES, data);
	},

	// =========================================
	//     New Model
	// =========================================
	[SET_SHOW_NEW_MODEL]: ({ commit }) => {
		commit(SET_SHOW_NEW_MODEL);
	},
	[SET_HIDE_NEW_MODEL]: ({ commit }) => {
		commit(SET_HIDE_NEW_MODEL);
	},

	// =========================================
	//     Template Default
	// =========================================
	[TEMPLATE_DEFAULT_REQUEST]: ({ commit }, { vm, companyId }) => {
		return new Promise((resolve) => {
			commit(TEMPLATE_DEFAULT_REQUEST);

			let url = `${INSTRUCTION_TEMPLATE}/base?companyId=${companyId}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(TEMPLATE_DEFAULT_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(TEMPLATE_DEFAULT_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},

	// =========================================
	//     List Clients
	// =========================================
	[CLIENTS_LIST_REQUEST]: ({ commit }, { vm, companyId }) => {
		return new Promise((resolve) => {
			commit(CLIENTS_LIST_REQUEST);

			let url = `${INSTRUCTION_TEMPLATE}/costumer?companyId=${companyId}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CLIENTS_LIST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					resolve(error);
					commit(CLIENTS_LIST_ERROR);
				});
		});
	},
	[ADD_CLIENT]: ({ commit }, data) => {
		commit(ADD_CLIENT, data);
	},

	// =========================================
	//     List Clients
	// =========================================
	[BANK_LIST_REQUEST]: ({ commit }, { vm, companyId }) => {
		return new Promise((resolve) => {
			commit(BANK_LIST_REQUEST);

			let url = `${INSTRUCTION_TEMPLATE}/bank?companyId=${companyId}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(BANK_LIST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					resolve(error);
					commit(BANK_LIST_ERROR);
				});
		});
	},

	// =========================================
	//     set template
	// =========================================
	[SET_TEMPLATE_INVOICE_DATA]: ({ commit }, data) => {
		commit(SET_TEMPLATE_INVOICE_DATA, data);
	},
	[SET_TEMPLATE_PACKING_DATA]: ({ commit }, data) => {
		commit(SET_TEMPLATE_PACKING_DATA, data);
	},
	[SET_TEMPLATE_BILL_LANDING_DATA]: ({ commit }, data) => {
		commit(SET_TEMPLATE_BILL_LANDING_DATA, data);
	},
	[SET_TEMPLATE_HEALTH_CERTIFICATE_DATA]: ({ commit }, data) => {
		commit(SET_TEMPLATE_HEALTH_CERTIFICATE_DATA, data);
	},
	[SET_TEMPLATE_CERTIFICATE_ORIGIN_DATA]: ({ commit }, data) => {
		commit(SET_TEMPLATE_CERTIFICATE_ORIGIN_DATA, data);
	},
	[SET_TEMPLATE_NAME_DATA]: ({ commit }, data) => {
		commit(SET_TEMPLATE_NAME_DATA, data);
	},
	[SET_TEMPLATE_DESTINATION_BANK_DATA]: ({ commit }, data) => {
		commit(SET_TEMPLATE_DESTINATION_BANK_DATA, data);
	},
	[SET_TEMPLATE_INVOICE_SHIPPING_TYPE]: ({ commit }, data) => {
		commit(SET_TEMPLATE_INVOICE_SHIPPING_TYPE, data);
	},
	[SET_TEMPLATE_PACKING_SHIPPING_TYPE]: ({ commit }, data) => {
		commit(SET_TEMPLATE_PACKING_SHIPPING_TYPE, data);
	},
	[SET_TEMPLATE_BILL_LANDING_SHIPPING_TYPE]: ({ commit }, data) => {
		commit(SET_TEMPLATE_BILL_LANDING_SHIPPING_TYPE, data);
	},
	[SET_TEMPLATE_HEALTH_CERTIFICATE_SHIPPING_TYPE]: ({ commit }, data) => {
		commit(SET_TEMPLATE_HEALTH_CERTIFICATE_SHIPPING_TYPE, data);
	},
	[SET_TEMPLATE_CERTIFICATE_ORIGIN_SHIPPING_TYPE]: ({ commit }, data) => {
		commit(SET_TEMPLATE_CERTIFICATE_ORIGIN_SHIPPING_TYPE, data);
	},
	[SET_TEMPLATE_DESTINATION_CUSTOMER_DATA]: ({ commit }, data) => {
		commit(SET_TEMPLATE_DESTINATION_CUSTOMER_DATA, data);
	},
	[SET_TEMPLATE_SEQUENCE_SALES_CONTRACT_DATA]: ({ commit }, data) => {
		commit(SET_TEMPLATE_SEQUENCE_SALES_CONTRACT_DATA, data);
	},
	[SET_TEMPLATE_SALES_CONTRACT_DATA]: ({ commit }, data) => {
		commit(SET_TEMPLATE_SALES_CONTRACT_DATA, data);
	},
	[CLEAR_NEW_TEMPLATE_REQUEST]: ({ commit }, data) => {
		commit(CLEAR_NEW_TEMPLATE_REQUEST);
	},

	// =========================================
	//    new Template
	// =========================================
	[SUBMIT_NEW_TEMPLATE_REQUEST]: ({ commit }, { vm, data }) => {
		return new Promise((resolve) => {
			commit(SUBMIT_NEW_TEMPLATE_REQUEST);

			http({
				method: 'POST',
				url: INSTRUCTION_TEMPLATE,
				data: data,
			})
				.then((response) => {
					// commit(SUBMIT_NEW_TEMPLATE_SUCCESS, response.data);
					vm.$toastr.s(vm.$t('modules.templateData.requests_messages.template_send'));
					resolve(response);
				})
				.catch((error) => {
					commit(SUBMIT_NEW_TEMPLATE_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},

	// =========================================
	//     Template Detail
	// =========================================
	[TEMPLATE_DETAIL_REQUEST]: (
		{ commit },
		{ vm, cdInstruction, companyId, contract, contractSeq },
	) => {
		return new Promise((resolve) => {
			commit(TEMPLATE_DETAIL_REQUEST);

			let url = `${INSTRUCTION_TEMPLATE}/getBy/${cdInstruction}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					response.data.companyId = companyId;
					response.data.salesContract = contract;
					response.data.seqSalesContract = contractSeq;
					commit(TEMPLATE_DETAIL_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(TEMPLATE_DETAIL_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[COUNTRIES_REQUEST]: ({ commit }, { vm }) => {
		return new Promise((resolve) => {
			commit(COUNTRIES_REQUEST);

			const url = `${ADDRESS_URL}/countries`;

			http({
				method: 'GET',
				url,
			})
				.then((response) => {
					commit(COUNTRIES_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(COUNTRIES_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[STATES_REQUEST]: ({ commit }, { vm, countryId }) => {
		return new Promise((resolve) => {
			commit(STATES_REQUEST);

			const url = `${ADDRESS_URL}/states/ByCountryId/${countryId}`;

			http({
				method: 'GET',
				url,
			})
				.then((response) => {
					commit(STATES_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(STATES_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CITIES_REQUEST]: ({ commit }, { vm, stateId }) => {
		return new Promise((resolve) => {
			commit(CITIES_REQUEST);

			const url = `${ADDRESS_URL}/cities/ByStateId/${stateId}`;

			http({
				method: 'GET',
				url,
			})
				.then((response) => {
					commit(CITIES_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(CITIES_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
