<template>
	<GenericMultiselect
		class="company-header-multiselect"
		ref="companyHeader"
		:options="companyHeaderOptions"
		:placeholder="$t('header.customer_company')"
		:fullWidth="!isWeb"
		:darkBlue="false"
		:searchable="true"
		:needType="true"
		:infiniteScroll="true"
		:multiple="false"
		:type="'companiesHeader'"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
		@handleOpen="verifyIfOpenModal"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	HEADER_COMPANY_FILTER_REQUEST,
	SAVE_HEADER_COMPANY_FILTER,
	DEFINE_DEFAULT_HEADER_COMPANY,
} from '@/store/common/filters/actions';
import { SET_STEP } from '@/store/modules/docInstruction/actions';

import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'CompanyInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
		allowDocInstruModal: {
			default: false,
		},
	},
	data: () => ({
		page: 1,
		hasDocInstrucAction: 'none',
	}),
	mounted() {
		this.$refs.companyHeader.setValue(this.headerCompanyFilterCache.value);
	},
	methods: {
		...mapActions('filters', {
			getCompanyFilter: HEADER_COMPANY_FILTER_REQUEST,
			saveCompanyFilter: SAVE_HEADER_COMPANY_FILTER,
			getHeaderCompanyFilter: HEADER_COMPANY_FILTER_REQUEST,
			setDefaultCompany: DEFINE_DEFAULT_HEADER_COMPANY,
		}),
		...mapActions('docInstruction', {
			setStep: SET_STEP,
		}),
		// DOC INSTRUC MODAL HANDLERS
		verifyIfOpenModal() {
			if (this.allowDocInstruModal && this.hasDocInstrucAction === 'none') {
				return this.$emit('handleDocInstrucModal', true);
			}
		},
		handleDocInstrucAction(value) {
			this.hasDocInstrucAction = value;
		},

		// INPUT HANDLERS
		changeInput(param) {
			this.page = 1;
			this.setStep(1);
			this.saveCompanyFilter(param);

			if (param.value != null) {
				localStorage.setItem('headerCompany', JSON.stringify(param.value));
			} else {
				this.getHeaderCompanyFilter({ vm: this })
					.then((res) => {
						this.setDefaultCompany();
					})
					.then(() => {
						this.setCachedInput();
					});
			}

			this.hasDocInstrucAction = 'none';
			this.$emit('handleDocInstrucModal', false);
		},
		clearInput() {
			this.$refs.companyHeader.setValue(null);
		},
		setCachedInput() {
			let cachedFilter = this.headerCompanyFilterCache.value;
			const localStorageCache = localStorage.getItem('headerCompany');

			if (localStorageCache) {
				cachedFilter = JSON.parse(localStorageCache);
			} else {
				cachedFilter = this.companyHeaderOptions[0];
				localStorage.setItem('headerCompany', JSON.stringify(cachedFilter));
			}

			this.$refs.companyHeader.setValue(cachedFilter);
		},

		// API METHODS
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getCompanyFilter({ vm: this, params: query });
		},
		nextPage() {
			const meta = this.headerCompanyFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getCompanyFilter({ vm: this, params: query });
		},
		createQueryString(name) {
			const query = new URLSearchParams();
			if (name) query.set('name', name);
			if (name) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
	},
	computed: {
		...mapGetters('filters', [
			'headerCompanyFilter',
			'headerCompanyFilterMeta',
			'headerCompanyFilterCache',
			'isLoadingHeaderCompanyFilter',
		]),
		companyHeaderOptions() {
			const rows = this.headerCompanyFilter != undefined ? this.headerCompanyFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'CompanyInput.scss';
</style>
