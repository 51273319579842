var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contract"},[_c('div',{staticClass:"contract-container"},[_c('div',{staticClass:"contract-row"},[_vm._l((_vm.userPreferences.header),function(item,index){return _c('div',{key:index,staticClass:"field",class:[
					item.name == 'company' ? (!_vm.isWeb ? 'w-100' : '') : '',
					item.name == 'status' ? 'status' : '',
					item.name == 'status' && !_vm.isWeb ? 'w-100' : '',
					item.name == 'contractNumber' && !_vm.isWeb ? 'w-100' : '',
				]},[(item.active)?_c('div',{staticClass:"field__label"},[_c('span',[_vm._v(_vm._s(_vm.$t(`modules.contract_management.table.header.${item.name}`)))])]):_vm._e(),(_vm.data[item.name] != undefined && item.active)?_c('div',{staticClass:"field__value",class:[
						!_vm.isWeb ? 'bold' : '',
						item.name == 'company' ? (!_vm.isWeb ? 'wrap' : 'w-50') : '',
					]},[(item.name != 'status')?_c('span',[_vm._v(" "+_vm._s(item.name == 'company' ? _vm.data[item.name].name : _vm.verifyDate(_vm.data[item.name], item.name))+" ")]):_vm._e(),(item.name == 'status')?_c('StatusChart',{attrs:{"data":_vm.data['status'],"isWeb":_vm.isWeb}}):_vm._e()],1):_vm._e()])}),_c('div',{staticClass:"field",class:_vm.isWeb ? 'ml' : ''},[_c('div',{staticClass:"field__value"},[_c('div',{staticClass:"field__label bell row"},[_c('BellIcon'),_c('SwitchIcon',{attrs:{"data":_vm.data},on:{"handleNotifications":_vm.handleNotifications}})],1)])]),_c('div',{staticClass:"open-table",class:!_vm.isWeb ? 't-3' : ''},[_c('button',{staticClass:"btn-row",on:{"click":_vm.handleTable}},[_c('ArrowDown',{class:_vm.showTable ? 'rotate' : '',attrs:{"stroke":'2',"color":'#234057'}})],1)])],2),(_vm.showTable)?_c('div',{staticClass:"table-container"},[_c('Table',{attrs:{"contractId":_vm.contractId,"isWeb":_vm.isWeb}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }