<template>
	<div class="documents-package__wrapper">
		<div class="documents-package">
			<div class="documents-package__select-one">
				<GenericMultiselect
					class="documents-package__input"
					ref="country"
					:options="bankOption"
					:placeholder="$t('modules.doc_instruction.documents_package.bank')"
					:fullWidth="!isWeb"
					:searchable="true"
					:showIcon="true"
					:type="'country'"
					:multiple="false"
					:needType="true"
					:initialValue="selectedBank"
					@changeInput="changeBank"
				/>
			</div>
			<div class="documents-package__select-two">
				<GenericMultiselect
					class="documents-package__input"
					ref="country"
					:options="consigneeOptions"
					:placeholder="$t('modules.doc_instruction.documents_package.otherRecipient')"
					:fullWidth="!isWeb"
					:searchable="true"
					:showIcon="true"
					:type="'country'"
					:multiple="false"
					:needType="true"
					:initialValue="selectedClient"
					@changeInput="changeCustomer"
				/>
			</div>
			<div class="documents-package__list">
				<div class="documents-package__item">
					<div class="documents-package__item--title">
						{{ $t('modules.templateData.items.invoice') }}
					</div>
					<div class="documents-package__item--checkbox">
						<GenericCheckbox
							id="bank.invoice"
							:value="invoice.bank"
							:emitObject="true"
							@input="(value) => changeShippingType(value.value, true, 'invoice')"
						/>
					</div>
					<div class="documents-package__item--checkbox">
						<GenericCheckbox
							id="other.invoice"
							:value="invoice.other"
							:emitObject="true"
							@input="(value) => changeShippingType(value.value, false, 'invoice')"
						/>
					</div>
				</div>
				<div class="documents-package__item">
					<div class="documents-package__item--title">
						{{ $t('modules.templateData.items.packing') }}
					</div>
					<div class="documents-package__item--checkbox">
						<GenericCheckbox
							id="bank.packing"
							:value="packing.bank"
							:emitObject="true"
							@input="(value) => changeShippingType(value.value, true, 'packing')"
						/>
					</div>
					<div class="documents-package__item--checkbox">
						<GenericCheckbox
							id="other.packing"
							:value="packing.other"
							:emitObject="true"
							@input="(value) => changeShippingType(value.value, false, 'packing')"
						/>
					</div>
				</div>
				<div class="documents-package__item">
					<div class="documents-package__item--title">
						{{ $t('modules.templateData.items.billOfLanding') }}
					</div>
					<div class="documents-package__item--checkbox">
						<GenericCheckbox
							id="bank.billLanding"
							:value="billLanding.bank"
							:emitObject="true"
							@input="(value) => changeShippingType(value.value, true, 'billLanding')"
						/>
					</div>
					<div class="documents-package__item--checkbox">
						<GenericCheckbox
							id="other.billLanding"
							:value="billLanding.other"
							:emitObject="true"
							@input="(value) => changeShippingType(value.value, false, 'billLanding')"
						/>
					</div>
				</div>
				<div class="documents-package__item">
					<div class="documents-package__item--title">
						{{ $t('modules.templateData.items.healthCertificate') }}
					</div>
					<div class="documents-package__item--checkbox">
						<GenericCheckbox
							id="bank.healthCertificate"
							:value="healthCertificate.bank"
							:emitObject="true"
							@input="(value) => changeShippingType(value.value, true, 'healthCertificate')"
						/>
					</div>
					<div class="documents-package__item--checkbox">
						<GenericCheckbox
							id="other.healthCertificate"
							:value="healthCertificate.other"
							:emitObject="true"
							@input="(value) => changeShippingType(value.value, false, 'healthCertificate')"
						/>
					</div>
				</div>
				<div class="documents-package__item">
					<div class="documents-package__item--title">
						{{ $t('modules.templateData.items.certificateOfOrigin') }}
					</div>
					<div class="documents-package__item--checkbox">
						<GenericCheckbox
							id="bank.certificateOrigin"
							:value="certificateOrigin.bank"
							:emitObject="true"
							@input="(value) => changeShippingType(value.value, true, 'certificateOrigin')"
						/>
					</div>
					<div class="documents-package__item--checkbox">
						<GenericCheckbox
							id="other.certificateOrigin"
							:value="certificateOrigin.other"
							:emitObject="true"
							@input="(value) => changeShippingType(value.value, false, 'certificateOrigin')"
						/>
					</div>
				</div>
			</div>
			<div v-if="!isWeb" class="documents-package__card-scroll">
				<div class="documents-package__card-container">
					<div class="documents-package__card-wrapper">
						<div class="documents-package__card-item">
							<div class="">{{ $t('modules.doc_instruction.documents_package.address') }}</div>
						</div>
						<div class="documents-package__card-text">
							{{ selectedBank ? selectedBank.address : '' }}
						</div>
						<!-- TODO Comentado caso o cliente mude de ideia -->
						<!-- <div class="documents-package__card-item">
							<div class="">{{ $t('modules.doc_instruction.documents_package.postCode') }}</div>
							<div class="">250131</div>
						</div>
						<div class="documents-package__card-item">
							<div class="">{{ $t('modules.doc_instruction.documents_package.fax') }}</div>
							<div class="">158634215896</div>
						</div>
						<div class="documents-package__card-item">
							<div class="">{{ $t('modules.doc_instruction.documents_package.taxID') }}</div>
							<div class="">1234567899U</div>
						</div>
						<div class="documents-package__card-item">
							<div class="">{{ $t('modules.doc_instruction.documents_package.receiverName') }}</div>
							<div class="">Wang Hua</div>
						</div> -->
						<button class="documents-package__btn" @click="toggleModalAddBank">
							{{ $t('buttons.edit') }}
						</button>
					</div>
					<div class="documents-package__card-wrapper">
						<div class="documents-package__card-item">
							<div class="">{{ $t('modules.doc_instruction.documents_package.address') }}</div>
						</div>
						<div class="documents-package__card-text">
							{{ selectedClient ? selectedClient.address : '' }}
						</div>
						<!-- TODO Comentado caso o cliente mude de ideia -->
						<!-- <div class="documents-package__card-item">
							<div class="">{{ $t('modules.doc_instruction.documents_package.postCode') }}</div>
							<div class="">250131</div>
						</div>
						<div class="documents-package__card-item">
							<div class="">{{ $t('modules.doc_instruction.documents_package.fax') }}</div>
							<div class="">158634215896</div>
						</div>
						<div class="documents-package__card-item">
							<div class="">{{ $t('modules.doc_instruction.documents_package.taxID') }}</div>
							<div class="">1234567899U</div>
						</div>
						<div class="documents-package__card-item">
							<div class="">{{ $t('modules.doc_instruction.documents_package.receiverName') }}</div>
							<div class="">Wang Hua</div>
						</div> -->
						<button class="documents-package__btn" @click="toggleModalAddBank">
							{{ $t('buttons.edit') }}
						</button>
					</div>
				</div>
			</div>
			<div v-if="isWeb" class="documents-package__card-one">
				<div class="documents-package__card-item">
					<div class="documents-package__card-item--title">
						{{ $t('modules.doc_instruction.documents_package.address') }}
					</div>
				</div>
				<div class="documents-package__card-text">
					{{ selectedBank ? selectedBank.address : '' }}
				</div>
				<!-- TODO Comentado caso o cliente mude de ideia -->
				<!-- <div class="documents-package__card-item">
					<div class="">{{ $t('modules.doc_instruction.documents_package.postCode') }}</div>
					<div class="">250131</div>
				</div>
				<div class="documents-package__card-item">
					<div class="">{{ $t('modules.doc_instruction.documents_package.fax') }}</div>
					<div class="">158634215896</div>
				</div>
				<div class="documents-package__card-item">
					<div class="">{{ $t('modules.doc_instruction.documents_package.taxID') }}</div>
					<div class="">1234567899U</div>
				</div>
				<div class="documents-package__card-item">
					<div class="">{{ $t('modules.doc_instruction.documents_package.receiverName') }}</div>
					<div class="">Wang Hua</div>
				</div> -->
				<button class="documents-package__btn" @click="toggleModalAddBank">
					{{ $t('buttons.edit') }}
				</button>
			</div>
			<div v-if="isWeb" class="documents-package__card-two">
				<div class="documents-package__card-item">
					<div class="">{{ $t('modules.doc_instruction.documents_package.address') }}</div>
				</div>
				<div class="documents-package__card-text">
					{{ selectedClient ? selectedClient.address : '' }}
				</div>
				<!-- TODO Comentado caso o cliente mude de ideia -->
				<!-- <div class="documents-package__card-item">
					<div class="">{{ $t('modules.doc_instruction.documents_package.postCode') }}</div>
					<div class="">250131</div>
				</div>
				<div class="documents-package__card-item">
					<div class="">{{ $t('modules.doc_instruction.documents_package.fax') }}</div>
					<div class="">158634215896</div>
				</div>
				<div class="documents-package__card-item">
					<div class="">{{ $t('modules.doc_instruction.documents_package.taxID') }}</div>
					<div class="">1234567899U</div>
				</div>
				<div class="documents-package__card-item">
					<div class="">{{ $t('modules.doc_instruction.documents_package.receiverName') }}</div>
					<div class="">Wang Hua</div>
				</div> -->
				<button class="documents-package__btn" @click="toggleModalAddBank">
					{{ $t('buttons.edit') }}
				</button>
			</div>
		</div>
		<ModalBank :isEdit="true" v-show="modalAddBankOpened" @closeModal="toggleModalAddBank()" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
import GenericCheckbox from '@/components/shared/GenericCheckbox/GenericCheckbox';
import ModalBank from '@/components/modules/DocInstruction/Modals/ModalBank/ModalBank.vue';

import {
	SET_TEMPLATE_INVOICE_DATA,
	SET_TEMPLATE_PACKING_DATA,
	SET_TEMPLATE_BILL_LANDING_DATA,
	SET_TEMPLATE_HEALTH_CERTIFICATE_DATA,
	SET_TEMPLATE_CERTIFICATE_ORIGIN_DATA,
	SET_TEMPLATE_DESTINATION_BANK_DATA,
	SET_TEMPLATE_INVOICE_SHIPPING_TYPE,
	SET_TEMPLATE_PACKING_SHIPPING_TYPE,
	SET_TEMPLATE_BILL_LANDING_SHIPPING_TYPE,
	SET_TEMPLATE_HEALTH_CERTIFICATE_SHIPPING_TYPE,
	SET_TEMPLATE_CERTIFICATE_ORIGIN_SHIPPING_TYPE,
	SET_TEMPLATE_DESTINATION_CUSTOMER_DATA,
} from '@/store/modules/docInstruction/actions';

export default {
	name: 'DocumentsPackage',
	components: {
		GenericMultiselect,
		GenericCheckbox,
		ModalBank,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		countryOptions: [{ id: 1, name: 'City' }],
		selectedClient: null,
		selectedBank: null,
		invoice: { bank: false, other: false },
		packing: { bank: false, other: false },
		billLanding: { bank: false, other: false },
		healthCertificate: { bank: false, other: false },
		certificateOrigin: { bank: false, other: false },
		modalAddBankOpened: false,
	}),
	created() {
		this.changeShippingType(false, true, 'invoice');
		this.changeShippingType(false, true, 'packing');
		this.changeShippingType(false, true, 'billLanding');
		this.changeShippingType(false, true, 'healthCertificate');
		this.changeShippingType(false, true, 'certificateOrigin');
		this.changeShippingType(false, false, 'invoice');
		this.changeShippingType(false, false, 'packing');
		this.changeShippingType(false, false, 'billLanding');
		this.changeShippingType(false, false, 'healthCertificate');
		this.changeShippingType(false, false, 'certificateOrigin');
		},
	watch: {
		
		destinationBank: {
			handler(newValue) {
				if (newValue == undefined) {
					this.selectedBank = null;
				}
			},
			deep: true,
		},
		destinationCustomer: {
			handler(newValue) {
				if (newValue == undefined) {
					this.selectedClient = null;
				}
			},
			deep: true,
		},
	},
	computed: {
		...mapGetters('docInstruction', ['templateData', 'clientsList', 'bankList']),
		consigneeOptions() {
			return (
				this.clientsList.map((item) => {
					return { id: item.id, name: item.name, address: item.address };
				}) ?? []
			);
		},
		bankOption() {
			return (
				this.bankList.map((item) => {
					return { id: item.id, name: item.name, address: item.address };
				}) ?? []
			);
		},
		newTemplateData() {
			return this.templateData;
		},
		invoiceType() {
			return this.templateData.invoice.shippingType;
		},
		packingType() {
			return this.templateData.packing.shippingType;
		},
		billLandingType() {
			return this.templateData.billLanding.shippingType;
		},
		healthCertificateType() {
			return this.templateData.healthCertificate.shippingType;
		},
		certificateOriginType() {
			return this.templateData.certificateOrigin.shippingType;
		},
		destinationBank() {
			return this.templateData.destinationBank;
		},
		destinationCustomer() {
			return this.templateData.destinationCustomer;
		},
	},
	methods: {
		...mapActions('docInstruction', {
			setInvoice: SET_TEMPLATE_INVOICE_DATA,
			setPacking: SET_TEMPLATE_PACKING_DATA,
			setBill: SET_TEMPLATE_BILL_LANDING_DATA,
			setHealth: SET_TEMPLATE_HEALTH_CERTIFICATE_DATA,
			setCertificate: SET_TEMPLATE_CERTIFICATE_ORIGIN_DATA,
			setDestinationBank: SET_TEMPLATE_DESTINATION_BANK_DATA,
			setDestinationCustomer: SET_TEMPLATE_DESTINATION_CUSTOMER_DATA,
			setInvoiceShippingType: SET_TEMPLATE_INVOICE_SHIPPING_TYPE,
			setPackingShippingType: SET_TEMPLATE_PACKING_SHIPPING_TYPE,
			setBillLandingShippingType: SET_TEMPLATE_BILL_LANDING_SHIPPING_TYPE,
			setHealthCertificateShippingType: SET_TEMPLATE_HEALTH_CERTIFICATE_SHIPPING_TYPE,
			setCertificateOriginShippingType: SET_TEMPLATE_CERTIFICATE_ORIGIN_SHIPPING_TYPE,
		}),

		changeCustomer(value) {
			this.selectedClient = value.value;
			this.setDestinationCustomer(this.selectedClient);
		},

		changeBank(value) {
			this.selectedBank = value.value;
			this.setDestinationBank(this.selectedBank);
		},

		changeShippingType(value, isBank, propertyName) {
			if (isBank) {
				this[propertyName].bank = value;
			} else {
				this[propertyName].other = value;
			}

			let shippingType = null;

			if (this[propertyName].bank && !this[propertyName].other) {
				shippingType = 1;
			} else if (!this[propertyName].bank && this[propertyName].other) {
				shippingType = 2;
			} else if (this[propertyName].bank && this[propertyName].other) {
				shippingType = 99;
			}

			if (propertyName == 'invoice') {
				this.setInvoiceShippingType(shippingType);
			} else if (propertyName == 'packing') {
				this.setPackingShippingType(shippingType);
			} else if (propertyName == 'billLanding') {
				this.setBillLandingShippingType(shippingType);
			} else if (propertyName == 'healthCertificate') {
				this.setHealthCertificateShippingType(shippingType);
			} else if (propertyName == 'certificateOrigin') {
				this.setCertificateOriginShippingType(shippingType);
			}
		},

		verifyType(origin, object) {
			this[object].bank = this[origin] == 1 || this[origin] == 99;
			this[object].other = this[origin] == 2 || this[origin] == 99;
		},

		toggleModalAddBank() {
			this.modalAddBankOpened = !this.modalAddBankOpened;
		},
	},
};
</script>

<style lang="scss">
@import 'DocumentsPackage.scss';
</style>
