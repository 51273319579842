<template>
	<div class="box-icon">
		<UsersIcon v-if="menuLabel == 'sidebar.user_management'" class="link-list__icon" />
		<ContractIcon v-if="menuLabel == 'sidebar.contract_management'" class="link-list__icon" />
		<DashboardIcon v-if="menuLabel == 'sidebar.dashboard'" class="link-list__icon" />
		<LoadManagementIcon v-if="menuLabel == 'sidebar.load_management'" class="link-list__icon" />
		<DocumentManagementIcon v-if="menuLabel == 'sidebar.doc_management'" class="link-list__icon" />
		<FinancialManagementIcon
			v-if="menuLabel == 'sidebar.financial_management'"
			class="link-list__icon"
		/>
		<DocInstructionIcon v-if="menuLabel == 'sidebar.doc_instruction'" class="link-list__icon" />
	</div>
</template>

<script>
import UsersIcon from '@/components/shared/Icons/MenuIcons/UsersIcon.vue';
import ContractIcon from '@/components/shared/Icons/MenuIcons/ContractIcon.vue';
import DashboardIcon from '@/components/shared/Icons/MenuIcons/DashboardIcon.vue';
import LoadManagementIcon from '@/components/shared/Icons/MenuIcons/LoadManagementIcon.vue';
import DocumentManagementIcon from '@/components/shared/Icons/MenuIcons/DocumentManagementIcon.vue';
import FinancialManagementIcon from '@/components/shared/Icons/MenuIcons/FinancialManagementIcon.vue';
import DocInstructionIcon from '@/components/shared/Icons/MenuIcons/DocInstructionIcon.vue';

export default {
	name: 'MenuIcons',
	components: {
		UsersIcon,
		ContractIcon,
		DashboardIcon,
		LoadManagementIcon,
		DocumentManagementIcon,
		FinancialManagementIcon,
		DocInstructionIcon,
	},
	props: {
		menuLabel: {
			required: true,
		},
	},
	data: () => ({}),
	created() {},
	methods: {},
};
</script>

<style lang="scss">
@import 'MenuIcons.scss';
</style>
