<template>
	<div class="summary">
		<div class="summary-row">
			<span class="summary-row__title"></span>
			<span class="summary-row__title">
				{{ $t('modules.financial_management.titles.overdue') }}
			</span>
			<span class="summary-row__title">
				{{ $t('modules.financial_management.titles.settle') }}
			</span>
		</div>
		<div v-for="item in summary" :key="item.currency" class="summary-row">
			<span class="summary-row__text">{{ setCurrency(item.currency) }}</span>
			<span class="summary-row__text" :style="{ minWidth: customSize.first }">
				{{ formatMoney(item.overDue) }}
			</span>
			<span class="summary-row__text" :style="{ minWidth: customSize.second }">
				{{ formatMoney(item.due) }}
			</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'FinancialSummary',
	components: {},
	props: {
		isWeb: {
			required: true,
		},
		due: {
			required: true,
		},
		overDue: {
			required: true,
		},
		isLoading: {
			required: true,
		},
	},
	data: () => ({
		summary: [],
		customSize: { first: '70px', second: '70px' },
	}),
	methods: {
		formatMoney(param) {
			return parseFloat(param)
				.toLocaleString('pt-br', {
					style: 'currency',
					currency: 'BRL',
				})
				.replace('R$', '')
				.trim();
		},
		setCurrency(value) {
			switch (value) {
				case 'USD':
					return this.$t('modules.financial_management.currency.dolar');

				case 'EUR':
					return this.$t('modules.financial_management.currency.euro');

				default:
					return this.$t('modules.financial_management.currency.yen');
			}
		},
		getTextSize() {
			this.customSize = {
				'en-US': { first: '146px', second: '108px' },
				'pt-BR': { first: '70px', second: '70px' },
				'es-ES': { first: '83px', second: '116px' },
				'ar-AR': { first: '70px', second: '70px' },
				'cn-CN': { first: '70px', second: '96px' },
			}[this.userLang];
		},
		sortData() {
			const initializeHolder = () => ({ currency: '', overDue: 0, due: 0 });
			const addBalance = (holder, item, key) => { holder[key] += item.invoiceBalance !== undefined ? item.invoiceBalance : item.allocatedValue; };
			let holder = initializeHolder();
			holder.currency = this.overDue?.[0]?.currency || this.due?.[0]?.currency || '';
			this.overDue.forEach((dueItem) => addBalance(holder, dueItem, 'overDue')); 
			this.due.forEach((dueItem) => addBalance(holder, dueItem, 'due'));
			this.summary.push(holder);
		},
	},
	computed: {
		...mapGetters('user', ['userLang']),
		...mapGetters('financialManagementFilters', ['lastQueryString']),
		...mapGetters('financialManagement', ['isLoadingPrePayments', 'isLoadingInvoices']),
	},
	watch: {
		isLoadingPrePayments(value) {
			this.summary = [];
			if (value == 'success') {
				this.sortData();
			}
		},
		userLang() {
			this.getTextSize();
		},
		lastQueryString() {
			this.summary = [];
		},
	},
};
</script>

<style lang="scss">
@import 'FinancialSummary.scss';
</style>
