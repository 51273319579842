<template>
	<div class="doc-headOptions">
		<div class="doc-headOptions-mobile__title" v-if="(step === 3 || step === 4) && !isWeb">
			{{ title }}
		</div>

		<SecondStepOption
			v-if="step === 2"
			@goToStep="goToTheBackStep"
			@goToNewModel="goToNewModel"
			:isWeb="isWeb"
		/>

		<ThirdStepOption
			v-if="step === 3"
			:step="step"
			@goToStep="goToTheBackStep"
			@goToPreviewStep="goToPreviewStep"
			:isWeb="isWeb"
		/>

		<FourthStepOption
			v-if="step === 4"
			:step="step"
			@goToStep="goToTheBackStep"
			@goToPreviewStep="goToPreviewStep"
			:isWeb="isWeb"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { RESET_STEPS, SET_STEP } from '@/store/modules/docInstruction/actions';
import { CLEAN_TEMPLATE } from '@/store/modules/docInstruction/selectInstruction/actions';

import SecondStepOption from './SecondStepOption/SecondStepOption.vue';
import ThirdStepOption from './ThirdStepOption/ThirdStepOption.vue';
import FourthStepOption from './FourthStepOption/FourthStepOption.vue';

export default {
	name: 'HeadOptions',
	props: {
		step: {
			require: true,
		},
		isWeb: {
			require: true,
		},
	},
	data: () => ({
		title: '',
	}),
	components: {
		SecondStepOption,
		ThirdStepOption,
		FourthStepOption,
	},
	methods: {
		...mapActions('docInstruction', {
			setResetSteps: RESET_STEPS,
			setStep: SET_STEP,
		}),
		...mapActions('selectInstruction', {
			clearTemplate: CLEAN_TEMPLATE,
		}),
		goToTheBackStep() {
			this.clearTemplate();
			this.setResetSteps();
		},
		goToPreviewStep() {
			this.setStep(this.step - 1);
		},
		goToNewModel(salesContract, selectedContractsSequence) {
			this.$router.push({
				path: `/main/docInstruction/newTemplate`,
			});
		},
	},
	watch: {
		$route: {
			handler(route) {
				this.title = this.$t(`${route.name}`);
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style lang="scss">
@import 'HeadOptions.scss';
</style>
