<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 25 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.61689 12.9808C5.96838 13.3124 5.98448 13.8662 5.65286 14.2177L3.24818 16.7662C2.93449 17.0987 2.41785 17.1337 2.06221 16.8466L0.325463 15.4449C-0.0505678 15.1414 -0.109368 14.5905 0.194125 14.2145C0.497663 13.8384 1.04852 13.7796 1.42459 14.0832L2.53112 14.9762L4.37999 13.0167C4.71162 12.6652 5.26541 12.6491 5.61689 12.9808Z"
			:fill="color"
		/>
		<path
			d="M8.83936 14.2541C8.48727 14.2541 8.20186 14.5395 8.20186 14.8916C8.20186 15.2437 8.48727 15.5291 8.83936 15.5291H23.1749C23.527 15.5291 23.8124 15.2437 23.8124 14.8916C23.8124 14.5395 23.527 14.2541 23.1749 14.2541H8.83936Z"
			:fill="color"
			:stroke="color"
			stroke-width="0.4"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.61689 6.60969C5.96838 6.94131 5.98448 7.4951 5.65286 7.84659L3.24818 10.3951C2.93449 10.7276 2.41785 10.7626 2.06221 10.4755L0.325463 9.07382C-0.0505678 8.77032 -0.109368 8.21942 0.194125 7.84339C0.497663 7.46732 1.04852 7.40852 1.42459 7.71206L2.53112 8.60512L4.37999 6.64561C4.71162 6.29412 5.26541 6.27802 5.61689 6.60969Z"
			:fill="color"
		/>
		<path
			d="M8.83936 7.88301C8.48727 7.88301 8.20186 8.16842 8.20186 8.52051C8.20186 8.8726 8.48727 9.15801 8.83936 9.15801H23.1749C23.527 9.15801 23.8124 8.8726 23.8124 8.52051C23.8124 8.16842 23.527 7.88301 23.1749 7.88301H8.83936Z"
			:fill="color"
			:stroke="color"
			stroke-width="0.4"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.61694 0.238595C5.96838 0.57022 5.98448 1.12401 5.65286 1.47549L3.24818 4.02402C2.93449 4.35647 2.41785 4.39147 2.0622 4.10443L0.325504 2.70272C-0.0505716 2.39923 -0.109372 1.84833 0.194122 1.4723C0.49766 1.09622 1.04856 1.03743 1.42459 1.34096L2.53112 2.23403L4.37999 0.274514C4.71166 -0.0769733 5.26545 -0.0930732 5.61694 0.238595Z"
			:fill="color"
		/>
		<path
			d="M8.83936 1.51191C8.48727 1.51191 8.20186 1.79733 8.20186 2.14941C8.20186 2.5015 8.48727 2.78691 8.83936 2.78691H23.1749C23.527 2.78691 23.8124 2.5015 23.8124 2.14941C23.8124 1.79733 23.527 1.51191 23.1749 1.51191H8.83936Z"
			:fill="color"
			:stroke="color"
			stroke-width="0.4"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#B47D4A',
		},
		height: {
			default: '18',
		},
		width: {
			default: '25',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
