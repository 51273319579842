<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'App',
	components: {},
	computed: {},
};
</script>

<style lang="scss">
@import 'App.scss';
</style>
