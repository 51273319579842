<template>
	<div class="mobile-sidebar" :class="!isOpen ? 'closed' : ''">
		<div class="mobile-sidebar__header">
			<div class="mobile-sidebar__logo">
				<MenuLogo />
			</div>
			<div class="mobile-sidebar__arrow" @click="handleSidebar()">
				<SidebarArrow />
			</div>
		</div>
		<div class="mobile-sidebar__profile">
			<UserInfo />
		</div>
		<div class="mobile-sidebar__content">
			<MenuContent :isMobile="true" @handleSidebar="handleSidebar" />
			<LangSelector class="mobile-sidebar__content--lang" />
		</div>
	</div>
</template>

<script>
import MenuLogo from '@/components/shared/Icons/MenuLogo.vue';
import SidebarArrow from '@/components/shared/Icons/SidebarArrow.vue';
import UserInfo from '@/components/shared/UserInfo/UserInfo.vue';
import MenuContent from '@/components/shared/MenuContent/MenuContent.vue';
import LangSelector from '@/components/shared/LangSelector/LangSelector.vue';

export default {
	name: 'MobileSidebar',
	props: {
		isOpen: {
			required: true,
		},
	},
	components: { MenuLogo, SidebarArrow, UserInfo, MenuContent, LangSelector },
	data: () => ({}),
	mounted() {},
	methods: {
		handleSidebar() {
			this.$emit('handleSidebar');
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'MobileSidebar.scss';
</style>
