<template>
	<form class="form" id="login-form" v-on:submit.prevent="doLogin">
		<div class="field">
			<label class="field__label" for="login">
				{{ $t('modules.login.login_label') }}
			</label>
			<input class="field__input" type="text" name="login" id="login-input" v-model="login" />
		</div>
		<div class="field">
			<label class="field__label" for="login">
				{{ $t('modules.login.password_label') }}
			</label>
			<InputPassword id="password-input" v-model="password" />
		</div>
		<div class="actions">
			<button
				type="submit"
				id="login-btn"
				class="actions__btn"
				v-if="!isLoginLoading && !isLoadingUserData"
			>
				<p>{{ $t('buttons.login') }}</p>
				<LoginArrow />
			</button>
			<SpinnerIcon color="white" v-else />
		</div>
	</form>
</template>

<script>
import LoginArrow from '@/components/shared/Icons/LoginArrow.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon.vue';
import InputPassword from '@/components/shared/InputPassword/InputPassword.vue';
import { LOGIN_REQUEST } from '@/store/common/auth/actions';
import { USER_DATA_REQUEST } from '@/store/common/user/actions';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'LoginForm',
	components: {
		InputPassword,
		LoginArrow,
		SpinnerIcon,
	},
	data: () => ({
		login: null,
		password: null,
		redirectTwoWay: true,
	}),
	mounted() {},
	methods: {
		...mapActions('auth', { loginRequest: LOGIN_REQUEST }),
		...mapActions('user', { getUserData: USER_DATA_REQUEST }),
		doLogin() {
			if (!this.login) {
				return this.$toastr.e(this.$t('errors.login_required'));
			}
			if (!this.password) {
				return this.$toastr.e(this.$t('errors.password_required'));
			}
			const body = {
				login: this.login,
				password: this.password,
			};
			this.loginRequest({ login: body, vm: this }).then((res) => {
				if (this.redirectTwoWay) {
					return this.$emit('handleTwoWay', res.hashCode);
				}

				this.getUserData({ vm: this }).then(() => {
					this.$router.push({ path: '/main/dashboard' });
				});
			});
		},
		viewChangePassword() {
			this.$emit('viewChangePassword');
		},
	},
	computed: {
		...mapGetters('auth', ['isLoginLoading']),
		...mapGetters('user', ['isLoadingUserData']),
	},
};
</script>

<style lang="scss" scoped>
@import 'LoginForm.scss';
</style>
