<template>
	<div class="modal">
		<ModalStructure :width="width" :height="height" :scroll="false">
			<template v-slot:title>
				<div class="modal__header">
					<p>
						{{ $t('modules.doc_instruction.modal_no_data.title') }}
					</p>
					<hr />
				</div>
			</template>
			<template v-slot:header>
				<div class="modal__body">
					<p>
						{{ $t('modules.doc_instruction.modal_no_data.text') }}
					</p>
					<button @click="handleCancel">
						{{ $t('buttons.back') }}
					</button>
				</div>
			</template>
		</ModalStructure>
	</div>
</template>

<script>
import ModalStructure from '@/components/shared/Modals/ModalStructure/ModalStructure.vue';

export default {
	name: 'ModalNoData',
	components: { ModalStructure },
	data: () => ({
		windowWidth: window.innerWidth,
	}),
	methods: {
		handleCancel() {
			this.$emit('closeModal');
		},
	},
	computed: {
		isWeb() {
			return this.windowWidth > 992;
		},
		height() {
			return this.isWeb ? '206px' : '200px';
		},
		width() {
			return this.isWeb ? '487px' : '100%';
		},
	},
};
</script>
<style scoped lang="scss">
@import 'ModalNoData.scss';
</style>
