import {
	CLEAR_USER_STATE,
	USER_DATA_SUCCESS,
	USER_DATA_REQUEST,
	USER_DATA_FAILED,
	CHANGE_USER_LANG,
} from './actions';
import api from '@/utils/api';
import { validators } from '@/utils/validators';

const { http } = api.getInstance();
const USER_URL = '/users';
const AUTH_URL = '/auth';

const defaultState = () => ({
	user: { login: '', name: '', email: '', cpf: null, uuid: null },
	permissions: [],
	lang: 'en',
	status: '',
});

const state = defaultState();

const getters = {
	userInfo: (state) => state.user,
	userPermissions: (state) => state.permissions,
	userLang: (state) => state.lang,
	isLoadingUserData: (state) => state.status === 'loading',
};

const mutations = {
	[CLEAR_USER_STATE]: (state) => {
		Object.assign(state, defaultState());
	},
	[USER_DATA_SUCCESS]: (state, { data }) => {
		state.user = data;
		state.lang = data.language;
		state.status = 'success';
	},
	[USER_DATA_REQUEST]: (state) => {
		state.user = null;
		state.status = 'loading';
	},
	[USER_DATA_FAILED]: (state) => {
		state.user = null;
		state.status = 'error';
	},
	[CHANGE_USER_LANG]: (state, lang) => {
		state.lang = lang;
	},
};

const actions = {
	[USER_DATA_REQUEST]({ commit }, { login, permissions, vm }) {
		return new Promise((resolve, reject) => {
			commit(USER_DATA_REQUEST);

			http({
				method: 'get',
				url: `${AUTH_URL}/user-data`,
			})
				.then((response) => {
					http.defaults.headers.common['Accept-Language'] = response.data.language;
					commit(USER_DATA_SUCCESS, { data: response.data });
					resolve(response);
				})
				.catch((error) => {
					commit(USER_DATA_FAILED);
					reject(error);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CHANGE_USER_LANG]({ commit }, { vm, id, body, fileLang }) {
		return new Promise((resolve, reject) => {
			http({
				method: 'PATCH',
				url: `${USER_URL}/${id}`,
				data: body,
			})
				.then((response) => {
					http.defaults.headers.common['Accept-Language'] = fileLang.toLowerCase();

					localStorage.setItem("systemLanguage", fileLang.toLowerCase());
					validators(fileLang);

					commit(CHANGE_USER_LANG, fileLang);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
