<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="wrapper">
				<div
					class="container"
					:class="scroll ? 'scroll' : ''"
					:style="{ width: width, height: height }"
				>
					<header class="title" v-if="hasTitle">
						<slot name="title"></slot>
					</header>
					<header class="header" v-if="hasHeader">
						<slot name="header"></slot>
					</header>
					<main class="body">
						<slot name="body"></slot>
					</main>
					<footer class="footer">
						<slot name="footer"></slot>
					</footer>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'ModalStructure',
	props: {
		width: {
			default: '250px',
		},
		height: {
			default: '250px',
		},
		scroll: {
			default: false,
		},
		hasTitle: {
			default: true,
		},
		hasHeader: {
			default: true,
		},
	},
	components: {},
	data: () => ({}),
	methods: {},
	computed: {},
};
</script>
<style scoped lang="scss">
@import 'ModalStructure.scss';
</style>
