import tableES from './contractTable/table-es';

export default {
	buttons: {
		cancel: 'Cancelar',
		login: 'Entrar',
		next: 'Próximo',
		ok: 'OK',
		save: 'Salvar',
		save_password: 'Salvar contraseña',
		filter: 'Filtrar',
		filters: 'Filtros',
		clear_filter: 'Limpiar Filtros',
		add: 'Adicionar',
		delete: 'Borrar',
		inative: 'Desactivar',
		edit_column: 'Editar columnas',
		confirm: 'Confirmar',
		back: 'Regresar',
		keep_inactive: 'Inactivar',
		keep_active: 'Activar',
		previous: 'Anterior',
		seeMore: 'Ver mas',
		seeLess: 'Ver menos',
		continue: 'Continuar',
		disable: 'Desactivar',
		active: 'Activar',
		new_template: 'Nuevo modelo',
		save_template: 'Salvar modelo',
		change_template: 'Alterar modelo',
		add_bank: 'Adicionar banco',
		notify: 'Adicionar destinatario/notificado',
		edit: 'Editar',
		disapprove: 'Reprovar',
		remove: 'Remover'
	},
	errors: {
		login_required: 'usuario',
		password_required: 'Contraseña',
		empty_data: 'Sin datos a exhibir',
		dashboard_empty_data: 'Sin nuevas actualizaciones',
		fill_fields: 'Complete todos los campos.',
		email_invalid: 'Dirección de correo invalida',
		passwords_rules:
			'Por favor averiguar si la contraseña posee 8 caracteres, y al menos  una letra miniscula, una letra mayuscula, un numero y un caracter especial.',
		passwords_dismatch: 'Contraseñas no corresponden',
		fixed_column: 'La columna no se puede deshabilitar',
		no_options: 'No encontrado',
		no_items: 'sin resultados ',
		get_items_error: 'Error al cargar la información',
		documents_error: 'Documentos no encontrados',
		template_name_required: 'El nombre de la plantilla es obligatorio',
	},
	sidebar: {
		user_management: 'Usuario',
		contract_management: 'Contrato',
		contract_details: 'Detalles del contrato',
		dashboard: 'Panel de control',
		load_management: 'Gestión de carga',
		doc_management: 'Gestión de Documentos',
		financial_management: 'Gestión Financiera',
		doc_instruction: 'Instruccion Documental',
	},
	header: {
		user_management: 'Gestión de Usuarios',
		contract_management: 'Gestión de Contractos',
		contract_details: 'Detalles del Contrato',
		header_contracts: 'Contratos',
		header_sequences: 'Secuencia',
		header_loads: 'Carga',
		header_po: 'PO',
		dashboard: 'Panel de control',
		load_management: 'Gestión de Carga',
		customer_company: 'Cliente',
		doc_management: 'Gestión de Documentos',
		financial_management: 'Gestión Financiera',
		doc_instruction: 'Instrucción Documental',
		new_template: 'Nuevo Modelo',
		edit_template: 'Editar Modelo',
	},
	months: {
		jan: 'Ene',
		feb: 'Feb',
		mar: 'Mar',
		apr: 'Abr',
		may: 'Mayo',
		jun: 'Jun',
		jul: 'Jul',
		aug: 'Ago',
		sep: 'Sep',
		oct: 'Oct',
		nov: 'Nov',
		dec: 'Dic',
	},
	contract_status: {
		contract: 'Contracto',
		load: 'Carregamiento planeado',
		process: 'Cargado',
		container: 'Contenedor',
		invoice: 'Loaded/Facturado',
		finished: 'Finalizado',
	},
	contract_step: {
		creation: 'Contrato en creación',
		released_seara_restriction: 'Credito/ precio',
		released_customer_restriction: 'Liberado con restricción',
		released: 'Liberado',
		programmed: 'Programado',
		planned: 'Planificado',
		billed: 'Facturada',
		issued_document: 'Cert. sanitario emitido',
		gate_in: 'Entregado en el puerto de origen',
		pre_payment_pending: 'Aguardando pago anticipado',
		rid: 'Despachado',
		draft_bl: 'Draft BL',
		boarded: 'Embarcado',
		documentation_issued: 'Documentación Emitida',
		documentation_sended: 'Documentación Enviada',
		documentation_delivered: 'Documentos recibidos en el destino',
		shipped_origin: 'Embarcado',
		transshipment_port: 'En Puerto de Transbordo',
		landed_destination: 'Desembarcado en el destino',
		gate_out: 'Retirado del puerto de destino',
		empty_return: 'Contenedor vacío devuelto',
		opened: 'Abierta',
		opened_delay: 'Atrasado',
		partial_paid: 'Pagado parcial',
		total_paid: 'Liquidado',
		finished: 'Finalizado',
		processing: 'En procesamiento',
		in_production: 'En Producción',
		in_loading: 'Cargando',
		delivered_in_pod: 'Entregado en el destino',
	},
	modules: {
		login: {
			login_label: 'Usuario',
			password_label: 'Contraseña',
			forgot_password: '¿Olvidaste tu contraseña?',
			forgot_password_title: 'Olvide mi contraseña',
			forgot_password_text:
				'¿olvidaste tu contraseña? informe su correo electrónico para obtener un link para restablecer su contraseña.',
			email_sended: 'Correo electrónico enviado para crear su nueva contraseña.',
			code_sended: 'Código de seguridad enviado',
			verify_email:
				'Por favor revise su correo electrónico e inserte el código de seguridad (6 dígitos) para completar su inicio de sesión.',
		},
		resetPassword: {
			new_password_label: 'Nueva contraseña',
			confirm_password_label: 'Confirmar nueva contraseña',
			new_password_title: 'Nueva contraseña',
			new_password_text: 'Ahora puedes cambiar tu contraseña:',
			password_changed_succesfully: 'Contraseña cambiada con éxito',
			rules_title: 'Criterios para nueva contraseña',
			rule_uppercase: 'Al menos una letra mayúscula',
			rule_lowercase: 'Al menos una letra minúscula',
			rule_number: 'Al menos un número',
			rule_length: 'Al menos 8 caracteres',
			rule_special: 'Al menos un carácter especial (!, @, #, $, %, &, *, ., etc)',
		},
		user_management: {
			name_placeholder: 'Nombre del usuario',
			email_placeholder: 'Correo electrónico',
			company_placeholder: 'Nombre de empresa',
			status_placeholder: 'Status',
			profiles: {
				client: 'Cliente',
				intermediary: 'Intermediario',
				internal: 'Interno',
			},
			status: {
				active: 'Activo',
				suspended: 'Inactivo',
			},
			requests_messages: {
				user_created: 'Usuario creado con éxito!',
				user_updated: 'Usuario actualizado con éxito!',
				user_deleted: 'Usuario desactivado exitosamente.',
				user_reactived: 'Usuário reactivado com sucesso.'
			},
			modal: {
				new_user: 'Registro de usuario',
				edit_user: 'Editar usuario',
				delete_question: '¿Estás seguro de que deseas desactivar a este usuario?',
				reactive_question: '¿Estás seguro de que deseas reactivar a este usuario?',
				name_label: 'Nombre',
				name_placeholder: 'Nombre completo',
				company_label: 'Empresa (nombre completo)',
				company_placeholder: 'Empresa (nombre fantasia)',
				email_label: 'Correo electrónico',
				email_placeholder: 'Tu correo electrónico',
				phone_label: 'Número de teléfono móvil',
				phone_placeholder: 'Número de teléfono',
				profile_label: 'Perfil',
				profile_placeholder: 'Perfil del usuario',
				status_label: 'Status',
				status_placeholder: 'Status del usuario',
				company_manager_label: 'Empresas que gestionas',
				company_manager_placeholder: 'Ingrese el nombre de la empresa',
				confirmation_active:
					'¿Quieres activar este usuario? <br /> Al activar al usuario, éste recibirá un email de bienvenida.<br/> ¿Quieres proceder?',
			},
			table: {
				name: 'Nombre',
				email: 'Correo electrónico',
				code: 'Código',
				status: 'Status',
			},
		},
		contract_management: {
			table: tableES,
			filter: {
				start_date: 'Fecha inicial:',
				end_date: 'Fecha final',
				contract_start_date: 'Fecha inicial del contrato',
				contract_end_date: 'Fecha final del contrato',
				contract_placeholder: 'Contrato',
				company_placeholder: 'Empresa',
				transport_placeholder: 'Transporte',
				product_placeholder: 'Producto',
				purchaseOrder_placeholder: 'Orden de compra',
				market_placeholder: 'Mercado',
			},
			column_modal: {
				title_modal: 'Editar columnas',
				header_columns: 'Encabezado (por contrato)',
				details_columns: 'Detalles (por carga)',
				active_status: 'Activo',
				disable_status: 'Inactivo',
				exit_question: '¿Quieres cerrar sin guardar tus cambios?',
			},
			requests_messages: {
				preferences_updated: 'Columnas salvadas con éxito.',
			},
		},
		contract_detail: {
			card: {
				title_tracking: 'gestíon de contratos ',
				title_contract: 'Contrato',
				title_sequence: 'Secuencia',
				title_load: 'numero de carga',
				title_document: 'gestíon de documentos',
			},
			contract: {
				companyName: 'Empresa',
				competenceMonth: 'Mes de la Competencia',
				contractDate: 'Fecha del contrato',
				contractNumber: 'Número de contrato',
				incoterm: 'Incoterm',
				market: 'Mercado',
				paymentMethod: 'Modalidad de pago',
				pod: 'Pais de Destino',
				priceType: 'Moneda',
				purchaseOrder: 'Orden de compra',
				purchaseOrderClient: 'Orden de compra del cliente',
				releaseDate: 'Fecha de liberación ',
				totalPendingVolume: 'Volumen pendiente',
				totalVolume: 'Volumen total',
				traderName: 'Comerciante',
				approved: 'Aprobado',
				disapproved: 'Desaprobado',
				approve: 'Aprobar',
				disapprove: 'Desaprobar',
				preposition: 'a las',
			},
			sequence: {
				brand: 'Marca',
				cneCode: 'Carta de crédito',
				contractSequence: 'Secuencia',
				dsInternationalOfc: 'Descripción del producto',
				endShipDate: 'Última fecha de envío',
				groupingDescription: 'Aprupamiento',
				permissionImt: 'Licencia de importación',
				permissionImtDate: 'Fecha de caducidad (licencia)',
				releaseSequenceDate: 'Fecha de liberación (secuencia)',
				sku: 'Código del producto',
				startShipDate: 'Primera fecha de envío',
				statusSequence: 'Status de la secuencia',
				totalVolume: 'Volumen',
				unitPrice: 'Precio unitario',
				weeksToShip: 'Semana de embarque',
				initials: 'Sigla',
			},
			load: {
				invoicedDate: 'Fecha facturación (planta)',
				loadNumber: 'Número de carga',
				loadPlace: 'Local de embarque',
				productionDate: 'Fecha de producción',
				totalGrossWeight: 'Peso bruto',
				totalKg: 'Peso total (kg)',
				totalVolume: 'Volumen total',
			},
			documents: {
				awbCourier: 'Courier awb',
				billLanding: 'BL/CRT/AWB',
				billLandingDate: 'Fecha del BL/CRT/AWB',
				blDate: 'Fecha del BL/CRT/AWB',
				courierDate: 'Fecha del envío de courier',
				csiIssuanceDate: 'Fecha de emisión del cert. sanitário',
				invoiceNumber: 'Factura',
				courierCompany: 'Empresa del courier',
				bankAwbCourier: 'Courier awb (banco)',
				bankCourierDate: 'Fecha de envío al Banco',
				bankCourierCompany: 'Courier (banco)',
			},
			table: {
				title_international: 'Barco / vehiculo',
				title_maritime: 'Marítimo',
				title_mercosul: 'transportista',
			},
			internationalTransport: {
				boardingType: 'Modalidad de embarque',
				origin: 'Puerto de embarque',
				destination: 'Puerto de destino',
				expectedEtdDate: 'ETS POL',
				expectedEtbDate: 'ATS POL',
				realAtdDate: 'ETA POD',
				realAtbDate: 'ETA POD',
			},
			maritimeTransport: {
				provider: 'Transportista',
				vessel: 'Buque / camión',
				container: 'Contenedor',
				bookingNumber: 'Número de reserva',
			},
			mercosurTransport: {
				shippingCompany: 'Transportista',
				vehiclePlate: 'Viaje del barco / placa de camión',
				localization: 'Ubicación',
				borderCrossingDate: 'Fecha de cruze en frontera',
			},
		},
		dashboard: {
			table: {
				title: 'Últimas actualización',
				contract: 'Contrato',
				sequence: 'Secuencia',
				load: 'Número De Carga',
				transport: 'Tipo De Transporte',
				invoice: 'Factura',
				code: 'Codigo De Producto',
				status: 'Status',
				volume: 'Peso neto',
			},
			charts: {
				analysis_title: 'En análisis',
				finance_title: 'Gestión Financiera',
				rounded: {
					in_process: 'En proceso',
					release_retrictions: ['Liberado Con ', 'Restricción De Embarque'],
					released: 'Liberado',
					open: 'Abierto',
					open_delay: 'Atrasado',
					partial_paid: 'Pagado Parcial',
					paid: 'Liquidado',
					finished: 'Finalizado',
				},
			},
			operation_chart: {
				operation_title: 'En operación',
				in_production: 'En producción',
				in_charging: 'Cargando',
				gate_in: 'Ingresado En El Puerto De Origen',
				wait_prepayment: 'Aguardando Pago Anticipado',
				embedded_doc: 'Documentos Recibidos En El Destino',
				sent_doc: 'Documentación Enviada',
				delivered: 'Embarcado',
				delivered_pod: 'Entregado En El Destino',
				gate_out: 'Retirado Del Puerto De Destino',
				empty_returned: 'Contenedor Vacío Devuelto',
			},
		},
		load_management: {
			filter: {
				contract: 'Contrato / Secuencia',
				po: 'Orden De Compra',
				load: 'Número De Carga',
				transport: 'Tipo De Transporte',
				container: 'Contenedor',
				product: 'Producto (Sigla/ Descripción)',
				ets: 'Ets / Ats - Puerto De Embarque',
				eta: 'Eta Puerto De Destino',
			},
			sections: {
				inProduction: 'En producción',
				loading: 'Cargando',
				gateInOrigin: 'Ingresado En El Puerto De Origen',
				shipped: 'Embarcado',
				deliveredToPOD: 'Entregado En El Destino',
				gateOutDestination: 'Retirado Del Puerto De Destino',
			},
			cargoTable: {
				cargo: 'Número De Carga',
				container: 'Contenedor',
				invoice: 'Factura',
				vehicle: 'Tipo De Transporte',
				POD: 'Puerto de Destino',
				gateIn: 'Fecha De Ingreso En Puerto De Origen',
				ets: 'Eta / Ats Puerto De Embarque',
				eta: 'Eta Puerto De Destino',
				gateOut: 'Fecha De Retirada Del Puerto De Destino',
				netWeight: 'Peso Neto',
			},
			contractTable: {
				sequence: 'Secuencia',
				acronym: 'Iten',
				description: 'Descripción',
				brand: 'Marca',
				sif: 'SIF',
				volume: 'Número de Cajas',
				weight: 'Peso Neto',
			},
			map: {
				contracts: 'Contratos',
				products: 'Productos',
				loads: 'Cargas',
				weigth: 'Peso Neto',
				awaiting_payment: 'Aguardando Pago Anticipado',
			},
		},
		financial_management: {
			titles: {
				pre_payment: 'Pago Adelantado',
				invoice: 'Factura',
				overdue: 'Atrasado',
				settle: 'Pagos Futuros',
				credit: 'Crédito',
				limit: 'Limite De Credito',
				balance_available: 'Saldo disponible',
			},
			filter: {
				market: 'Mercado',
				init_date: 'De:',
				end_date: 'Hasta: ',
				currency: 'Moneda',
				status: 'Status',
			},
			status: {
				paid: 'Pagado',
				awaiting_paid: 'En Espera Del Pago',
				overdue: 'ATRASADO',
			},
			table: {
				due_date: 'Fecha De Vencimiento',
				contract: 'Contrato',
				invoice: 'Factura',
				total_invoice: 'Total de la Factura',
				po: 'Orden De Compra',
				currency: 'Moneda',
				status: 'Status',
				total: 'Total',
				payment: 'Pago',
				balance: 'Saldo',
			},
			currency: {
				dolar: 'DÓLAR',
				euro: 'EURO',
				yen: 'YEN',
			},
		},
		doc_management: {
			filter: {
				init_date: 'Fecha Inicial:',
				end_date: 'Fecha Final:',
				invoice: 'Factura',
				contract: 'Contrato',
				sequence: 'Secuencia',
				po: 'Orden De Compra',
				container: 'Contenedor',
				transport: 'Tipo De Transporte',
				pod: 'Puerto De Destino',
				load: 'Número De Carga',
				acronym: 'Sigla',
				description: 'Descripción Del Producto',
			},
			doc_row: {
				invoice: 'Factura',
				vehicle: 'Tipo De Transporte',
				pol: 'Puerto de Embarque',
				atsPol: 'Salida de Brasil',
				pod: 'Puerto de Destino',
				etaPod: 'Llegada estimada (POD)',
				plTon: 'Volumen',
				value: 'Volumen Total',
				download: 'Download',
				payment_status: 'Status De Los Pagamentos',
				approval_status: 'Status De La Aprobación',
				shipment_status: 'Status De Envío De Los Documentos',
			},
			doc_detail: {
				contract: 'Contratos',
				condition: 'Términos De Pago',
				term: 'Plazo De Pagamento',
				type: 'Tipo De Documento',
				publication: 'Fecha de Publicación',
				responsible: 'Responsable',
				approval: 'Fecha Aprobación',
				approver: 'Aprobador',
				observation: 'Observaciones',
				download: 'Download',
				status: 'Status',
				not_sended: 'No Enviado',
				pending: 'Pendiente',
				viewed: 'Visto',
				approved: 'Aprobado',
				repproved: 'Desaprobado',
				cancelled: 'Cancelado',
			},
			doc_table: {
				container: 'Contenedor',
				cargo: 'Cargar',
				po: 'Orden De Compra',
				plton: 'Volumen',
				sequence: 'Secuencia',
				courier: 'Empresa Del Courier',
				awb: 'Awb',
				shipping: 'Fecha de Envío',
				destiny: 'Destino',
				delivery: 'Fecha de Entrega',
				status: 'Status',
				notSent: 'No Enviado',
				delivered: 'Entregado',
				inTransit: 'En tránsito',
			},
			reproval_modal: {
				title: 'Tipo De Documento',
				subtitle: 'Por Favor Inserte Un Comentario Sobre Su Desaprobación',
				comment: 'Comentario',
				approved: '¡Documento aprobado exitosamente!',
				repproved: '¡Documento rechazado exitosamente!',
			},
		},
		doc_instruction: {
			filter: {
				pod: 'Puerto De Destino',
				acro_description: 'Descripción',
				contract: 'Contrato / Secuencia',
			},
			steps: {
				title: 'Nombre De  La Instrucion De Los Documientos',
				selectTheContract: 'Seleccione Los Contratos Que Desea Registrar La Instruccion Documental',
				selectDocumentaryInstruction:
					'Elija La Instrucción Documental Que Desea Indicar O Registre Una Nueva.',
				confirmTheData: 'Confirme Los Datos',
				enterDestination: 'Informar La Dirección Completa Para Envío De Los Documentos',
				notFound: 'No Encontrado',
				step_one_description:
					'Seleccione Los Contratos Que Desea Registrar La Instruccion Documental',
				step_two_description:
					'Elija la instrucción documental que desea indicar o registre una nueva',
				step_three_description: 'Confirme Los Datos De La Instrucción Documental',
				step_four_description: 'Informar La Dirección Completa Para Envío De Los Documentos',
				step_by_step: 'Paso a Paso',
				mobile_step: 'Paso {step} de 4',
			},
			doc_row: {
				code: 'Código',
				name: 'Nombre',
				update_date: 'Ultima Actualización',
				contract: 'Contrato',
				customer: 'Cliente',
			},
			doc_table: {
				sequence: 'Secuencia',
				pod: 'Puerto De Destino',
				acro: 'Descripción',
			},
			inactive_templates_title: 'Modelo Inactivo',
			select_doc_instruction: 'Seleccione la instrucción documental',
			selected_contracts_title: 'Seleccione El Contrato/ Secuencia',
			consignee_modal: {
				header: 'Consignatario / Notificar',
				name: 'Nombre',
				email: 'Correo Electrónico',
				phone: 'Número De Telefono',
				address: 'Dirección',
				country: 'País',
				state: 'Estado/ Provincia',
				city: 'Ciudad',
				zipcode: 'Código Postal',
				tax: 'Tax Id / Ruc / Rut',
				summary: 'Resumen',
				characters: 'Caracteres' 
			},
			documents_package: {
				bank: 'Nombre Del Banco',
				otherRecipient: 'Otro Destinatario Indicado',
				address: 'Dirección',
				postCode: 'Código postal',
				fax: 'Número De Telefono',
				taxID: 'Tax Id / Ruc / Rut',
				receiverName: 'Persona De Contacto',
			},
			modal_add_bank: {
				titleEdit: 'Editar banco',
				title: 'Añadir Nuevo Banco',
				text: 'Por Favor Contactar Al Asistente Comercial De Seara Para Informar Toda La Información Del Banco Que Desea Insertar.',
			},
			confirm_modal: {
				warning:
					'¡Atención! Usted Está Cambiando El Cliente Con Una Instrucción Documental Parcialmente Llenada, En Caso De Continuar Se Perderán Sus Cambios. <br /> ¿Quieres Continuar?',
			},
			modal_no_data: {
				title: 'Banco',
				text: 'No se encontraron bancos para su registro. Por favor, póngase en contacto con el equipo de Experiencia del Cliente de Seara.',
			},
		},
		templateData: {
			inputs: {
				default: 'Estándar',
				perOrder: 'Modelo',
				template_title: 'Nombre del Template',
			},
			items: {
				invoice: 'Factura',
				packing: 'Packing List',
				billOfLanding: 'Bill Of Lading',
				healthCertificate: 'Certificado Sanitario',
				certificateOfOrigin: 'Certificado de Origen',
				consignee: 'Consignatario',
				notify: 'Notificado',
				buyer: 'Comprador',
				adress: 'Dirección',
				observations: 'Observaciones',
				secondNotify: '2º Notificado',
				freigtClause: 'Cláusula De Frete',
				telexRelease: 'Telex Release ',
				responsable: 'Responsable',
				countriesOfTransit: 'Pais De Transito',
				importer: 'Importador',
				behalf: 'On Behalf Of',
				both: 'Ambos',
			},
			actions: {
				edit: 'Editar',
			},
			requests_messages: {
				template_status_change: 'Modelo Actualizado Con Éxito',
				template_error: 'Se Debe Marcar Al Menos Una Opción Para El Conocimiento De Embarque.',
				template_send: 'Instrucción Documental Enviada Con Éxito!',
			},
		},
	},
};
