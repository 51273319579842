<template>
	<div class="doc-fourthStepOption">
		<div class="doc-fourthStepOption__head" v-if="isWeb">
			<button class="doc-fourthStepOption__backButton" @click="goToPreviewStep">
				{{ $t('buttons.back') }}
			</button>

			<button
				v-if="isWeb"
				class="doc-fourthStepOption__steps"
				:class="{ active: showStepList }"
				@click="handleSteps()"
			>
				<StepsIcon />
			</button>

			<button
				v-if="!isWeb"
				class="btn doc-fourthStepOption__steps--modal isStep"
				@click="handleSteps()"
			>
				<StepsIcon />{{ $t('modules.doc_instruction.steps.step_by_step') }}
			</button>
		</div>
		<div v-else class="--mobile">
			<CompanyInput ref="companiesHeader" :isWeb="false" />
			<div class="doc-thirdStepOption__wrapper">
				<button class="doc-thirdStepOption__backButton" @click="goToPreviewStep">
					{{ $t('buttons.back') }}
				</button>
				<button class="btn doc-thirdStepOption__steps--modal isStep" @click="handleSteps()">
					<StepsIcon />{{ $t('modules.doc_instruction.steps.step_by_step') }}
				</button>
			</div>
			<StepList v-show="showStepList" />
		</div>

		<h1 class="doc-fourthStepOption__title" v-if="!isWeb">
			{{ $t('modules.doc_instruction.steps.enterDestination') }}
		</h1>
		<div class="doc-fourthStepOption__select" v-if="!isWeb">
			<SelectedContracts :contracts="sequencesList" />
		</div>
		<div class="doc-fourthStepOption__buttons" v-if="!isWeb">
			<button class="doc-fourthStepOption__change" @click="toggleModalAddBank()">
				{{ $t('buttons.add_bank') }}
			</button>
			<button	class="doc-fourthStepOption__next" :class="{ disabled: disabledConfirm }" @click="handleSubmitTemplate">
				{{ $t('buttons.confirm') }}
			</button>
		</div>
		<ModalBank v-show="modalAddBankOpened" @closeModal="toggleModalAddBank()" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SET_SHOW_STEP_LIST } from '@/store/modules/docInstruction/actions';
import PlusIcon from '@/components/shared/Icons/PlusIcon.vue';
import StepsIcon from '@/components/shared/Icons/StepsIcon.vue';
import StepList from '@/components/modules/DocInstruction/Steps/StepList/StepList.vue';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
import ModalBank from '@/components/modules/DocInstruction/Modals/ModalBank/ModalBank.vue';
import CompanyInput from '@/components/shared/Header/Inputs/CompanyInput/CompanyInput.vue';
import SelectedContracts from '@/components/modules/DocInstruction/StepTwo/SelectedContracts/SelectedContracts.vue';
import {
	SET_STEP,
	SUBMIT_NEW_TEMPLATE_REQUEST,
	CLEAR_CONTRACT_STATE,
	CLEAR_NEW_TEMPLATE_REQUEST,
	CLEAR_SELECTED_CONTRACTS_SEQUENCES,
} from '@/store/modules/docInstruction/actions';

export default {
	name: 'FourthStepOption',
	components: {
		PlusIcon,
		StepsIcon,
		StepList,
		GenericMultiselect,
		ModalBank,
		CompanyInput,
		SelectedContracts,
	},
	props: {
		isWeb: {
			require: true,
		},
	},
	data: () => ({
		countryOptions: [{ id: 1, name: 'City' }],
		modalAddBankOpened: false,
	}),
	computed: {
		...mapGetters('docInstruction', ['showStepList', 'selectedContractsSequence', 'templateData']),
		...mapGetters('selectInstruction', ['selectedTemplateData']),
		disabledConfirm() {
			if (
				this.templateData.invoice.shippingType == null ||
				this.templateData.packing.shippingType == null ||
				this.templateData.billLanding.shippingType == null ||
				this.templateData.certificateOrigin.shippingType == null ||
				this.templateData.healthCertificate.shippingType == null
			) {
				return true;
			}
			return false;
		},
	},
	methods: {
		...mapActions('docInstruction', {
			setShowStepList: SET_SHOW_STEP_LIST,
			setStep: SET_STEP,
			submitTemplate: SUBMIT_NEW_TEMPLATE_REQUEST,
			clearContract: CLEAR_CONTRACT_STATE,
			clearNewTemplate: CLEAR_NEW_TEMPLATE_REQUEST,
			clearSelectContract: CLEAR_SELECTED_CONTRACTS_SEQUENCES,
		}),
		goToBackStep() {
			this.$emit('goToStep');
		},
		goToPreviewStep() {
			this.$emit('goToPreviewStep');
		},
		handleSteps() {
			this.setShowStepList(!this.showStepList);
		},
		toggleModalAddBank() {
			this.modalAddBankOpened = !this.modalAddBankOpened;
		},
		handleSubmitTemplate() {
			if (!this.disabledConfirm) {
				this.submitTemplate({ vm: this, data: this.templateData }).then(() => {
					this.setStep(1);
					this.clearNewTemplate();
					this.clearSelectContract();
					this.$emit('updateContractList');
				});
			}
		},
	},
};
</script>

<style lang="scss">
@import 'FourthStepOption.scss';
</style>
