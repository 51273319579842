<template>
	<div class="filter-container">
		<div class="filter-row">
			<MarketInput
				ref="marketInput"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
			<InitialDateInput ref="initialDateInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<EndDateInput ref="endDateInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<CurrencyInput
				ref="currencyInput"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
			<StatusInput
				ref="statusInput"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
			<InvoiceInput ref="invoiceInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<button class="btn-outline" type="button" @click="clearCachedFilters()" v-if="isWeb">
				{{ $t('buttons.clear_filter') }}
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
	CLEAR_ALL_FILTERS,
	SAVE_LAST_QUERY_STRING,
} from '@/store/modules/filtersByModules/financialManagementFilters/actions';
import MarketInput from './Inputs/MarketInput/MarketInput.vue';
import InitialDateInput from './Inputs/InitialDateInput/InitialDateInput.vue';
import EndDateInput from './Inputs/EndDateInput/EndDateInput.vue';
import CurrencyInput from './Inputs/CurrencyInput/CurrencyInput.vue';
import StatusInput from './Inputs/StatusInput/StatusInput.vue';
import InvoiceInput from './Inputs/InvoiceInput/InvoiceInput.vue';
import ClearIcon from '@/components/shared/Icons/ClearIcon.vue';

export default {
	name: 'FilterList',
	components: {
		MarketInput,
		InitialDateInput,
		EndDateInput,
		CurrencyInput,
		StatusInput,
		InvoiceInput,
		ClearIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		filter: {
			market: null,
			currency: null,
			status: null,
			invoice: null,
			fromDate: null,
			toDate: null,
		},
		refreshCalendars: true,
	}),
	mounted() {},
	methods: {
		...mapActions('financialManagementFilters', {
			clearStoreFilters: CLEAR_ALL_FILTERS,
			saveLastQueryString: SAVE_LAST_QUERY_STRING,
		}),
		filterRequest(param) {
			if (param.type == 'fromDate' || param.type == 'toDate' || param.type == 'market') {
				this.filter[param.type] = param.value ? param.value.id : null;
			} else {
				this.filter[param.type] = param.value ? param.value.map((item) => item.id) : null;
			}
			let queryString = this.createQueryString();
			let filterObj = {
				queryString: queryString,
				skip: true,
			};

			if (!param.value || !param.value.length) {
				this.$emit('setPageOne');
			}

			this.$emit('refreshFilters', filterObj);
			//refresh dos outros componentes
			// this.$emit('refreshContracts', queryString);
			this.saveLastQueryString(queryString);
		},
		filterCachedRequest() {
			const marketCache = this.marketFilterCache.value;
			const currencyCache = this.currencyFilterCache.value;
			const statusCache = this.statusFilterCache.value;
			const fromDateCache = this.initDateFilterCache.value;
			const toDateCache = this.endDateFilterCache.value;
			const invoiceCache = this.invoiceFilterCache.value;

			if (marketCache && Object.keys(marketCache).length) {
				this.filter['market'] = marketCache.map((item) => item.id);
			}

			if (currencyCache && Object.keys(currencyCache).length) {
				this.filter['currency'] = currencyCache.map((item) => item.id);
			}

			if (statusCache && Object.keys(statusCache).length) {
				this.filter['status'] = statusCache.map((item) => item.id);
			}

			if (fromDateCache.id != undefined) {
				this.filter['fromDate'] = fromDateCache.id;
			}

			if (toDateCache.id != undefined) {
				this.filter['toDate'] = toDateCache.id;
			}

			if (invoiceCache && Object.keys(invoiceCache).length) {
				this.filter['invoice'] = invoiceCache.map((item) => item.id);
			}

			let queryString = this.createQueryString();
			this.$emit('refreshContracts', queryString);
		},
		createQueryString() {
			let { market, currency, status, fromDate, toDate, invoice } = this.filter;

			const query = new URLSearchParams();

			if (market && market.length) query.set('market', market);
			if (currency && currency.length) query.set('currency', currency);
			if (status && status.length) query.set('status', status);
			if (fromDate) query.set('fromDate', fromDate);
			if (toDate) query.set('toDate', toDate);
			if (invoice && invoice.length) query.set('invoice', invoice);

			return query.toString();
		},
		clearCachedFilters() {
			this.clearStoreFilters();
			this.clearFilters();
		},
		clearFilters() {
			this.$refs.marketInput.clearInput();
			this.$refs.initialDateInput.clearInput();
			this.$refs.endDateInput.clearInput();
			this.$refs.currencyInput.clearInput();
			this.$refs.statusInput.clearInput();
			this.$refs.invoiceInput.clearInput();

			this.filter = {
				market: null,
				currency: null,
				status: null,
				invoice: null,
				fromDate: null,
				toDate: null,
			};

			this.$emit('refreshFilters');
		},
		setCachedMarkets() {
			this.$refs.marketInput.setCachedInput();
		},
		setCachedCurrency() {
			this.$refs.currencyInput.setCachedInput();
		},
		setCachedStatus() {
			this.$refs.statusInput.setCachedInput();
		},
		setCachedInvoice() {
			this.$refs.invoiceInput.setCachedInput();
		},
	},
	computed: {
		...mapGetters('user', ['userLang']),
		...mapGetters('financialManagementFilters', [
			'marketFilterCache',
			'currencyFilterCache',
			'statusFilterCache',
			'initDateFilterCache',
			'endDateFilterCache',
			'invoiceFilterCache',
		]),
	},
	watch: {
		userLang(newValue) {
			if (newValue) {
				this.refreshCalendars = false;
				setTimeout(() => {
					this.refreshCalendars = true;
				}, 100);
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'FilterList.scss';
</style>
