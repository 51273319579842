<template>
	<svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M24.1001 2.08287C23.7345 1.4496 23.2086 0.923724 22.5753 0.558104C21.942 0.192483 21.2237 0 20.4924 0C19.7612 0 19.0428 0.192483 18.4096 0.558104C17.7763 0.923724 17.2504 1.4496 16.8848 2.08287L1.54295 28.6558C1.17734 29.2891 0.984858 30.0074 0.984863 30.7387C0.984869 31.4699 1.17736 32.1882 1.54298 32.8215C1.9086 33.4548 2.43448 33.9806 3.06775 34.3462C3.70102 34.7118 4.41937 34.9043 5.1506 34.9043H35.8343C36.5655 34.9043 37.2838 34.7118 37.9171 34.3462C38.5504 33.9806 39.0763 33.4548 39.4419 32.8215C39.8075 32.1882 40 31.4699 40 30.7387C40 30.0074 39.8075 29.2891 39.4419 28.6558L24.1001 2.08287ZM37.1888 31.5206C37.0538 31.7603 36.8568 31.9593 36.6185 32.0969C36.3802 32.2345 36.1094 32.3055 35.8343 32.3027H5.1506C4.87606 32.3027 4.60635 32.2304 4.36858 32.0931C4.13081 31.9559 3.93337 31.7584 3.79609 31.5207C3.65881 31.2829 3.58653 31.0132 3.58653 30.7387C3.58652 30.4641 3.65877 30.1944 3.79604 29.9566L19.1379 3.38366C19.2751 3.14587 19.4726 2.94842 19.7104 2.81113C19.9481 2.67385 20.2179 2.60157 20.4924 2.60157C20.767 2.60157 21.0367 2.67385 21.2745 2.81113C21.5123 2.94842 21.7097 3.14587 21.847 3.38366L37.1888 29.9566C37.3289 30.1934 37.4027 30.4635 37.4027 30.7386C37.4027 31.0137 37.3289 31.2838 37.1888 31.5206V31.5206Z"
			:fill="color"
		/>
		<path
			d="M20.4924 23.473C20.8374 23.473 21.1683 23.3359 21.4122 23.092C21.6562 22.848 21.7932 22.5172 21.7932 22.1722V10.6997C21.7932 10.3547 21.6562 10.0239 21.4122 9.77992C21.1683 9.53597 20.8374 9.39893 20.4924 9.39893C20.1474 9.39893 19.8166 9.53597 19.5726 9.77992C19.3287 10.0239 19.1917 10.3547 19.1917 10.6997V22.1722C19.1917 22.5172 19.3287 22.848 19.5727 23.092C19.8166 23.3359 20.1475 23.473 20.4924 23.473Z"
			:fill="color"
		/>
		<path
			d="M20.4924 26.0889C20.2201 26.0889 19.954 26.1696 19.7276 26.3209C19.5012 26.4722 19.3247 26.6872 19.2205 26.9387C19.1163 27.1903 19.0891 27.4671 19.1422 27.7342C19.1953 28.0012 19.3264 28.2465 19.519 28.439C19.7115 28.6316 19.9568 28.7627 20.2239 28.8158C20.4909 28.8689 20.7677 28.8417 21.0193 28.7375C21.2708 28.6333 21.4858 28.4568 21.6371 28.2304C21.7884 28.004 21.8691 27.7378 21.8691 27.4656C21.8691 27.2848 21.8335 27.1057 21.7643 26.9387C21.6951 26.7717 21.5937 26.6199 21.4659 26.4921C21.3381 26.3642 21.1863 26.2628 21.0193 26.1937C20.8522 26.1245 20.6732 26.0889 20.4924 26.0889Z"
			:fill="color"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: '#803423',
		},
	},
};
</script>
