<template>
    <div class="doc-selectedContractBadge">
        <div class="doc-selectedContractBadge__item">
            {{ contractId }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectedContractBadge',
    props: {
        contractId: {
            require: true
        }
    }
}
</script>

<style lang="scss">
@import 'SelectedContractBadge.scss';
</style>