export default {
	placeholder: {
		search: 'Search',
	},
	header: {
		contractNumber: 'العقد',
		contractDate: 'تاريخ العقد',
		competenceMonth: 'الشهر الموافق',
		releaseDate: 'تاريخ الإصدار',
		company: 'الشركة',
		status: 'الحالة',
		totalVolume: 'الحجم الإجمالي',
	},
	detail: {
		actualDateOfBoarding: 'Actual Date of Boarding',
		awbCourier: 'شركة بوليصة الشحن الجوي',
		balance: 'الرصيد',
		billLanding: 'بوليصة الشحن',
		billLandingDate: 'تاريخ بوليصة الشحن',
		blDate: 'تاريخ بوليصة الشحن',
		blDateId: 'Id Data Bl',
		bookingNumber: 'رقم الحج',
		boardingType: 'Boarding Type',
		borderCrossingDate: 'Border Crossing Date',
		borderPost: 'Border Post',
		borderPostsCode: 'Border Post Code',
		branch: 'الفرع',
		brand: 'العلامة التجارية',
		brandCode: 'Brand Code',
		business: 'Business',
		closedContractSequence: 'تسلسل العقد المغلق',
		cneCode: 'رمز CNE',
		container: 'الحاوية',
		contractSequence: 'تسلسل العقد',
		country: 'الدولة',
		courierDate: 'التاريخ اللاحق',
		courierCompany: 'Courier Company',
		csiIssuanceDate: 'تاريخ إصدار CSI',
		destination: 'destination',
		destinationPort: 'Destination Port',
		destinationPort4: 'Destination Port 4',
		dsInternationalOfc: 'دي إس انترناشيونا',
		dueSituationCode: 'Due Situation Code',
		endShipDate: 'تاريخ انتهاء الشحن',
		etaPodDate: 'التاريخ المتوقع للوصول إلى ميناء التفريغ',
		etdPolDate: 'التاريخ المتوقع للوصول إلى ميناء التحميل',
		etdRealDate: 'التاريخ المتوقع الفعلي للمغادرة',
		etsRfsDate: 'التاريخ المتوقع للإبحار حسب وثيقة طلب الاختيار',
		etsRfsId: 'ETS RFS id',
		expectedEtdDate: 'ETS POL',
		expectedEtbDate: 'ATS POL',
		expectedEtsDate: 'Expected Ets Date',
		gateInDate: 'تاريخ تصريح بوابة الدخو',
		idBlock: 'Block Id',
		idStatus: 'Status Id',
		incoterm: 'بند التجارة الدولية',
		initials: 'الأحرف الأولى',
		invoiceNumber: 'رقم الفاتورة',
		invoicedDate: 'تاريخ الفاتورة',
		invoiceIssuanceDate: 'Invoice Issuance Date',
		loadNumber: 'رقم التحميل',
		loadPlace: 'مكان التحميل',
		loadCode: 'Load Code',
		loadingDate: 'Loading Date',
		localization: 'Localization',
		market: 'السو',
		groupingDescription: 'Grouping Description',
		maxGroupingQuantity: 'حد أقصى كمية تجميع',
		minGroupingQuantity: 'أقل كمية تجميع',
		netWeight: 'الوزن الصافي',
		nfiIssuanceDate: 'تاريخ إصدار NFI',
		origin: 'Origin',
		paymentDeadline: 'Payment Deadline',
		paymentDeadlineCode: 'Payment Deadline Code',
		paymentMethod: 'طريقة الدفع',
		paymentMode: 'وسيلة الدفع',
		permissionImt: 'إذن IMT',
		permissionImtDate: 'تاريخ إذن IMT',
		planningCode: 'Planning Code',
		plannerLoadingDate: 'تاريخ التحميل حسب المخطط',
		pod: 'ميناء التفريغ',
		pol: 'ميناء التحميل',
		priceType: 'نوع السعر',
		productionMonth: 'شهر الإنتاج',
		productionDate: 'تاريخ الإنتاج',
		productName: 'اسم المنتج',
		provider: 'Provider',
		purchaseOrder: 'أمر الشراء',
		purchaseOrderClient: 'العميل حسب أمر الشراء',
		realAtdDate: 'Real Atd Date',
		realAtbDate: 'ETA POD',
		realArrivalDate: 'Real Arrival Date',
		regional: 'إقليمي',
		releaseSequenceDate: 'تاريخ تسلسل الإصدار',
		restritionCode: 'Restrition Code',
		remarks: 'ملاحظات',
		sentDraftDate: 'Sent Draft Date',
		scheduledDate: 'التاريخ المحدد',
		shippingCompany: 'Shipping Company',
		shippingLine: 'خط الشحن',
		sif: 'ملف بتنسيق Sif ',
		sku: 'وحدة مخزون',
		startShipDate: 'تاريخ بدء الشحن',
		statusClosure: 'Status Closure',
		statusPrePaid: 'الحالة "مسبق الدفع"',
		status: 'الحالة "متسلسل"',
		stockLocation: 'موقع المخزون',
		totalGrossWeight: 'الوزن الإجمالي',
		totalKg: 'الإجمالي بالكيلوجرام',
		totalVolume: 'الحجم الإجمالي',
		traderName: 'اسم التاجر',
		unitPrice: 'سعر الوحدة',
		vehiclePlate: 'Vehicle Plate',
		vessel: 'السفينة',
		weeksToShip: 'عدد أسابيع الشحن',
		stage: 'Status',
	},
};
