<template>
	<div class="contract">
		<div class="contract-row">
			<div class="contract-row__field">
				<div class="contract-row__field--label">
					<span>
						{{ $t('modules.doc_instruction.doc_row.contract') }}
					</span>
				</div>
				<div class="contract-row__field--value">
					<span> {{ contractData.contractNumber }} </span>
				</div>
			</div>
			<div class="contract-row__field">
				<div class="contract-row__field--label">
					<span>
						{{ $t('modules.doc_instruction.doc_row.customer') }}
					</span>
				</div>
				<div class="contract-row__field--value">
					<span> {{ contractData.company.name }} </span>
				</div>
			</div>
			<div class="contract-row__action">
				<button class="contract-row__action--btn" @click="handleTable">
					<ArrowDown :class="showTable ? 'rotate' : ''" :stroke="'2'" :color="'#234057'" />
				</button>
			</div>
		</div>
		<div class="table-container" v-if="showTable">
			<div class="loader" v-if="isLoadingContractsSequences == 'loading'">
				<DefaultLoader color="gray" />
			</div>
			<Table :isWeb="isWeb" v-if="isLoadingContractsSequences == 'success'" />
			<div class="no__data" v-if="isLoadingContractsSequences == 'empty'">
				{{ $t('errors.empty_data') }}
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CONTRACT_SEQUENCE_REQUEST,
	SET_TEMPLATE_SALES_CONTRACT_DATA,
	CLEAR_SELECTED_CONTRACTS_SEQUENCES,
} from '@/store/modules/docInstruction/actions';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import Table from '../Table/Table.vue';
import DefaultLoader from '@/components/shared/DefaultLoader/DefaultLoader.vue';

export default {
	name: 'ContractRow',
	components: {
		ArrowDown,
		Table,
		DefaultLoader,
	},
	props: {
		isWeb: {
			required: true,
		},
		contractData: {
			required: true,
		},
	},
	data: () => ({
		showTable: false,
	}),
	methods: {
		...mapActions('docInstruction', {
			getContractSequence: CONTRACT_SEQUENCE_REQUEST,
			setTemplateSalesContract: SET_TEMPLATE_SALES_CONTRACT_DATA,
			clearContractSequence: CLEAR_SELECTED_CONTRACTS_SEQUENCES,
		}),
		closeTable() {
			this.showTable = false;
		},
		handleTable() {
			this.showTable = !this.showTable;
			this.$emit('closeAllTables', this.contractData.id);
			this.setTemplateSalesContract(this.contractData.contractNumber);
			this.getContractSequence({ vm: this, contractNumber: this.contractData.contractNumber });
			this.clearContractSequence();
		},
	},
	computed: {
		...mapGetters('docInstruction', ['isLoadingContractsSequences']),
	},
};
</script>

<style scoped lang="scss">
@import 'ContractRow.scss';
</style>
