<template>
	<div id="financial-management" class="financial-management">
		<Content>
			<template v-slot:header>
				<MobileFilter v-if="!isWeb" :fullWidth="true">
					<template v-slot:inputs-list>
						<FilterList ref="filterFinancial" :isWeb="isWeb" @refreshFilters="refreshFilters" />
					</template>
				</MobileFilter>
				<WebFilter v-else>
					<template v-slot:inputs-list>
						<FilterList ref="filterFinancial" :isWeb="isWeb" @refreshFilters="refreshFilters" />
					</template>
				</WebFilter>
			</template>
			<template v-slot:body>
				<FinancialCredit
					v-if="isInternal"
					:isWeb="isWeb"
					:limit="limit.limit"
					:available="limit.balance"
				/>
				<FinancialRow
					:isWeb="isWeb"
					:title="$t('modules.financial_management.titles.pre_payment')"
					:showPayments="true"
				>
					<Table
						slot="body"
						ref="financialTable"
						@changePage="changePage"
						@sortTable="sortTable"
						:isWeb="isWeb"
					/>
					<FinancialSummary
						slot="footer"
						:isWeb="isWeb"
						:due="prePaymentsDue"
						:overDue="prePaymentsOverDue"
						:isLoading="isLoadingPrePayments"
					/>
				</FinancialRow>
				<FinancialRow
					:isWeb="isWeb"
					:title="$t('modules.financial_management.titles.invoice')"
					:showInvoices="true"
				>
					<TableInvoice
						slot="body"
						ref="invoiceTable"
						@changePage="changeInvoicePage"
						@sortTable="sortInvoiceTable"
						:isWeb="isWeb"
					/>
					<FinancialSummary
						slot="footer"
						:isWeb="isWeb"
						:due="invoiceDue"
						:overDue="invoiceOverDue"
						:isLoading="isLoadingInvoices"
					/>
				</FinancialRow>
				<div class="financial-management__action-row">
					<div @click="handleDownload()" class="financial-management__action-button">Download</div>
				</div>
			</template>
		</Content>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
	MARKET_FILTER_REQUEST,
	CURRENCY_FILTER_REQUEST,
} from '@/store/modules/filtersByModules/financialManagementFilters/actions';
import Content from '@/components/shared/Content/Content.vue';
import MobileFilter from '@/components/shared/MobileFilter/MobileFilter.vue';
import WebFilter from '@/components/shared/WebFilter/WebFilter.vue';
import FilterList from '@/components/modules/FinancialManagement/Filter/FilterList.vue';
import FinancialRow from '@/components/modules/FinancialManagement/FinancialRow/FinancialRow.vue';
import Table from '@/components/modules/FinancialManagement/Table/Table.vue';
import TableInvoice from '@/components/modules/FinancialManagement/TableInvoice/TableInvoice.vue';
import FinancialSummary from '@/components/modules/FinancialManagement/FinancialSummary/FinancialSummary.vue';
import FinancialCredit from '@/components/modules/FinancialManagement/FinancialCredit/FinancialCredit.vue';

import {
	CLEAR_FINANCE_STATE,
	PRE_PAYMENT_REQUEST,
	INVOICE_REQUEST,
	LIMIT_REQUEST,
	FINANCIAL_REPORT_REQUEST,
} from '@/store/modules/financialManagement/actions';
export default {
	name: 'FinancialManagement',
	components: {
		Content,
		MobileFilter,
		WebFilter,
		FilterList,
		FinancialRow,
		Table,
		TableInvoice,
		FinancialSummary,
		FinancialCredit,
	},
	data: () => ({
		windowWidth: window.innerWidth,
		currentMarketFilter: '',
		currentCurrencyFilter: '',
		currentStatusFilter: '',
		currentInitDateFilter: '',
		currentEndDateFilter: '',
		sortTableQuery: '',
		sortInvoiceTableQuery: '',
	}),
	created() {
		this.resetState();
		this.clearFinanceState();
		this.refreshFilters();
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
		this.normalizeFilters();
		this.getAllData();
		this.getLimit({ vm: this, companyId: this.headerCompanyFilterCache.value.id });
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions('financialManagementFilters', {
			getMarketFilter: MARKET_FILTER_REQUEST,
			getCurrencyFilter: CURRENCY_FILTER_REQUEST,
		}),
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		...mapActions('financialManagement', {
			clearFinanceState: CLEAR_FINANCE_STATE,
			getLimit: LIMIT_REQUEST,
			getPrePaymentItems: PRE_PAYMENT_REQUEST,
			getInvoiceItems: INVOICE_REQUEST,
			resetState: CLEAR_FINANCE_STATE,
			downloadReport: FINANCIAL_REPORT_REQUEST,
		}),
		sortTable(param) {
			this.sortTableQuery = param;
			this.getPrePaymentItems({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				invoiceIds: this.invoiceFilterCache.value.map((invoice) => invoice.id),
				market: this.currentMarketFilter,
				currency: this.currentCurrencyFilter,
				status: this.currentStatusFilter,
				fromDate: this.currentInitDateFilter,
				toDate: this.currentEndDateFilter,
				page: this.prePaymentsPage,
				sort: param,
			});
		},
		changePage(param) {
			this.getPrePaymentItems({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				invoiceIds: this.invoiceFilterCache.value.map((invoice) => invoice.id),
				market: this.currentMarketFilter,
				currency: this.currentCurrencyFilter,
				status: this.currentStatusFilter,
				fromDate: this.currentInitDateFilter,
				toDate: this.currentEndDateFilter,
				page: param.params,
				sort: this.sortTableQuery,
			});
		},
		sortInvoiceTable(param) {
			this.normalizeFilters();
			this.sortInvoiceTableQuery = param;
			this.getInvoiceItems({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				invoiceIds: this.invoiceFilterCache.value.map((invoice) => invoice.id),
				market: this.currentMarketFilter,
				currency: this.currentCurrencyFilter,
				status: this.currentStatusFilter,
				fromDate: this.currentInitDateFilter,
				toDate: this.currentEndDateFilter,
				page: this.prePaymentsPage,
				sort: param,
			});
		},
		changeInvoicePage(param) {
			this.normalizeFilters();
			this.getInvoiceItems({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				invoiceIds: this.invoiceFilterCache.value.map((invoice) => invoice.id),
				market: this.currentMarketFilter,
				currency: this.currentCurrencyFilter,
				status: this.currentStatusFilter,
				fromDate: this.currentInitDateFilter,
				toDate: this.currentEndDateFilter,
				page: param.params,
				sort: this.sortInvoiceTableQuery,
			});
		},
		refreshFilters(param) {
			const filterObject = { vm: this };

			if (param != undefined && param != null) {
				filterObject['params'] = param.queryString;
				filterObject['skip'] = param.skip;
			} else {
				filterObject['params'] = null;
				filterObject['skip'] = false;
				filterObject['lastQuery'] = this.lastQueryString;
			}

			this.getMarketFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterFinancial.setCachedMarkets();
				}
			});
			this.getCurrencyFilter(filterObject).then((res) => {
				if (res.status == 200) {
					this.$refs.filterFinancial.setCachedCurrency();
				}
			});
			this.$refs.filterFinancial.setCachedStatus();
		},
		normalizeFilters() {
			this.currentMarketFilter =
				this.marketFilterCache.value != null ? this.marketFilterCache.value.id : '';

			this.currentCurrencyFilter = '';
			this.currencyFilterCache.value.forEach((item) => {
				this.currentCurrencyFilter = item.id + ',' + this.currentCurrencyFilter;
			});

			this.currentStatusFilter = '';
			this.statusFilterCache.value.forEach((item) => {
				this.currentStatusFilter = item.id + ',' + this.currentStatusFilter;
			});

			this.currentInitDateFilter =
				this.initDateFilterCache.value != null ? this.initDateFilterCache.value.id : '';

			this.currentEndDateFilter =
				this.endDateFilterCache.value != null ? this.endDateFilterCache.value.id : '';
		},
		getAllData() {
			this.getPrePaymentItems({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				invoiceIds: this.invoiceFilterCache.value.map((invoice) => invoice.id),
				market: this.currentMarketFilter,
				currency: this.currentCurrencyFilter,
				status: this.currentStatusFilter,
				fromDate: this.currentInitDateFilter,
				toDate: this.currentEndDateFilter,
				contract: this.contractId,
			});

			this.getInvoiceItems({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				invoiceIds: this.invoiceFilterCache.value.map((invoice) => invoice.id),
				market: this.currentMarketFilter,
				currency: this.currentCurrencyFilter,
				status: this.currentStatusFilter,
				fromDate: this.currentInitDateFilter,
				toDate: this.currentEndDateFilter,
				contract: this.contractId,
			});
		},
		handleDownload() {
			this.downloadReport({
				vm: this,
				companyId: this.headerCompanyFilterCache.value.id,
				invoiceIds: this.invoiceFilterCache.value.id,
				market: this.currentMarketFilter,
				currency: this.currentCurrencyFilter,
				status: this.currentStatusFilter,
				fromDate: this.currentInitDateFilter,
				toDate: this.currentEndDateFilter,
				page: this.prePaymentsPage,
				sortIPrePayment: this.sortTableQuery,
				sortInvoice: this.sortInvoiceTableQuery,
			});
		},
	},
	watch: {
		lastQueryString() {
			this.getLimit({ vm: this, companyId: this.headerCompanyFilterCache.value.id });
			this.normalizeFilters();
			this.getAllData();
		},
		headerCompanyFilterCache(value) {
			if (value.value !== null) {
				this.refreshFilters();
				this.$refs.filterFinancial.clearCachedFilters();
				this.getLimit({ vm: this, companyId: this.headerCompanyFilterCache.value.id });
				this.normalizeFilters();
				this.getAllData();
			}
		},
	},
	computed: {
		isWeb() {
			return this.windowWidth > 992;
		},
		isInternal() {
			return this.userInfo.type === 'internal';
		},
		contractId() {
			return this.$route.query.contractId || '';
		},
		...mapGetters('filters', ['headerCompanyFilterCache']),
		...mapGetters('financialManagementFilters', [
			'invoiceFilterCache',
			'marketFilterCache',
			'currencyFilterCache',
			'statusFilterCache',
			'initDateFilterCache',
			'endDateFilterCache',
			'lastQueryString',
		]),
		...mapGetters('financialManagement', [
			'limit',

			'invoiceDue',
			'invoiceOverDue',
			'invoicesMax',
			'invoicesPage',

			'prePayments',
			'prePaymentsDue',
			'prePaymentsOverDue',
			'prePaymentsMax',
			'prePaymentsPage',
			'isLoadingPrePayments',
			'isLoadingInvoices',
		]),
		...mapGetters('user', ['userInfo']),
	},
};
</script>

<style lang="scss">
@import 'FinancialManagement.scss';
</style>
