<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 14 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="8"
			y="13.8359"
			width="1.99998"
			height="11.9999"
			rx="0.999991"
			transform="rotate(180 8 13.8359)"
			:fill="color"
		/>
		<rect
			x="13"
			y="6.83594"
			width="1.99998"
			height="11.9999"
			rx="0.999992"
			transform="rotate(90 13 6.83594)"
			:fill="color"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			default: '#fff',
		},
		height: {
			default: '15',
		},
		width: {
			default: '14',
		},
	},
};
</script>
<style lang="scss" scoped></style>
