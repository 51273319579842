<template>
	<div class="filter">
		<div class="filter-action" v-if="!isWeb">
			<div class="filter-action__text">{{ $t('buttons.filters') }}:</div>
			<button class="btn-outline" @click="clearCachedFilters()">
				<ClearIcon /> {{ $t('buttons.clear_filter') }}
			</button>
		</div>
		<div class="filter-row">
			<InitialDateInput ref="initialDateInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<EndDateInput ref="endDateInput" :isWeb="isWeb" @filterRequest="filterRequest" />
			<PodInput
				ref="podInput"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
			<DescriptionInput
				ref="descriptionInput"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
			<ContractInput
				ref="contractInput"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
			<div class="filter-container__right-buttons">
				<button class="btn-outline" type="button" @click="clearCachedFilters()" v-if="isWeb">
					{{ $t('buttons.clear_filter') }}
				</button>
				<button
					v-if="isWeb"
					class="filter-container__steps"
					:class="{ active: showStepList }"
					@click="handleSteps"
				>
					<StepsIcon />
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
	CLEAR_ALL_DOC_FILTERS,
	SAVE_LAST_QUERY_STRING,
} from '@/store/modules/filtersByModules/docInstructionFilters/actions';
import { SET_SHOW_STEP_LIST } from '@/store/modules/docInstruction/actions';

// Calendar Inputs
import InitialDateInput from './Inputs/InitialDateInput/InitialDateInput.vue';
import EndDateInput from './Inputs/EndDateInput/EndDateInput.vue';

// Select inputs
import PodInput from './Inputs/PodInput/PodInput.vue';
import DescriptionInput from './Inputs/DescriptionInput/DescriptionInput.vue';
import ContractInput from './Inputs/ContractInput/ContractInput.vue';
import ClearIcon from '@/components/shared/Icons/ClearIcon.vue';
import StepsIcon from '@/components/shared/Icons/StepsIcon.vue';

export default {
	name: 'FilterList',
	components: {
		InitialDateInput,
		EndDateInput,
		PodInput,
		DescriptionInput,
		ContractInput,
		ClearIcon,
		StepsIcon,
	},
	props: {
		isWeb: {
			type: Boolean,
			required: true,
		},
	},
	data: () => ({
		filter: {
			fromDate: null,
			toDate: null,
			contractNumberSequence: null,
			podValue: null,
			descriptionValue: null,
		},
		refreshCalendars: true,
	}),
	mounted() {
		this.clearCachedFilters()
	},
	methods: {
		...mapActions('docInstructionFilters', {
			clearStoreFilters: CLEAR_ALL_DOC_FILTERS,
			saveLastQueryString: SAVE_LAST_QUERY_STRING,
		}),
		...mapActions('docInstruction', {
			setShowStepList: SET_SHOW_STEP_LIST,
		}),
		filterRequest(param) {
			if (param.type == 'fromDate' || param.type == 'toDate') {
				this.filter[param.type] = param.value ? param.value.id : null;
			} else {
				this.filter[param.type] = param.value ? param.value.map((item) => item.id) : null;
			}
			let queryString = this.createQueryString();

			let filterObj = {
				queryString: queryString,
				skip: true,
			};

			if (!param.value || !param.value.length) {
				this.$emit('setPageOne');
			}

			this.$emit('refreshFilters', filterObj);

			//refresh dos outros componentes
			// this.$emit('refreshContracts', queryString);

			this.saveLastQueryString(queryString);
		},
		filterCachedRequest() {
			// Calendar Caches
			const fromDateCache = this.initDateFilterCache.value;
			const toDateCache = this.endDateFilterCache.value;

			// Select Caches
			const contractFilterCache = this.contractFilterCache.value;
			const podFilterCache = this.podFilterCache.value;
			const descriptionFilterCache = this.descriptionFilterCache.value;

			if (fromDateCache.id != undefined) {
				this.filter['fromDate'] = fromDateCache.id;
			}

			if (toDateCache.id != undefined) {
				this.filter['toDate'] = toDateCache.id;
			}

			if (contractFilterCache && Object.keys(contractFilterCache).length) {
				this.filter['contractNumberSequence'] = contractFilterCache.map((item) => item.id);
			}

			if (podFilterCache && Object.keys(podFilterCache).length) {
				this.filter['podValue'] = podFilterCache.map((item) => item.id);
			}

			if (descriptionFilterCache && Object.keys(descriptionFilterCache).length) {
				this.filter['descriptionValue'] = descriptionFilterCache.map((item) => item.id);
			}

			let queryString = this.createQueryString();

			this.$emit('refreshContracts', queryString);
		},
		createQueryString() {
			let { fromDate, toDate, contractNumberSequence, podValue, descriptionValue } = this.filter;

			const query = new URLSearchParams();

			// Calendar Filters
			if (fromDate) query.set('startDate', fromDate);
			if (toDate) query.set('endDate', toDate);

			//MultiSelect Filters
			if (contractNumberSequence && contractNumberSequence.length)
				query.set('contract', contractNumberSequence);

			if (podValue && podValue.length) query.set('pod', podValue);

			if (descriptionValue && descriptionValue.length)
				query.set('skuDescription', descriptionValue);

			return query.toString();
		},
		clearCachedFilters() {
			this.clearStoreFilters();
			this.clearFilters();
		},
		clearFilters() {
			this.$refs.initialDateInput.clearInput();
			this.$refs.endDateInput.clearInput();
			this.$refs.podInput.clearInput();
			this.$refs.descriptionInput.clearInput();
			this.$refs.contractInput.clearInput();

			this.filter = {
				fromDate: null,
				toDate: null,
				contractNumberSequence: null,
			};

			// refresh dos outros componentes
			// this.$emit('refreshContracts', null);
			this.$emit('refreshFilters');
		},
		setCachedPodInput() {
			this.$refs.podInput.setCachedInput();
		},
		setCachedDescriptionInput() {
			this.$refs.descriptionInput.setCachedInput();
		},
		setCachedContractInput() {
			this.$refs.contractInput.setCachedInput();
		},
		handleSteps() {
			this.setShowStepList(!this.showStepList);
		},
	},
	computed: {
		...mapGetters('user', ['userLang']),
		...mapGetters('docInstructionFilters', [
			'initDateFilterCache',
			'endDateFilterCache',
			'podFilterCache',
			'descriptionFilterCache',
			'contractFilterCache',
		]),
		...mapGetters('docInstruction', ['showStepList']),
	},
	watch: {
		userLang(newValue) {
			if (newValue) {
				this.refreshCalendars = false;

				setTimeout(() => {
					this.refreshCalendars = true;
				}, 100);
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'FilterList.scss';
</style>
