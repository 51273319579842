import ptBR from '@/lang/language/pt-br';
import enUS from '@/lang/language/en-us';
import esES from '@/lang/language/es-es';
import arAR from '@/lang/language/ar-ar';
import cnCN from '@/lang/language/cn-cn';

export default {
	'pt-BR': ptBR,
	'en-US': enUS,
	'es-ES': esES,
	'ar-AR': arAR,
	'cn-CN': cnCN,
};
