<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 16 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.7482 15.1709C10.6685 16.6085 9.46297 17.75 7.9875 17.75C6.51203 17.75 5.30649 16.6085 5.22679 15.1709H1.84631C0.826682 15.1709 0 14.3563 0 13.3504C0 12.3459 0.825989 11.5299 1.84391 11.5299C1.84271 11.5299 1.84327 7.88866 1.84327 7.88866C1.84327 5.32397 3.46714 3.07536 5.83702 2.20245V2.12249C5.83702 0.950625 6.80119 0 7.9875 0C9.17508 0 10.138 0.950053 10.138 2.12249V2.2025C12.5078 3.07553 14.1317 5.32451 14.1317 7.88866V11.5294C15.1475 11.5299 15.975 12.3456 15.975 13.3504C15.975 14.3552 15.1477 15.1709 14.1287 15.1709H10.7482ZM6.45901 15.171C6.53608 15.9376 7.19098 16.5364 7.98748 16.5364C8.78398 16.5364 9.43889 15.9376 9.51595 15.171H6.45901ZM3.07227 7.88861C3.07227 5.71098 4.53576 3.818 6.62188 3.22373L7.06602 3.0972V2.12244C7.06602 1.62134 7.47955 1.21362 7.98766 1.21362C8.49685 1.21362 8.90929 1.62057 8.90929 2.12244V3.09721L9.35342 3.22375C11.4394 3.81808 12.903 5.71151 12.903 7.88861V11.5293C12.903 12.2014 13.4523 12.7435 14.1312 12.7435C14.4695 12.7435 14.7463 13.0164 14.7463 13.3504C14.7463 13.6844 14.4696 13.9572 14.1289 13.9572H1.84647C1.50489 13.9572 1.229 13.6853 1.229 13.3504C1.229 13.0161 1.50489 12.7435 1.84407 12.7435C2.52171 12.7435 3.07227 12.1994 3.07227 11.5293V7.88861Z"
			:fill="color"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#234057',
		},
		height: {
			default: '18',
		},
		width: {
			default: '16',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
