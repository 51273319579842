<template>
	<div class="financial-row">
		<div class="financial-row__header">
			<div class="financial-row__container">
				<h1 class="financial-row__container--title">
					{{ title }}
				</h1>
			</div>
			<div class="row align-center">
				<FinancialInfo
					:title="$t('modules.financial_management.titles.overdue')"
					:infoData="overDueValues"
					v-if="showPayments && isWeb"
				/>
				<FinancialInfo
					:title="$t('modules.financial_management.titles.settle')"
					:infoData="dueValues"
					v-if="showPayments && isWeb"
				/>
				<FinancialInfo
					:title="$t('modules.financial_management.titles.overdue')"
					:infoData="overDueInvoiceValues"
					v-if="showInvoices && isWeb"
				/>
				<FinancialInfo
					:title="$t('modules.financial_management.titles.settle')"
					:infoData="dueInvoiceValues"
					v-if="showInvoices && isWeb"
				/>
				<div class="financial-row__icon" @click="handleContent()">
					<ArrowDown :class="showContent ? 'rotate' : ''" :color="'#234057'" />
				</div>
			</div>
		</div>
		<div class="financial-row__body" :class="!showContent ? 'closed' : ''">
			<slot name="body"></slot>
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import FinancialInfo from '../FinancialInfo/FinancialInfo.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'FinancialRow',
	props: {
		isWeb: {
			required: true,
		},
		title: {
			required: true,
		},
		showPayments: {
			default: false,
		},
		showInvoices: {
			default: false,
		},
	},
	components: {
		ArrowDown,
		FinancialInfo,
	},
	data: () => ({
		showContent: false,
		mockedValues: [
			{ prefix: '$', value: 30800.19 },
			{ prefix: '€', value: 30800.19 },
			{ prefix: '£', value: 30800.19 },
			{ prefix: '¥', value: 30800.19 },
		],
		dueValues: [],
		overDueValues: [],
		dueInvoiceValues: [],
		overDueInvoiceValues: [],
	}),
	created() {},
	mounted() {},
	beforeDestroy() {},
	methods: {
		handleContent() {
			this.showContent = !this.showContent;
		},
		selectCurrencySymbol(value) {
			switch (value) {
				case 'BRL':
					return 'R$';
				case 'USD':
					return '$';
				case 'JPL':
					return '¥';
				case 'EUR':
					return '€';
				default:
					return '£ ';
			}
		},
	},
	computed: {
		...mapGetters('financialManagement', [
			'prePaymentsDue',
			'prePaymentsOverDue',
			'isLoadingPrePayments',
			'invoiceDue',
			'invoiceOverDue',
			'isLoadingInvoices',
		]),
	},
	watch: {
		isLoadingPrePayments(value) {
			this.overDueValues = [];
			this.dueValues = [];
			const normalizeItem = (item) => ({ prefix: this.selectCurrencySymbol(item.currency), value: item.allocatedValue, });

			if (value === 'success') {
				this.dueValues = this.prePaymentsDue.map(normalizeItem); 
				this.overDueValues = this.prePaymentsOverDue.map(normalizeItem);
			}
		},
		isLoadingInvoices(value) {
			this.overDueInvoiceValues = [];
			this.dueInvoiceValues = [];
			const normalizeItem = (item) => ({ prefix: this.selectCurrencySymbol(item.currency), value: item.invoiceBalance, });

			if (value === 'success') {
				this.dueInvoiceValues = this.invoiceDue.map(normalizeItem);
				this.overDueInvoiceValues = this.invoiceOverDue.map(normalizeItem);
			}
		},
	},
};
</script>

<style lang="scss">
@import 'FinancialRow.scss';
</style>
