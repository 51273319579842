import {
	CLEAR_DOC_STATE,
	DOC_REQUEST,
	DOC_REQUEST_SUCCESS,
	DOC_REQUEST_ERROR,
	CONTRACT_DETAIL_REQUEST,
	CONTRACT_DETAIL_REQUEST_SUCCESS,
	CONTRACT_DETAIL_REQUEST_ERROR,
	CONTAINER_LIST_REQUEST,
	CONTAINER_LIST_REQUEST_SUCCESS,
	CONTAINER_LIST_REQUEST_ERROR,
	COURIER_LIST_REQUEST,
	COURIER_LIST_REQUEST_SUCCESS,
	COURIER_LIST_REQUEST_ERROR,
	DOC_DOWNLOAD_REQUEST,
	DOC_DOWNLOAD_REQUEST_SUCCESS,
	DOC_DOWNLOAD_REQUEST_ERROR,
	DOCUMENT_APPROVAL_REQUEST,
	DOCUMENT_APPROVAL_REQUEST_SUCCESS,
	DOCUMENT_APPROVAL_REQUEST_ERROR,
} from './actions';

import api from '@/utils/api';

const DOCUMENT_MANAGEMENT_URL = '/invoices';

const { http } = api.getInstance();

const defaultState = () => ({
	docsList: [],
	docsListPage: 1,
	docsListMax: 0,
	isLoadingDocsList: '',

	contractDetail: [],
	contractDetailPage: 1,
	contractDetailMax: 0,
	isLoadingContractDetail: '',

	containerList: [],
	isLoadingContainerList: '',

	courierList: [],
	isLoadingCourierList: '',
});

const state = defaultState();

const getters = {
	// DOC LIST
	docsList: (state) => state.docsList,
	docsListPage: (state) => state.docsListPage,
	docsListMax: (state) => state.docsListMax,
	isLoadingDocsList: (state) => state.isLoadingDocsList,

	// CONTRACT DETAIL
	contractDetail: (state) => state.contractDetail,
	contractDetailPage: (state) => state.contractDetailPage,
	contractDetailMax: (state) => state.contractDetailMax,
	isLoadingContractDetail: (state) => state.isLoadingContractDetail,

	// CONTAINER LIST
	containerList: (state) => state.containerList,
	isLoadingContainerList: (state) => state.isLoadingContainerList,

	// COURIER LIST
	courierList: (state) => state.courierList,
	isLoadingCourierList: (state) => state.isLoadingCourierList,

	// DOCUMENT APPROVAL
	isLoadingDocApproval: (state) => state.isLoadingDocApproval === 'loading',
};

const mutations = {
	[CLEAR_DOC_STATE]: (state) => {
		Object.assign(state, defaultState());
	},

	//DOC REQUEST
	[DOC_REQUEST]: (state) => {
		state.isLoadingDocsList = 'loading';
	},
	[DOC_REQUEST_SUCCESS]: (state, data) => {
		state.docsListPage = data.meta.currentPage;
		state.docsListMax = data.meta.totalPages;
		state.docsList = data.items;

		state.isLoadingDocsList = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingDocsList = 'empty';
		}
	},
	[DOC_REQUEST_ERROR]: (state) => {
		state.docsList = [];
		state.isLoadingDocsList = 'error';
	},

	//CONTRACAT DETAIL
	[CONTRACT_DETAIL_REQUEST]: (state) => {
		state.isLoadingContractDetail = 'loading';
	},
	[CONTRACT_DETAIL_REQUEST_SUCCESS]: (state, data) => {
		let incomingData = {
			invoiceNumber: data.invoiceNumber,
			data: data.response,
		};

		const currentData = state.contractDetail.filter(
			(item) => item.invoiceNumber != data.invoiceNumber,
		);
		currentData.push(incomingData);

		state.contractDetail = currentData;
		state.isLoadingContractDetail = 'success';
		if (data.length == 0) {
			state.isLoadingContractDetail = 'empty';
		}
	},
	[CONTRACT_DETAIL_REQUEST_ERROR]: (state) => {
		state.contractDetail = [];
		state.isLoadingContractDetail = 'error';
	},

	//CONTAINER LIST
	[CONTAINER_LIST_REQUEST]: (state) => {
		state.isLoadingContainerList = 'loading';
	},
	[CONTAINER_LIST_REQUEST_SUCCESS]: (state, data) => {
		let incomingData = {
			invoiceNumber: data.invoiceNumber,
			data: data.response,
		};

		const currentData = state.containerList.filter(
			(item) => item.invoiceNumber != data.invoiceNumber,
		);
		currentData.push(incomingData);

		state.containerList = currentData;
		state.isLoadingContainerList = 'success';
		if (data.length == 0) {
			state.isLoadingContainerList = 'empty';
		}
	},
	[CONTAINER_LIST_REQUEST_ERROR]: (state) => {
		state.containerList = [];
		state.isLoadingContainerList = 'error';
	},

	//COURIER LIST
	[COURIER_LIST_REQUEST]: (state) => {
		state.isLoadingCourierList = 'loading';
	},
	[COURIER_LIST_REQUEST_SUCCESS]: (state, data) => {
		let incomingData = {
			invoiceNumber: data.invoiceNumber,
			data: data.response,
		};

		const currentData = state.courierList.filter(
			(item) => item.invoiceNumber != data.invoiceNumber,
		);
		currentData.push(incomingData);

		state.courierList = currentData;
		state.isLoadingCourierList = 'success';
		if (data.length == 0) {
			state.isLoadingCourierList = 'empty';
		}
	},
	[COURIER_LIST_REQUEST_ERROR]: (state) => {
		state.courierList = [];
		state.isLoadingCourierList = 'error';
	},

	//DOCUMENT APPROVAL
	[DOCUMENT_APPROVAL_REQUEST]: (state) => {
		state.isLoadingDocApproval = 'loading';
	},
	[DOCUMENT_APPROVAL_REQUEST_SUCCESS]: (state, data) => {
		state.isLoadingDocApproval = 'success';
	},
	[DOCUMENT_APPROVAL_REQUEST_ERROR]: (state) => {
		state.isLoadingDocApproval = 'error';
	},
};

const actions = {
	[CLEAR_DOC_STATE]: ({ commit }) => {
		commit(CLEAR_DOC_STATE);
	},

	// DOC REQUEST
	[DOC_REQUEST]: (
		{ commit },
		{
			vm,
			companyId,
			page = 1,
			limit = 10,
			fromDate = '',
			toDate = '',
			invoice = '',
			contract = '',
			sequence = '',
			purchaseOrder = '',
			container = '',
			vehicle = '',
			pod = '',
			load = '',
			acronym = '',
			description = '',
			sort = '',
		},
	) => {
		return new Promise((resolve) => {
			commit(DOC_REQUEST);

			let url = `${DOCUMENT_MANAGEMENT_URL}?companyId=${companyId}&page=${page}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}&invoice=${invoice}&contract=${contract}&sequence=${sequence}&purchaseOrder=${purchaseOrder}&container=${container}&vehicle=${vehicle}&pod=${pod}&load=${load}&acronym=${acronym}&description=${description}&${sort}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DOC_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},

	// CONTAINER LIST
	[CONTAINER_LIST_REQUEST]: (
		{ commit },
		{ vm, invoiceNumber, companyId, page = 1, limit = 10 },
	) => {
		return new Promise((resolve) => {
			commit(CONTAINER_LIST_REQUEST);

			let url = `${DOCUMENT_MANAGEMENT_URL}/${invoiceNumber}/items?companyId=${companyId}&page=${page}&limit=${limit}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CONTAINER_LIST_REQUEST_SUCCESS, {
						response: response.data,
						invoiceNumber: invoiceNumber,
					});
					resolve(response);
				})
				.catch((error) => {
					commit(CONTAINER_LIST_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},

	// COURIER LIST
	[COURIER_LIST_REQUEST]: ({ commit }, { vm, invoiceNumber, companyId, page = 1, limit = 10 }) => {
		return new Promise((resolve) => {
			commit(COURIER_LIST_REQUEST);

			let url = `${DOCUMENT_MANAGEMENT_URL}/${invoiceNumber}/couriers?companyId=${companyId}&page=${page}&limit=${limit}`;

			http({
				method: 'GET',
				url: url,
				headers: {
					'Accept-Language': vm.$i18n.locale,
				},
			})
				.then((response) => {
					commit(COURIER_LIST_REQUEST_SUCCESS, {
						response: response.data,
						invoiceNumber: invoiceNumber,
					});
					resolve(response);
				})
				.catch((error) => {
					commit(COURIER_LIST_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},

	// CONTRACT DETAIL
	[CONTRACT_DETAIL_REQUEST]: ({ commit }, { vm, invoiceNumber }) => {
		return new Promise((resolve) => {
			commit(CONTRACT_DETAIL_REQUEST);

			let url = `${DOCUMENT_MANAGEMENT_URL}/${invoiceNumber}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(CONTRACT_DETAIL_REQUEST_SUCCESS, {
						response: response.data,
						invoiceNumber: invoiceNumber,
					});
					resolve(response);
				})
				.catch((error) => {
					commit(CONTRACT_DETAIL_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},

	// DOCUMENT DOWNLOAD
	[DOC_DOWNLOAD_REQUEST]: ({ commit }, { vm, code, name = null }) => {
		return new Promise((resolve) => {
			commit(DOC_DOWNLOAD_REQUEST);

			name = name !== null ? name : 'document';

			let url = `${DOCUMENT_MANAGEMENT_URL}/download/${code}`;

			http({
				method: 'GET',
				url: url,
				responseType: 'blob',
			})
				.then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					const type = `${name}.pdf`;
					link.href = url;
					link.setAttribute('download', type);
					document.body.appendChild(link);
					link.click();
					link.remove();

					commit(DOC_DOWNLOAD_REQUEST_SUCCESS);
					resolve(response);
				})
				.catch((error) => {
					commit(DOC_DOWNLOAD_REQUEST_ERROR);
				});
		});
	},

	// DOCUMENT APPROVAL
	[DOCUMENT_APPROVAL_REQUEST]({ commit }, { vm, body, code }) {
		return new Promise((resolve, reject) => {
			let url = `${DOCUMENT_MANAGEMENT_URL}/update/${code}`;

			http({
				method: 'PATCH',
				url: url,
				data: body,
			})
				.then((response) => {
					commit(DOCUMENT_APPROVAL_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DOCUMENT_APPROVAL_REQUEST_ERROR);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
