export const GET_ACTIVED_TEMPLATES = 'GET_ACTIVED_TEMPLATES';
export const GET_DISABLED_TEMPLATES = 'GET_DISABLED_TEMPLATES';
export const CLEAN_ALL_TEMPLATES = 'CLEAN_ALL_TEMPLATES';
export const TEMPLATES_ACTIVED_REQUEST_SUCCESS = 'TEMPLATES_ACTIVED_REQUEST_SUCCESS';
export const TEMPLATES_DISABLED_REQUEST_SUCCESS = 'TEMPLATES_DISABLED_REQUEST_SUCCESS';
export const TEMPLATES_ACTIVED_REQUEST_ERROR = 'TEMPLATES_ACTIVED_REQUEST_ERROR';
export const TEMPLATES_DISABLED_REQUEST_ERROR = 'TEMPLATES_DISABLED_REQUEST_ERROR';
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const CLEAN_TEMPLATE = 'CLEAN_TEMPLATE';
export const CHANGE_TEMPLATE_STATUS = 'CHANGE_TEMPLATE_STATUS';
