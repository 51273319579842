<template>
	<div id="trackingTable">
		<div class="table-container" v-if="!verifyTableData && !isLoadingContractItem">
			<table class="table">
				<thead class="table__header">
					<tr>
						<th colspan="2" :style="`background: ${color}`">
							{{ title }}
						</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="(row, index) in tableData">
						<tr :key="index">
							<td class="bold">{{ $t(`modules.contract_detail.${labelFlag}.${index}`) }}</td>
							<td class="stripped">
								{{ verifyDate(row) }}
							</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<div class="loader" v-if="isLoadingContractItem">
			<SpinnerIcon />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import moment from 'moment';

export default {
	name: 'TrackingTable',
	components: { SpinnerIcon },
	props: {
		isWeb: {
			required: true,
		},
		title: {
			default: 'Título',
		},
		color: {
			default: '#234057',
		},
		tableData: {
			required: true,
		},
		labelFlag: {
			required: true,
		},
	},
	data: () => ({
		isLoading: false,
	}),
	mounted() {},
	methods: {
		verifyDate(param) {
			let value = param;

			if (
				param !== null &&
				param.toString().includes('-') &&
				new Date(param) !== 'Invalid Date' &&
				!isNaN(new Date(param))
			) {
				value = moment(param).format('DD/MM/YYYY');
			}
			return value ? value : '-';
		},
	},
	computed: {
		...mapGetters('contractDetail', ['isLoadingContractItem']),
		verifyTableData() {
			const isNull = Object.values(this.tableData).every((value) => {
				if (value == null) {
					return true;
				}

				return false;
			});

			return this.labelFlag !== 'internationalTransport' ? isNull : false;
		},
	},
};
</script>

<style scoped lang="scss">
@import 'TrackingTable.scss';
</style>
