<template>
	<div class="load-row" :class="showContent ? 'open' : ''">
		<div class="load-row__header" v-if="isWeb">
			<div class="load-row__column">
				<div class="load-row__status">
					<DollarIcon />
				</div>
			</div>
			<div class="load-row__column">
				<span class="load-row__column--title">
					{{ $t('modules.load_management.cargoTable.cargo') }}
				</span>
				<span class="load-row__column--subtitle"> {{ cargo.loadNumber }} </span>
			</div>
			<div class="load-row__column">
				<span class="load-row__column--title">
					{{ $t('modules.load_management.cargoTable.container') }}
				</span>
				<span class="load-row__column--subtitle"> {{ cargo.container }} </span>
			</div>
			<div class="load-row__column">
				<span class="load-row__column--title">
					{{ $t('modules.load_management.cargoTable.invoice') }}
				</span>
				<span class="load-row__column--subtitle"> {{ cargo.invoiceNumber }} </span>
			</div>
			<div class="load-row__column">
				<span class="load-row__column--title">
					{{ $t('modules.load_management.cargoTable.vehicle') }}
				</span>
				<span class="load-row__column--subtitle"> {{ cargo.vessel }} </span>
			</div>
			<div class="load-row__column">
				<span class="load-row__column--title">
					{{ $t('modules.load_management.cargoTable.POD') }}
				</span>
				<span class="load-row__column--subtitle"> {{ cargo.origin }} </span>
			</div>
			<div class="load-row__column">
				<span class="load-row__column--title">
					{{ $t('modules.load_management.cargoTable.gateIn') }}
				</span>
				<span class="load-row__column--subtitle"> {{ formateDate(cargo.gateInDate) }} </span>
			</div>
			<div class="load-row__column">
				<span class="load-row__column--title">
					{{ $t('modules.load_management.cargoTable.ets') }}
				</span>
				<span class="load-row__column--subtitle"> {{ formateDate(cargo.expectedEtbDate) }} </span>
			</div>
			<div class="load-row__column">
				<span class="load-row__column--title">
					{{ $t('modules.load_management.cargoTable.eta') }}
				</span>
				<span class="load-row__column--subtitle"> {{ formateDate(cargo.realAtbDate) }} </span>
			</div>
			<div class="load-row__column">
				<span class="load-row__column--title">
					{{ $t('modules.load_management.cargoTable.gateOut') }}
				</span>
				<span class="load-row__column--subtitle"> {{ formateDate(cargo.gateOutDate) }} </span>
			</div>
			<div class="load-row__column">
				<span class="load-row__column--title">
					{{ $t('modules.load_management.cargoTable.netWeight') }}
				</span>
				<span class="load-row__column--subtitle">{{ roundWeight(cargo.netWeight) }} t</span>
			</div>
			<div class="load-row__icon" @click="handleContent()">
				<ArrowDown :class="showContent ? 'rotate' : ''" :color="'#234057'" />
			</div>
		</div>

		<div class="load-row__header" v-if="!isWeb">
			<div class="load-row__column">
				<span class="load-row__column--subtitle">
					<strong>#{{ cargo.loadNumber }}</strong>
				</span>
				<span class="load-row__column--subtitle"> {{ formateDate(cargo.gateInDate) }} </span>
			</div>
			<div class="load-row__column-group">
				<div class="load-row__column">
					<span class="load-row__column--subtitle">{{ roundWeight(cargo.netWeight) }} t</span>
				</div>
				<div class="load-row__column">
					<div class="load-row__status">
						<DollarIcon />
					</div>
				</div>
				<div class="load-row__icon" @click="handleContent()">
					<ArrowDown :class="showContent ? 'rotate' : ''" :color="'#234057'" />
				</div>
			</div>
		</div>

		<div class="load-row__body" v-if="!isWeb">
			<div class="detail__row">
				<div class="detail__column">{{ $t('modules.load_management.cargoTable.cargo') }}</div>
				<div class="detail__column">{{ cargo.loadNumber }}</div>
			</div>
			<div class="detail__row">
				<div class="detail__column">{{ $t('modules.load_management.cargoTable.container') }}</div>
				<div class="detail__column">{{ cargo.container }}</div>
			</div>
			<div class="detail__row">
				<div class="detail__column">{{ $t('modules.load_management.cargoTable.invoice') }}</div>
				<div class="detail__column">{{ cargo.invoiceNumber }}</div>
			</div>
			<div class="detail__row">
				<div class="detail__column">{{ $t('modules.load_management.cargoTable.vehicle') }}</div>
				<div class="detail__column">{{ cargo.vessel }}</div>
			</div>
			<div class="detail__row">
				<div class="detail__column">{{ $t('modules.load_management.cargoTable.POD') }}</div>
				<div class="detail__column">{{ cargo.origin }}</div>
			</div>
			<div class="detail__row">
				<div class="detail__column">{{ $t('modules.load_management.cargoTable.gateIn') }}</div>
				<div class="detail__column">{{ formateDate(cargo.gateInDate) }}</div>
			</div>
			<div class="detail__row">
				<div class="detail__column">{{ $t('modules.load_management.cargoTable.ets') }}</div>
				<div class="detail__column">{{ formateDate(cargo.expectedEtbDate) }}</div>
			</div>
			<div class="detail__row">
				<div class="detail__column">{{ $t('modules.load_management.cargoTable.eta') }}</div>
				<div class="detail__column">{{ formateDate(cargo.realAtbDate) }}</div>
			</div>
			<div class="detail__row">
				<div class="detail__column">{{ $t('modules.load_management.cargoTable.gateOut') }}</div>
				<div class="detail__column">{{ formateDate(cargo.gateOutDate) }}</div>
			</div>
			<div class="detail__row">
				<div class="detail__column">{{ $t('modules.load_management.cargoTable.netWeight') }}</div>
				<div class="detail__column">{{ roundWeight(cargo.netWeight) }} t</div>
			</div>
		</div>

		<div class="load-row__body m-scroll">
			<div v-if="contractList.length > 0" class="table-head">
				<div class="table-head table-column">
					{{ $t('modules.load_management.contractTable.sequence') }}
				</div>
				<div class="table-head table-column">
					{{ $t('modules.load_management.contractTable.acronym') }}
				</div>
				<div class="table-head table-column">
					{{ $t('modules.load_management.contractTable.description') }}
				</div>
				<div class="table-head table-column">
					{{ $t('modules.load_management.contractTable.brand') }}
				</div>
				<div class="table-head table-column">
					{{ $t('modules.load_management.contractTable.sif') }}
				</div>
				<div class="table-head table-column">
					{{ $t('modules.load_management.contractTable.volume') }}
				</div>
				<div class="table-head table-column">
					{{ $t('modules.load_management.contractTable.weight') }}
				</div>
			</div>
			<DefaultLoader v-else dataStatus="loading" color="gray" />
			<div v-for="(contract, i) in contractList" :key="i" class="table-row">
				<div class="table-row table-column">
					{{ contract.contractNumber + '.' + contract.contractSequence }}
				</div>
				<div class="table-row table-column">{{ contract.sku }}</div>
				<div class="table-row table-column">{{ contract.dsInternationalOfc }}</div>
				<div class="table-row table-column">{{ contract.brand }}</div>
				<div class="table-row table-column">{{ contract.sif }}</div>
				<div class="table-row table-column">{{ contract.totalVolume }}</div>
				<div class="table-row table-column">
					{{ kiloToTonne(contract.totalKg) }}
					t
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import DollarIcon from '@/components/shared/Icons/DollarIcon.vue';
import DefaultLoader from '@/components/shared/DefaultLoader/DefaultLoader.vue';

import { CARGO_CONTRACTS_REQUEST } from '@/store/modules/loadManagement/actions';

export default {
	name: 'LoadRow',
	props: {
		isWeb: {
			required: true,
		},
		cargo: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	components: {
		ArrowDown,
		DollarIcon,
		DefaultLoader,
	},
	data: () => ({
		labels: [
			'Carga',
			'Container',
			'Invoice',
			'Veículo',
			'POD',
			'Gate In',
			'ETS / ATS POL',
			'ETA POD',
			'Gate Out',
			'Peso Líquido',
		],
		showContent: false,
		contractList: [],
	}),
	created() {},
	mounted() {},
	beforeDestroy() {},
	methods: {
		...mapActions('loadManagement', {
			getContracts: CARGO_CONTRACTS_REQUEST,
		}),
		handleContent() {
			this.showContent = !this.showContent;
			if (this.showContent === true) {
				this.getContracts({ vm: this, loadNumber: this.cargo.loadNumber });
			}
		},
		kiloToTonne(value) {
			let newValue = value / 1000.0;
			const normalizedValue = newValue.toFixed(2);
			return normalizedValue.toString().replace('.', ',');
		},
		roundWeight(value) {
			const normalizedValue = value.toFixed(2);
			return normalizedValue.toString().replace('.', ',');
		},
		formateDate(value) {
			if (!value) {
				return '';
			}

			const createdDate = new Date(value);
			let month = parseInt(createdDate.getMonth()) + 1;
			month = month.toString().length > 1 ? month : '0' + month;

			let day = createdDate.getDate();
			day = day.toString().length > 1 ? day : '0' + day;

			return day + '/' + month + '/' + createdDate.getFullYear();
		},
	},
	computed: {
		...mapGetters('loadManagement', ['cargoContractList']),
	},
	watch: {
		cargoContractList(newValue) {
			if (newValue.length > 0) {
				const handleDate = newValue.filter((item) => item.loadNumber == this.cargo.loadNumber);
				this.contractList = handleDate[0] ? handleDate[0].data : [];
				return;
			}
			this.contractList = [];
		},
		computed: {
			...mapGetters('loadManagement', ['cargoContractList']),
		},
	},
};
</script>

<style lang="scss">
@import 'LoadRow.scss';
</style>
