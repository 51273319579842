export const CLEAR_ALL_DOC_FILTERS = 'CLEAR_ALL_DOC_FILTERS';

//INIT_DATE
export const SAVE_INIT_DATE_FILTER = 'SAVE_INIT_DATE_FILTER';
export const CLEAR_INIT_DATE_FILTER = 'CLEAR_INIT_DATE_FILTER';

//END_DATE
export const SAVE_END_DATE_FILTER = 'SAVE_END_DATE_FILTER';
export const CLEAR_END_DATE_FILTER = 'CLEAR_END_DATE_FILTER';

export const SAVE_LAST_QUERY_STRING = 'SAVE_LAST_QUERY_STRING';

//DOC_INVOICE
export const DOC_INVOICE_FILTER_REQUEST = 'DOC_INVOICE_FILTER_REQUEST';
export const DOC_INVOICE_FILTER_REQUEST_SUCCESS = 'DOC_INVOICE_FILTER_REQUEST_SUCCESS';
export const DOC_INVOICE_FILTER_REQUEST_ERROR = 'DOC_INVOICE_FILTER_REQUEST_ERROR';
export const SAVE_DOC_INVOICE_FILTER = 'SAVE_DOC_INVOICE_FILTER';
export const CLEAR_DOC_INVOICE_FILTER = 'CLEAR_DOC_INVOICE_FILTER';

//DOC_CONTRACT
export const DOC_CONTRACT_FILTER_REQUEST = 'DOC_CONTRACT_FILTER_REQUEST';
export const DOC_CONTRACT_FILTER_REQUEST_SUCCESS = 'DOC_CONTRACT_FILTER_REQUEST_SUCCESS';
export const DOC_CONTRACT_FILTER_REQUEST_ERROR = 'DOC_CONTRACT_FILTER_REQUEST_ERROR';
export const SAVE_DOC_CONTRACT_FILTER = 'SAVE_DOC_CONTRACT_FILTER';
export const CLEAR_DOC_CONTRACT_FILTER = 'CLEAR_DOC_CONTRACT_FILTER';

//DOC_SEQUENCE
export const DOC_SEQUENCE_FILTER_REQUEST = 'DOC_SEQUENCE_FILTER_REQUEST';
export const DOC_SEQUENCE_FILTER_REQUEST_SUCCESS = 'DOC_SEQUENCE_FILTER_REQUEST_SUCCESS';
export const DOC_SEQUENCE_FILTER_REQUEST_ERROR = 'DOC_SEQUENCE_FILTER_REQUEST_ERROR';
export const SAVE_DOC_SEQUENCE_FILTER = 'SAVE_DOC_SEQUENCE_FILTER';
export const CLEAR_DOC_SEQUENCE_FILTER = 'CLEAR_DOC_SEQUENCE_FILTER';

//DOC_PO
export const DOC_PO_FILTER_REQUEST = 'DOC_PO_FILTER_REQUEST';
export const DOC_PO_FILTER_REQUEST_SUCCESS = 'DOC_PO_FILTER_REQUEST_SUCCESS';
export const DOC_PO_FILTER_REQUEST_ERROR = 'DOC_PO_FILTER_REQUEST_ERROR';
export const SAVE_DOC_PO_FILTER = 'SAVE_DOC_PO_FILTER';
export const CLEAR_DOC_PO_FILTER = 'CLEAR_DOC_PO_FILTER';

//DOC_CONTAINER
export const DOC_CONTAINER_FILTER_REQUEST = 'DOC_CONTAINER_FILTER_REQUEST';
export const DOC_CONTAINER_FILTER_REQUEST_SUCCESS = 'DOC_CONTAINER_FILTER_REQUEST_SUCCESS';
export const DOC_CONTAINER_FILTER_REQUEST_ERROR = 'DOC_CONTAINER_FILTER_REQUEST_ERROR';
export const SAVE_DOC_CONTAINER_FILTER = 'SAVE_DOC_CONTAINER_FILTER';
export const CLEAR_DOC_CONTAINER_FILTER = 'CLEAR_DOC_CONTAINER_FILTER';

//DOC_VESSEL
export const DOC_VESSEL_FILTER_REQUEST = 'DOC_VESSEL_FILTER_REQUEST';
export const DOC_VESSEL_FILTER_REQUEST_SUCCESS = 'DOC_VESSEL_FILTER_REQUEST_SUCCESS';
export const DOC_VESSEL_FILTER_REQUEST_ERROR = 'DOC_VESSEL_FILTER_REQUEST_ERROR';
export const SAVE_DOC_VESSEL_FILTER = 'SAVE_DOC_VESSEL_FILTER';
export const CLEAR_DOC_VESSEL_FILTER = 'CLEAR_DOC_VESSEL_FILTER';

//DOC_POD
export const DOC_POD_FILTER_REQUEST = 'DOC_POD_FILTER_REQUEST';
export const DOC_POD_FILTER_REQUEST_SUCCESS = 'DOC_POD_FILTER_REQUEST_SUCCESS';
export const DOC_POD_FILTER_REQUEST_ERROR = 'DOC_POD_FILTER_REQUEST_ERROR';
export const SAVE_DOC_POD_FILTER = 'SAVE_DOC_POD_FILTER';
export const CLEAR_DOC_POD_FILTER = 'CLEAR_DOC_POD_FILTER';

//DOC_CARGO
export const DOC_CARGO_FILTER_REQUEST = 'DOC_CARGO_FILTER_REQUEST';
export const DOC_CARGO_FILTER_REQUEST_SUCCESS = 'DOC_CARGO_FILTER_REQUEST_SUCCESS';
export const DOC_CARGO_FILTER_REQUEST_ERROR = 'DOC_CARGO_FILTER_REQUEST_ERROR';
export const SAVE_DOC_CARGO_FILTER = 'SAVE_DOC_CARGO_FILTER';
export const CLEAR_DOC_CARGO_FILTER = 'CLEAR_DOC_CARGO_FILTER';

//DOC_ACRONYM
export const DOC_ACRONYM_FILTER_REQUEST = 'DOC_ACRONYM_FILTER_REQUEST';
export const DOC_ACRONYM_FILTER_REQUEST_SUCCESS = 'DOC_ACRONYM_FILTER_REQUEST_SUCCESS';
export const DOC_ACRONYM_FILTER_REQUEST_ERROR = 'DOC_ACRONYM_FILTER_REQUEST_ERROR';
export const SAVE_DOC_ACRONYM_FILTER = 'SAVE_DOC_ACRONYM_FILTER';
export const CLEAR_DOC_ACRONYM_FILTER = 'CLEAR_DOC_ACRONYM_FILTER';

//DOC_DESCRIPTION
export const DOC_DESCRIPTION_FILTER_REQUEST = 'DOC_DESCRIPTION_FILTER_REQUEST';
export const DOC_DESCRIPTION_FILTER_REQUEST_SUCCESS = 'DOC_DESCRIPTION_FILTER_REQUEST_SUCCESS';
export const DOC_DESCRIPTION_FILTER_REQUEST_ERROR = 'DOC_DESCRIPTION_FILTER_REQUEST_ERROR';
export const SAVE_DOC_DESCRIPTION_FILTER = 'SAVE_DOC_DESCRIPTION_FILTER';
export const CLEAR_DOC_DESCRIPTION_FILTER = 'CLEAR_DOC_DESCRIPTION_FILTER';
