import { render, staticRenderFns } from "./MinifyIcon.vue?vue&type=template&id=a8da0766&scoped=true"
import script from "./MinifyIcon.vue?vue&type=script&lang=js"
export * from "./MinifyIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8da0766",
  null
  
)

export default component.exports