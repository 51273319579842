<template>
	<svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path  d="M21.0187 4.09688C21.433 4.09688 21.7687 4.43267 21.7687 4.84688V9.34688C21.7687 9.76109 21.433 10.0969 21.0187 10.0969H16.5187C16.1045 10.0969 15.7687 9.76109 15.7687 9.34688C15.7687 8.93266 16.1045 8.59688 16.5187 8.59688H20.2687V4.84688C20.2687 4.43267 20.6045 4.09688 21.0187 4.09688Z" 
        :fill="color" 
        :stroke="color"
        stroke-width="0.1" />
        <path d="M13.4607 4.64456C12.0056 4.35468 10.4973 4.50284 9.12653 5.07032C7.75573 5.6378 6.58402 6.59909 5.75964 7.83259C4.93526 9.06609 4.49524 10.5164 4.49524 12C4.49524 13.4836 4.93526 14.9339 5.75964 16.1674C6.58402 17.4009 7.75573 18.3622 9.12653 18.9297C10.4973 19.4972 12.0056 19.6453 13.4607 19.3554C14.9157 19.0656 16.2521 18.3506 17.3007 17.3011C17.5935 17.0081 18.0684 17.0079 18.3614 17.3007C18.6544 17.5935 18.6546 18.0684 18.3618 18.3614C17.1034 19.6208 15.4998 20.4787 13.7538 20.8265C12.0077 21.1744 10.1977 20.9966 8.55278 20.3156C6.90782 19.6346 5.50178 18.4811 4.51252 17.0009C3.52327 15.5207 2.99524 13.7803 2.99524 12C2.99524 10.2197 3.52327 8.47931 4.51252 6.99911C5.50178 5.51891 6.90782 4.36536 8.55278 3.68439C10.1977 3.00341 12.0077 2.82561 13.7538 3.17347C15.4996 3.5213 17.1031 4.37905 18.3614 5.63823V5.63823L21.5483 8.81577C21.8416 9.10823 21.8423 9.5831 21.5499 9.87643C21.2574 10.1698 20.7825 10.1705 20.4892 9.87799L17.3007 6.69887C16.2521 5.64936 14.9157 4.93445 13.4607 4.64456Z" 
        :fill="color"
        :stroke="color"
        stroke-width="0.1" />
    </svg>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: '#234057',
		},
	},
};
</script>