export default {
	placeholder: {
		search: 'Search',
	},
	header: {
		contractNumber: '合同号码',
		contractDate: '合同签发日期',
		competenceMonth: '月份盘',
		releaseDate: '合同发出日期',
		company: '公司名称',
		status: '階段',
		totalVolume: '总量',
	},
	detail: {
		actualDateOfBoarding: 'Actual Date of Boarding',
		awbCourier: 'AWB运单号',
		balance: '实际装船量与订单量差额',
		billLanding: '提单',
		billLandingDate: '提单签发日期',
		blDate: 'BI签发日期',
		blDateId: 'Id Data Bl',
		bookingNumber: '订舱号',
		boardingType: 'Boarding Type',
		borderCrossingDate: 'Border Crossing Date',
		borderPost: 'Border Post',
		borderPostsCode: 'Border Post Code',
		branch: '公司支部',
		brand: '品牌名称',
		brandCode: 'Brand Code',
		business: 'Business',
		closedContractSequence: '合同号',
		cneCode: 'CNE号',
		container: '柜号',
		contractSequence: '合同序列号',
		country: '国家',
		courierDate: '邮件发出日期',
		courierCompany: 'Courier Company',
		csiIssuanceDate: '卫生证签发日期',
		destination: 'Destination',
		destinationPort: 'Destination Port',
		destinationPort4: 'Destination Port 4',
		dsInternationalOfc: 'DS International',
		dueSituationCode: 'Due Situation Code',
		endShipDate: '截止装运日期',
		etaPodDate: '到港日期',
		etdPolDate: '装运日期',
		etdRealDate: '实际装运日期',
		etsRfsDate: 'RFS日期',
		etsRfsId: 'ETS RFS id',
		expectedEtdDate: 'ETS POL',
		expectedEtbDate: 'ATS POL',
		expectedEtsDate: 'Expected Ets Date',
		gateInDate: '船到启运港日期',
		idBlock: 'Block Id',
		idStatus: 'Status Id',
		incoterm: '贸易条款',
		initials: 'Product Code',
		invoiceNumber: '发票号',
		invoicedDate: '发票签发日期',
		invoiceIssuanceDate: 'Invoice Issuance Date',
		loadNumber: '货物编码',
		loadPlace: '装运地址',
		loadCode: 'Load Code',
		loadingDate: 'Loading Date',
		localization: 'Localization',
		market: '市场',
		groupingDescription: 'Grouping',
		maxGroupingQuantity: 'Max Grouping Quantity',
		minGroupingQuantity: 'Min Grouping Quantity',
		netWeight: '净重',
		nfiIssuanceDate: '内部发票签发日期',
		origin: 'Origin',
		paymentDeadline: 'Payment Deadline',
		paymentDeadlineCode: 'Payment Deadline Code',
		paymentMethod: '付款方式',
		paymentMode: '付款模式',
		permissionImt: 'Permission Imt',
		permissionImtDate: 'Permission Imt Date',
		planningCode: 'Planning Code',
		plannerLoadingDate: 'Planner Loading Date',
		pod: '目的港',
		pol: '启运港',
		priceType: '价格类型',
		productionMonth: '生产月份',
		productionDate: '生产日期',
		productName: '产品名称',
		provider: 'Provider',
		purchaseOrder: '订单号码',
		purchaseOrderClient: '客户订单号码',
		realAtdDate: 'Real Atd Date',
		realAtbDate: 'ETA POD',
		realArrivalDate: 'Real Arrival Date',
		regional: '地区',
		releaseSequenceDate: 'Release Sequence Date',
		restritionCode: 'Restriction Code',
		remarks: '备注',
		sentDraftDate: 'Sent Draft Date',
		scheduledDate: '计划日期',
		shippingCompany: 'Shipping Company',
		shippingLine: '船公司',
		sif: '厂号',
		sku: '产品代码',
		startShipDate: '启运日期',
		statusClosure: 'Status Closure',
		statusPrePaid: '预付情况',
		status: 'Status Sequence',
		stockLocation: '库存所在地',
		totalGrossWeight: '总毛重',
		totalKg: '总千克',
		totalVolume: '总量',
		traderName: '销售经理姓名',
		unitPrice: '单价',
		vehiclePlate: 'Vehicle Plate',
		vessel: '船名',
		weeksToShip: '启运周',
		stage: 'Status',
	},
};
