export const CLEAR_CONTRACT_MANAGEMENT_STATE = 'CLEAR_CONTRACT_MANAGEMENT_STATE';

export const CONTRACT_MANAGEMENT_REQUEST = 'CONTRACT_MANAGEMENT_REQUEST';
export const CONTRACT_MANAGEMENT_REQUEST_SUCCESS = 'CONTRACT_MANAGEMENT_REQUEST_SUCCESS';
export const CONTRACT_MANAGEMENT_REQUEST_ERROR = 'CONTRACT_MANAGEMENT_REQUEST_ERROR';

export const CONTRACT_TABLE_MANAGEMENT_REQUEST = 'CONTRACT_TABLE_MANAGEMENT_REQUEST';
export const CONTRACT_TABLE_MANAGEMENT_REQUEST_SUCCESS =
	'CONTRACT_TABLE_MANAGEMENT_REQUEST_SUCCESS';
export const CONTRACT_TABLE_MANAGEMENT_REQUEST_ERROR = 'CONTRACT_TABLE_MANAGEMENT_REQUEST_ERROR';

export const CONTRACT_PREFERENCES_REQUEST = 'CONTRACT_PREFERENCES_REQUEST';
export const CONTRACT_PREFERENCES_REQUEST_SUCCESS = 'CONTRACT_PREFERENCES_REQUEST_SUCCESS';
export const CONTRACT_PREFERENCES_REQUEST_ERROR = 'CONTRACT_PREFERENCES_REQUEST_ERROR';

export const CONTRACT_NOTIFICATION_REQUEST = 'CONTRACT_NOTIFICATION_REQUEST';
export const CONTRACT_NOTIFICATION_REQUEST_SUCCESS = 'CONTRACT_NOTIFICATION_REQUEST_SUCCESS';
export const CONTRACT_NOTIFICATION_REQUEST_ERROR = 'CONTRACT_NOTIFICATION_REQUEST_ERROR';

export const CONTRACT_DOWNLOAD_REQUEST = 'CONTRACT_DOWNLOAD_REQUEST';
export const CONTRACT_DOWNLOAD_REQUEST_SUCCESS = 'CONTRACT_DOWNLOAD_REQUEST_SUCCESS';
export const CONTRACT_DOWNLOAD_REQUEST_ERROR = 'CONTRACT_DOWNLOAD_REQUEST_ERROR';

export const CONTRACT_APPROVE_REQUEST = 'CONTRACT_APPROVE_REQUEST';
export const CONTRACT_APPROVE_RESET = 'CONTRACT_APPROVE_RESET';
export const CONTRACT_APPROVE_REQUEST_SUCCESS = 'CONTRACT_APPROVE_REQUEST_SUCCESS';
export const CONTRACT_APPROVE_REQUEST_ERROR = 'CONTRACT_APPROVE_REQUEST_ERROR';

export const UPDATE_PREFERENCES_REQUEST = 'UPDATE_PREFERENCES_REQUEST';

export const SAVE_LAST_ROW_OPENED = 'SAVE_LAST_ROW_OPENED';
