<template>
	<nav>
		<ul class="link-list" :class="isMobile ? 'mobile' : ''">
			<transition-group name="fade">
				<template v-for="(route, index) in navItems">
					<router-link
						:to="route.path"
						class="link-list__item"
						:class="verifyContractChild(route)"
						:key="`transition-${index}`"
						@click.native="closeSidebar()"
						v-if="route.showOnMenu"
					>
						<MenuIcons v-if="route.showOnMenu" :menuLabel="route.menuLabel" />
						<p class="hide">
							{{ $t(`${route.menuLabel}`) }}
						</p>
					</router-link>
				</template>
			</transition-group>
		</ul>
	</nav>
</template>

<script>
import { routes } from '@/router/router.js';
import MenuIcons from './MenuIcons/MenuIcons';

export default {
	name: 'MenuContent',
	components: {
		MenuIcons,
	},
	props: {
		isMobile: {
			default: false,
		},
	},
	data: () => ({
		navItems: [],
		uploadContracts: false,
		isUploadActive: false,
	}),
	created() {
		this.navItems = routes[0].children;
	},
	methods: {
		closeSidebar() {
			if (this.isMobile) this.$emit('handleSidebar');
		},
		verifyContractChild(param) {
			const actualRoute = this.$route.name;

			if (actualRoute == 'header.contract_details' && param.name == 'header.contract_management') {
				return 'router-link-exact-active router-link-active';
			}
		},
	},
};
</script>

<style lang="scss">
@import 'MenuContent.scss';
</style>
