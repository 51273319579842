<template>
	<GenericMultiselect
		class="users-company-multiselect"
		ref="company"
		:options="companyOptions"
		:placeholder="$t('modules.user_management.company_placeholder')"
		:fullWidth="isFullwidth || !isWeb"
		:searchable="true"
		:needType="true"
		:infiniteScroll="true"
		:showIcon="false"
		:multiple="isMultiple"
		:type="'company'"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { COMPANY_FILTER_REQUEST_ALL } from '@/store/common/filters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'CompanyInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
		isMultiple: {
			default: false,
		},
		isFullwidth: {
			default: null,
		},
		isModal: {
			default: false,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('filters', {
			getCompanyFilter: COMPANY_FILTER_REQUEST_ALL,
		}),
		changeInput(param) {
			if (this.isModal) {
				this.$emit('changeInput', param);
			} else {
				this.page = 1;
				this.$emit('filterRequest', param);
			}
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getCompanyFilter({ vm: this, params: query });
		},
		nextPage() {
			const meta = this.companyFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getCompanyFilter({ vm: this, params: query });
		},
		createQueryString(name) {
			const query = new URLSearchParams();

			if (name) query.set('name', name);
			if (name) this.page = 1;

			query.set('limit', '10');
			query.set('page', this.page);

			return query.toString();
		},
		setValue(param) {
			this.$refs.company.setValue(param);
		},
		clearInput() {
			this.$refs.company.setValue(null);
		},
	},
	computed: {
		...mapGetters('filters', ['companyFilter', 'companyFilterMeta', 'isLoadingCompanyFilter']),
		companyOptions() {
			const rows = this.companyFilter != undefined ? this.companyFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'CompanyInput.scss';
</style>
