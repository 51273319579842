import {
	CLEAR_DASHBOARD_STATE,
	DASHBOARD_CHARTS_REQUEST,
	DASHBOARD_CHARTS_REQUEST_SUCCESS,
	DASHBOARD_CHARTS_REQUEST_ERROR,
	DASHBOARD_TABLE_REQUEST,
	DASHBOARD_TABLE_REQUEST_SUCCESS,
	DASHBOARD_TABLE_REQUEST_ERROR,
} from './actions';
import api from '@/utils/api';

const { http } = api.getInstance();
const DASHBOARD_URL = '/dashboard';

const defaultState = () => ({
	dashboardCharts: {
		underAnalysisGraph: {
			percentage: 0,
			detail: [
				{
					status: 'Em processamento',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Restrições cliente',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Em produção',
					totalVolume: 0,
					percentage: 0,
				},
			],
		},
		financialGraph: {
			percentage: 0,
			detail: [
				{
					status: 'Aberta',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Aberta em atraso',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Pago parcial',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Pago total',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Finalizado',
					totalVolume: 0,
					percentage: 0,
				},
			],
		},
		barGraph: {
			percentage: 0,
			detail: [
				{
					status: 'Em produção',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Em carregamento',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Gate in origem',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Aguarda pré pgto',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Embarcado',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Documentação enviada',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Documentação entregue',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Entregue no POD',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Gate out destino',
					totalVolume: 0,
					percentage: 0,
				},
				{
					status: 'Vazio devolvido',
					totalVolume: 0,
					percentage: 0,
				},
			],
		},
	},
	isLoadingCharts: '',
	dashboardTable: [],
	isLoadingTable: '',
});

const state = defaultState();

const getters = {
	dashboardCharts: (state) => state.dashboardCharts,
	isLoadingCharts: (state) => state.isLoadingCharts === 'loading',
	dashboardTable: (state) => state.dashboardTable,
	isLoadingTable: (state) => state.isLoadingTable === 'loading',
};

const mutations = {
	[CLEAR_DASHBOARD_STATE]: (state) => {
		Object.assign(state, defaultState());
	},
	[DASHBOARD_CHARTS_REQUEST]: (state) => {
		state.dashboardCharts = {
			underAnalysisGraph: {
				percentage: 0,
				detail: [],
			},
			financialGraph: {
				percentage: 0,
				detail: [],
			},
			barGraph: {
				percentage: 0,
				detail: [],
			},
		};
		state.isLoadingCharts = 'loading';
	},
	[DASHBOARD_CHARTS_REQUEST_SUCCESS]: (state, data) => {
		state.dashboardCharts = data;
		state.isLoadingCharts = 'success';
	},
	[DASHBOARD_CHARTS_REQUEST_ERROR]: (state) => {
		state.dashboardCharts = {
			underAnalysisGraph: {
				percentage: 0,
				detail: [],
			},
			financialGraph: {
				percentage: 0,
				detail: [],
			},
			barGraph: {
				percentage: 0,
				detail: [],
			},
		};
		state.isLoadingCharts = 'error';
	},
	[DASHBOARD_TABLE_REQUEST]: (state) => {
		//state.dashboardTable = [];
		//state.isLoadingTable = 'loading';
	},
	[DASHBOARD_TABLE_REQUEST_SUCCESS]: (state, data) => {
		state.dashboardTable = data;
		state.isLoadingTable = 'success';
	},
	[DASHBOARD_TABLE_REQUEST_ERROR]: (state) => {
		state.dashboardTable = [];
		state.isLoadingTable = 'error';
	},
};

const actions = {
	[DASHBOARD_CHARTS_REQUEST]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			commit(DASHBOARD_CHARTS_REQUEST);

			let url = `${DASHBOARD_URL}/graphics`;

			if (params) {
				url = `${DASHBOARD_URL}/graphics?${params}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DASHBOARD_CHARTS_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DASHBOARD_CHARTS_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[DASHBOARD_TABLE_REQUEST]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			commit(DASHBOARD_TABLE_REQUEST);

			let url = `${DASHBOARD_URL}/table?page=1&limit=20`;

			if (params) {
				url = `${DASHBOARD_URL}/table?${params}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(DASHBOARD_TABLE_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(DASHBOARD_TABLE_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
