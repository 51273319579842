<template>
	<multiselect
		v-model="value"
		class="generic-multiselect"
		:class="[
			longPlaceholder ? 'pt-1' : '',
			fullWidth ? 'full-width' : '',
			capitalize ? 'capitalize' : '',
			multiple ? 'full-height' : '',
			darkBlue ? 'dark-blue' : '',
		]"
		:options="options"
		:searchable="searchable"
		:close-on-select="true"
		:show-labels="true"
		:multiple="multiple"
		select-label=""
		selected-label=""
		:deselect-label="getTranslatedDeselectLabel()"
		track-by="id"
		label="name"
		:open-direction="direction"
		:placeholder="placeholder"
		:disabled="disabled"
		@open="handleOpen"
		@input="changeInput"
		@change="changeInput"
		@search-change="requestByChange"
	>
		<template slot="caret">
			<span class="arrow-caret" v-if="!plusIcon && showIcon">
				<MultiselectIcon :color="darkBlue ? '#fff' : '#234057'" />
			</span>
			<span class="arrow-caret-plus" v-else-if="plusIcon && showIcon">
				<PlusIcon :color="'#b47d4a'" />
			</span>
		</template>
		<template slot="noResult"> {{ $t('errors.no_options') }} </template>
		<template slot="noOptions"> {{ $t('errors.no_options') }} </template>
		<template v-if="infiniteScroll" slot="afterList">
			<div v-observe-visibility="reachedEndOfList" />
		</template>
	</multiselect>
</template>

<script>
import debounce from 'lodash.debounce';
import MultiselectIcon from '@/components/shared/Icons/MultiselectIcon.vue';
import PlusIcon from '@/components/shared/Icons/PlusIcon.vue';

export default {
	name: 'GenericMultiselect',
	components: { MultiselectIcon, PlusIcon },
	props: {
		title: {
			default: 'generic',
		},
		placeholder: {
			required: true,
		},
		longPlaceholder: {
			default: false,
		},
		options: {
			required: true,
		},
		fullWidth: {
			default: false,
		},
		selectedOption: {
			default: null,
		},
		multiple: {
			default: false,
		},
		plusIcon: {
			default: false,
		},
		type: {
			default: null,
		},
		searchable: {
			default: false,
		},
		capitalize: {
			default: false,
		},
		needType: {
			default: false,
		},
		disabled: {
			default: false,
		},
		showIcon: {
			default: true,
		},
		darkBlue: {
			default: false,
		},
		direction: {
			default: 'bottom',
		},
		infiniteScroll: {
			default: false,
		},
		initialValue: {
			type: Object,
			default: () => {
				return {};
			},
		},
		showPerPage: {
			default: 10,
		},
	},
	data: () => ({
		value: null,
	}),
	mounted() {
		if (this.selectedOption) {
			this.value = { name: this.selectedOption.trim(), id: this.selectedOption.trim() };
		}
	},
	watch: {
		initialValue: {
			handler(newValue) {
				if (newValue.name) {
					this.value = newValue;
				}
			},
			deep: true,
		},
	},
	methods: {
		changeInput() {
			if (!this.multiple && !this.needType) {
				this.$emit('changeInput', this.value);
			} else if (!this.multiple && this.needType) {
				this.$emit('changeInput', { type: this.type, value: this.value });
			} else {
				this.$emit('changeInput', { type: this.type, value: this.value });
			}
		},
		requestByChange: debounce(function (param) {
			if (this.infiniteScroll) {
				this.$emit('requestByChange', param);
			}
		}, 500),
		setValue(param) {
			this.value = param;
		},
		getValue() {
			return this.value;
		},
		reachedEndOfList(reached) {
			if (reached && this.options.length >= this.showPerPage) {
				this.$emit('nextPage');
			}
		},
		getTranslatedDeselectLabel() {
			return this.$t('buttons.remove');
		},
		handleOpen() {
			this.$emit('handleOpen');
		},
	},
};
</script>

<style lang="scss">
@import 'GenericMultiselect.scss';
</style>
