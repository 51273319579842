<template>
	<div id="userTable">
		<div class="table-container" v-if="tableRows.length != 0 && !isLoadingUsers">
			<table class="table">
				<thead class="table__header">
					<tr>
						<th
							v-for="(header, index) in tableHeader"
							:class="header.field == 'edit' || header.field == 'delete' ? 'icon' : ''"
							:key="index"
						>
							<div class="row">
								{{ header.label }}
								<div class="header-icon" @click="sortTable(header.filter)">
									<FilterTable v-if="header.field != 'edit' && header.field != 'delete'" />
								</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="(row, index) in tableRows">
						<tr
							:key="index"
							:class="[index % 2 == 0 ? 'stripped' : '', row.isInvalid ? 'invalid' : '']"
						>
							<td>
								{{ row.name }}
							</td>
							<td>
								{{ row.email }}
							</td>
							<td>
								{{ row.companyName }}
							</td>
							<td :class="row.active ? 'active' : ''">
								{{ row.active ? 'Ativo' : 'Suspenso' }}
							</td>
							<td class="icon edit">
								<div class="icon-edit" @click="editRow(row)">
									<EditIcon />
								</div>
							</td>
							<td class="icon delete">
								<div class="icon-delete" @click="row.active ? deleteRow(row) : activeRow(row)">
									<DeleteIcon  v-if="row.active" />
									<ReactiveIcon v-else />
								</div>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<div class="loader" v-if="isLoadingUsers">
			<SpinnerIcon />
		</div>
		<div class="no__data" v-if="tableRows.length == 0 && !isLoadingUsers">
			{{ $t('errors.empty_data') }}
		</div>
		<WarningModal
			:title="$t('modules.user_management.modal.delete_question')"
			btnLabel="inative"
			:isWeb="isWeb"
			v-show="openWarning"
			@closeWarning="handleWarningModal"
			@confirmWarning="confirmWarning"
		/>
		<ActiveModal
			:title="$t('modules.user_management.modal.reactive_question')"
			v-if="openActive"
			@closeDelete="handleActiveModal"
			@confirmDelete="confirmActive"
		/>
		<Pagination
			ref="pagination"
			v-if="tableRows.length != 0 && !isLoadingUsers"
			:totalPages="pagination.totalPages"
			:currentFromReq="pagination.currentPage"
			@tableRequest="tableRequest"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DELETE_USER_MANAGEMENT, ACTIVE_USER_MANAGEMENT } from '@/store/modules/userManagement/actions';
import FilterTable from '@/components/shared/Icons/FilterTable.vue';
import EditIcon from '@/components/shared/Icons/EditIcon.vue';
import DeleteIcon from '@/components/shared/Icons/DeleteIcon.vue';
import ReactiveIcon from '@/components/shared/Icons/ReactiveIcon.vue';
import Pagination from '@/components/shared/Pagination/Pagination';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import WarningModal from '@/components/shared/Modals/WarningModal/WarningModal';
import ActiveModal from '@/components/shared/Modals/ActiveModal/ActiveModal';

export default {
	name: 'Table',
	components: {
		FilterTable,
		EditIcon,
		DeleteIcon,
		ReactiveIcon,
		Pagination,
		SpinnerIcon,
		WarningModal,
		ActiveModal
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		openWarning: false,
		openActive: false,
		selectedUser: null,
		sort: {
			sortName: null,
			sortEmail: null,
			sortCompanies: null,
			sortActive: null,
		},
	}),
	mounted() {},
	methods: {
		...mapActions('userManagement', {
			deleteUser: DELETE_USER_MANAGEMENT,
			activeUser: ACTIVE_USER_MANAGEMENT,
		}),
		tableRequest(param) {
			this.$emit('changePage', param);
		},
		sortTable(filter) {
			if (this.sort[filter] == null) {
				this.sort = {
					sortName: null,
					sortEmail: null,
					sortCompanies: null,
					sortActive: null,
				};
			}

			this.sort[filter] == 'ASC' ? (this.sort[filter] = 'DESC') : (this.sort[filter] = 'ASC');
			const queryString = this.createQueryString();
			this.$emit('sortTable', queryString);
		},
		createQueryString() {
			const query = new URLSearchParams();
			let { sortName, sortEmail, sortCompanies, sortActive } = this.sort;

			if (sortName) query.set('sortName', sortName);
			if (sortEmail) query.set('sortEmail', sortEmail);
			if (sortCompanies) query.set('sortCompanies', sortCompanies);
			if (sortActive) query.set('sortActive', sortActive);

			return query.toString();
		},
		setPageOne() {
			this.$refs.pagination.setPageOne();
		},
		editRow(row) {
			const user = row;
			this.$emit('editUser', user);
		},
		deleteRow(row) {
			this.handleWarningModal();
			this.selectedUser = row;
		},
		activeRow(row) {
			this.handleActiveModal();
			this.selectedUser = row;
		},
		confirmWarning() {
			this.deleteUser({ vm: this, id: this.selectedUser.uuid }).then(() => {
				this.selectedUser = null;
				this.handleWarningModal();
				this.$emit('refreshUsers');
			});
		},
		confirmActive() {
			this.activeUser({ vm: this, id: this.selectedUser.uuid }).then(() => {
				this.selectedUser = null;
				this.handleActiveModal();
				this.$emit('refreshUsers');
			});
		},
		handleWarningModal() {
			this.openWarning = !this.openWarning;
		},
		handleActiveModal() {
			this.openActive = !this.openActive;
		},
	},
	computed: {
		...mapGetters('userManagement', ['userList', 'isLoadingUsers']),
		tableHeader() {
			return [
				{
					field: 'name',
					filter: 'sortName',
					label: this.$t('modules.user_management.table.name'),
				},
				{
					field: 'email',
					filter: 'sortEmail',
					label: this.$t('modules.user_management.table.email'),
				},
				{
					field: 'code',
					filter: 'sortCompanies',
					label: this.$t('modules.user_management.table.code'),
				},
				{
					field: 'status',
					filter: 'sortActive',
					label: this.$t('modules.user_management.table.status'),
				},
				{
					field: 'edit',
					filter: null,
					label: '',
				},
				{
					field: 'delete',
					filter: null,
					label: '',
				},
			];
		},
		tableRows() {
			const rows = this.userList.items != undefined ? this.userList.items : [];
			return !rows.length ? [] : rows;
		},
		pagination() {
			const pagination =
				this.userList.meta != undefined ? this.userList.meta : { currentPage: 1, totalPages: 1 };

			return pagination;
		},
	},
};
</script>

<style scoped lang="scss">
@import 'Table.scss';
</style>
