<template>
	<div class="detail-card">
		<div class="card-title">
			<span>{{ title }}</span>
			<button class="btn-row" @click="handleList">
				<ArrowDown :class="showList ? 'rotate' : ''" :stroke="'2'" :color="'#234057'" />
			</button>
		</div>
		<transition-group type="transition" :name="'fadeHeight'">
			<div class="content-card-data" v-show="showList" :key="title">
				<div class="card-data" v-for="(val, index) in data" :key="index">
					<span class="card-data__label"
						>{{ $t(`modules.contract_detail.${labelFlag}.${index}`) }}
					</span>
					<span class="card-data__text">{{ verifyData(val, index) }}</span>
				</div>
			</div>
		</transition-group>
		<div class="card-footer" v-if="showList && isContract">
			<span class="sales-label" :class="actionInfoStatus">Sales Confirmation</span>
			<span v-if="isApprovingStatus != 'loading'">
				<div class="btn__container" v-if="!isWeb">
					<button class="btn btn-cloud" @click="handleDownload()" :disabled="downloadDisabled">
						<CloudIcon :color="'#234057'" />
					</button>
					<button class="btn btn-confirm" @click="handleConfirmation()" :disabled="approveEnabled">
						<ConfirmationIcon :color="'#FFFFFF'" />
					</button>
					<button class="btn btn-cancel" @click="handleCancel()" :disabled="approveEnabled">
						<CancelIcon :color="'#FFFFFF'" />
					</button>
				</div>

				<div class="btn__web-container" v-else>
					<div class="btn__web-container__actions">
						<button class="btn btn-cloud" @click="handleDownload()" :disabled="downloadDisabled">
							<CloudIcon :color="'#234057'" />
						</button>
						<span class="btn__web-container__border"></span>
						<button
							class="btn btn-confirm"
							:class="actionInfoStatus"
							@click="handleConfirmation()"
							:disabled="approveEnabled"
						>
							{{
								actionInfo.status != 1
									? $t(`modules.contract_detail.contract.approve`)
									: $t(`modules.contract_detail.contract.approved`)
							}}
						</button>
						<button
							class="btn btn-cancel"
							:class="actionInfoStatus"
							@click="handleCancel()"
							:disabled="approveEnabled"
						>
							{{
								actionInfo.status == 0
									? $t(`modules.contract_detail.contract.disapprove`)
									: $t(`modules.contract_detail.contract.disapproved`)
							}}
						</button>
					</div>
					<div v-if="actionInfo.status != 0" class="btn__web-container__status">
						<div :class="actionInfoStatus">
							{{
								actionInfo.status == 1
									? $t(`modules.contract_detail.contract.approved`)
									: $t(`modules.contract_detail.contract.disapproved`)
							}}
						</div>
						<div v-if="actionInfo.name">
							{{ actionInfo.name }} -
							{{
								formatDateTime(actionInfo.date, $t(`modules.contract_detail.contract.preposition`))
							}}
						</div>
					</div>
				</div>
			</span>
			<div class="loader" v-if="isApprovingStatus == 'loading'">
				<SpinnerIcon />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';
import CancelIcon from '@/components/shared/Icons/CancelIcon.vue';
import CloudIcon from '@/components/shared/Icons/CloudIcon.vue';
import ConfirmationIcon from '@/components/shared/Icons/ConfirmationIcon.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import moment from 'moment';
import api from '@/utils/api';
import {
	CONTRACT_APPROVE_REQUEST,
	CONTRACT_APPROVE_RESET,
} from '@/store/modules/contractManagement/actions';

export default {
	name: 'ContractDetailCard',
	components: {
		ArrowDown,
		CancelIcon,
		CloudIcon,
		ConfirmationIcon,
		SpinnerIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
		title: {
			required: true,
		},
		data: {
			required: true,
		},
		canApprove: {
			type: Boolean,
			default: false,
			required: false,
		},
		actionInfo: {
			required: false,
		},
		canDownload: {
			type: Boolean,
			default: false,
			required: false,
		},
		labelFlag: {
			required: true,
		},
		isContract: {
			required: false,
			default: false,
		},
	},
	data: () => ({
		showList: false,
		approveStatus: true,
	}),
	created() {
		this.resetLoadStatus();
	},
	mounted() {
		this.defineStatus();
	},
	beforeDestroy() {},
	methods: {
		...mapActions('contractManagement', {
			approveContract: CONTRACT_APPROVE_REQUEST,
			resetLoadStatus: CONTRACT_APPROVE_RESET,
		}),
		verifyData(param, flag) {
			let value = param;

			if (
				param !== null &&
				param.toString().includes('-') &&
				new Date(param) !== 'Invalid Date' &&
				!isNaN(new Date(param)) &&
				flag != 'productionDate' &&
				param.split('-')[0].match(/^[0-9]+$/)
			) {
				value = moment(param).format('DD/MM/YYYY');
			} else if (flag == 'totalKg') {
				value =
					param != null
						? param.toLocaleString(undefined)
						: null;
			} else if (flag == 'totalVolume' || flag == 'totalGrossWeight') {
				value =
					param != null
						? param.toLocaleString(undefined, {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
						  })
						: null;
			} else if (flag == 'unitPrice') {
				value = this.formatMoney(value);
			} else if (flag == 'productionDate') {
				value = param != null ? moment(param).format('MM/YYYY') : null;
			}

			return value ? value : '-';
		},
		handleList() {
			this.showList = !this.showList;
		},
		formatTons(param) {
			if (!param) {
				return '0t';
			}
			const tons = parseInt(param) / 1000;

			return `${tons.toFixed(3)}t`;
		},
		formatMoney(value) {
			switch (this.userLang) {
				case 'pt-br':
					return value.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					});
				case 'en-us':
					return value.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
					});
				case 'es-es':
					return value.toLocaleString('en-US', {
						style: 'currency',
						currency: 'EUR',
					});
				default:
					return value;
			}
		},
		formatDateTime(value, preposition) {
			return moment(value).format(`DD/MM/YYYY [${preposition}] H:m:s`);
		},
		handleCancel() {
			this.$swal({
				title: 'Deixe um comentário sobre os motivos da reprovação',
				input: 'textarea',
				inputAttributes: {
					autocapitalize: 'off',
				},
				showCancelButton: true,
				confirmButtonText: 'Confirmar',
				confirmButtonColor: '#00b564',
				cancelButtonText: 'Cancelar',
				cancelButtonColor: '#db1b1b',
				showLoaderOnConfirm: true,

				allowOutsideClick: () => !Swal.isLoading(),
			}).then((result) => {
				if (result.isConfirmed) {
					this.approveContract({
						vm: this,
						id: this.contractId,
						action: 'disapprove',
						note: result.value,
					}).then(() => {
						this.approveStatus = false;
						this.canApprove = false;
						this.actionInfo.status = 2;
					});
				}
			});
		},
		handleDownload() {
			const { http } = api.getInstance();
			const url = `/contracts/downloadDocs?contractId=${this.contractId}`;
			http({
				method: 'GET',
				url: url,
				responseType: 'blob',
			})
				.then((response) => {
					const url = window.URL.createObjectURL(
						new Blob([response.data], { type: response.headers['content-type'] }),
					);
					const link = document.createElement('a');
					const type = response.headers['content-type'];
					link.href = url;
					link.setAttribute('download', type);
					document.body.appendChild(link);
					link.click();
					link.remove();
					this.approveStatus = true;
					if (this.actionInfo.status == 0) {
						this.canApprove = true;
					}
				})
				.catch((error) => {
					this.$toastr.e(error.response.data.message);
				});
		},
		handleConfirmation() {
			this.approveContract({
				vm: this,
				id: this.contractId,
				action: 'approve',
				note: '',
			}).then(() => {
				this.approveStatus = false;
				this.canApprove = false;
				this.actionInfo.status = 1;
			});
		},
		defineStatus() {
			this.approveStatus = this.canApprove;
		},
	},
	computed: {
		...mapGetters('user', ['userLang']),
		...mapGetters('contractManagement', ['isApprovingStatus']),
		contractId() {
			return this.$route.query.contractId;
		},
		approveEnabled() {
			if (this.approveStatus && this.canApprove != false) {
				return null;
			}
			return true;
		},
		downloadDisabled() {
			if (this.canDownload) {
				return null;
			}
			return true;
		},
		actionInfoStatus() {
			if (!this.actionInfo) {
				return null;
			}
			return this.actionInfo.status == 1
				? 'approved'
				: this.actionInfo.status == 2
				? 'disapproved'
				: '';
		},
	},
};
</script>

<style lang="scss">
@import 'ContractDetailCard.scss';
</style>
