<template>
	<GenericMultiselect
		class="doc-contract-multiselect"
		ref="contract"
		:options="contractOptions"
		:placeholder="$t('modules.doc_management.filter.contract')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="true"
		:infiniteScroll="true"
		:type="'contract'"
		:multiple="true"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	DOC_CONTRACT_FILTER_REQUEST,
	SAVE_DOC_CONTRACT_FILTER,
} from '@/store/modules/filtersByModules/docManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'ContractInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('docManagementFilters', {
			getContractFilter: DOC_CONTRACT_FILTER_REQUEST,
			saveContractFilter: SAVE_DOC_CONTRACT_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveContractFilter(param);

			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getContractFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		nextPage() {
			const meta = this.contractFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getContractFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		createQueryString(sequenceType) {
			const query = new URLSearchParams();

			if (sequenceType) query.set('contract', sequenceType);
			if (sequenceType) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.contract.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.contractFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.contract.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('docManagementFilters', [
			'contractFilter',
			'contractFilterMeta',
			'contractFilterCache',
			'isLoadingContractFilter',
			'lastQueryString',
		]),
		contractOptions() {
			const rows = this.contractFilter != undefined ? this.contractFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'ContractInput.scss';
</style>
