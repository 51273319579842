<template>
	<div class="credit">
		<div class="credit-title">
			<span class="credit-title__text">
				{{ $t('modules.financial_management.titles.credit') }}
			</span>
		</div>
		<div class="credit-content">
			<span class="credit-content__text">
				{{ $t('modules.financial_management.titles.limit') }}
			</span>
			<span class="credit-content__text">R$ {{ formatMoney(limit) }}</span>
		</div>
		<div class="credit-content" :class="available < 0 ? 'negative' : ''">
			<span class="credit-content__text">
				{{ $t('modules.financial_management.titles.balance_available') }}
			</span>
			<span class="credit-content__text">R$ {{ formatMoney(available) }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FinancialCredit',
	components: {},
	props: {
		isWeb: {
			required: true,
		},
		limit: {
			required: true,
			default: '00.00',
		},
		available: {
			required: true,
			default: '00.00',
		},
	},
	data: () => ({}),
	created() {},
	mounted() {},
	methods: {
		formatMoney(param) {
			return parseFloat(param)
				.toLocaleString('pt-br', {
					style: 'currency',
					currency: 'BRL',
				})
				.replace('R$', '')
				.trim();
		},
	},
	computed: {},
};
</script>

<style lang="scss">
@import 'FinancialCredit.scss';
</style>
