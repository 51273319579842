<template>
	<GenericMultiselect
		class="load-multiselect"
		ref="load"
		:options="loadOptions"
		:placeholder="$t('modules.doc_instruction.filter.acro_description')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="false"
		:infiniteScroll="true"
		:type="'descriptionValue'"
		:multiple="true"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	DOC_DESCRIPTION_FILTER_REQUEST,
	SAVE_DESCRIPTION_CONTRACT_FILTER,
} from '@/store/modules/filtersByModules/docInstructionFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'DescriptionInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('docInstructionFilters', {
			getDescriptionFilter: DOC_DESCRIPTION_FILTER_REQUEST,
			saveDescriptionFilter: SAVE_DESCRIPTION_CONTRACT_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveDescriptionFilter(param);
			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getDescriptionFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		nextPage() {
			const meta = this.descriptionFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getDescriptionFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		createQueryString(skuDescription) {
			const query = new URLSearchParams();

			if (skuDescription) query.set('skuDescription', skuDescription);
			if (skuDescription) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.load.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.descriptionFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.load.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('docInstructionFilters', [
			'descriptionFilter',
			'descriptionFilterMeta',
			'descriptionFilterCache',
			'lastQueryString',
		]),
		loadOptions() {
			const rows = this.descriptionFilter != undefined ? this.descriptionFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'DescriptionInput.scss';
</style>
