<template>
	<div class="modal">
		<ModalStructure :width="width" :height="height" :scroll="false">
			<template v-slot:title>
				<div class="modal__header">
					<p v-if="isEdit">
						{{ $t('modules.doc_instruction.modal_add_bank.titleEdit') }}
					</p>
					<p v-else>
						{{ $t('modules.doc_instruction.modal_add_bank.title') }}
					</p>
					<hr />
				</div>
			</template>
			<template v-slot:header>
				<div class="modal__body">
					<p>
						{{ $t('modules.doc_instruction.modal_add_bank.text') }}
					</p>
					<button @click="handleCancel">
						{{ $t('buttons.back') }}
					</button>
				</div>
			</template>
		</ModalStructure>
	</div>
</template>

<script>
import ModalStructure from '@/components/shared/Modals/ModalStructure/ModalStructure.vue';

export default {
	name: 'ModalBank',
	components: { ModalStructure },
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		windowWidth: window.innerWidth,
	}),
	methods: {
		handleCancel() {
			this.$emit('closeModal');
		},
	},
	computed: {
		isWeb() {
			return this.windowWidth > 992;
		},
		height() {
			return this.isWeb ? '206px' : '200px';
		},
		width() {
			return this.isWeb ? '487px' : '100%';
		},
	},
};
</script>
<style scoped lang="scss">
@import 'ModalBank.scss';
</style>
