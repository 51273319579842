import { render, staticRenderFns } from "./SendDocumentationIcon.vue?vue&type=template&id=72a73bd8&scoped=true"
import script from "./SendDocumentationIcon.vue?vue&type=script&lang=js"
export * from "./SendDocumentationIcon.vue?vue&type=script&lang=js"
import style0 from "./SendDocumentationIcon.vue?vue&type=style&index=0&id=72a73bd8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a73bd8",
  null
  
)

export default component.exports