<template>
	<div class="operation-card">
		<div class="operation-card__title">
			<span> {{ $t('modules.dashboard.operation_chart.operation_title') }}</span>
		</div>
		<div class="operation-card__chart" v-if="!isLoadingCharts">
			<div
				class="operation-card__chart__item"
				v-for="(chart, index) in chartData"
				:key="chart.status"
			>
				<div class="operation-card__chart__item__indicator">
					<ProductionIcon v-if="chart.status == 'Em produção'" />
					<LoadingIcon v-if="chart.status == 'Em carregamento'" />
					<GateOriginIcon v-if="chart.status == 'Gate in origem'" />
					<WaitPaymentIcon v-if="chart.status == 'Aguarda pré pgto'" />
					<EmbeddedIcon v-if="chart.status == 'Embarcado'" />
					<SendDocumentationIcon v-if="chart.status == 'Documentação enviada'" />
					<DeliveredDocumentationIcon v-if="chart.status == 'Documentação entregue'" />
					<PodDeliveredIcon v-if="chart.status == 'Entregue no POD'" />
					<GateOutIcon v-if="chart.status == 'Gate out destino'" />
					<AvoidReturnIcon v-if="chart.status == 'Vazio devolvido'" />
					<span>
						{{ $t(`modules.dashboard.operation_chart.${getLabel(index)}`) }}
					</span>
				</div>
				<div class="operation-card__chart__bar-chart">
					<div class="operation-card__chart__bar-chart__background"></div>
					<div
						class="operation-card__chart__bar-chart__bar"
						:class="getBackground(index)"
						:style="!isWeb ? `width: ${chart.value}` : `height: ${chart.value}`"
						v-tooltip.top-center="{
							content: getTemplate(formatTons(chart.totalVolume)),
						}"
					></div>
				</div>
			</div>
		</div>
		<div class="loader" v-else>
			<SpinnerIcon />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductionIcon from '@/components/shared/Icons/ChartIndicatorIcons/ProductionIcon.vue';
import LoadingIcon from '@/components/shared/Icons/ChartIndicatorIcons/LoadingIcon.vue';
import GateOriginIcon from '@/components/shared/Icons/ChartIndicatorIcons/GateOriginIcon.vue';
import WaitPaymentIcon from '@/components/shared/Icons/ChartIndicatorIcons/WaitPaymentIcon.vue';
import EmbeddedIcon from '@/components/shared/Icons/ChartIndicatorIcons/EmbeddedIcon.vue';
import SendDocumentationIcon from '@/components/shared/Icons/ChartIndicatorIcons/SendDocumentationIcon.vue';
import DeliveredDocumentationIcon from '@/components/shared/Icons/ChartIndicatorIcons/DeliveredDocumentationIcon.vue';
import PodDeliveredIcon from '@/components/shared/Icons/ChartIndicatorIcons/PodDeliveredIcon.vue';
import GateOutIcon from '@/components/shared/Icons/ChartIndicatorIcons/GateOutIcon.vue';
import AvoidReturnIcon from '@/components/shared/Icons/ChartIndicatorIcons/AvoidReturnIcon.vue';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';

export default {
	name: 'OperationChart',
	components: {
		ProductionIcon,
		LoadingIcon,
		GateOriginIcon,
		WaitPaymentIcon,
		EmbeddedIcon,
		SendDocumentationIcon,
		DeliveredDocumentationIcon,
		PodDeliveredIcon,
		GateOutIcon,
		AvoidReturnIcon,
		SpinnerIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({}),
	methods: {
		getTemplate(amount) {
			const template = `
			<div class="custom-tooltip">
					<span class="custom-tooltip__text" style="color: #234057">
					Total: ${amount}</span>
			</div>`;

			return template;
		},
		getLabel(index) {
			return [
				'in_production',
				'in_charging',
				'gate_in',
				'wait_prepayment',
				'delivered',
				'sent_doc',
				'embedded_doc',
				'delivered_pod',
				'gate_out',
				'empty_returned',
			][index];
		},
		getBackground(index) {
			return [
				'production',
				'loading',
				'gateOrigin',
				'waitPayment',
				'embedded',
				'deliveredDocumentation',
				'sendDocumentation',
				'podDelivered',
				'gateOut',
				'avoidReturn',
			][index];
		},
		formatTons(param) {
			if (!param) {
				return '0t';
			}
			const tons = parseInt(param) / 1000;

			return `${tons.toFixed(3)}t`;
		},
	},
	computed: {
		...mapGetters('dashboard', ['dashboardCharts', 'isLoadingCharts']),
		chartsStructure() {
			return [
				{
					status: 'Em produção',
					value: '0%',
					totalVolume: 0,
				},
				{
					status: 'Em carregamento',
					value: '0%',
					totalVolume: 0,
				},
				{
					status: 'Gate in origem',
					value: '0%',
					totalVolume: 0,
				},
				{
					status: 'Aguarda pré pgto',
					value: '0%',
					totalVolume: 0,
				},
				{
					status: 'Embarcado',
					value: '0%',
					totalVolume: 0,
				},
				{
					status: 'Documentação enviada',
					value: '0%',
					totalVolume: 0,
				},
				{
					status: 'Documentação entregue',
					value: '0%',
					totalVolume: 0,
				},
				{
					status: 'Entregue no POD',
					value: '0%',
					totalVolume: 0,
				},
				{
					status: 'Gate out destino',
					value: '0%',
					totalVolume: 0,
				},
				{
					status: 'Vazio devolvido',
					value: '0%',
					totalVolume: 0,
				},
			];
		},
		chartData() {
			const barChart = this.dashboardCharts.barGraph;

			const chartData =
				barChart && barChart.detail.length
					? barChart.detail.map((item) => {
							return {
								status: item.status,
								value: `${item.percentage.toFixed(0)}%`,
								totalVolume: item.totalVolume,
							};
					  })
					: this.chartsStructure;

			return chartData;
		},
	},
};
</script>

<style scoped lang="scss">
@import 'OperationChart.scss';
</style>
