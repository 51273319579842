export const CLEAR_USER_MANAGEMENT_STATE = 'CLEAR_USER_MANAGEMENT_STATE';

export const USER_MANAGEMENT_REQUEST = 'USER_MANAGEMENT_REQUEST';
export const USER_MANAGEMENT_REQUEST_SUCCESS = 'USER_MANAGEMENT_REQUEST_SUCCESS';
export const USER_MANAGEMENT_REQUEST_ERROR = 'USER_MANAGEMENT_REQUEST_ERROR';

export const CREATE_USER_MANAGEMENT = 'CREATE_USER_MANAGEMENT';
export const UPDATE_USER_MANAGEMENT = 'UPDATE_USER_MANAGEMENT';
export const DELETE_USER_MANAGEMENT = 'DELETE_USER_MANAGEMENT';
export const ACTIVE_USER_MANAGEMENT = 'ACTIVE_USER_MANAGEMENT';
