<template>
	<div class="step" :class="{ selected: getStep === stepNumber }">
		<div class="step-title">
			<div class="step-title__icon">
				<StepOne v-if="stepNumber === 1" />
				<StepTwo v-if="stepNumber === 2" />
				<StepThree v-if="stepNumber === 3" />
				<StepFour v-if="stepNumber === 4" />
			</div>
			<h1 class="step-title__text">{{ title }} {{ stepNumber }}</h1>
		</div>
		<div class="step-body">
			<p class="step-body__text">{{ description }}</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

// Filter List
import StepOne from '@/components/shared/Icons/InstructionStepIcons/StepOne';
import StepTwo from '@/components/shared/Icons/InstructionStepIcons/StepTwo';
import StepThree from '@/components/shared/Icons/InstructionStepIcons/StepThree';
import StepFour from '@/components/shared/Icons/InstructionStepIcons/StepFour';

export default {
	name: 'StepCard',
	components: {
		StepOne,
		StepTwo,
		StepThree,
		StepFour,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		stepNumber: {
			type: Number,
			required: true,
		},
	},
	computed: {
		...mapGetters('docInstruction', ['getStep']),
	},
};
</script>

<style lang="scss" scoped>
@import 'StepCard.scss';
</style>
