import { render, staticRenderFns } from "./TableInvoice.vue?vue&type=template&id=2c346c72&scoped=true"
import script from "./TableInvoice.vue?vue&type=script&lang=js"
export * from "./TableInvoice.vue?vue&type=script&lang=js"
import style0 from "./TableInvoice.vue?vue&type=style&index=0&id=2c346c72&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c346c72",
  null
  
)

export default component.exports