export const CLEAR_ALL_DOC_FILTERS = 'CLEAR_ALL_DOC_FILTERS';

//INIT_DATE
export const SAVE_INIT_DATE_FILTER = 'SAVE_INIT_DATE_FILTER';
export const CLEAR_INIT_DATE_FILTER = 'CLEAR_INIT_DATE_FILTER';

//END_DATE
export const SAVE_END_DATE_FILTER = 'SAVE_END_DATE_FILTER';
export const CLEAR_END_DATE_FILTER = 'CLEAR_END_DATE_FILTER';

export const SAVE_LAST_QUERY_STRING = 'SAVE_LAST_QUERY_STRING';

//POD
export const DOC_POD_FILTER_REQUEST = 'DOC_POD_FILTER_REQUEST';
export const DOC_POD_FILTER_REQUEST_SUCCESS = 'DOC_POD_FILTER_REQUEST_SUCCESS';
export const DOC_POD_FILTER_REQUEST_ERROR = 'DOC_POD_FILTER_REQUEST_ERROR';
export const SAVE_POD_CONTRACT_FILTER = 'SAVE_POD_CONTRACT_FILTER';
export const CLEAR_POD_CONTRACT_FILTER = 'CLEAR_POD_CONTRACT_FILTER';

//DESCRIPTION
export const DOC_DESCRIPTION_FILTER_REQUEST = 'DOC_DESCRIPTION_FILTER_REQUEST';
export const DOC_DESCRIPTION_FILTER_REQUEST_SUCCESS = 'DOC_DESCRIPTION_FILTER_REQUEST_SUCCESS';
export const DOC_DESCRIPTION_FILTER_REQUEST_ERROR = 'DOC_DESCRIPTION_FILTER_REQUEST_ERROR';
export const SAVE_DESCRIPTION_CONTRACT_FILTER = 'SAVE_DESCRIPTION_CONTRACT_FILTER';
export const CLEAR_DESCRIPTION_CONTRACT_FILTER = 'CLEAR_DESCRIPTION_CONTRACT_FILTER';

//CONTRACT
export const DOC_CONTRACT_FILTER_REQUEST = 'DOC_CONTRACT_FILTER_REQUEST';
export const DOC_CONTRACT_FILTER_REQUEST_SUCCESS = 'DOC_CONTRACT_FILTER_REQUEST_SUCCESS';
export const DOC_CONTRACT_FILTER_REQUEST_ERROR = 'DOC_CONTRACT_FILTER_REQUEST_ERROR';
export const SAVE_DOC_CONTRACT_FILTER = 'SAVE_DOC_CONTRACT_FILTER';
export const CLEAR_DOC_CONTRACT_FILTER = 'CLEAR_DOC_CONTRACT_FILTER';
