export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';

//MARKET
export const MARKET_FILTER_REQUEST = 'MARKET_FILTER_REQUEST';
export const MARKET_FILTER_REQUEST_SUCCESS = 'MARKET_FILTER_REQUEST_SUCCESS';
export const MARKET_FILTER_REQUEST_ERROR = 'MARKET_FILTER_REQUEST_ERROR';
export const SAVE_MARKET_FILTER = 'SAVE_MARKET_FILTER';
export const CLEAR_MARKET_FILTER = 'CLEAR_MARKET_FILTER';

//CURRENCY
export const CURRENCY_FILTER_REQUEST = 'CURRENCY_FILTER_REQUEST';
export const CURRENCY_FILTER_REQUEST_SUCCESS = 'CURRENCY_FILTER_REQUEST_SUCCESS';
export const CURRENCY_FILTER_REQUEST_ERROR = 'CURRENCY_FILTER_REQUEST_ERROR';
export const SAVE_CURRENCY_FILTER = 'SAVE_CURRENCY_FILTER';
export const CLEAR_CURRENCY_FILTER = 'CLEAR_CURRENCY_FILTER';

//STATUS
export const STATUS_FILTER_REQUEST = 'STATUS_FILTER_REQUEST';
export const STATUS_FILTER_REQUEST_SUCCESS = 'STATUS_FILTER_REQUEST_SUCCESS';
export const STATUS_FILTER_REQUEST_ERROR = 'STATUS_FILTER_REQUEST_ERROR';
export const SAVE_STATUS_FILTER = 'SAVE_STATUS_FILTER';
export const CLEAR_STATUS_FILTER = 'CLEAR_STATUS_FILTER';

//INVOICE
export const INVOICE_FILTER_REQUEST = 'INVOICE_FILTER_REQUEST';
export const INVOICE_FILTER_REQUEST_SUCCESS = 'INVOICE_FILTER_REQUEST_SUCCESS';
export const INVOICE_FILTER_REQUEST_ERROR = 'INVOICE_FILTER_REQUEST_ERROR';
export const SAVE_INVOICE_FILTER = 'SAVE_INVOICE_FILTER';
export const CLEAR_INVOICE_FILTER = 'CLEAR_INVOICE_FILTER';

//INIT_DATE
export const SAVE_INIT_DATE_FILTER = 'SAVE_INIT_DATE_FILTER';
export const CLEAR_INIT_DATE_FILTER = 'CLEAR_INIT_DATE_FILTER';

//END_DATE
export const SAVE_END_DATE_FILTER = 'SAVE_END_DATE_FILTER';
export const CLEAR_END_DATE_FILTER = 'CLEAR_END_DATE_FILTER';

export const SAVE_LAST_QUERY_STRING = 'SAVE_LAST_QUERY_STRING';
