<template>
	<div v-if="dataStatus == 'loading'" class="default-loader__feedback" :class="color">
		<div></div>
		<div></div>
		<div></div>
	</div>
	<div class="default-loader__error" v-else-if="dataStatus == 'empty'">
		{{ $t('errors.no_items') }}
	</div>
	<div class="default-loader__error" v-else-if="dataStatus == 'error'">Erro ao carregar Itens</div>
</template>

<script>
export default {
	name: 'DefaultLoader',
	props: {
		dataStatus: {
			type: String,
			default: 'loading',
		},
		color: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss">
@import 'DefaultLoader.scss';
</style>
