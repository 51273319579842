<template>
	<div class="filter-container">
		<div class="filter-row">
			<CompanyInput
				ref="companies"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
			<ContractInput
				ref="contracts"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
			<MarketInput
				ref="markets"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
			<POInput
				ref="purchaseOrders"
				:isWeb="isWeb"
				@filterRequest="filterRequest"
				@filterCachedRequest="filterCachedRequest"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContractInput from './Inputs/ContractInput/ContractInput.vue';
import CompanyInput from './Inputs/CompanyInput/CompanyInput.vue';
import MarketInput from './Inputs/MarketInput/MarketInput.vue';
import RegionalInput from './Inputs/RegionalInput/RegionalInput.vue';
import POInput from './Inputs/POInput/POInput.vue';
import ClearIcon from '@/components/shared/Icons/ClearIcon.vue';

export default {
	name: 'FilterList',
	components: {
		ContractInput,
		CompanyInput,
		MarketInput,
		RegionalInput,
		POInput,
		ClearIcon,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		filter: {
			contracts: null,
			companies: null,
			markets: null,
			purchaseOrders: null,
		},
	}),
	methods: {
		filterRequest(param) {
			this.filter[param.type] = param.value ? param.value.map((item) => item.id) : null;

			let queryString = this.createQueryString();

			if (param.value && !param.value.length) {
				this.$emit('setPageOne');
			}

			this.$emit('refreshDashboard', queryString);
		},
		filterCachedRequest() {
			const companyCache = this.companyFilterCache.value;
			const contractCache = this.contractsFilterCache.value;
			const marketCache = this.marketFilterCache.value;
			const purchaseOrder = this.poFilterCache.value;

			if (companyCache && companyCache.length) {
				this.filter['companies'] = companyCache.map((item) => item.id);
			}

			if (contractCache && contractCache.length) {
				this.filter['contracts'] = contractCache.map((item) => item.id);
			}

			if (marketCache && marketCache.length) {
				this.filter['market'] = marketCache.map((item) => item.id);
			}

			if (purchaseOrder && purchaseOrder.length) {
				this.filter['purchaseOrder'] = purchaseOrder.map((item) => item.id);
			}

			let queryString = this.createQueryString();
			this.$emit('refreshDashboard', queryString);
		},
		createQueryString() {
			const query = new URLSearchParams();
			let { contracts, companies, markets, purchaseOrders } = this.filter;

			if (contracts && contracts.length) query.set('contracts', contracts);
			if (companies && companies.length) query.set('companies', companies);
			if (markets && markets.length) query.set('market', markets);
			if (purchaseOrders && purchaseOrders.length) query.set('purchaseOrder', purchaseOrders);

			return query.toString();
		},
		clearFilters() {
			this.$refs.contracts.clearInput();
			this.$refs.companies.clearInput();

			this.filter = {
				contracts: null,
				companies: null,
				markets: null,
				purchaseOrders: null,
			};

			this.$emit('refreshDashboard', null);
		},
		setCachedContracts() {
			this.$refs.contracts.setCachedInput();
		},
		setCachedCompany() {
			this.$refs.companies.setCachedInput();
		},
		setCachedMarket() {
			this.$refs.markets.setCachedInput();
		},
		setCachedPurchaseOrder() {
			this.$refs.purchaseOrders.setCachedInput();
		},
	},
	computed: {
		...mapGetters('filters', [
			'companyFilterCache',
			'contractsFilterCache',
			'marketFilterCache',
			'poFilterCache',
		]),
	},
};
</script>

<style scoped lang="scss">
@import 'FilterList.scss';
</style>
