<template>
	<GenericMultiselect
		class="status-multiselect"
		ref="status"
		:options="statusOptions"
		:placeholder="$t('modules.financial_management.filter.status')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="true"
		:infiniteScroll="true"
		:type="'status'"
		:multiple="true"
		@changeInput="changeInput"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SAVE_STATUS_FILTER } from '@/store/modules/filtersByModules/financialManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'StatusInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('financialManagementFilters', {
			saveStatusFilter: SAVE_STATUS_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveStatusFilter(param);

			this.$emit('filterRequest', param);
		},
		createQueryString(statusName) {
			const query = new URLSearchParams();

			if (statusName) query.set('status', statusName);
			if (statusName) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.status.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.statusFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.status.setValue(cachedFilter);
		},
		getPaymentStatus(status) {
			switch (status) {
				case 'PAGO':
					return this.$t('modules.financial_management.status.paid');
				case 'NÃO PAGO':
					return this.$t('modules.financial_management.status.awaiting_paid');
				case 'NAO PAGO':
					return this.$t('modules.financial_management.status.awaiting_paid');
				default:
					return this.$t('modules.financial_management.status.overdue');
			}
		},
	},
	computed: {
		...mapGetters('financialManagementFilters', [
			'statusFilter',
			'statusFilterMeta',
			'statusFilterCache',
			'isLoadingStatusFilter',
			'lastQueryString',
		]),
		statusOptions() {
			return [
				{
					id: 'PAGO',
					name: this.$t('modules.financial_management.status.paid'),
				},
				{
					id: 'NAO PAGO',
					name: this.$t('modules.financial_management.status.awaiting_paid'),
				},
				{
					id: 'ATRASADO',
					name: this.$t('modules.financial_management.status.overdue'),
				},
			];
		},
	},
};
</script>

<style lang="scss">
@import 'StatusInput.scss';
</style>
