<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 15 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0 0V3.17105L5.31522 8.09648V11.853L9.61811 14V8.09648L14.9333 3.17105V0H0ZM8.35254 7.665V12.1048L6.5808 11.2208V7.665L1.85268 3.28371H13.0807L8.35254 7.665ZM13.6681 2.18914H1.26557V1.09457H13.6681V2.18914Z"
			:fill="color"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#fff',
		},
		height: {
			default: '14',
		},
		width: {
			default: '15',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
