import jwt from 'jsonwebtoken';

export async function isValidJWT(token, key) {
	try {
		const isValidSignature = await jwt.verify(token, key);
		return Boolean(isValidSignature);
	} catch (err) {
		return false;
	}
}
