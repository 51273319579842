<template>
	<div class="finance-card">
		<div class="finance-card__title" v-if="!refreshChart && !isLoadingCharts">
			<span>{{ $t('modules.dashboard.charts.finance_title') }}</span>
		</div>
		<div class="finance-card__chart" v-if="!refreshChart">
			<div class="finance-card__chart-info">
				<span>{{ percentage }}%</span>
			</div>
			<RoundChart :isWeb="isWeb" :data="chartData" :labels="labels" :colors="colors" />
		</div>
		<div class="loader" v-else>
			<SpinnerIcon />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerIcon from '@/components/shared/Icons/SpinnerIcon';
import RoundChart from '@/components/shared/RoundChart/RoundChart.vue';

export default {
	name: 'FinanceChart',
	components: { SpinnerIcon, RoundChart },
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		refreshChart: false,
		labels: [],
	}),
	created() {
		this.labels = [
			this.$t('modules.dashboard.charts.rounded.open'),
			this.$t('modules.dashboard.charts.rounded.open_delay'),
			this.$t('modules.dashboard.charts.rounded.partial_paid'),
			this.$t('modules.dashboard.charts.rounded.paid'),
			this.$t('modules.dashboard.charts.rounded.finished'),
		];
	},
	methods: {},
	computed: {
		...mapGetters('user', ['userLang']),
		...mapGetters('dashboard', ['dashboardCharts', 'isLoadingCharts']),
		colors() {
			const colors = ['#234057', '#B47D4A', '#803423', '#678AA3'];

			return colors;
		},
		chartData() {
			const analysis = this.dashboardCharts.financialGraph;
			const chartData =
				analysis && analysis.detail.length
					? analysis.detail.map((item) => {
							if (item.status != 'Finalizado') return item.percentage;
					  })
					: [];

			return chartData;
		},
		percentage() {
			const analysis = this.dashboardCharts.financialGraph;
			let percentage = analysis && analysis.percentage?.toFixed(2) ? analysis.percentage : 0;
			percentage = percentage < 10 ? `0${percentage?.toFixed(0)}` : percentage?.toFixed(0);

			return percentage;
		},
	},
	watch: {
		userLang() {
			this.refreshChart = true;
			this.labels = [];

			this.labels = [
				this.$t('modules.dashboard.charts.rounded.open'),
				this.$t('modules.dashboard.charts.rounded.open_delay'),
				this.$t('modules.dashboard.charts.rounded.partial_paid'),
				this.$t('modules.dashboard.charts.rounded.paid'),
				this.$t('modules.dashboard.charts.rounded.finished'),
			];

			setTimeout(() => {
				this.refreshChart = false;
			}, 500);
		},
		isLoadingCharts() {
			this.refreshChart = true;

			setTimeout(() => {
				this.refreshChart = false;
			}, 500);
		},
	},
};
</script>

<style scoped lang="scss">
@import 'FinanceChart.scss';
</style>
