<template>
	<GenericMultiselect
		class="product-multiselect"
		ref="product"
		:options="productOptions"
		:placeholder="$t('modules.load_management.filter.product')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="false"
		:infiniteScroll="true"
		:type="'product'"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	PRODUCT_FILTER_REQUEST,
	SAVE_PRODUCT_FILTER,
} from '@/store/modules/filtersByModules/loadManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'ProductInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('loadManagementFilters', {
			getProductFilter: PRODUCT_FILTER_REQUEST,
			saveProductFilter: SAVE_PRODUCT_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveProductFilter(param);

			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getProductFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		nextPage() {
			const meta = this.productFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getProductFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		createQueryString(productNumber) {
			const query = new URLSearchParams();

			if (productNumber) query.set('product', productNumber);
			if (productNumber) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.product.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.productFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.product.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('loadManagementFilters', [
			'productFilter',
			'productFilterMeta',
			'productFilterCache',
			'isLoadingProductFilter',
			'lastQueryString',
		]),
		productOptions() {
			const rows = this.productFilter != undefined ? this.productFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'ProductInput.scss';
</style>
