<template>
	<div id="doc-instruction" class="doc-instruction">
		<Content>
			<template v-slot:header>
				<TemplateHeader
					:hasConsigneeModal="editMode"
					@handleModal="toggleModalNewConsignee"
					@handleNewTemplate="handleNewTemplate"
					@handleCancel="handleCancel"
				/>
			</template>
			<template v-slot:body>
				<TemplateData ref="template-data" :isWeb="isWeb" />
			</template>
		</Content>
		<!-- <MobileStep v-show="!noContractsSelected && !isWeb" /> -->
		<ModalConsignee
			v-if="showNewConsignee"
			:isWeb="isWeb"
			@cancel="toggleModalNewConsignee"
			@confirm="handleNewConsignee"
		/>
	</div>
</template>

<script>
// Layout Components
import Content from '@/components/shared/Content/Content.vue';

// Filter List
import TemplateData from '@/components/modules/DocInstruction/TemplateData/TemplateData.vue';
import TemplateHeader from '@/components/modules/DocInstruction/TemplateData/TemplateHeader/TemplateHeader.vue';
import ModalConsignee from '@/components/modules/DocInstruction/Modals/ModalConsignee/ModalConsignee.vue';

import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import {
	ADD_CLIENT,
	SET_STEP,
	RESET_CONSIGNEE_NOTIFY,
	SET_TEMPLATE_INVOICE_DATA,
	SET_TEMPLATE_PACKING_DATA,
	SET_TEMPLATE_BILL_LANDING_DATA,
	SET_TEMPLATE_HEALTH_CERTIFICATE_DATA,
	SET_TEMPLATE_CERTIFICATE_ORIGIN_DATA,
} from '@/store/modules/docInstruction/actions';

import { SAVE_TEMPLATE } from '@/store/modules/docInstruction/selectInstruction/actions';

export default {
	name: 'NewTemplate',
	components: {
		// Layout Components
		Content,
		TemplateHeader,
		TemplateData,
		ModalConsignee,
	},
	data: () => ({
		windowWidth: window.innerWidth,

		// cached filter handlers
		selectedPods: '',
		selectedDescriptions: '',
		selectedContracts: '',

		editMode: false,
		// modal handler
		showNewConsignee: false,
	}),
	mounted() {
		this.editMode = !!this.$route.params.id;
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		...mapActions('selectInstruction', {
			saveTemplate: SAVE_TEMPLATE,
		}),
		...mapActions('docInstruction', {
			addConsignee: ADD_CLIENT,
			setStep: SET_STEP,
			resetConsigneeNotify: RESET_CONSIGNEE_NOTIFY,
			setTemplateInvoice: SET_TEMPLATE_INVOICE_DATA,
			setTemplatePacking: SET_TEMPLATE_PACKING_DATA,
			setTemplateBillLanding: SET_TEMPLATE_BILL_LANDING_DATA,
			setTemplateHealthCertificate: SET_TEMPLATE_HEALTH_CERTIFICATE_DATA,
			setTemplateCertificateOrigin: SET_TEMPLATE_CERTIFICATE_ORIGIN_DATA,
		}),

		// =========================================
		//     Modal Methods
		// =========================================
		toggleModalNewConsignee() {
			this.showNewConsignee = !this.showNewConsignee;
		},

		handleNewTemplate() {
			// TO-DO integração ATUALIZAR TEMPLATE
			const newTemplateData = this.$refs['template-data'].getForm();

			this.setTemplateInvoice(newTemplateData.invoice);
			this.setTemplatePacking(newTemplateData.packing);
			this.setTemplateBillLanding(newTemplateData.billOfLanding);
			this.setTemplateHealthCertificate(newTemplateData.healthCertificate);
			this.setTemplateCertificateOrigin(newTemplateData.certificateOfOrigin);

			this.templateData.companyId = this.headerCompanyFilterCache.value.id;
			this.templateData.salesContract = this.contract;
			this.templateData.seqSalesContract = this.contractSeq;
			this.saveTemplate({
				cdTemplate: this.templateData.nameTemplate.replace(/\//g, "%2F"),
				nmTemplate: this.templateData.nameTemplate,
				status: true,
				updatedDate: moment().format(),
			});
			this.setStep(3);
			this.$router.push({ path: '/main/docInstruction' });
		},
		handleNewConsignee(form) {
			this.addConsignee(form);
			this.toggleModalNewConsignee();
		},

		handleCancel() {
			this.resetConsigneeNotify();
			this.$refs['template-data'].restoreForm();
			this.$router.go(-1);
		},
	},
	computed: {
		isWeb() {
			return this.windowWidth > 992;
		},
		...mapGetters('filters', ['headerCompanyFilterCache']),
		...mapGetters('docInstruction', ['selectedContractsSequence', 'templateData']),
		noContractsSelected() {
			return !this.selectedContractsSequence.length;
		},
		contract() {
			return this.selectedContractsSequence[0].contractNumber;
		},
		contractSeq() {
			return this.selectedContractsSequence.map((item) => {
				return item.seq;
			});
		},
	},
};
</script>

<style lang="scss">
@import 'NewTemplate.scss';
</style>
