<template>
	<div class="tracking-container">
		<div class="tracking-card">
			<div class="tracking-card__header">
				<span class="tracking-card__header-text">
					{{ $t('modules.contract_detail.card.title_tracking') }}
				</span>
				<div class="open-table" v-if="isWeb">
					<button class="btn-row" @click="handleTable">
						<ArrowDown :class="showTable ? 'rotate' : ''" :stroke="'2'" :color="'#234057'" />
					</button>
				</div>
			</div>
			<transition name="fadeHeight">
				<div
					class="no__data"
					v-show="
						internationalTransport.length == 0 &&
						maritimeTransport.length == 0 &&
						mercosurTransport.length == 0 &&
						showTable &&
						!isLoadingContractItem
					"
				>
					{{ $t('errors.empty_data') }}
				</div>
			</transition>
			<transition name="fadeHeight">
				<div class="tracking-card__body" v-show="showTable">
					<TrackingTable
						:title="$t('modules.contract_detail.table.title_international')"
						:color="'#234057'"
						:isWeb="isWeb"
						:tableData="internationalTransport"
						:labelFlag="'internationalTransport'"
					/>
					<TrackingTable
						:title="$t('modules.contract_detail.table.title_maritime')"
						:color="'#598097'"
						:isWeb="isWeb"
						:tableData="maritimeTransport"
						:labelFlag="'maritimeTransport'"
					/>
					<TrackingTable
						:title="$t('modules.contract_detail.table.title_mercosul')"
						:color="'#AAC3DB'"
						:isWeb="isWeb"
						:tableData="mercosurTransport"
						:labelFlag="'mercosurTransport'"
					/>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import TrackingTable from '../TrackingTable/TrackingTable.vue';
import ArrowDown from '@/components/shared/Icons/ArrowDown.vue';

export default {
	name: 'TrackingCard',
	components: {
		TrackingTable,
		ArrowDown,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		showTable: true,
	}),
	methods: {
		handleTable() {
			this.showTable = !this.showTable;
		},
	},
	computed: {
		...mapGetters('contractDetail', ['contractItemList', 'isLoadingContractItem']),
		internationalTransport() {
			const tracking = this.contractItemList.tracking;
			const tableData = tracking != undefined ? tracking.internationalTransport : [];

			return tableData;
		},
		maritimeTransport() {
			const tracking = this.contractItemList.tracking;
			const tableData = tracking != undefined ? tracking.maritimeTransport : [];

			return tableData;
		},
		mercosurTransport() {
			const tracking = this.contractItemList.tracking;
			const tableData = tracking != undefined ? tracking.mercosurTransport : [];

			return tableData;
		},
	},
};
</script>

<style scoped lang="scss">
@import 'TrackingCard.scss';
</style>
