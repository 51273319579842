export const CLEAR_FINANCE_STATE = 'CLEAR_FINANCE_STATE';

export const LIMIT_REQUEST = 'LIMIT_REQUEST';
export const LIMIT_REQUEST_SUCCESS = 'LIMIT_REQUEST_SUCCESS';
export const LIMIT_REQUEST_ERROR = 'LIMIT_REQUEST_ERROR';

export const PRE_PAYMENT_REQUEST = 'PRE_PAYMENT_REQUEST';
export const PRE_PAYMENT_REQUEST_SUCCESS = 'PRE_PAYMENT_REQUEST_SUCCESS';
export const PRE_PAYMENT_REQUEST_ERROR = 'PRE_PAYMENT_REQUEST_ERROR';

export const INVOICE_REQUEST = 'INVOICE_REQUEST';
export const INVOICE_REQUEST_SUCCESS = 'INVOICE_REQUEST_SUCCESS';
export const INVOICE_REQUEST_ERROR = 'INVOICE_REQUEST_ERROR';

export const FINANCIAL_REPORT_REQUEST = 'FINANCIAL_REPORT_REQUEST';
export const FINANCIAL_REPORT_REQUEST_SUCCESS = 'FINANCIAL_REPORT_REQUEST_SUCCESS';
export const FINANCIAL_REPORT_REQUEST_ERROR = 'FINANCIAL_REPORT_REQUEST_ERROR';
