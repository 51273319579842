<template>
	<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.0135 8.15793L9.52091 0.650521C9.95771 0.233004 10.5387 0 11.1429 0C11.7472 0 12.3281 0.233004 12.7649 0.650521L14.3325 2.21806C14.7618 2.64868 15.0029 3.23197 15.0029 3.84007C15.0029 4.44816 14.7618 5.03145 14.3325 5.46208L13.0209 6.77369L6.82419 12.9695C6.17511 13.618 5.29574 13.9832 4.37821 13.9854H4.30904L1.89678 13.937C1.67359 13.9326 1.46076 13.842 1.30283 13.6842C1.1449 13.5265 1.05411 13.3137 1.04946 13.0905L0.997586 10.6739C0.989555 10.2079 1.07536 9.74494 1.24988 9.31271C1.42441 8.88047 1.6841 8.48775 2.0135 8.15793ZM10.7461 1.87308L10.044 2.57255L12.4104 4.93985L13.1108 4.23952C13.2164 4.13339 13.2756 3.98978 13.2756 3.84007C13.2756 3.69036 13.2164 3.54674 13.1108 3.44062L11.5458 1.87308C11.4381 1.76931 11.2943 1.71134 11.1446 1.71134C10.995 1.71134 10.8512 1.76931 10.7435 1.87308H10.7461ZM2.72681 10.6385L2.7588 12.2216L4.34103 12.2527C4.57353 12.2551 4.80422 12.2117 5.01993 12.1249C5.23563 12.0381 5.43213 11.9097 5.59818 11.7469L11.1836 6.16241L8.82144 3.79597L3.23606 9.38135C3.07115 9.546 2.94108 9.74215 2.85359 9.95813C2.76609 10.1741 2.72298 10.4055 2.72681 10.6385Z"
			:fill="color"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: '#234057',
		},
	},
};
</script>
