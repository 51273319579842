import { render, staticRenderFns } from "./ProductInput.vue?vue&type=template&id=0c736562"
import script from "./ProductInput.vue?vue&type=script&lang=js"
export * from "./ProductInput.vue?vue&type=script&lang=js"
import style0 from "./ProductInput.vue?vue&type=style&index=0&id=0c736562&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports