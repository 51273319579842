<template>
	<GenericMultiselect
		class="po-multiselect"
		ref="purchaseOrders"
		:options="poOptions"
		:placeholder="$t('modules.contract_management.filter.purchaseOrder_placeholder')"
		:fullWidth="!isWeb"
		:darkBlue="!isWeb"
		:searchable="true"
		:needType="true"
		:infiniteScroll="true"
		:multiple="true"
		:type="'purchaseOrders'"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	CONTRACTS_FILTER_REQUEST,
	PO_FILTER_REQUEST,
	SAVE_PO_FILTER,
} from '@/store/common/filters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
export default {
	name: 'POInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('filters', {
			getPoFilter: PO_FILTER_REQUEST,
			getContractFilter: CONTRACTS_FILTER_REQUEST,
			savePoFilter: SAVE_PO_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.savePoFilter(param);
			if (param && param.value.length) {
				this.getPoFilter(param.value);
			} else {
				this.getPoFilter({ vm: this });
			}
			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);
			this.getPoFilter({ vm: this, params: query });
		},
		nextPage() {
			const meta = this.poFilterMeta;
			if (meta.totalPages == this.page) {
				return;
			}
			this.page++;
			let query = this.createQueryString();
			this.getPoFilter({ vm: this, params: query });
		},
		createQueryString(name) {
			const query = new URLSearchParams();
			if (name) query.set('name', name);
			if (name) this.page = 1;
			query.set('limit', '20');
			query.set('page', this.page);
			return query.toString();
		},
		clearInput() {
			this.$refs.purchaseOrders.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.poFilterCache.value;
			if (!cachedFilter || cachedFilter.length <= 0) {
				return;
			}
			this.$refs.purchaseOrders.setValue(cachedFilter);
			this.$emit('filterCachedRequest');
		},
	},
	computed: {
		...mapGetters('filters', ['poFilter', 'poFilterMeta', 'poFilterCache']),
		poOptions() {
			const rows = this.poFilter != undefined ? this.poFilter : [];
			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'POInput.scss';
</style>