<template>
	<div class="eta-load-datepicker">
		<MobileDatePicker
			v-if="!isWeb"
			:buttonLabel="$t('modules.load_management.filter.eta')"
			:title="'realAtbDate'"
			ref="realAtbDate"
			@changeInput="changeInput"
		/>
		<GenericDatepicker
			v-else
			:buttonLabel="$t('modules.load_management.filter.eta')"
			:title="'realAtbDate'"
			:largeLabel="true"
			ref="realAtbDate"
			@changeInput="changeInput"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SAVE_ETA_FILTER } from '@/store/modules/filtersByModules/loadManagementFilters/actions';
import MobileDatePicker from '@/components/shared/MobileDatepicker/MobileDatepicker.vue';
import GenericDatepicker from '@/components/shared/GenericDatepicker/GenericDatepicker.vue';

export default {
	name: 'ETAInput',
	components: {
		MobileDatePicker,
		GenericDatepicker,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({}),
	mounted() {
		const dateCached = this.etaFilterCache.value;

		if (dateCached.id != undefined) {
			this.$refs.realAtbDate.setValue(`${dateCached.id}T00:00:00`);
		}
	},
	methods: {
		...mapActions('loadManagementFilters', { saveRealAtbDate: SAVE_ETA_FILTER }),
		changeInput(param) {
			this.saveRealAtbDate(param);
			this.$emit('filterRequest', param);
		},
		clearInput() {
			this.$refs.realAtbDate.clearValue();
		},
	},
	computed: {
		...mapGetters('loadManagementFilters', ['etaFilterCache']),
	},
};
</script>

<style lang="scss">
@import 'ETAInput.scss';
</style>
