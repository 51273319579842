<template>
	<GenericMultiselect
		class="doc-sequence-multiselect"
		ref="sequence"
		:options="sequenceOptions"
		:placeholder="$t('modules.doc_management.filter.sequence')"
		:fullWidth="!isWeb"
		:searchable="true"
		:needType="true"
		:showIcon="true"
		:infiniteScroll="true"
		:type="'sequence'"
		:multiple="true"
		@changeInput="changeInput"
		@requestByChange="requestByChange"
		@nextPage="nextPage"
	/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
	DOC_SEQUENCE_FILTER_REQUEST,
	SAVE_DOC_SEQUENCE_FILTER,
} from '@/store/modules/filtersByModules/docManagementFilters/actions';
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';

export default {
	name: 'SequenceInput',
	components: {
		GenericMultiselect,
	},
	props: {
		isWeb: {
			required: true,
		},
	},
	data: () => ({
		page: 1,
	}),
	mounted() {},
	methods: {
		...mapActions('docManagementFilters', {
			getSequenceFilter: DOC_SEQUENCE_FILTER_REQUEST,
			saveSequenceFilter: SAVE_DOC_SEQUENCE_FILTER,
		}),
		changeInput(param) {
			this.page = 1;
			this.saveSequenceFilter(param);
			this.$emit('filterRequest', param);
		},
		requestByChange(param) {
			let query = this.createQueryString(param);

			this.getSequenceFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		nextPage() {
			const meta = this.sequenceFilterMeta;

			if (meta.totalPages == this.page) {
				return;
			}

			this.page++;
			let query = this.createQueryString();

			this.getSequenceFilter({
				vm: this,
				params: query,
				skip: false,
				lastQuery: this.lastQueryString,
			});
		},
		createQueryString(sequenceType) {
			const query = new URLSearchParams();

			if (sequenceType) query.set('sequence', sequenceType);
			if (sequenceType) this.page = 1;

			query.set('limit', '20');
			query.set('page', this.page);

			return query.toString();
		},
		clearInput() {
			this.$refs.sequence.setValue(null);
		},
		setCachedInput() {
			const cachedFilter = this.sequenceFilterCache.value;

			if (!cachedFilter || Object.keys(cachedFilter).length <= 0) {
				return;
			}

			this.$refs.sequence.setValue(cachedFilter);
		},
	},
	computed: {
		...mapGetters('docManagementFilters', [
			'sequenceFilter',
			'sequenceFilterMeta',
			'sequenceFilterCache',
			'isLoadingSequenceFilter',
			'lastQueryString',
		]),
		sequenceOptions() {
			const rows = this.sequenceFilter != undefined ? this.sequenceFilter : [];

			return !rows.length ? [] : rows;
		},
	},
};
</script>

<style lang="scss">
@import 'SequenceInput.scss';
</style>
