<template>
	<svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.2746 0.000535892C14.1582 0.000535892 16.0467 0.712341 17.4833 2.14876C18.5979 3.2634 19.262 4.65813 19.5119 6.104C22.3642 6.56713 24.5501 9.02434 24.5501 12.0028C24.5501 15.3108 21.8567 18.004 18.5489 18.004H5.18279C2.32105 18.004 0 15.683 0 12.8212C0 10.0352 2.20415 7.78208 4.96116 7.66371C4.88143 5.67876 5.56283 3.66088 7.07509 2.14823C8.51054 0.71278 10.3914 0 12.2749 0L12.2746 0.000535892ZM12.2746 1.09155C10.6676 1.09155 9.06964 1.69663 7.84199 2.92428C6.42311 4.34317 5.83079 6.26991 6.05188 8.11536C6.07148 8.27157 6.02277 8.42855 5.91831 8.54595C5.81385 8.66354 5.66372 8.73032 5.50637 8.72918H5.18252C2.90628 8.72918 1.09084 10.5446 1.09084 12.8209C1.09084 15.0971 2.90628 16.9125 5.18252 16.9125H18.5487C21.2668 16.9125 23.4587 14.7208 23.4587 12.0025C23.4587 9.43592 21.5032 7.33679 19.0006 7.10956H19.0004C18.7435 7.0873 18.5372 6.88808 18.506 6.63216C18.3487 5.27645 17.7575 3.96565 16.7159 2.92407C15.489 1.69715 13.8818 1.09134 12.2747 1.09134L12.2746 1.09155ZM12.2746 6.54712C12.576 6.54712 12.8202 6.79144 12.8202 7.09263V12.9574L14.9086 11.0565C15.1204 10.8632 15.4918 10.8781 15.6844 11.0906C15.8769 11.3031 15.8659 11.6711 15.6418 11.8664L12.6411 14.5941C12.5631 14.6655 12.4204 14.7277 12.2746 14.7306C12.1298 14.7306 12.0282 14.7039 11.9082 14.5941L8.9075 11.8664C8.69401 11.6748 8.65481 11.3011 8.86487 11.0906C9.06752 10.8876 9.42884 10.8632 9.64064 11.0565L11.7291 12.9574V7.09263C11.7291 6.79124 11.9732 6.54712 12.2746 6.54712H12.2746Z"
			:fill="color"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#234057',
		},
	},
};
</script>
<style lang="scss" scoped>
.path {
	fill: none !important;
}
</style>
