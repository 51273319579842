import tableAR from './contractTable/table-ar';

export default {
	buttons: {
		cancel: 'الالغاء',
		login: 'تسجيل الدخول',
		next: 'التالي',
		ok: 'نعم',
		save: 'احفظ',
		save_password: 'احفظ كلمة المرور',
		filter: 'اختار',
		filters: 'المرشحات',
		clear_filter: 'تحديدات واضحة',
		add: 'اضف',
		delete: 'امسح',
		inative: 'تعطيل',
		edit_column: 'تحرير العمود',
		confirm: 'تأكيد',
		back: 'خلف',
		keep_inactive: 'إبقاء غير نشط',
		keep_active: 'تفعيل المستخدم',
		previous: 'سابق',
		seeMore: 'شاهد المزيد',
		seeLess: 'انظر أقل',
		continue: 'أكمل',
		disable: 'إلغاء التنشيط',
		active: 'تفعيل',
		new_template: 'قالب جديد',
		save_template: 'حفظ القالب',
		change_template: 'تغيير القالب',
		add_bank: 'إضافة بنك',
		notify: 'إشعار',
		edit: 'تعديل',
		disapprove: 'أرفض',
		remove: 'إزالة'
	},
	errors: {
		login_required: 'املأ حقل تسجيل الدخول.',
		password_required: 'املأ حقل كلمة المرور.',
		empty_data: 'لا توجد بيانات ليتم عرضها!',
		dashboard_empty_data: 'لا توجد تحديثات جديدة!',
		fill_fields: 'املأ كل الحقول.',
		email_invalid: 'بريد إلكتروني خاطئ.',
		passwords_rules: 'التأكد من أن كلمة المرور مكونة من 8 أحرف، حرف كبير وحرف صغير ورقم وحرف خاص.',
		passwords_dismatch: 'كلمات المرور غير متطابقة.',
		fixed_column: 'لا يمكن تعطيل هذا العمود.',
		no_options: 'لم يتم العثور على خيارات.',
		no_items: 'لا توجد عناصر لعرضها',
		get_items_error: 'حدث خطأ أثناء تحميل العناصر',
		documents_error: 'لايوجد وثائق لعرضها!',
		template_name_required: 'اسم القالب مطلوب',
	},
	sidebar: {
		user_management: 'إدارة المستخدم',
		contract_management: 'إدارة العقود',
		contract_details: 'تفاصيل العقد',
		dashboard: 'لوحة القيادة',
		load_management: 'إدارة البضائع',
		doc_management: 'إدارة الوثيقة.',
		financial_management: 'ادارة مالية',
		doc_instruction: 'تعليمات المستندات',
	},
	header: {
		user_management: 'إدارةالمستخدم',
		contract_management: 'إدارة العقود',
		contract_details: 'تفاصيل العقد',
		header_contracts: 'العقود',
		header_sequences: 'تسلسلات',
		header_loads: 'حمولة',
		header_po: 'امر الشراء العميلPO',
		dashboard: 'لوحة القيادة',
		load_management: 'إدارة البضائع',
		customer_company: 'عميل',
		doc_management: 'إدارة الوثائق',
		financial_management: 'ادارة مالية',
		doc_instruction: 'تعليمات المستندات',
		new_template: 'قالب جديد',
		edit_template: 'تعديل القالب',
	},
	months: {
		jan: 'يناير',
		feb: 'فبراير',
		mar: 'مارس',
		apr: 'أبريل',
		may: 'مايو',
		jun: 'يونيو',
		jul: 'يوليو',
		aug: 'أغسطس',
		sep: 'سبتمبر',
		oct: 'أكتوبر',
		nov: 'نوفمبر',
		dec: 'ديسمبر',
	},
	contract_status: {
		contract: 'تم إصدار العقد',
		load: 'تم إنشاء التحميل',
		process: 'تم التخطيط للتحميل',
		container: 'تم تحميل الحاوية',
		invoice: 'تم الشحن/إصدار الفاتورة',
		finished: 'تم التوصيل',
	},
	contract_step: {
		creation: 'يتم إنشاء العقد',
		released_seara_restriction: 'الائتمان / التسعير',
		released_customer_restriction: 'تم الإصدار - القيود المفروضة على العملاء',
		released: 'مطلق سراحه',
		programmed: 'مبرمجة',
		planned: 'السفينة المخططة',
		billed: 'تم تحميل الحاوية',
		issued_document: 'تم إصدار الشهادة الصحية',
		gate_in: 'الدخول إلى ميناء المنشأ',
		pre_payment_pending: 'في انتظار الدفع المسبق',
		rid: 'جاهز للتسليم',
		draft_bl: 'مشروع بل',
		boarded: 'تم شحن',
		documentation_issued: 'الوثائق الصادرة',
		documentation_sended: 'تم إرسال المستندات',
		documentation_delivered: 'تم تسليم المستندات',
		shipped_origin: 'صعدت من ميناء المنشأ',
		transshipment_port: 'ميناء الإعادة',
		landed_destination: 'نزلت في ميناء الوجهة',
		gate_out: 'خرجت من ميناء الوجهة',
		empty_return: 'تم إرجاعها فارغة',
		opened: 'افتتح',
		opened_delay: 'متأخر',
		partial_paid: 'مدفوعة جزئيا',
		total_paid: 'مدفوعة بالكامل',
		finished: 'انتهى',
		processing: 'يعالج',
		in_production: 'في الانتاج',
		in_loading: 'تحميل',
		delivered_in_pod: 'تسليمها إلى ميناء الوجهة',
	},
	modules: {
		login: {
			login_label: 'تسجيل الدخول',
			password_label: 'كلمة المرور',
			forgot_password: 'نسيت كلمة السر؟',
			forgot_password_title: 'نسيت كلمة المرور الخاصة بي',
			forgot_password_text:
				'نسيت كلمة السر؟ قم بإبلاغ بريدك الإلكتروني للحصول على رابط لإعادة تعيين كلمة المرور الخاصة بك.',
			email_sended: 'تم إرسال البريد الإلكتروني لإنشاء كلمة المرور الجديدة الخاصة بك.',
			code_sended: 'تم إرسال رمز الأمان',
			verify_email:
				'يرجى التحقق من بريدك الإلكتروني وإدخال رمز الأمان (6 أرقام) لإكمال تسجيل الدخول الخاص بك.',
		},
		resetPassword: {
			new_password_label: 'كلمة المرور الجديدة',
			confirm_password_label: 'تأكيد كلمة المرور الجديدة',
			new_password_title: 'كلمة المرور الجديدة',
			new_password_text: 'الآن يمكنك تغيير كلمة المرور الخاصة بك',
			password_changed_succesfully: 'تم تغيير كلمة المرور بنجاح',
			rules_title: 'معايير كلمة المرور الجديدة',
			rule_uppercase: 'ما لا يقل عن حرف واحد',
			rule_lowercase: 'حرف واحد صغير على الأقل',
			rule_number: 'رقم واحد على الأقل',
			rule_length: ' ٨أحرف على الأقل',
			rule_special: 'حرف خاص واحد على الأقل (!، @، #، $، %، *، .، إلخ).',
		},
		user_management: {
			name_placeholder: 'اسم المستخدم',
			email_placeholder: 'بريد إلكتروني',
			company_placeholder: 'اسم الشركة',
			status_placeholder: 'حالة المستخدم',
			profiles: {
				client: 'عميل',
				intermediary: 'وسيط',
				internal: 'داخلي',
			},
			status: {
				active: 'نشيط',
				suspended: 'غير نشط',
			},
			requests_messages: {
				user_created: 'تم إنشاء المستخدم بنجاح',
				user_updated: 'تم تحديث المستخدم بنجاح',
				user_deleted: 'تم تعطيل المستخدم بنجاح.',
				user_reactived: 'تم إعادة تنشيط المستخدم بنجاح.'
			},
			modal: {
				new_user: 'تسجيل مستخدم جديد',
				edit_user: 'تحرير العضو',
				delete_question: 'هل أنت متأكد من رغبتك في تعطيل هذا المستخدم؟',
				reactive_question: 'هل أنت متأكد أنك تريد إعادة تنشيط هذا المستخدم؟',
				name_label: 'اسم',
				name_placeholder: 'اكمل الاسم',
				company_label: 'الاسم الكامل',
				company_placeholder: 'الشركة (اسم الخيال)',
				email_label: 'بريد إلكتروني',
				email_placeholder: 'بريدك الالكتروني',
				phone_label: 'رقم الهاتف المحمول',
				phone_placeholder: 'رقم هاتف',
				profile_label: 'حساب تعريفي',
				profile_placeholder: 'ملف تعريفي للمستخدم',
				status_label: 'حالة',
				status_placeholder: 'حالة المستخدم',
				company_manager_label: 'إدارة الشركات',
				company_manager_placeholder: 'أدخل اسم الشركة',
				confirmation_active:
					'هل تريد تفعيل هذا المستخدم؟ عند تفعيل المستخدم، سيتلقى رسالة ترحيب عبر البريد الإلكتروني. هل تريد المتابعة؟',
			},
			table: {
				name: 'الاسم',
				email: 'بريد إلكتروني',
				code: 'شفرة',
				status: 'حالة',
			},
		},
		contract_management: {
			table: tableAR,
			filter: {
				start_date: 'الموعد الاول',
				end_date: 'اخر موعد',
				contract_start_date: 'التاريخ المبدئي للعقد',
				contract_end_date: 'التاريخ النهائي للعقد',
				contract_placeholder: 'عقد',
				company_placeholder: 'شركة',
				transport_placeholder: 'سفينة/شاحنة',
				product_placeholder: 'رمز التخزين التعريفي',
				purchaseOrder_placeholder: 'أمر شراء',
				market_placeholder: 'سوق',
			},
			column_modal: {
				title_modal: 'تحرير الأعمدة',
				header_columns: 'عقد',
				details_columns: 'رقم التحميل',
				active_status: 'نشيط',
				disable_status: 'غير نشط',
				exit_question: 'هل تريد الإغلاق دون حفظ التغييرات؟',
			},
			requests_messages: {
				preferences_updated: 'تم حفظ الأعمدة بنجاح',
			},
		},
		contract_detail: {
			card: {
				title_tracking: 'ادارة العقود',
				title_contract: 'عقد',
				title_sequence: 'تسلسل',
				title_load: 'رقم التحميل',
				title_document: 'إدارة الوثائق',
			},
			contract: {
				companyName: 'شركة',
				competenceMonth: 'شهر الكفاءة',
				contractDate: 'تاريخ العقد',
				contractNumber: 'رقم التعاقد',
				incoterm: 'مصطلح التجارة الدولية',
				market: 'سوق',
				paymentMethod: 'شروط الدفع',
				pod: 'بلد القدر',
				priceType: 'عملة',
				purchaseOrder: 'أمر شراء',
				purchaseOrderClient: 'أمر الشراء العميل',
				releaseDate: 'تاريخ الافراج عنه',
				totalPendingVolume: 'الحجم المعلق',
				totalVolume: 'الحجم الكلي',
				traderName: 'التاجر',
				approved: 'موافقة',
				disapproved: 'مرفوض',
				approve: 'موافقة',
				disapprove: 'مرفوض',
				preposition: 'at',
			},
			sequence: {
				brand: 'ماركة',
				cneCode: 'رمز خطاب الاعتماد',
				contractSequence: 'تسلسل',
				dsInternationalOfc: 'وصف البضاعة',
				endShipDate: 'تاريخ الشحن الأخير',
				groupingDescription: 'التجميع',
				permissionImt: 'رخصة استيراد',
				permissionImtDate: 'تاريخ انتهاء الصلاحية (الترخيص)',
				releaseSequenceDate: 'تاريخ الإصدار (التسلسل)',
				sku: 'رمز التخزين التعريفي',
				startShipDate: 'تاريخ الشحن الأول',
				statusSequence: 'تسلسل الحالة',
				totalVolume: 'مقدار',
				unitPrice: 'سعر الوحدة',
				weeksToShip: 'عدد أسابيع الشحن',
				initials: 'أسبوع الشحن',
			},
			load: {
				invoicedDate: 'تاريخ إصدار الفاتورة (المصنع)',
				loadNumber: 'رقم التحميل',
				loadPlace: 'مكان التحميل',
				productionDate: 'تاريخ الإنتاج',
				totalGrossWeight: 'الوزن الإجمالي',
				totalKg: 'الوزن الكلي',
				totalVolume: 'الحجم الكلي',
			},
			documents: {
				awbCourier: 'ساعي أوب',
				billLanding: 'بل / كرت / أوب',
				billLandingDate: 'تاريخ BL/CRT/AWB',
				blDate: 'تاريخ البوليصة',
				courierDate: 'تاريخ البريد السريع',
				csiIssuanceDate: 'تاريخ إصدار الشهادة الصحية',
				invoiceNumber: 'فاتورة',
				courierCompany: 'شركة البريد السريع',
				bankAwbCourier: 'البريد السريع AWB (البنك)',
				bankCourierDate: 'تاريخ البريد السريع (البنك)',
				bankCourierCompany: 'شركة البريد السريع (البنك)',
			},
			table: {
				title_international: 'سفينة/شاحنة',
				title_maritime: 'شركة شحن',
				title_mercosul: 'النقل إلى السوق المشتركة لجنوب أمريكا اللاتينية',
			},
			internationalTransport: {
				boardingType: 'الشحن عن طريق',
				origin: 'ميناء التحميل',
				destination: 'ميناء المقصد',
				expectedEtdDate: 'الوقت المقدر للشحن من ميناء المنشأ',
				expectedEtbDate: 'خدمة النقل البديلة - ميناء التحميل',
				realAtdDate: 'الوقت المقدر للوصول إلى ميناء الوجهة',
				realAtbDate: 'الوقت المقدر للوصول إلى ميناء الوجهة',
			},
			maritimeTransport: {
				provider: 'شركة شحن',
				vessel: 'سفينة / شاحنة',
				container: 'حاوية',
				bookingNumber: 'رقم الحجز',
			},
			mercosurTransport: {
				shippingCompany: 'شركة شحن',
				vehiclePlate: 'لوحة السفينة',
				localization: 'موقع',
				borderCrossingDate: 'تاريخ عبور الحدود',
			},
		},
		dashboard: {
			table: {
				title: 'آخر تحديث للحالة',
				contract: 'عقد',
				sequence: 'تسلسل',
				load: 'رقم التحميل',
				transport: 'سفينة/شاحنة',
				invoice: 'فاتورة',
				code: 'رمز التخزين التعريفي',
				status: 'حالة',
				volume: 'الوزن الصافي',
			},
			charts: {
				analysis_title: 'قيد التحليل',
				finance_title: 'ادارة مالية',
				rounded: {
					in_process: 'قيد_المعالجة',
					release_retrictions: 'تم الإصدار - القيود المفروضة على العملاء',
					released: 'مطلق سراحه',
					open: 'افتتح',
					open_delay: 'متأخر',
					partial_paid: 'مدفوعة جزئيا',
					paid: 'مدفوعة بالكامل',
					finished: 'انتهى',
				},
			},
			operation_chart: {
				operation_title: 'في عملية',
				in_production: 'في الانتاج',
				in_charging: 'تحميل',
				gate_in: 'دخلت ميناء المنشأ',
				wait_prepayment: 'في انتظار الدفع المسبق',
				embedded_doc: 'تم تسليم المستندات',
				sent_doc: 'تم إرسال المستندات',
				delivered: 'شحنها',
				delivered_pod: 'تسليمها إلى ميناء الوجهة',
				gate_out: 'غادرت الحاوية ميناء الوجهة',
				empty_returned: 'تم إرجاعها فارغة',
			},
		},
		load_management: {
			filter: {
				contract: 'العقد / ما يلي.',
				po: 'امر شراء العميل',
				load: 'رقم التحميل',
				transport: 'سفينة/شاحنة',
				container: 'حاوية',
				product: 'وصف السلعة',
				ets: 'الوقت المقدر للتحميل من ميناء المنشاء',
				eta: 'الوقت المقدر للوصول إلى ميناء الوجهة',
			},
			sections: {
				inProduction: 'مبرمجة',
				loading: 'التحميل (المنشأة)',
				gateInOrigin: 'دخلت ميناء المنشأ',
				shipped: 'شحنها',
				deliveredToPOD: 'جراب التسليم',
				gateOutDestination: 'غادرت الحاوية ميناء الوجهة',
			},
			cargoTable: {
				cargo: 'رقم التحميل',
				container: 'حاوية',
				invoice: 'فاتورة',
				vehicle: 'سفينة/شاحنة',
				POD: 'جراب',
				gateIn: 'البوابة في التاريخ',
				ets: 'الوقت المقدر للتحميل من ميناء المنشاء',
				eta: 'الوقت المقدر للوصول إلى ميناء الوجهة',
				gateOut: 'غادرت الحاوية ميناء الوجهة',
				netWeight: 'الوزن الصافي',
			},
			contractTable: {
				sequence: 'تسلسل',
				acronym: 'البند',
				description: 'وصف',
				brand: 'ماركة',
				sif: 'رقم المجزر',
				volume: 'عدد الكراتين',
				weight: 'الوزن الصافي',
			},
			map: {
				contracts: 'انكماش',
				products: 'منتجات',
				loads: 'الأحمال',
				weigth: 'الوزن الصافي',
				awaiting_payment: 'في انتظار الدفع المسبق',
			},
		},
		financial_management: {
			titles: {
				pre_payment: 'الدفع المسبق',
				invoice: 'فاتورة',
				overdue: 'متأخر',
				settle: 'استحقاق الدفع',
				credit: 'الوضع الائتماني',
				limit: 'الحد الحالي',
				balance_available: 'الرصيد متاح',
			},
			filter: {
				market: 'سوق',
				init_date: 'التاريخ الأولي',
				end_date: 'اخر موعد',
				currency: 'عملة',
				status: 'حالة',
			},
			status: {
				paid: 'مدفوع',
				awaiting_paid: 'انتظار الدفع',
				overdue: 'متأخر',
			},
			table: {
				due_date: 'تاريخ الاستحقاق',
				contract: 'عقد',
				invoice: 'فاتورة',
				total_invoice: 'المبلغ الإجمالي',
				po: 'امر شراء',
				currency: 'عملة',
				status: 'حالة',
				total: 'المبلغ الإجمالي',
				payment: 'دفع',
				balance: 'رصيد',
			},
			currency: {
				dolar: 'DOLLAR',
				euro: 'EURO',
				yen: 'YEN',
			},
		},
		doc_management: {
			filter: {
				init_date: 'التاريخ الأولي',
				end_date: 'اخر موعد',
				invoice: 'فاتورة',
				contract: 'عقد',
				sequence: 'تسلسل',
				po: 'امر شراء',
				container: 'حاوية',
				transport: 'سفينة/شاحنة',
				pod: 'ميناء الوجهة',
				load: 'حمولة',
				acronym: 'غرض',
				description: 'وصف البضاعة',
			},
			doc_row: {
				invoice: 'فاتورة',
				vehicle: 'سفينة/شاحنة',
				pol: 'ميناء المنشاء',
				atsPol: 'الوقت المقدر للتحميل من ميناء المنشاء',
				pod: 'ميناء الوجهة',
				etaPod: 'الوقت المقدر للوصول إلى ميناء الوجهة',
				plTon: 'مقدار',
				value: 'القيمة الإجمالية',
				download: 'تحميل',
				payment_status: 'حالة السداد',
				approval_status: 'حالة القبول',
				shipment_status: 'حالة الإرسال (DOX)',
			},
			doc_detail: {
				contract: 'عقد',
				condition: 'مصطلح الدفع',
				term: 'حالة الدفع',
				type: 'وثيقة (محددة)',
				publication: 'تاريخ الرفع',
				responsible: 'تحميل مخصص',
				approval: 'تاريخ الموافقة',
				approver: 'موافقة مخصصة',
				observation: 'تعليقات',
				download: 'تحميل',
				status: 'حالة',
				not_sended: 'لم ترسل',
				pending: 'قيد الانتظار',
				viewed: 'تم مشاهدتها',
				approved: 'موافقة',
				repproved: 'مرفوض',
				cancelled: 'ألغيت',
			},
			doc_table: {
				container: 'حاوية',
				cargo: 'رقم التحميل',
				po: 'امر شراء',
				plton: 'مقدار',
				sequence: 'تسلسل',
				courier: 'شركة البريد السريع',
				awb: 'بوليصة الشحن الجوي',
				shipping: 'تاريخ الإرسال',
				destiny: 'وجهة',
				delivery: 'تاريخ التسليم او الوصول',
				status: 'حالة',
				notSent: 'يشعر',
				delivered: ' في مرحلة انتقالية',
				sent: 'تم الإرسال',
				inTransit: 'في مرحلة انتقالية',
			},
			reproval_modal: {
				title: 'وثيقة (محددة)',
				subtitle: 'الرجاء إدخال تعليق حول رفضك',
				comment: 'تعليقات',
				approved: 'موافقة!',
				repproved: 'مرفوض!',
			},
		},
		doc_instruction: {
			filter: {
				pod: 'ميناء الوجهة',
				acro_description: 'وصف السلعة',
				contract: 'العقد / التسلسل',
			},
			steps: {
				title: 'اسم التعليمات',
				selectTheContract: 'اختر العقد الذي ترغب في اختياره/تسجيل التعليمات المستندية',
				selectDocumentaryInstruction:
					'اختر التعليمات المستندية التي تريد الإشارة إليها أو قم بتسجيل تعليمات جديدة.',
				confirmTheData: 'قم بتأكيد تفاصيل تعليماتك المستندية',
				enterDestination: 'إبلاغ الوجهة (العنوان الكامل) بالمستندات الأصلية',
				notFound: 'غير معثور عليه',
				step_one_description: 'اختر العقد الذي ترغب في اختياره/تسجيل التعليمات المستندية',
				step_two_description:
					'اختر التعليمات المستندية التي تريد الإشارة إليها أو قم بتسجيل تعليمات جديدة.',
				step_three_description: 'قم بتأكيد تفاصيل تعليماتك المستندية',
				step_four_description: 'إبلاغ الوجهة (العنوان الكامل) بالمستندات الأصلية',
				step_by_step: 'خطوة بخطوة',
				mobile_step: 'الخطوة {الخطوة} من 4',
			},
			doc_row: {
				code: 'شفرة',
				name: 'اسم',
				update_date: 'اخر تحديث',
				contract: 'عقد',
				customer: 'عميل',
			},
			doc_table: {
				sequence: 'تسلسل',
				pod: 'ميناء الوجهة',
				acro: 'وصف السلعة',
			},
			inactive_templates_title: 'قوالب غير نشطة',
			select_doc_instruction: 'حدد التعليمات المستندية',
			selected_contracts_title: 'حدد العقود / التسلسل (التسلسلات)',
			consignee_modal: {
				header: 'المرسل إليه بإخطار',
				name: 'الاسم (شركة أم شخص؟)',
				email: 'بريد إلكتروني',
				phone: 'هاتف',
				address: 'عنوان',
				country: 'دولة',
				state: 'ولاية',
				city: 'مدينة',
				zipcode: 'الرمز البريدي',
				tax: 'الرقم الضريبي',
				summary: 'ملخص',
				characters: 'Characters' 
			},
			documents_package: {
				bank: 'اسم البنك',
				otherRecipient: 'جهاز استقبال آخر',
				address: 'عنوان',
				postCode: 'الرمز البريدي',
				fax: 'هاتف',
				taxID: 'الرقم الضريبي',
				receiverName: 'الشخص الذي يمكن الاتصال به',
			},
			modal_add_bank: {
				titleEdit: 'تحرير البنك',
				title: 'إضافة بنك جديد',
				text: 'يرجى الاتصال بالمساعد التجاري لشركة سيارا وإبلاغه بالمعلومات الكاملة للبنك الذي تريد إدراجه.',
			},
			confirm_modal: {
				warning:
					'تحذير! أنت على استعداد لمبادلة العميل بتعليمات مستندية مملوءة جزئيًا، وفي حالة استمرارك، ستفقد التغييرات. هل تريد الاستمرار؟',
			},
			modal_no_data: {
				title: 'Bank',
				text: "No banks were found for your registration. Please contact Seara's Customer Experience team.",
			},
		},
		templateData: {
			inputs: {
				default: 'معيار',
				perOrder: 'نموذج',
				template_title: 'اسم القالب',
			},
			items: {
				invoice: 'فاتورة',
				packing: 'قائمة التعبئة',
				billOfLanding: 'بوليصة الشحن',
				healthCertificate: 'شهادة صحية',
				certificateOfOrigin: 'شهادة المنشأ',
				consignee: 'المرسل إليه',
				notify: 'إشعار',
				buyer: 'مشتر',
				adress: 'عنوان',
				observations: 'معلومات إضافية',
				secondNotify: 'الإخطار الثاني',
				freigtClause: 'بند الشحن',
				telexRelease: 'إرسال برقية',
				responsable: 'المسؤول',
				countriesOfTransit: 'في العبور إلى',
				importer: 'المستورد',
				behalf: 'باسم',
				both: 'كلاهما',
			},
			actions: {
				edit: 'تعديل',
			},
			requests_messages: {
				template_status_change: 'تم تحديث القالب بنجاح',
				template_error: 'يجب تحديد خيار واحد على الأقل لبوليصة الشحن',
				template_send: 'تم إرسال التعليمات المستندية بنجاح',
			},
		},
	},
};
