<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.4375 7.10518C17.1269 7.10518 16.875 7.35708 16.875 7.66768V13.2188C16.875 14.1884 16.4899 15.1184 15.8042 15.8042C15.1185 16.4899 14.1884 16.875 13.2188 16.875H4.78125C3.81162 16.875 2.88157 16.4899 2.19584 15.8042C1.51011 15.1185 1.125 14.1884 1.125 13.2188V4.78125C1.125 3.81162 1.51015 2.88157 2.19584 2.19584C2.88153 1.51011 3.81162 1.125 4.78125 1.125H10.3323C10.6429 1.125 10.8948 0.8731 10.8948 0.5625C10.8948 0.2519 10.6429 0 10.3323 0H4.78125C3.51358 0.00125558 2.29817 0.505366 1.40171 1.40171C0.505366 2.29817 0.00128571 3.51358 0 4.78125V13.2188C0.00125558 14.4864 0.505366 15.7018 1.40171 16.5983C2.29817 17.4946 3.51358 17.9987 4.78125 18H13.2188C14.4864 17.9987 15.7018 17.4946 16.5983 16.5983C17.4946 15.7018 17.9987 14.4864 18 13.2188V7.66768C18 7.51842 17.9407 7.37544 17.8352 7.26998C17.7297 7.16435 17.5868 7.10518 17.4375 7.10518Z"
			fill="#234057"
		/>
		<path
			d="M5.53154 6.75643C5.3115 6.53969 4.95775 6.54094 4.73926 6.7591C4.52063 6.97741 4.51906 7.33116 4.73549 7.55138L8.0317 10.8476H8.03185C8.13732 10.9532 8.28046 11.0127 8.42972 11.0127C8.57897 11.0127 8.72226 10.9532 8.82773 10.8476L16.7815 2.89304C16.8882 2.78773 16.9486 2.64412 16.9493 2.49423C16.9497 2.34419 16.8902 2.20027 16.7842 2.09433C16.6781 1.98824 16.534 1.92907 16.3841 1.92969C16.2341 1.93048 16.0906 1.99106 15.9854 2.09794L8.42906 9.65432L5.53154 6.75643Z"
			fill="#234057"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		color: {
			type: String,
			default: '#234057',
		},
	},
};
</script>
<style lang="scss" scoped></style>
