export const CLEAR_LOGIN_STATE = 'CLEAR_LOGIN_STATE';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const TWO_WAY_SUCCESS = 'TWO_WAY_SUCCESS';
export const TWO_WAY_REQUEST = 'TWO_WAY_REQUEST';
export const TWO_WAY_FAILED = 'TWO_WAY_FAILED';

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const NEW_PASSWORD = 'NEW_PASSWORD';
export const LOGOUT = 'LOGOUT';
