<template>
	<div :class="{ error: hasError }">
		<slot></slot>
	</div>
</template>

<script>

export default {
	name: 'GenericErrorMessage',
	props: {
		hasError: {
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'GenericErrorMessage.scss';
</style>
