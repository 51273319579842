import {
	CLEAR_USER_MANAGEMENT_STATE,
	USER_MANAGEMENT_REQUEST,
	USER_MANAGEMENT_REQUEST_SUCCESS,
	USER_MANAGEMENT_REQUEST_ERROR,
	CREATE_USER_MANAGEMENT,
	UPDATE_USER_MANAGEMENT,
	DELETE_USER_MANAGEMENT,
	ACTIVE_USER_MANAGEMENT
} from './actions';
import api from '@/utils/api';

const { http } = api.getInstance();
const USER_MANAGEMENT_URL = '/users';

const defaultState = () => ({
	userList: [],
	isLoadingUsers: '',
});

const state = defaultState();

const getters = {
	userList: (state) => state.userList,
	isLoadingUsers: (state) => state.isLoadingUsers === 'loading',
};

const mutations = {
	[CLEAR_USER_MANAGEMENT_STATE]: (state) => {
		Object.assign(state, defaultState());
	},
	[USER_MANAGEMENT_REQUEST]: (state) => {
		// state.userList = [];
		// state.isLoadingUsers = 'loading';
	},
	[USER_MANAGEMENT_REQUEST_SUCCESS]: (state, data) => {
		state.userList = data;
		state.isLoadingUsers = 'success';
	},
	[USER_MANAGEMENT_REQUEST_ERROR]: (state) => {
		state.userList = [];
		state.isLoadingUsers = 'error';
	},
};

const actions = {
	[USER_MANAGEMENT_REQUEST]: ({ commit }, { vm, params }) => {
		return new Promise((resolve) => {
			commit(USER_MANAGEMENT_REQUEST);

			let url = `${USER_MANAGEMENT_URL}?page=1&limit=10`;

			if (params) {
				url = `${USER_MANAGEMENT_URL}?${params}`;
			}

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					commit(USER_MANAGEMENT_REQUEST_SUCCESS, response.data);
					resolve(response);
				})
				.catch((error) => {
					commit(USER_MANAGEMENT_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CREATE_USER_MANAGEMENT]: async ({ dispatch }, { vm, body }) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'POST',
				url: `${USER_MANAGEMENT_URL}`,
				data: body,
			})
				.then((response) => {
					resolve(response);
					vm.$toastr.s(vm.$t('modules.user_management.requests_messages.user_created'));
				})
				.catch((error) => {
					reject(error);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[UPDATE_USER_MANAGEMENT]: async ({ dispatch }, { vm, id, body }) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'PATCH',
				url: `${USER_MANAGEMENT_URL}/${id}`,
				data: body,
			})
				.then((response) => {
					resolve(response);
					vm.$toastr.s(vm.$t('modules.user_management.requests_messages.user_updated'));
				})
				.catch((error) => {
					reject(error);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[DELETE_USER_MANAGEMENT]: async ({ dispatch }, { vm, id }) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'DELETE',
				url: `${USER_MANAGEMENT_URL}/${id}`,
			})
				.then((response) => {
					resolve(response);
					vm.$toastr.s(vm.$t('modules.user_management.requests_messages.user_deleted'));
				})
				.catch((error) => {
					reject(error);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[ACTIVE_USER_MANAGEMENT]: async ({ dispatch }, { vm, id }) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'PATCH',
				url: `${USER_MANAGEMENT_URL}/active/${id}`,
			})
				.then((response) => {
					resolve(response);
					vm.$toastr.s(vm.$t('modules.user_management.requests_messages.user_reactived'));
				})
				.catch((error) => {
					reject(error);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
