import {
	GET_ACTIVED_TEMPLATES,
	GET_DISABLED_TEMPLATES,
	TEMPLATES_ACTIVED_REQUEST_SUCCESS,
	TEMPLATES_DISABLED_REQUEST_SUCCESS,
	TEMPLATES_ACTIVED_REQUEST_ERROR,
	TEMPLATES_DISABLED_REQUEST_ERROR,
	CLEAN_ALL_TEMPLATES,
	SAVE_TEMPLATE,
	CLEAN_TEMPLATE,
	CHANGE_TEMPLATE_STATUS,
} from './actions';

import api from '@/utils/api';

const { http } = api.getInstance();

const DOCUMENT_TEMPLATES_URL = '/instruction-templates';

const defaultState = () => ({
	templateActivedList: [],
	templateActivedListPage: 1,
	templateActivedListMax: 0,
	isLoadingTemplateActivedList: 'empty',

	templateDisabledList: [],
	templateDisabledListPage: 1,
	templateDisabledListMax: 0,
	isLoadingTemplateDisabledList: 'success',

	selectedTemplateData: null,
});

const state = defaultState();

const getters = {
	getTemplateActivedListData: (state) => state.templateActivedList,
	templateActivedListPage: (state) => state.templateActivedListPage,
	templateActivedListMax: (state) => state.templateActivedListMax,
	isLoadingTemplateActivedList: (state) => state.isLoadingTemplateActivedList,

	getTemplateDisabledListData: (state) => state.templateDisabledList,
	templateDisabledListPage: (state) => state.templateDisabledListPage,
	templateDisabledListMax: (state) => state.templateDisabledListMax,
	isLoadingTemplateDisabledList: (state) => state.isLoadingTemplateDisabledList,

	selectedTemplateData: (state) => state.selectedTemplateData,
};

const mutations = {
	[SAVE_TEMPLATE]: (state, data) => {
		state.selectedTemplateData = data;
	},
	[CLEAN_TEMPLATE]: (state, data) => {
		state.selectedTemplateData = null;
	},
	[GET_ACTIVED_TEMPLATES]: (state) => {
		state.isLoadingTemplateActivedList = 'loading';
	},
	[GET_DISABLED_TEMPLATES]: (state) => {
		state.isLoadingTemplateDisabledList = 'loading';
	},
	[TEMPLATES_ACTIVED_REQUEST_SUCCESS]: (state, data) => {
		data.items.forEach((item) => {
			state.templateActivedList.push(item);
		});
		state.templateActivedListPage = data.meta.currentPage;
		state.templateActivedListMax = data.meta.totalPages;

		state.isLoadingTemplateActivedList = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingTemplateActivedList = 'empty';
		}
	},
	[TEMPLATES_DISABLED_REQUEST_SUCCESS]: (state, data) => {
		data.items.forEach((item) => {
			state.templateDisabledList.push(item);
		});
		state.templateDisabledListPage = data.meta.currentPage;
		state.templateDisabledListMax = data.meta.totalPages;

		state.isLoadingTemplateDisabledList = 'success';
		if (data.meta.totalItems == 0) {
			state.isLoadingTemplateDisabledList = 'empty';
		}
	},
	[TEMPLATES_ACTIVED_REQUEST_ERROR]: (state) => {
		state.templateActivedList = [];
		state.isLoadingTemplateActivedList = 'error';
	},
	[TEMPLATES_DISABLED_REQUEST_ERROR]: (state) => {
		state.templateDisabledList = [];
		state.isLoadingTemplateDisabledList = 'error';
	},
	[CLEAN_ALL_TEMPLATES]: (state, data) => {
		state.templateActivedList = [];
		state.templateDisabledList = [];

		state.templateActivedListPage = 1;
		state.templateActivedListMax = 0;
		state.templateDisabledListPage = 1;
		state.templateDisabledListMax = 0;

		state.isLoadingTemplateActivedList = '';
		state.isLoadingTemplateDisabledList = '';
	},
	[CHANGE_TEMPLATE_STATUS]: (state, data) => {
		state.isLoadingTemplateActivedList = '';
		state.isLoadingTemplateDisabledList = '';
	},
};

const actions = {
	[SAVE_TEMPLATE]: ({ commit }, data) => {
		if (data) {
			commit(SAVE_TEMPLATE, data);
		}
	},
	[CLEAN_TEMPLATE]: ({ commit }, data) => {
		commit(CLEAN_TEMPLATE);
	},
	[GET_ACTIVED_TEMPLATES]: ({ commit }, { vm, companyId, page = 1, limit = 2 }) => {
		return new Promise((resolve) => {
			commit(GET_ACTIVED_TEMPLATES);

			let url = `${DOCUMENT_TEMPLATES_URL}?companyId=${companyId}&status=true&page=${page}&limit=${limit}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					const { data } = response;
					commit(TEMPLATES_ACTIVED_REQUEST_SUCCESS, data);
					resolve(response);
				})
				.catch((error) => {
					commit(TEMPLATES_ACTIVED_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[GET_DISABLED_TEMPLATES]: ({ commit }, { vm, companyId, page = 1, limit = 2 }) => {
		return new Promise((resolve) => {
			commit(GET_DISABLED_TEMPLATES);

			let url = `${DOCUMENT_TEMPLATES_URL}?companyId=${companyId}&status=false&page=${page}&limit=${limit}`;

			http({
				method: 'GET',
				url: url,
			})
				.then((response) => {
					const { data } = response;
					commit(TEMPLATES_DISABLED_REQUEST_SUCCESS, data);
					resolve(response);
				})
				.catch((error) => {
					commit(TEMPLATES_DISABLED_REQUEST_ERROR);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
	[CLEAN_ALL_TEMPLATES]: ({ commit }, data) => {
		commit(CLEAN_ALL_TEMPLATES, data);
	},
	[CHANGE_TEMPLATE_STATUS]: ({ commit, dispatch }, { vm, cdTemplate, data }) => {
		commit(CHANGE_TEMPLATE_STATUS);
		return new Promise((resolve) => {
			let url = `${DOCUMENT_TEMPLATES_URL}/update/${cdTemplate}`;

			http({
				method: 'PATCH',
				url: url,
				data: data,
			})
				.then((response) => {
					resolve(true);
					vm.$toastr.s(vm.$t('modules.templateData.requests_messages.template_status_change'));
				})
				.catch((error) => {
					resolve(false);
					vm.$toastr.e(error.response.data.message);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
