<template>
	<div class="packingBlock-container">
		<TemplateHeaderItem
			:title="$t('modules.templateData.items.healthCertificate')"
			:showBell="false"
			@handleCollapse="handleCollapse"
		/>
		<div class="healthBlock-container__content" :class="!isCollapsed ? 'open' : ''">
			<div class="healthBlock-container__itens">
				<div class="healthBlock-container__itens--item">
					<h5>{{ $t('modules.templateData.items.consignee') }}</h5>

					<div class="healthBlock-container__itens--title">{{ templateData.consignee.name }}</div>
					<div class="healthBlock-container__itens--info">
						{{ templateData.consignee.address }}
					</div>
					<div class="healthBlock-container__actions">
						<div class="healthBlock-container__actions--date" @click="handleEdit">
							<EditIcon color="#aac3db" />
							10/10/2023
						</div>
						<div class="healthBlock-container__actions--btn" @click="handleEdit">
							<EditIcon />
							{{ $t('modules.templateData.actions.edit') }}
						</div>
					</div>
				</div>
				<div class="healthBlock-container__itens--item">
					<h5>{{ $t('modules.templateData.items.responsable') }}</h5>

					<div class="healthBlock-container__itens--title">
						{{ templateData.responsable.name }}
					</div>
					<div class="healthBlock-container__itens--info">
						{{ templateData.responsable.address }}
					</div>
					<div class="healthBlock-container__actions">
						<div class="healthBlock-container__actions--date" @click="handleEdit">
							<EditIcon color="#aac3db" />
							10/10/2023
						</div>
						<div class="healthBlock-container__actions--btn" @click="handleEdit">
							<EditIcon />
							{{ $t('modules.templateData.actions.edit') }}
						</div>
					</div>
				</div>
				<!-- <div class="healthBlock-container__itens--item">
					<h5>{{ $t('modules.templateData.items.countriesOfTransit') }}</h5>

					<div class="healthBlock-container__itens--info">
						{{ templateData.countriesOfTransit }}
					</div>
					<div class="healthBlock-container__actions">
						<div class="healthBlock-container__actions--date" @click="handleEdit">
							<EditIcon color="#aac3db" />
							10/10/2023
						</div>
						<div class="healthBlock-container__actions--btn" @click="handleEdit">
							<EditIcon />
							{{ $t('modules.templateData.actions.edit') }}
						</div>
					</div>
				</div> -->
				<div class="healthBlock-container__itens--item">
					<h5>{{ $t('modules.templateData.items.observations') }}</h5>

					<div class="healthBlock-container__itens--info">
						{{ templateData.observations }}
					</div>
					<div class="healthBlock-container__actions">
						<div class="healthBlock-container__actions--date" @click="handleEdit">
							<EditIcon color="#aac3db" />
							10/10/2023
						</div>
						<div class="healthBlock-container__actions--btn" @click="handleEdit">
							<EditIcon />
							{{ $t('modules.templateData.actions.edit') }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import GenericMultiselect from '@/components/shared/GenericMultiselect/GenericMultiselect.vue';
import BellTemplateIcon from '@/components/shared/Icons/BellTemplateIcon.vue';
import EditIcon from '@/components/shared/Icons/EditIcon.vue';
import TemplateHeaderItem from '@/components/modules/DocInstruction/TemplateData/TemplateHeaderItem/TemplateHeaderItem.vue';

export default {
	name: 'InvoiceBlock',
	components: {
		GenericMultiselect,
		BellTemplateIcon,
		EditIcon,
		TemplateHeaderItem,
	},
	props: {
		templateData: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data: () => ({
		isCollapsed: false,
	}),
	methods: {
		handleCollapse() {
			this.isCollapsed = !this.isCollapsed;
		},
		handleEdit() {
			this.$emit('editData');
		},
	},
	computed: {},
};
</script>

<style lang="scss">
@import 'HealthBlock.scss';
</style>
